import { faFileExclamation, faLocationPinSlash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'

export interface ICalendarStripIssues {
  unassignedPackagesCount?: number
  labelIssuesCount?: number
}

const CalendarStripIssues: FC<ICalendarStripIssues> = ({
  unassignedPackagesCount = 0,
  labelIssuesCount = 0,
}) => (
  <div className="block md:flex gap-1 text-red-500" data-testid="shipping-issue">
    {unassignedPackagesCount > 0 && (
      <div className="flex items-center gap-0.5" data-testid="unassigned-packages-count">
        <FontAwesomeIcon icon={faLocationPinSlash} className="fa-fw" />
        {Intl.NumberFormat( 'en-US' ).format( unassignedPackagesCount )}
      </div>
    )}
    {labelIssuesCount > 0 && (
      <div className="flex items-center gap-0.5" data-testid="label-issues-count">
        <FontAwesomeIcon icon={faFileExclamation} className="fa-fw" />
        {Intl.NumberFormat( 'en-US' ).format( labelIssuesCount )}
      </div>
    )}
  </div>
)

export default CalendarStripIssues
