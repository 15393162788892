import { loader } from 'graphql.macro'
import {
  IGetCustomerChoicesQuery,
  IGetCustomerChoicesQueryVariables,
} from 'src/graphql/queries/getCustomerChoices.types'
import { RequestPolicy, useQuery } from 'urql'
import useAppParams from './useAppParams'

interface IProps {
  packageIds?: string[] | null
  requestPolicy?: RequestPolicy
  toShipOn?: string | null
}

const getCustomerChoices = loader( 'src/graphql/queries/getCustomerChoices.graphql' )

export const useGetCustomerChoices = ({
  packageIds,
  requestPolicy = 'cache-and-network',
  toShipOn: overrideToShipOn,
}: IProps ) => {
  const { mdashAccountId, toShipOnAsString: toShipOn } = useAppParams()

  const [{ data, error, fetching }] = useQuery<
    IGetCustomerChoicesQuery,
    IGetCustomerChoicesQueryVariables
  >({
    query: getCustomerChoices,
    variables: {
      mdashAccountId,
      toShipOn: overrideToShipOn || toShipOn,
      packageIds,
    },
    requestPolicy,
    pause: Number( packageIds?.length || 0 ) < 1,
  })

  return { data, error, fetching }
}
