import { FC, ReactNode } from 'react'
import classNames from 'classnames'
import { NavLink, NavLinkProps } from 'react-router-dom'
import Button from '../Button'

interface IMainNavLink extends NavLinkProps {
  /**
   * Force the nav link to be active
   */
  forceActive?: boolean

  /**
   * Whether or not to display the link as a button.
   */
  isButton?: boolean

  /**
   * Class names to pass to the containing element.
   */
  className?: string
}

const MainNavLink: FC<IMainNavLink> = ({
  children,
  isButton,
  className,
  forceActive,
  ...props
}) => (
  <NavLink
    className={({ isActive }) =>
      classNames(
        'inline-block relative after:content-[""] after:block after:w-full after:h-1 after:rounded-xl after:absolute after:left-0 after:-bottom-[2px] after:hover:bg-gb-gray-600',
        {
          'text-sm px-3 py-2 font-semibold hover:no-underline': !isButton,
          'pt-1 pb-1': isButton,
          'text-gb-gray-800 hidden md:flex': !forceActive && !isActive,
          'active text-gb-gray-900 md:after:bg-gb-blue-600 md:after:hover:bg-gb-blue-600 md:flex':
            forceActive || isActive,
        },
        className
      )
    }
    {...props}
  >
    {isButton ? (
      <Button className="py-1 px-2 rounded-md">{children as ReactNode}</Button>
    ) : (
      children
    )}
  </NavLink>
)

MainNavLink.displayName = 'MainNavLink'

export default MainNavLink
