import { FC } from 'react'
import { camelCase } from 'lodash'

import Alert, { AlertType } from 'src/components/01-atoms/Alert'

export interface IAlertListProps {
  type?: AlertType
  alerts: string[]
  prefaceText?: string
  className?: string | string[]
}

const AlertList: FC<IAlertListProps> = ({ type, alerts, prefaceText, className = '' }) => (
  <Alert type={type} className={className}>
    {prefaceText || 'The following occurred:'}
    <ul className="pl-10 list-disc pt-2">
      {alerts.map(( message ) => (
        <li key={`${type}-${camelCase( message )}`}>{message}</li>
      ))}
    </ul>
  </Alert>
)

export default AlertList
