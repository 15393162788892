import { FC } from 'react'
import Table from 'src/components/02-molecules/Table'
import { getIssuePercentageValue } from './utils/issuePercentage'

import './styles.css'

export interface IIssuesTableProps {
  issueItems: {
    type: string
    numberOfIssues?: number
    percentOfPackagesWithIssues?: number
  }[]
}

const IssuesTable: FC<IIssuesTableProps> = ({ issueItems }) => {
  const tableHeaders = [{ name: 'Type' }, { name: '# Packages' }, { name: '% of Packages' }]
  const columnWidths = [{ className: 'lg:w-1/2' }, {}, {}, {}]

  const tableCellClasses = 'flex gap-x-4 first-of-type:pt-4 pb-4 pl-4 pr-4 lg:p-4 lg:table-cell'

  return (
    <Table
      tableClasses="issues"
      headers={tableHeaders}
      borderRule="headers-only"
      columnWidths={columnWidths}
    >
      {issueItems &&
        issueItems.map(({ type, numberOfIssues, percentOfPackagesWithIssues }, index ) => {
          const percentDisplay = getIssuePercentageValue( percentOfPackagesWithIssues )
          return (
            <tr
              key={`issues-${type}`}
              className="flex flex-col even:bg-gb-gray-50 odd:bg-white lg:table-row"
              data-testid={`issues-row-${index}`}
            >
              <td className={tableCellClasses}>
                <div className="font-bold w-1/3 lg:hidden">{tableHeaders[0].name}</div>
                {type}
              </td>
              <td className={tableCellClasses}>
                <div className="font-bold w-1/3 lg:hidden">{tableHeaders[1].name}</div>
                {numberOfIssues}
              </td>
              <td className={tableCellClasses}>
                <div className="font-bold w-1/3 lg:hidden">{tableHeaders[2].name}</div>
                {percentDisplay}
              </td>
            </tr>
          )
        })}
    </Table>
  )
}

export default IssuesTable
