import { FC } from 'react'
import classNames from 'classnames'
import TabsNav from 'src/components/02-molecules/TabsNav'
import { useLocation } from 'react-router-dom'

const IssuesTabs: FC = () => {
  const location = useLocation()
  const tabs = [
    {
      text: 'Missed Shipments',
      key: 'missed shipments',
      isActive:
        !location.pathname.includes( 'reshipments' ) && !location.pathname.includes( 'refunds' ),
      to: 'missed_shipments',
    },
    {
      text: 'Reshipments',
      key: 'reshipments',
      to: 'reshipments',
      isActive: location.pathname.includes( 'reshipments' ),
    },
    {
      text: 'Refunds',
      key: 'refunds',
      to: 'refunds',
      isActive: location.pathname.includes( 'refunds' ),
    },
  ]

  const { text } =
    tabs.filter(({ text }) => location.pathname.includes( text.toLowerCase()))[0] ?? ''
  return (
    <div className={classNames( 'relative' )} data-testid="issues-tab-navigation">
      <TabsNav mobileTriggerTitle={text} className="mt-5 mb-4" tabs={tabs} />
    </div>
  )
}

export default IssuesTabs
