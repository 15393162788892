import { FC, PropsWithChildren } from 'react'
import classNames from 'classnames'

interface IContainerProps {
  isCentered?: boolean
  className?: string
}

const Container: FC<PropsWithChildren<IContainerProps>> = ({
  isCentered = true,
  className = '',
  children,
}) => (
  <section
    className={classNames(
      {
        'screen:mx-auto': isCentered,
      },
      className,
      'screen:container screen:px-4 screen:md:px-6'
    )}
  >
    {children}
  </section>
)

export default Container
