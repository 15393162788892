import { ReactEventHandler, FC, useState, useEffect, useRef } from 'react'
import classNames from 'classnames'

interface IShippingLabelProps {
  /**
   * The path to the image.
   */
  imgPath: string
  /**
   * Method to run after the image loads.
   */
  handleLoad?: ReactEventHandler<HTMLImageElement>
  /**
   * Method to run if the image returns an error.
   */
  handleError?: ReactEventHandler<HTMLImageElement>

  /**
   * Additional classnames for the image element.
   */
  className?: string

  /**
   * The rule that decides whether ot not to rotate the image based on dimensions. Used primarily
   * for US Letter Slip N Labels where dry ice stickers are added.
   * @option 'portrait' - Rotate the image if the height is greater than the width.
   * @option 'landscape' - Rotate the image if the width is greater than the height.
   */
  forceOrientation?: 'portrait' | 'landscape'

  /**
   * The width of the image in inches.
   */
  prescribedWidthInInches?: number

  /**
   * The height of the image in inches.
   */
  prescribedHeightInInches?: number
}

const ShippingLabel: FC<IShippingLabelProps> = ({
  imgPath,
  forceOrientation,
  prescribedWidthInInches,
  prescribedHeightInInches,
  handleLoad = () => {},
  handleError = () => {},
  className,
}) => {
  const [ rotate, setRotate ] = useState<boolean>( !!forceOrientation )
  const [ timestamp, setTimestamp ] = useState<string>( '' )
  const [ naturalWidth, setNaturalWidth ] = useState<number>( 0 )
  const [ naturalHeight, setNaturalHeight ] = useState<number>( 0 )
  const [ resultingHeight, setResultingHeight ] = useState<string>( `${prescribedHeightInInches}in` )
  const [ resultingWidth, setResultingWidth ] = useState<string>( `${prescribedWidthInInches}in` )
  const [ showLabel, setShowLabel ] = useState<boolean>( !forceOrientation )
  const containerRef = useRef<HTMLDivElement>( null )

  useEffect(() => {
    setTimestamp( new Date().getTime().toString())
  }, [])

  useEffect(() => {
    if ( !forceOrientation || !naturalHeight || !naturalWidth ) return

    const doRotate =
      ( forceOrientation === 'landscape' && naturalHeight > naturalWidth ) ||
      ( forceOrientation === 'portrait' && naturalWidth > naturalHeight )
    setRotate( doRotate )
    if ( doRotate && !prescribedHeightInInches && !prescribedWidthInInches ) {
      setResultingHeight( `${naturalWidth}px` )
      setResultingWidth( `${naturalHeight}px` )
    }
    setShowLabel( true )
  }, [ forceOrientation, naturalHeight, naturalWidth ])

  const transform =
    forceOrientation && rotate
      ? forceOrientation === 'landscape'
        ? `translateY(${resultingHeight}) rotate(-90deg) scale(${naturalWidth / naturalHeight})`
        : `translateX(${resultingWidth}) rotate(90deg) scale(${naturalWidth / naturalHeight})`
      : undefined

  /**
   * TODO - We might want to set Tailwind values for heights and widths for shipping labels once we
   * start supporting multiple templates.
   */
  return (
    <div
      className={classNames( showLabel ? 'visible' : 'invisible', className )}
      style={{ height: resultingHeight, width: resultingWidth }}
      ref={containerRef}
    >
      <img
        data-testid="label-image"
        data-orientation={
          forceOrientation ??
          ( containerRef.current &&
          containerRef.current.clientHeight > containerRef.current.clientWidth
            ? 'portrait'
            : 'landscape' )
        }
        src={`${imgPath}${timestamp && `?t=${timestamp}`}`}
        style={{ transform }}
        className={classNames(
          { [`origin-top-left`]: rotate },
          showLabel ? 'visible' : 'invisible',
          'inline-block m-0'
        )}
        alt="Shipping label"
        onLoad={( e ) => {
          if ( !timestamp ) return
          handleLoad( e )

          const { currentTarget } = e
          const { naturalHeight, naturalWidth } = currentTarget
          setNaturalWidth( naturalWidth )
          setNaturalHeight( naturalHeight )
        }}
        onError={( e ) => {
          if ( !timestamp ) return
          handleError( e )
        }}
      />
    </div>
  )
}

export default ShippingLabel
