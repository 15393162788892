import { FC } from 'react'
import TaxonomyCell from './TaxonomyCell'

interface IProps {
  productId: string
  taxonomies: { id: string; name: string }[]
  productTaxonomies: {
    id: string
    taxonomyId: string
    productId: string
    isEnabled?: boolean | null
  }[]
}

const TaxonomyRow: FC<IProps> = ({ productId, taxonomies, productTaxonomies }) => (
  <>
    {taxonomies.map(( taxonomy ) => (
      <TaxonomyCell
        key={taxonomy.id}
        productId={productId}
        taxonomyId={taxonomy.id}
        productTaxonomy={productTaxonomies.find(( x ) => x.taxonomyId === taxonomy.id )}
      />
    ))}
  </>
)

export default TaxonomyRow
