/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { FC } from 'react'
import { loader } from 'graphql.macro'
import { ScaleLoader } from 'react-spinners'
import { useMutation } from 'urql'
import {
  IAssignProductToTaxonomyMutation,
  IAssignProductToTaxonomyMutationVariables,
} from 'src/graphql/mutations/assignProductToTaxonomy.types'
import {
  IRemoveProductFromTaxonomyMutation,
  IRemoveProductFromTaxonomyMutationVariables,
} from 'src/graphql/mutations/removeProductFromTaxonomy.types'
import useAppParams from 'src/utils/hooks/useAppParams'
import classNames from 'classnames'

interface IProps {
  productId: string
  taxonomyId: string
  productTaxonomy:
    | { id: string; taxonomyId: string; productId: string; isEnabled?: boolean | null }
    | undefined
}

const assignProductToTaxonomy = loader( 'src/graphql/mutations/assignProductToTaxonomy.graphql' )
const removeProductFromTaxonomy = loader( 'src/graphql/mutations/removeProductFromTaxonomy.graphql' )

const TaxonomyCell: FC<IProps> = ({ productId, taxonomyId, productTaxonomy }) => {
  const { mdashAccountId } = useAppParams()
  const [ assignmentResult, assign ] = useMutation<
    IAssignProductToTaxonomyMutation,
    IAssignProductToTaxonomyMutationVariables
  >( assignProductToTaxonomy )

  const [ removalResult, remove ] = useMutation<
    IRemoveProductFromTaxonomyMutation,
    IRemoveProductFromTaxonomyMutationVariables
  >( removeProductFromTaxonomy )

  const isFetching = assignmentResult.fetching || removalResult.fetching

  const toggleProductTaxonomy = () => {
    if ( isFetching ) return

    if ( productTaxonomy && productTaxonomy.isEnabled ) {
      remove({ mdashAccountId: String( mdashAccountId ), productTaxonomyId: productTaxonomy.id })
    } else {
      assign({ mdashAccountId: String( mdashAccountId ), productId, taxonomyId })
    }
  }

  return (
    <td
      className={classNames(
        'border text-xl sm:text-3xl',
        isFetching ? 'bg-gb-gray-100 cursor-wait' : 'cursor-pointer'
      )}
      onClick={toggleProductTaxonomy}
    >
      <div className="flex justify-center w-full">
        {isFetching ? (
          <ScaleLoader height={16} width={4} margin={1} />
        ) : productTaxonomy?.isEnabled ? (
          <span className="spec__">✔</span>
        ) : (
          ''
        )}
      </div>
    </td>
  )
}

export default TaxonomyCell
