import { createContext } from 'react'
import { IGetMissedShipmentIssuesQuery } from 'src/graphql/queries/getMissedShipmentIssues.types'
import { IGetRefundIssuesQuery } from 'src/graphql/queries/getRefundIssues.types'
import { IGetReshipmentIssuesQuery } from 'src/graphql/queries/getReshipmentIssues.types'

export interface IssuesContextProps {
  reshipmentIssues?: IGetReshipmentIssuesQuery['reshipmentIssues']
  missedShipmentIssues?: IGetMissedShipmentIssuesQuery['missedShipmentIssues']
  refundIssues?: IGetRefundIssuesQuery['refundIssues']
}

const IssuesContext = createContext<IssuesContextProps>({})

export default IssuesContext
