import { IFacilityType, IManifestAggregate } from 'src/graphql/types'
import useAppParams from './useAppParams'

interface IProps {
  /**
   * facilityAggregates list all shipping and pick-up locations
   * including those with zero orders and intangible ones
   */
  facilityAggregates: IManifestAggregate[]
}

const useMultiFacilityParams = ({ facilityAggregates }: IProps ) => {
  // activeFacilities are tangible shipping locations that are active at observed ship date
  const { activeFacilities, isTangibleFacility } = useAppParams()

  // assignable Facilities are defined as:
  // - any tangible pick-up locations
  // - tangible shipping locations that are active at observed ship date
  const assignableFacilities = facilityAggregates.filter(
    ( f ) =>
      Number( f.query || 0 ) > 0 &&
      ( f.category === String( IFacilityType.PICK_UP_LOCATION ) ||
        activeFacilities.find(( x ) => x.id === f.query ))
  )

  const facilitiesWithOrders = facilityAggregates.filter(
    ( f ) => Number( f.query || 0 ) > 0 && f.value > 0
  )

  // display assignment buttons if:
  // - there are more than one assignable locations, OR
  // - there is only one assignable locations BUT aggregates return multiple locations
  const shouldDisplayFacilityAssignmentButtons =
    assignableFacilities.length > 1 ||
    ( assignableFacilities.length === 1 && facilitiesWithOrders.length > 1 )

  // physical actions are those that produce real-world output, e.g. printing.
  // These actions are available if:
  // - single facility, OR
  // - only one assignable facilities AND only one facility from aggregates, OR
  // - only one assignable facilities AND multiple facility from aggregates AND viewing tangibles, OR
  // - viewing tangibles

  const isPhysicallyActionable =
    assignableFacilities.length === 0 ||
    ( assignableFacilities.length === 1 && facilitiesWithOrders.length <= 1 ) ||
    ( assignableFacilities.length === 1 && facilitiesWithOrders.length > 1 && isTangibleFacility ) ||
    isTangibleFacility

  return {
    shouldDisplayFacilityAssignmentButtons,
    isPhysicallyActionable,
    assignableFacilities,
    facilitiesWithOrders,
  }
}

export default useMultiFacilityParams
