import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AnimatePresence, motion } from 'framer-motion'
import { loader } from 'graphql.macro'
import { Dictionary } from 'lodash'
import { FC, useCallback } from 'react'
import { ScaleLoader } from 'react-spinners'
import Button from 'src/components/01-atoms/Button'
import ChoiceInput from 'src/components/01-atoms/ChoiceInput'
import Dropdown from 'src/components/01-atoms/Dropdown'
import {
  ICreateAutoAssignmentRuleMutation,
  ICreateAutoAssignmentRuleMutationVariables,
} from 'src/graphql/mutations/createAutoAssignmentRule.types'
import {
  IRemoveAutoAssignmentRuleMutation,
  IRemoveAutoAssignmentRuleMutationVariables,
} from 'src/graphql/mutations/removeAutoAssignmentRule.types'
import {
  IUpdateAutoAssignmentRuleMutation,
  IUpdateAutoAssignmentRuleMutationVariables,
} from 'src/graphql/mutations/updateAutoAssignmentRule.types'
import { IFacilityAutoAssignmentRuleMode } from 'src/graphql/types'
import { useMutation } from 'urql'

type TDimension = {
  x: number
  y: number
}

type TRule = {
  ruleId?: string
  productId?: string | null
  productName?: string
  deliveryState?: string | null
  selectedShipperFacilityId?: string
  isBlocklist?: boolean
}

export type TPopUp = TRule & { dimension?: TDimension }

interface IProps {
  shipperId: string
  dimension?: TDimension
  rule: TRule
  shipperFacilitiesDictionary: Dictionary<{ name: string | null | undefined; index: number }>
  facilityOptions: { [key: string]: string }
  onClose: () => void
  onChange: <T extends keyof TRule>( key: T | 'dimension', value: TRule[T] | TDimension ) => void
}

const createAutoAssignmentRule = loader( 'src/graphql/mutations/createAutoAssignmentRule.graphql' )
const updateAutoAssignmentRule = loader( 'src/graphql/mutations/updateAutoAssignmentRule.graphql' )
const removeAutoAssignmentRule = loader( 'src/graphql/mutations/removeAutoAssignmentRule.graphql' )

const PopUp: FC<IProps> = ({
  dimension,
  shipperId,
  shipperFacilitiesDictionary,
  facilityOptions,
  rule: { ruleId, productId, productName, deliveryState, selectedShipperFacilityId, isBlocklist },
  onClose,
  onChange,
}) => {
  const [ createRuleResponse, createRule ] = useMutation<
    ICreateAutoAssignmentRuleMutation,
    ICreateAutoAssignmentRuleMutationVariables
  >( createAutoAssignmentRule )
  const [ updateRuleResponse, updateRule ] = useMutation<
    IUpdateAutoAssignmentRuleMutation,
    IUpdateAutoAssignmentRuleMutationVariables
  >( updateAutoAssignmentRule )
  const [ removeRuleResponse, removeRule ] = useMutation<
    IRemoveAutoAssignmentRuleMutation,
    IRemoveAutoAssignmentRuleMutationVariables
  >( removeAutoAssignmentRule )

  const handleCreateOrUpdateAction = useCallback(() => {
    const args = {
      shipperId,
      productId,
      deliveryState,
      shipperFacilityId: String( selectedShipperFacilityId ),
      mode: ( isBlocklist ? 'blocklist' : 'allowlist' ) as IFacilityAutoAssignmentRuleMode,
    }

    if ( ruleId ) {
      updateRule({ ...args, ruleId }).then(() => setTimeout(() => onClose(), 200 ))
    } else {
      createRule( args ).then(() => setTimeout(() => onClose(), 200 ))
    }
  }, [
    shipperId,
    productId,
    deliveryState,
    selectedShipperFacilityId,
    isBlocklist,
    ruleId,
    createRule,
    updateRule,
  ])

  const handleRemoveAction = useCallback(() => {
    removeRule({
      shipperId,
      ruleId: String( ruleId ),
    }).then(() => setTimeout(() => onClose(), 200 ))
  }, [ shipperId, ruleId, removeRule, onClose ])

  return (
    <AnimatePresence>
      {dimension && (
        <motion.div
          className="bg-white absolute w-64 z-60 rounded drop-shadow-md origin-top-left"
          style={{ top: dimension.y, left: dimension.x }}
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0 }}
          transition={{ duration: 0.25 }}
        >
          <div className="p-4">
            <div className="mb-2 max-w-[80%]">
              {!deliveryState && !productId && 'Default Rule '}
              {!deliveryState && productId && `Product Rule for ${productName || productId} `}
              {deliveryState && !productId && `Delivery Rule to ${deliveryState} `}
              {deliveryState &&
                productId &&
                `Product+Delivery Rule for ${productName || productId} to ${deliveryState} `}
            </div>
            <ChoiceInput
              labelText="Enable this Facility"
              checked={!isBlocklist}
              onChange={( x ) => onChange( 'isBlocklist', !x.target.checked )}
            />

            <Dropdown
              showDropdownArrow
              className="my-2"
              selected={String( shipperFacilitiesDictionary[selectedShipperFacilityId || ''].name )}
              options={facilityOptions}
              handleChange={( val: string ) => onChange( 'selectedShipperFacilityId', val )}
            />

            <div className="flex items-center">
              <Button
                className="mr-4"
                disabled={createRuleResponse.fetching || updateRuleResponse.fetching}
                variant={ruleId ? 'success' : 'primary'}
                onClick={handleCreateOrUpdateAction}
              >
                {ruleId ? 'Update' : 'Add'}
              </Button>
              <ScaleLoader
                loading={createRuleResponse.fetching || updateRuleResponse.fetching}
                color="#233D90"
                margin={1}
                height={16}
              />
            </div>
          </div>
          {ruleId && (
            <div className="p-4 border-t border-gb-gray-700 flex items-center">
              <Button
                variant="alert"
                className="mr-4"
                disabled={removeRuleResponse.fetching}
                onClick={handleRemoveAction}
              >
                Remove
              </Button>
              <ScaleLoader
                loading={removeRuleResponse.fetching}
                color="#233D90"
                margin={1}
                height={16}
              />
            </div>
          )}
          <FontAwesomeIcon
            icon={faTimes}
            className="absolute top-4 right-4 cursor-pointer"
            onClick={onClose}
          />
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default PopUp
