import { FC, useState } from 'react'

import Alert from 'src/components/01-atoms/Alert'
import PackageListTable from 'src/components/03-organisms/PackageListTable'
import SuccessBlock from 'src/components/01-atoms/SuccessBlock'

import useAppParams from 'src/utils/hooks/useAppParams'
import DiscardLabelsWrapper from 'src/pages/elements/DiscardLabelsWrapper'
import PackagesContext from 'src/contexts/PackagesContext'
import { IShippingLabelStatus } from 'src/graphql/types'
import FinishButton from 'src/components/02-molecules/FinishButton'

const Discard: FC = () => {
  const { makeLinkUrls, packageSort } = useAppParams()
  const [ hasBeenDiscarded, setHasBeenDiscarded ] = useState( false )

  const continueButton = !hasBeenDiscarded
    ? {
        label: 'Confirm Do Not Ship',
        action: () => {
          setHasBeenDiscarded( true )
        },
      }
    : undefined

  const finishButton = hasBeenDiscarded ? (
    <FinishButton className="flex-1 center md:flex-initial" to={makeLinkUrls().manifest} />
  ) : undefined

  return (
    <DiscardLabelsWrapper
      pageTitle="Do Not Ship List"
      labelPrintStatus={IShippingLabelStatus.NEED_DISCARD}
      continueButton={continueButton}
      additionalButtons={finishButton}
    >
      <PackagesContext.Consumer>
        {({ packages }) =>
          !hasBeenDiscarded ? (
            <>
              <Alert
                titleText="Discard the labels listed below and Do Not Ship these orders!"
                size="xl"
                type="error"
                className="mb-6"
              >
                These orders have been canceled, though the labels have been printed already, be
                sure not to ship them.
              </Alert>
              <PackageListTable
                packages={packages}
                showPackageActionLinks={false}
                sortValue={packageSort}
              />
            </>
          ) : (
            <SuccessBlock />
          )
        }
      </PackagesContext.Consumer>
    </DiscardLabelsWrapper>
  )
}

export default Discard
