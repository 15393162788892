const twoLetters = ({ text }: { text: string }) => {
  if ( !text ) return ''

  const splits = text.split( /\s+/ )

  if ( splits.length === 1 ) {
    return [ splits[0][0].toUpperCase(), splits[0][1] ? splits[0][1].toLowerCase() : '' ].join( '' )
  }

  return [ splits[0][0].toUpperCase(), splits.reverse()[0][0].toUpperCase() ].join( '' )
}

export default twoLetters
