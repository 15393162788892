import { useMutation, UseMutationResponse } from 'urql'
import { loader } from 'graphql.macro'
import {
  IDiscardPackagesShippingLabelsMutation,
  IDiscardPackagesShippingLabelsMutationVariables,
} from 'src/graphql/mutations/discardPackagesShippingLabels.types'

const discardPackagesShippingLabels = loader(
  'src/graphql/mutations/discardPackagesShippingLabels.graphql'
)

type DiscardResponse = [
  UseMutationResponse<
    IDiscardPackagesShippingLabelsMutation,
    IDiscardPackagesShippingLabelsMutationVariables
  >[1],
  UseMutationResponse<
    IDiscardPackagesShippingLabelsMutation,
    IDiscardPackagesShippingLabelsMutationVariables
  >[0],
]

const useMarkLabelsDiscarded = (): DiscardResponse => {
  const [ discardResponse, markDiscarded ] = useMutation<
    IDiscardPackagesShippingLabelsMutation,
    IDiscardPackagesShippingLabelsMutationVariables
  >( discardPackagesShippingLabels )

  return [ markDiscarded, discardResponse ]
}

export default useMarkLabelsDiscarded
