import { FC } from 'react'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import {
  faDiamondExclamation as duoDiamondExclamation,
  faTriangleExclamation as duoTriangleExclamation,
  faCheckCircle as duoCheckCircle,
  faInfoCircle as duoInfoCircle,
} from '@fortawesome/pro-duotone-svg-icons'
import {
  faDiamondExclamation as regDiamondExclamation,
  faTriangleExclamation as regTriangleExclamation,
  faCheckCircle as regCheckCircle,
  faInfoCircle as regInfoCircle,
} from '@fortawesome/pro-regular-svg-icons'
import classNames from 'classnames'

export type Level = 'error' | 'warning' | 'info' | 'success'

interface IStatusIconProps extends Omit<FontAwesomeIconProps, 'icon'> {
  /**
   * The icon level to use.
   */
  level: Level
  /**
   * Whether or not the outline version of the icon should be used.
   */
  outline?: Boolean

  /**
   * Classes to pass down to the icon.
   */
  className?: string
}

const StatusIcon: FC<IStatusIconProps> = ({ level, outline, className, ...iconProps }) =>
  ({
    error: (
      <FontAwesomeIcon
        size="xl"
        {...iconProps}
        icon={outline ? regDiamondExclamation : duoDiamondExclamation}
        className={classNames( 'text-red-600', className )}
      />
    ),
    warning: (
      <FontAwesomeIcon
        size="xl"
        {...iconProps}
        icon={outline ? regTriangleExclamation : duoTriangleExclamation}
        className={classNames( 'text-amber-500', className )}
      />
    ),
    info: (
      <FontAwesomeIcon
        size="xl"
        {...iconProps}
        icon={outline ? regInfoCircle : duoInfoCircle}
        className={classNames( 'text-gray-600', className )}
      />
    ),
    success: (
      <FontAwesomeIcon
        size="xl"
        {...iconProps}
        icon={outline ? regCheckCircle : duoCheckCircle}
        className={classNames( 'text-green-500', className )}
      />
    ),
  }[level])

export default StatusIcon
