import { FC, PropsWithChildren } from 'react'
import { faCircleCheck } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button, { ButtonProps } from 'src/components/01-atoms/Button'
import classNames from 'classnames'

type IFinishButtonProps = ButtonProps

const FinishButton: FC<PropsWithChildren<IFinishButtonProps>> = ({
  children,
  className,
  ...buttonProps
}) => (
  <Button
    {...buttonProps}
    variant="success"
    className={classNames( 'gap-2 center', className )}
    data-testid="finish-button"
  >
    <FontAwesomeIcon className="text-white h-4 w-4" icon={faCircleCheck} />
    {children || 'Finish'}
  </Button>
)

export default FinishButton
