import { FC, useEffect, useRef, useState } from 'react'
import ChoiceInput from 'src/components/01-atoms/ChoiceInput'
import SectionCard from 'src/components/01-atoms/SectionCard'
import { useForm, useFieldArray, useWatch } from 'react-hook-form'
import Button from 'src/components/01-atoms/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrashCan } from '@fortawesome/pro-solid-svg-icons'
import TextInput from 'src/components/01-atoms/TextInput'
import { IGetMdashAccountQuery } from 'src/graphql/queries/getMdashAccount.types'
import { IMerchantNotificationContactInput, IShipperInput } from 'src/graphql/types'
import pluralize from 'pluralize'
import Alert from 'src/components/01-atoms/Alert'
import { ISetNotificationSettingsMutation } from 'src/graphql/mutations/setNotificationSettings.types'
import classNames from 'classnames'

interface IContactInput extends IMerchantNotificationContactInput {
  __id?: string
  id?: string
  isInitialValue?: boolean
}
interface IShippersInput extends IShipperInput {
  __id?: string
}
export interface INotificationSettingsOutput {
  merchantNotificationContactsAttributes?: Array<IContactInput>
  shippersAttributes?: Array<IShippersInput>
  smsNotificationEnabled: boolean
}

interface INotificationSettingsCard {
  data: IGetMdashAccountQuery['mdashAccount']
  onSubmit: ( output: INotificationSettingsOutput ) => any
  disabled?: boolean
  mutationData: ISetNotificationSettingsMutation | undefined
}

const NotificationsSettingsCard: FC<INotificationSettingsCard> = ({
  data,
  onSubmit,
  mutationData,
  disabled = false,
}) => {
  const [ showSuccess, setShowSuccess ] = useState( false )
  const timeoutRef = useRef( setTimeout(() => null ))
  const [ isSubmitting, setIsSubmitting ] = useState( false )
  const dataSrc = mutationData?.merchantNotificationSettings?.mdashAccount || data

  const merchantNotificationContactsAttributes: IContactInput[] =
    dataSrc?.merchantNotificationContacts
      ? dataSrc.merchantNotificationContacts.map(( contact ) => {
          // removing unneeded __typename var from the contacts, creates error for the mutation input
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { __typename, phoneNumber, ...rest } = contact
          return {
            ...rest,
            __id: contact.id,
            phoneNumber: phoneNumber.split( '+1' ).join( '' ),
            isInitialValue: true,
          }
        })
      : []

  const shippersAttributes: IShippersInput[] = data?.shippers
    ? data.shippers.map(( shipper ) => {
        // removing unneeded __typename var from the shippers, creates error for the mutation input
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { __typename, ...rest } = shipper
        return { ...rest, __id: shipper.id }
      })
    : []
  const {
    control,
    handleSubmit,
    register,
    reset,
    getFieldState,
    formState: { isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      merchantNotificationContactsAttributes,
      shippersAttributes,
      smsNotificationEnabled: !!data?.smsNotificationEnabled,
    },
  })

  const resetForm = () => {
    reset({
      merchantNotificationContactsAttributes,
      shippersAttributes,
      smsNotificationEnabled: !!data?.smsNotificationEnabled,
    })
  }

  useEffect(() => {
    if ( !disabled && isSubmitting && mutationData?.merchantNotificationSettings?.mdashAccount ) {
      setIsSubmitting( false )
      setShowSuccess( true )
      resetForm()
      clearTimeout( timeoutRef.current )
      timeoutRef.current = setTimeout(() => {
        setShowSuccess( false )
      }, 5000 )
    }
  }, [ data, isSubmitting, disabled, mutationData ])

  useEffect(() => {
    if ( timeoutRef.current && isDirty ) {
      clearTimeout( timeoutRef.current )
      setShowSuccess( false )
      timeoutRef.current = setTimeout(() => null )
    }
  }, [ isDirty ])

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'merchantNotificationContactsAttributes',
  })

  const removeOrDelete = ( index: number ) => {
    const field = fields[index]
    if ( !field.__id ) {
      return remove( index )
    }

    return update( index, { _destroy: true, ...field })
  }

  const notificationsEnabled = useWatch({ control, name: 'smsNotificationEnabled' })

  const beforeSubmit = ( _data: INotificationSettingsOutput ) => {
    setIsSubmitting( true )

    const merchantNotificationContacts = _data.merchantNotificationContactsAttributes?.map(
      ( contact ) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { role, id, __id, isInitialValue, ...rest } = contact
        return { role: 'Business Contact', id: __id, ...rest }
      }
    )

    const shippers = _data.shippersAttributes?.map(( shipper ) => {
      const { receivesRealtimeNotifications, __id } = shipper
      return { receivesRealtimeNotifications, id: __id }
    })

    const { smsNotificationEnabled } = _data

    onSubmit({
      merchantNotificationContactsAttributes: merchantNotificationContacts,
      shippersAttributes: shippers,
      smsNotificationEnabled,
    })
  }

  return (
    <SectionCard title="Notification Settings" className="col-span-12 lg:col-span-8 xl:col-span-6">
      <form className="pl-1" onSubmit={handleSubmit( beforeSubmit )}>
        <div className="mb-1 py-4">
          {data?.shippers &&
            data.shippers.map(({ fulfillmentEmail }, index ) => (
              <div key={fulfillmentEmail} className="mb-4">
                <ChoiceInput
                  disabled={isSubmitting}
                  type="checkbox"
                  {...register( `shippersAttributes.${index}.receivesRealtimeNotifications` )}
                  labelText={`Send immediate notifications of new orders to the following ${pluralize(
                    'address',
                    fulfillmentEmail ? ( fulfillmentEmail.match( /,/g ) || []).length + 1 : 0
                  )}: \n ${fulfillmentEmail}`}
                />
              </div>
            ))}
        </div>
        <div className="font-bold">
          <ChoiceInput
            disabled={isSubmitting}
            type="checkbox"
            {...register( `smsNotificationEnabled` )}
            labelText="Enable SMS Text Notifications for Critical Fulfillment Updates"
          />
        </div>
        <div className="mt-1 pl-6 pr-2">
          <p className="mb-2 text-xs text-gb-gray-800 leading-normal">
            By opting-in to SMS text communications, you will receive critical and time sensitive
            information about urgent weather and shipping updates. Adding multiple contacts is
            recommended.
          </p>
          <table className={`mt-4 ${disabled && 'opacity-50'}`}>
            <tbody>
              {fields.map(
                ( field, index ) =>
                  field.isInitialValue && (
                    <tr
                      className={classNames(
                        'pb-1',
                        { 'opacity-50': !notificationsEnabled || disabled || !!field._destroy },
                        { 'line-through': !!field._destroy }
                      )}
                      key={field.phoneNumber}
                    >
                      <td className="font-bold pr-4">
                        {field.firstName} {field.lastName}
                      </td>
                      <td>
                        ({field.phoneNumber.substring( 0, 3 )}) {field.phoneNumber.slice( 3, 6 )}-
                        {field.phoneNumber.slice( 6, 10 )}{' '}
                      </td>
                      <td>
                        <button
                          disabled={!notificationsEnabled || !!field._destroy}
                          className=" text-xs hover:opacity-80"
                          type="button"
                          onClick={() => removeOrDelete( index )}
                        >
                          <FontAwesomeIcon icon={faTrashCan} className="ml-2" />
                        </button>
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </table>

          {fields.map(
            ( field, index ) =>
              !field.isInitialValue && (
                <fieldset
                  disabled={!notificationsEnabled || disabled || !!field._destroy}
                  key={field.id}
                  className="flex bg-gray-100 rounded p-4 justify-between items-start mt-4 disabled:opacity-50"
                >
                  <div className="grid grid-rows gap-4 md:grid-cols-2 md:gap-2 pr-4">
                    <TextInput
                      className={
                        getFieldState( `merchantNotificationContactsAttributes.${index}.firstName` )
                          .error && 'border-red-600'
                      }
                      outline
                      labelText="First Name"
                      placeholder="John"
                      {...register( `merchantNotificationContactsAttributes.${index}.firstName`, {
                        required: true,
                      })}
                    />
                    <TextInput
                      className={
                        getFieldState( `merchantNotificationContactsAttributes.${index}.lastName` )
                          .error && 'border-red-600'
                      }
                      outline
                      labelText="Last Name"
                      placeholder="Smith"
                      {...register( `merchantNotificationContactsAttributes.${index}.lastName`, {
                        required: 'Please enter a Last Name',
                      })}
                    />
                    <TextInput
                      className={
                        getFieldState( `merchantNotificationContactsAttributes.${index}.phoneNumber` )
                          .error && 'border-red-600'
                      }
                      outline
                      labelText="Phone Number"
                      placeholder="(555) 555-5555"
                      type="tel"
                      {...register( `merchantNotificationContactsAttributes.${index}.phoneNumber`, {
                        required: 'Please enter a Phone Number',
                        pattern: /^\d{10}$/,
                      })}
                    />
                  </div>
                  <button
                    disabled={!notificationsEnabled || !!field._destroy}
                    className="text-gb-blue-600 text-xl hover:opacity-80"
                    type="button"
                    onClick={() => removeOrDelete( index )}
                  >
                    <FontAwesomeIcon icon={faTrashCan} className="mr-2" />
                  </button>
                </fieldset>
              )
          )}

          <button
            type="button"
            className="text-gb-blue-600 py-3 px-4 mt-2 disabled:opacity-50"
            disabled={!notificationsEnabled || disabled}
            onClick={() => {
              append({
                firstName: '',
                lastName: '',
                phoneNumber: '',
              })
            }}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Add Phone Number
          </button>
        </div>
        <div className="col-span-12 h-8 mt-12">
          {isDirty && (
            <div className="flex mr-2 ">
              <Button type="submit" disabled={disabled || !isValid} className="mr-2">
                Save
              </Button>
              <Button
                outline
                disabled={disabled}
                onClick={() => {
                  resetForm()
                }}
              >
                Cancel
              </Button>
            </div>
          )}
          {!isDirty && showSuccess && (
            <Alert className="transition" type="success">
              Saved!
            </Alert>
          )}
        </div>
      </form>
    </SectionCard>
  )
}

export default NotificationsSettingsCard
