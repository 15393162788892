import { FC, useMemo, useState } from 'react'
import useProductTaxonomies from 'src/utils/hooks/useProductTaxonomies'
import { ScaleLoader } from 'react-spinners'
import Table from 'src/components/02-molecules/Table'
import useAppParams from 'src/utils/hooks/useAppParams'
import TaxonomyRow from './TaxonomyRow'
import NewTaxonomy from './NewTaxonomy'
import EditTaxonomy from './EditTaxonomy'

const ProductTaxonomies: FC = () => {
  const { isAdmin } = useAppParams()
  const { data, fetching } = useProductTaxonomies()
  const [ sortMode, setSortMode ] = useState<'name' | 'id'>( 'name' )
  const [ editTag, setEditTag ] = useState<{ taxonomyId: string; taxonomyName: string } | null>( null )
  const sortedProducts = useMemo(
    () =>
      ( data?.mdashAccount?.products || []).sort(( a, b ) =>
        sortMode === 'name' ? a.name.localeCompare( b.name ) : Number( a.id ) - Number( b.id )
      ),

    [ data?.mdashAccount?.products, sortMode ]
  )
  const taxonomies = useMemo(
    () => ( data?.mdashAccount?.taxonomies || []).sort(( a, b ) => a.name.localeCompare( b.name )),
    [ data?.mdashAccount?.taxonomies ]
  )

  if ( !isAdmin ) return null

  return (
    <>
      <NewTaxonomy />
      <EditTaxonomy {...editTag} onCancel={() => setEditTag( null )} />
      <div className="flex justify-center" data-testid="product-taxonomies-container">
        <div className="mt-4">
          {fetching ? (
            <ScaleLoader />
          ) : (
            <div className="max-h-[calc(100vh-200px)] sm:max-h-[calc(100vh-218px)] max-w-[calc(100vw-8px)] overflow-auto text-xs sm:text-sm">
              <Table
                stickyHeader
                headers={[
                  {
                    name: `Products (${sortMode === 'name' ? 'A' : '#'})`,
                    className: 'cursor-pointer text-xs sm:text-base',
                    onClick: () => setSortMode( sortMode === 'name' ? 'id' : 'name' ),
                  },
                ].concat(
                  taxonomies.map(( taxonomy ) => ({
                    name: taxonomy.name,
                    className: 'cursor-pointer whitespace-wrap text-center text-xs sm:text-base',
                    onClick: () =>
                      setEditTag({ taxonomyId: taxonomy.id, taxonomyName: taxonomy.name }),
                  }))
                )}
              >
                {sortedProducts.map(( product ) => (
                  <tr key={product.id}>
                    <td className="sticky left-0 bg-gb-gray-300 z-20 border border-b-gb-gray-50 min-w-[192px] p-2 max-w-[320px] ">
                      <span className="font-semibold">{product.name}</span>
                      <span className="text-gb-gray-700">{` #${product.id}`}</span>
                    </td>

                    <TaxonomyRow
                      productId={product.id}
                      taxonomies={taxonomies}
                      productTaxonomies={
                        data?.mdashAccount?.productTaxonomies.filter(
                          ( x ) => x.productId === product.id
                        ) || []
                      }
                    />
                  </tr>
                ))}
              </Table>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ProductTaxonomies
