import classNames from 'classnames'
import { FC, PropsWithChildren } from 'react'

import './styles.css'

export enum PageSize {
  US_LETTER_STRICT = 'us_letter_strict',
  US_LETTER_LOOSE = 'us_letter_loose',
  THERMAL_4x6 = 'thermal_4x6',
}

interface IPrintablePageProps {
  /**
   * The size of the page that is going to be printed.
   *
   * us_letter_strict = 8.5" x 11"
   *
   * us_letter_loose = 8.5" x 11"+
   */
  size?: PageSize

  /**
   * Classes to pass down to the containing element.
   */
  className?: string
}

const PrintablePage: FC<PropsWithChildren<IPrintablePageProps>> = ({
  size = PageSize.US_LETTER_STRICT,
  className = '',
  children,
}) => (
  <article
    className={classNames(
      'bg-white text-sm overflow-hidden',
      'screen:border screen:border-solid screen:border-gb-gray-900 screen:shadow-lg text-black',
      {
        'printable-us-letter-loose': size === PageSize.US_LETTER_LOOSE,
        'printable-us-letter-strict': size === PageSize.US_LETTER_STRICT,
        'printable-thermal-4x6': size === PageSize.THERMAL_4x6,
      },
      className
    )}
  >
    {children}
  </article>
)

export default PrintablePage
