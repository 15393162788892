import classNames from 'classnames'
import { FC, PropsWithChildren } from 'react'

import './style.css'

interface ITimelineListProps {
  className?: string
}

const TimelineList: FC<PropsWithChildren<ITimelineListProps>> = ({ children, className }) => (
  <div className="relative">
    <ul
      className={classNames(
        className,
        'timeline-list text-gb-gray-800 font-light text-sm list-none'
      )}
    >
      {children}
    </ul>
  </div>
)

export default TimelineList
