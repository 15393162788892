import { FC } from 'react'
import { max, min, times } from 'lodash'
import Skeleton from 'react-loading-skeleton'

import Button from 'src/components/01-atoms/Button'
import Grid from 'src/components/01-atoms/Grid'
import SectionCard from 'src/components/01-atoms/SectionCard'
import Tag, { Color } from 'src/components/01-atoms/Tag'
import CustomerAddressesBlock, {
  ICustomerAddressesBlockProps,
} from 'src/components/02-molecules/CustomerAddressesBlock'
import CustomerHistoryBlock, {
  ICustomerHistoryBlockProps,
} from 'src/components/02-molecules/CustomerHistoryBlock'
import CustomerSummaryBlock, {
  ICustomerSummaryBlockProps,
} from 'src/components/02-molecules/CustomerSummaryBlock'
import PurchaserInfoForm from 'src/components/02-molecules/PurchaserInfoForm'
import OrderSummaryAccordion, {
  IOrderSummaryAccordionProps,
} from 'src/components/03-organisms/OrderSummaryAccordion'

import { ICustomerAccount } from 'src/utils/types/ICustomerAccount'

interface ICustomerProfile {
  /**
   * The customer account information.
   */
  account: ICustomerAccount

  /**
   * The link to place a new order.
   */
  placeNewOrderLink?: string

  /**
   * The customer account orders.
   */
  orders?: IOrderSummaryAccordionProps[]

  /**
   * The function to get the order summary contents.
   * @param orderId The order ID.
   * @returns The order summary contents.
   */
  handleGetOrderSummaryContents?: ( orderId: string ) => void

  /**
   * The function to request more orders.
   */
  handleViewMoreOrders?: () => void

  /**
   * Whether or not more orders are loading.
   */
  isLoadingMoreOrders?: boolean

  /**
   * The customer account metadata.
   */
  accountSummary: ICustomerSummaryBlockProps

  /**
   * The customer account history.
   */
  accountHistory: ICustomerHistoryBlockProps

  /**
   * The customer account addresses.
   */
  accountAddresses: ICustomerAddressesBlockProps['addresses']

  /**
   * The number of orders to show per page.
   */
  pageLimit?: number

  /**
   * The link to GBMC.
   */
  gbmcLink?: string

  /**
   * Whether to show a link to GBMC.
   */
  showLinkToGbmc?: boolean
}

const CustomerProfile: FC<ICustomerProfile> = ({
  account,
  placeNewOrderLink,
  orders = [],
  handleGetOrderSummaryContents = () => {},
  handleViewMoreOrders = () => {},
  isLoadingMoreOrders = false,
  accountSummary,
  accountHistory,
  accountAddresses,
  pageLimit = 5,
  gbmcLink,
  showLinkToGbmc = false,
}) => {
  const { firstName, lastName, phone, email, isGuest = false } = account

  const numOrdersShowing = orders.length
  const numOrdersTotal = accountSummary.numTotalOrders ?? 1

  const numOrdersToFetch = max([ min([ numOrdersTotal - numOrdersShowing, pageLimit ]), 0 ]) as number

  const accountType = isGuest
    ? {
        label: 'Guest Account',
        color: Color.ORANGE,
      }
    : {
        label: 'Registered Account',
        color: Color.EMERALD,
      }

  return (
    <>
      {!lastName || !firstName ? (
        <div data-testid="loading-name">
          <Skeleton className="w-full h-8" />
        </div>
      ) : (
        <div className="flex flex-col items-start md:flex-row gap-2 md:items-center">
          <h1 className="text-2xl font-semibold">
            {lastName}, {firstName}
          </h1>
          <Tag data-testid="account-type" color={accountType.color}>
            {accountType.label}
          </Tag>
          {showLinkToGbmc && (
            <a
              className="font-semibold text-sm text-gb-blue-600 hover:text-gb-blue-800"
              data-testid="gbmc-link"
              href={gbmcLink}
            >
              View in GBMC
            </a>
          )}
        </div>
      )}

      {placeNewOrderLink && (
        <div className="md:inline-block mt-4">
          <Button to={placeNewOrderLink} className="center">
            Place New Order
          </Button>
        </div>
      )}
      <Grid className="mt-6">
        <div className="col-span-12 lg:col-span-8 flex flex-col gap-4">
          <SectionCard title="Contact Information" data-testid="contact-info">
            <fieldset disabled>
              <PurchaserInfoForm
                fieldProps={{
                  firstName: { defaultValue: firstName ?? '' },
                  lastName: { defaultValue: lastName ?? '' },
                  phone: { defaultValue: phone ?? '' },
                  email: { defaultValue: email ?? '', helperText: '' },
                }}
              />
            </fieldset>
          </SectionCard>
          <SectionCard
            title="Order History"
            data-testid="order-history"
            contentClassName="flex flex-col gap-4"
          >
            {orders.length > 0
              ? orders?.map(( order ) => (
                  <div data-testid="order-summary" key={`order-${order.orderId}`}>
                    <OrderSummaryAccordion handleOpen={handleGetOrderSummaryContents} {...order} />
                  </div>
                ))
              : 'No orders found.'}
            {isLoadingMoreOrders &&
              numOrdersToFetch > 0 &&
              times( numOrdersToFetch, ( i ) => (
                <Skeleton key={`order-loading-${i}`} className="w-full h-[88px] md:h-[72px]" />
              ))}
            {accountSummary.numTotalOrders > numOrdersShowing ? (
              <div className="center">
                <Button
                  outline
                  disabled={
                    numOrdersShowing >= accountSummary.numTotalOrders || isLoadingMoreOrders
                  }
                  onClick={() => handleViewMoreOrders()}
                >
                  Load More Orders
                </Button>
              </div>
            ) : (
              accountSummary.numTotalOrders > 0 && (
                <div className="pt-4 border-t border-gb-gray-400 center">
                  <p>You&apos;ve reached the end of this customer&apos;s order history.</p>
                </div>
              )
            )}
          </SectionCard>
        </div>
        <div className="col-span-12 gap-4 grid grid-cols-1 auto-rows-min md:grid-cols-2 lg:col-span-4 lg:grid-cols-1">
          <CustomerSummaryBlock {...accountSummary} />
          <CustomerHistoryBlock {...accountHistory} />
          {!isGuest && <CustomerAddressesBlock addresses={accountAddresses} />}
        </div>
      </Grid>
    </>
  )
}

export default CustomerProfile
