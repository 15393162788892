import { FC, PropsWithChildren, useState } from 'react'
import { Popover, PopoverTrigger } from '@radix-ui/react-popover'
import classNames from 'classnames'
import { kebabCase } from 'lodash'

import Container from 'src/components/01-atoms/Container'
import Tab from 'src/components/01-atoms/Tab'
import TabNavLink, { ITabNavLink } from 'src/components/01-atoms/TabNavLink'
import NavDropdownContent from 'src/components/02-molecules/NavDropdownContent'

import { IChildLink } from 'src/utils/types/IChildLink'

export interface ITabsNav {
  /**
   * The title text for the tab nav trigger on mobile.
   */
  mobileTriggerTitle: string

  /**
   * The actual tabs and their respective props.
   */
  tabs?: ITabNavLink[]

  /**
   * Classes to pass down to the containing element.
   */
  className?: string
}

const TabsNav: FC<ITabsNav & PropsWithChildren> = ({
  mobileTriggerTitle,
  tabs = [],
  className = '',
}) => {
  const [ isOpen, setIsOpen ] = useState( false )
  return (
    <div>
      <Container
        className={classNames( 'relative center lg:hidden', className )}
        aria-label="Mobile Tabs Navigation"
      >
        <Popover defaultOpen={false} open={isOpen}>
          <PopoverTrigger className="w-full" onClick={() => setIsOpen( !isOpen )}>
            <Tab isActive disabled={tabs.length === 0}>
              {mobileTriggerTitle}
            </Tab>
          </PopoverTrigger>
          <NavDropdownContent
            className="lg:hidden translate-y-1 w-screen container px-4 md:px-6"
            onInteractOutside={() => setIsOpen( false )}
            handleLinkClick={() => setIsOpen( false )}
            links={tabs as IChildLink[]}
          />
        </Popover>
      </Container>
      <nav className={classNames( 'hidden lg:block', className )} aria-label="Tabs Navigation">
        <Container className="flex items-end gap-x-2">
          {tabs?.length > 0 &&
            tabs.map(({ text, ...tabProps }) => (
              <TabNavLink key={`tabnavlink-${kebabCase( text )}`} text={text} {...tabProps} />
            ))}
        </Container>
      </nav>
    </div>
  )
}

export default TabsNav
