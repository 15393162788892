import { FC } from 'react'

import DetailsList from 'src/components/02-molecules/DetailsList'
import { centsToUSD } from 'src/utils/helpers/currencies'

import './styles.css'

export interface IOrderSummaryProps {
  /**
   * The subtotal amount for the order, in cents.
   */
  subtotal?: number

  /**
   * The amount for shipping, in cents.
   */
  shipping?: number

  /**
   * The total amount to be paid for the order, in cents.
   */
  total?: number

  /**
   * Just in case we have to support tax too.
   */
  tax?: number

  /**
   * The sum of all of the promo codes on the order, in cents.
   */
  promoAmount?: number

  /**
   * A description of the promo code being applied.
   */
  promoDescription?: string

  /**
   * The sum of the gift cards on the order, in cents.
   */
  giftCardAmount?: number
}

const OrderSummary: FC<IOrderSummaryProps> = ({
  subtotal = 0,
  shipping = 0,
  tax = 0,
  total = 0,
  promoAmount = 0,
  promoDescription = '',
  giftCardAmount = 0,
}) => {
  const optional = []
  if ( tax > 0 ) optional.push({ label: 'Tax', value: centsToUSD( tax ) })
  if ( promoAmount > 0 )
    optional.push({
      label: 'Promotion',
      value: (
        <>
          {centsToUSD( promoAmount * -1 )}
          {promoDescription && (
            <div className="text-2xs text-gb-gray-800">({promoDescription})</div>
          )}
        </>
      ),
    })
  if ( giftCardAmount > 0 )
    optional.push({ label: 'Gift Card(s)', value: centsToUSD( giftCardAmount * -1 ) })

  const list = [
    { label: 'Subtotal', value: centsToUSD( subtotal ) },
    { label: 'Shipping', value: centsToUSD( shipping ) },
    ...optional,
    { label: 'Total', value: centsToUSD( total ) },
  ]

  return <DetailsList testIdPrefix="order-summary" variant="bordered" list={list} />
}

export default OrderSummary
