import { FC, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import Button from 'src/components/01-atoms/Button'
import Modal, { IModalProps } from 'src/components/02-molecules/Modal'
import AlertList from 'src/components/02-molecules/AlertList'

import UserContext from 'src/contexts/UserContext'

import useManageUserSession from 'src/utils/hooks/useManageUserSession'

interface ILogoutModalProps extends IModalProps {
  handleClose?: CallableFunction
  className?: string
}

const LogoutModal: FC<ILogoutModalProps> = ({ handleClose, className, ...modalProps }) => {
  const navigate = useNavigate()
  const { setIdentity } = useContext( UserContext )
  const [ errors, setErrors ] = useState<string[]>([])
  const {
    logoutResponse: { fetching },
    logUserOut,
  } = useManageUserSession()

  const handleLogout = () =>
    logUserOut().then(({ data }) => {
      if ( data?.logout?.success && data.logout.errors.length === 0 ) {
        if ( handleClose ) handleClose()
        setIdentity({})
        navigate( '/login', { replace: true })
        return
      }

      if ( data?.logout?.errors && data.logout.errors.length > 0 ) setErrors( data.logout.errors )
    })

  return (
    <Modal contentClassName={classNames( className, 'p-6 w-72' )} {...modalProps}>
      <div data-testid="logout-modal">
        <p className="mb-4 text-center">Are you sure you want to log out?</p>
        {errors.length > 0 && <AlertList type="error" alerts={errors} />}
        <div className="mt-4 flex gap-4 justify-center">
          <Button
            onClick={() => {
              if ( !handleClose ) return
              handleClose()
            }}
            disabled={fetching}
            outline
          >
            No
          </Button>
          <Button onClick={handleLogout} disabled={fetching}>
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default LogoutModal
