import { useEffect, useState } from 'react'
import { loader } from 'graphql.macro'
import { FieldValues, useFormContext } from 'react-hook-form'

import Fieldset from 'src/components/01-atoms/Fieldset'
import SectionCard from 'src/components/01-atoms/SectionCard'
import GiftMessageForm from 'src/components/02-molecules/GiftMessageForm'

import useManageOrderPartial from 'src/utils/hooks/useManageOrderPartial'

import {
  IUpdateGiftMessageMutation,
  IUpdateGiftMessageMutationVariables,
} from 'src/graphql/mutations/updateGiftMessage.types'
import { IGetOrderGiftMessageQuery } from 'src/graphql/queries/getOrderGiftMessage.types'

const getGiftMessageQuery = loader( 'src/graphql/queries/getOrderGiftMessage.graphql' )
const updateGiftMessageMutation = loader( 'src/graphql/mutations/updateGiftMessage.graphql' )

type GiftMessageOnly = Omit<IUpdateGiftMessageMutationVariables, 'orderId'>

const GiftMessage = () => {
  const [ fetched, setFetched ] = useState<boolean>( false )
  const { data, fetching, errors, update } = useManageOrderPartial<
    IGetOrderGiftMessageQuery,
    IUpdateGiftMessageMutation,
    GiftMessageOnly
  >( getGiftMessageQuery, updateGiftMessageMutation, fetched )

  const { register, setValue, getValues } = useFormContext()

  useEffect(() => {
    if ( !data || fetching ) return
    setValue( 'giftMessage', data.order.giftMessage )
    setValue( 'giftMessageFrom', data.order.giftMessageFrom )
    setFetched( true )
  }, [ data ])

  const onSubmit = () => {
    const values: FieldValues = getValues()
    update({
      giftMessage: values.giftMessage,
      giftMessageFrom: values.giftMessageFrom,
    } as GiftMessageOnly )
  }

  return (
    <Fieldset onBlur={onSubmit}>
      <SectionCard size="medium" title="Gift Message (Optional)" error={errors.length > 0}>
        <GiftMessageForm
          fieldProps={{
            giftMessage: register( 'giftMessage' ),
            giftMessageFrom: register( 'giftMessageFrom' ),
          }}
        />
      </SectionCard>
    </Fieldset>
  )
}

export default GiftMessage
