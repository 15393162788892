import { FC } from 'react'
import classNames from 'classnames'

import MotionSafeImage from 'src/components/01-atoms/MotionSafeImage'

import { ReactComponent as StackedLogo } from './logo-stacked.svg'
import { ReactComponent as ShipFoodLove } from './logo-ship-food-love.svg'
import { ReactComponent as AdminLogo } from './logo-admin.svg'
import { ReactComponent as BetaLogo } from './logo-beta.svg'
import HalloweenLogo from './logo-halloween.gif'
import HalloweenLogoStill from './logo-halloween.png'
import ThanksgivingLogo from './logo-turkey.gif'
import ThanksgivingLogoStill from './logo-turkey.png'
import GiftingLogo from './logo-gifting.gif'
import GiftingLogoStill from './logo-gifting.png'

export enum LogoType {
  ADMIN = 'admin',
  BETA = 'beta',
  MERCHANT = 'merchant',
  STACKED = 'stacked',
  HALLOWEEN = 'halloween',
  THANKSGIVING = 'thanksgiving',
  GIFTING = 'gifting',
}
interface ILogoProps {
  className?: string
  type?: LogoType
  variant?: 'dark' | 'light'
}

const Logo: FC<ILogoProps> = ({ className, type = LogoType.STACKED, variant = 'dark' }) => {
  const logoTypeMap = {
    dark: 'text-gb-gray-900',
    light: 'text-white',
  }

  const classes = classNames( className, logoTypeMap[variant])

  switch ( type ) {
    case LogoType.ADMIN:
      return <AdminLogo className={classes} data-testid="goldbelly-admin-logo" />
    case LogoType.BETA:
      return <BetaLogo className={classes} data-testid="goldbelly-beta-logo" />
    case LogoType.MERCHANT:
      return <ShipFoodLove className={classes} data-testid="goldbelly-merchant-logo" />
    case LogoType.HALLOWEEN:
      return (
        <MotionSafeImage
          movingImageUrl={HalloweenLogo}
          stillImageUrl={HalloweenLogoStill}
          className={classes}
          alt="Boo! Happy Halloween from the Goldbelly Product Team!"
          data-testid="goldbelly-halloween-logo"
        />
      )
    case LogoType.THANKSGIVING:
      return (
        <MotionSafeImage
          movingImageUrl={ThanksgivingLogo}
          stillImageUrl={ThanksgivingLogoStill}
          className={classes}
          alt="Happy Thanksgiving from the Goldbelly Product Team!"
          data-testid="goldbelly-thanksgiving-logo"
        />
      )
    case LogoType.GIFTING:
      return (
        <MotionSafeImage
          movingImageUrl={GiftingLogo}
          stillImageUrl={GiftingLogoStill}
          className={classes}
          alt="Happy Holidays from the Goldbelly Product Team!"
          data-testid="goldbelly-gifting-logo"
        />
      )
    case LogoType.STACKED:
    default:
      return <StackedLogo className={classes} data-testid="goldbelly-default-logo" />
  }
}

export default Logo
