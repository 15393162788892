import { FC, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useOutsideClick } from 'rooks'
import ScaleLoader from 'react-spinners/ScaleLoader'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faBox, faFrown } from '@fortawesome/pro-duotone-svg-icons'
import { faGlobe, faBuildingUser } from '@fortawesome/pro-solid-svg-icons'

import SimpleSearch from 'src/components/01-atoms/SimpleSearch'

interface IPackageSearchResult {
  purchaserName?: string | null
  packageId: string
  orderId: string
  recipientName: string
  shipDate: string
  packageDetailLink: string
  isOnDifferentAccount?: boolean
  isCancelled?: boolean
  companyName?: string | null
}

interface IAccountSearchResult {
  id: string
  name: string
}
interface IProps {
  packageSearchResults?: IPackageSearchResult[]
  accountSearchResults?: IAccountSearchResult[]
  isSearchingPackages?: boolean
  allowsGlobalSearch?: boolean
  canQueryPurchaserInfo?: boolean
  onChange?: ( x: string ) => void
  onScopeChange?: ( x: boolean ) => void
}

const AccountPackageSearch: FC<IProps> = ({
  packageSearchResults,
  accountSearchResults,
  allowsGlobalSearch,
  canQueryPurchaserInfo = false,
  isSearchingPackages,
  onChange,
  onScopeChange,
}) => {
  const ref = useRef( null )
  const [ isCleared, setIsCleared ] = useState( false )
  const [ isFocused, setIsFocused ] = useState( true )
  const [ isGlobalSearch, setIsGlobalSearch ] = useState( false )
  const isPackageSearchResultsExpanded =
    ( isSearchingPackages || packageSearchResults ) && !isCleared && isFocused
  const isAccountSearchResultsExpanded =
    allowsGlobalSearch &&
    !isCleared &&
    isFocused &&
    accountSearchResults &&
    accountSearchResults.length > 0

  const handleChange = ( x: string ) => {
    setIsCleared( false )
    if ( onChange ) onChange( x )
  }

  useEffect(() => {
    if ( onScopeChange ) onScopeChange( isGlobalSearch )
  }, [ isGlobalSearch ])

  useOutsideClick( ref, () => setTimeout(() => setIsFocused( false ), 200 ))

  return (
    <div
      className="md:w-80 w-full pointer-events-auto"
      ref={ref}
      data-testid="global-search-container"
    >
      <div className="flex items-center ml-4 md:ml-0 w-full pr-8 md:pr-0">
        <SimpleSearch
          showSpyGlass
          showSearchButton={false}
          placeholder="Search..."
          handleChange={( x: string ) => handleChange( x )}
          handleClear={() => setIsCleared( true )}
          handleFocus={() => setIsFocused( true )}
          testId="global-search-form"
          className="w-full md:w-auto"
        />
        {allowsGlobalSearch && (
          <FontAwesomeIcon
            data-testid="global-search-scope-switcher"
            icon={isGlobalSearch ? faGlobe : faBuildingUser}
            className="text-xl pl-2 cursor-pointer w-10"
            onClick={() => setIsGlobalSearch(( prev ) => !prev )}
          />
        )}
      </div>
      <div className="absolute w-full h-0 z-30 md:mt-2 mt-4">
        <div
          data-testid="account-search-result"
          className={classNames(
            'bg-white md:rounded-md md:shadow-md md:w-80 text-sm transition-all duration-200',
            isAccountSearchResultsExpanded
              ? 'md:max-h-32 overflow-y-auto max-h-32 mb-2'
              : 'md:max-h-0 overflow-y-hidden max-h-0'
          )}
        >
          {isAccountSearchResultsExpanded && (
            <div className="bg-gb-gray-50 text-gb-gray-800 font-semibold border-b px-4 py-2 sticky top-0">
              MDashAccounts
            </div>
          )}
          {accountSearchResults?.map(( result ) => (
            <Link
              key={result.id}
              to={`/${result.id}/manifest`}
              className="font-bold text-gb-blue-600 block px-4 py-1 hover:bg-gb-blue-100"
            >
              {result.name}
            </Link>
          ))}
        </div>
        <div
          className={classNames(
            'bg-white md:rounded-md md:shadow-md md:w-80 text-sm transition-all duration-200 mb-4',
            isPackageSearchResultsExpanded
              ? 'md:max-h-80 overflow-y-auto max-h-[calc(100vh-72px)]'
              : 'md:max-h-0 overflow-y-hidden'
          )}
        >
          {isSearchingPackages && (
            <div className="flex content-center justify-center items-center flex-col flex-wrap h-32">
              <div className="w-80 center pb-2">
                <ScaleLoader color="hsla(0, 0%, 67%, 1)" height={24} />
              </div>
              <div className="text-gb-gray-700 text-sm">Searching all packages...</div>
            </div>
          )}
          {!isSearchingPackages && packageSearchResults && packageSearchResults.length === 0 && (
            <div className="flex content-center flex-col justify-center flex-wrap h-32">
              <FontAwesomeIcon icon={faFrown} className="md:w-80 text-gb-gray-700 text-2xl pb-2" />
              <div className="w-80 text-gb-gray-700 text-sm text-center">No Results</div>
              {allowsGlobalSearch && !isGlobalSearch && (
                <button
                  type="button"
                  className="text-gb-blue-600 hover:underline font-bold"
                  onClick={() => setIsGlobalSearch( true )}
                >
                  Try searching all accounts?
                </button>
              )}
            </div>
          )}
          {!isSearchingPackages && packageSearchResults && packageSearchResults.length > 0 && (
            <div className="text-gb-gray-800" data-testid="global-search-result">
              {packageSearchResults.map(( result, i ) => (
                <Link
                  key={result.packageId}
                  to={result.packageDetailLink}
                  data-testid={`${result.packageId}-result`}
                >
                  <div
                    className={classNames( 'leading-6 px-4 hover:bg-gb-gray-200', {
                      'text-gb-gray-500': result.isCancelled,
                    })}
                  >
                    <div className={classNames( 'pt-2', { 'border-t': i > 0 })}>
                      <FontAwesomeIcon icon={result.isCancelled ? faBan : faBox} className="pr-2" />
                      <b className="pr-1">Package ID: </b>
                      <span className="font-bold text-gb-blue-600 hover:underline">
                        {result.packageId}
                      </span>
                      {result.isOnDifferentAccount && (
                        <FontAwesomeIcon icon={faGlobe} className="pl-1" />
                      )}
                    </div>
                    <div>
                      <b className="pr-1">Customer Order: </b>
                      {result.orderId}
                    </div>
                    {result.companyName && (
                      <div>
                        <b className="pr-1">Company: </b>
                        {result.companyName}
                      </div>
                    )}
                    {canQueryPurchaserInfo && result.purchaserName && (
                      <div>
                        <b className="pr-1">Purchaser: </b>
                        {result.purchaserName}
                      </div>
                    )}
                    <div>
                      <b className="pr-1">Recipient: </b>
                      {result.recipientName}
                    </div>
                    <div className="pb-2">
                      <b className="pr-1">Ship Date: </b>
                      <span className="pr-2">{result.shipDate}</span>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default AccountPackageSearch
