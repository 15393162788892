import { FC, PropsWithChildren } from 'react'
import classNames from 'classnames'

interface IPackageListTableCellProps {
  className?: string
}

const PackageListTableCell: FC<PropsWithChildren<IPackageListTableCellProps>> = ({
  children,
  className,
}) => (
  <td
    className={classNames(
      'border-solid border-gb-gray-400 p-2 text-xs md:align-top md:table-cell md:p-4',
      className
    )}
  >
    {children}
  </td>
)

export default PackageListTableCell
