import { FC, useState } from 'react'
import { Popover, PopoverTrigger } from '@radix-ui/react-popover'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGears, faUserAstronaut } from '@fortawesome/pro-duotone-svg-icons'
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons'
import { truncate } from 'lodash'

import { IIdentity } from 'src/contexts/UserContext'

import Button, { ButtonProps } from 'src/components/01-atoms/Button'
import PopoverContent from 'src/components/02-molecules/PopoverContent'

export type Account = {
  name: string
  id: number
}
export interface IUserDropdownProps extends IIdentity {
  accounts?: Account[]
  mdashAccountName?: string
  popoverIsOpen?: boolean
  logoutButtonProps?: ButtonProps & {
    logoutButtonAction?: CallableFunction
  }
  className?: string
}

const UserDropdown: FC<IUserDropdownProps> = ({
  isAdmin = false,
  accounts,
  mdashAccountName = '',
  mdashAccountId,
  userName,
  logoutButtonProps = {},
  className,
}) => {
  const [ isOpen, setIsOpen ] = useState( false )
  const { logoutButtonAction, ...remainingLogoutButtonProps } = logoutButtonProps

  return (
    <div className={className}>
      <Popover defaultOpen={false} open={isOpen}>
        <PopoverTrigger onClick={() => setIsOpen( !isOpen )}>
          <div className={isAdmin ? 'text-gray-900' : 'text-white'}>
            <div className="w-8 h-8 flex items-center relative justify-center md:hidden group">
              <div className="w-full h-full absolute z-0 top-0 left-0 group-hover:bg-gb-gray-900/50 mix-blend-multiply rounded block" />
              <FontAwesomeIcon className="relative h-6" icon={faUserAstronaut} />
            </div>
            <div className=" pl-4 pr-2 pt-6 relative pb-5 h-full hidden md:block group">
              <div className="w-full h-full absolute top-0 left-0 z-0 group-hover:bg-gb-gray-900/50 mix-blend-multiply block" />
              <div className="text-sm relative font-bold flex justify-between items-center">
                <span className="pr-2">
                  {truncate( userName, { length: 20 })}
                  {isAdmin && ' (GB Admin)'}&nbsp;
                </span>
                <FontAwesomeIcon icon={faAngleDown} />
              </div>
              <div className="text-xs relative text-left">
                {truncate( mdashAccountName, { length: 25 })}&nbsp;
              </div>
            </div>
          </div>
        </PopoverTrigger>
        <PopoverContent
          onInteractOutside={() => setIsOpen( false )}
          alignOffset={-4}
          align="end"
          useArrow
          arrowOffset={10}
        >
          <a
            href={`/${mdashAccountId}/settings`}
            className="hover:bg-gb-blue-600/25 block rounded w-full text-gb-gray-900 py-1 px-3 text-left"
          >
            <FontAwesomeIcon className="pr-2" icon={faGears} />
            Account Settings
          </a>

          {accounts && accounts.length > 0 && (
            <ul className="mt-2 border-t border-gb-gray-400 pt-2">
              {accounts.map(( account ) => (
                <li
                  key={account.id}
                  className="flex w-full justify-between text-gb-gray-800 py-1 px-3"
                >
                  <span>{truncate( account.name, { length: 25 })}</span>
                  <span>#{account.id}</span>
                </li>
              ))}
            </ul>
          )}

          {!isAdmin && (
            <div className="mt-2 border-t border-gb-gray-400 pt-2 px-3">
              <Button
                onClick={() => {
                  if ( !logoutButtonAction ) return
                  setIsOpen( false )
                  logoutButtonAction()
                }}
                {...remainingLogoutButtonProps}
                outline
              >
                Log Out
              </Button>
            </div>
          )}
        </PopoverContent>
      </Popover>
    </div>
  )
}

export default UserDropdown
