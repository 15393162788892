import { FC, useState } from 'react'
import classNames from 'classnames'
import { isArray } from 'lodash'
import {
  Root as ReduxSelect,
  Value,
  Icon,
  Content,
  Viewport,
  Item,
  ItemText,
  ScrollUpButton,
  ScrollDownButton,
} from '@radix-ui/react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/pro-solid-svg-icons'

import Button, { IProps as IButtonProps } from 'src/components/01-atoms/Button'

interface IDropdownProps extends Pick<IButtonProps, 'size' | 'variant'> {
  /**
   * The key of the selected value.
   */
  selected: string

  /**
   * The available options, either as a key: value object pair or an array of strings.
   */
  options: { [key: string]: string } | string[]

  /**
   * Callback function to run when the value changes.
   */
  handleChange?: CallableFunction

  /**
   * Classes for the trigger button.
   */
  triggerButtonClasses?: string

  /**
   * Whether or not to show the arrow at the top of the dropdown.
   */
  showDropdownArrow?: boolean

  /**
   * Classes for the containing element.
   */
  className?: string
}

/**
 * A fully styled dropdown component. Not intended to be used as a select box for forms.
 * For that, please see the Select component.
 */
const Dropdown: FC<IDropdownProps> = ({
  selected,
  options,
  handleChange,
  triggerButtonClasses,
  showDropdownArrow = false,
  size = 'default',
  variant,
  className,
}) => {
  const [ isOpen, setIsOpen ] = useState( false )

  return (
    <div className={classNames( 'inline-block', className )}>
      <ReduxSelect
        open={isOpen}
        defaultValue={selected}
        onOpenChange={setIsOpen}
        onValueChange={( value: string ) => {
          if ( !handleChange ) return
          handleChange( value )
        }}
      >
        <Button
          outline
          size={size}
          variant={variant}
          className={classNames( 'w-full gap-x-2 justify-between', triggerButtonClasses )}
          onClick={() => {
            setIsOpen( !isOpen )
          }}
        >
          <Value aria-label={selected}>{selected}</Value>
          <Icon>
            <FontAwesomeIcon icon={faChevronDown} />
          </Icon>
        </Button>
        <div className="flex flex-col w-full items-center">
          <Content
            className={classNames( 'block mt-px', {
              'text-xs': size === 'small',
              'text-sm': size === 'default',
            })}
          >
            {showDropdownArrow && (
              <svg
                className="block mb-0 w-5 h-2 text-white fill-white rotate-180 mx-auto"
                width="10"
                height="5"
                viewBox="0 0 30 10"
                preserveAspectRatio="none"
              >
                <polygon points="0,0 30,0 15,10" />
              </svg>
            )}
            <div className="bg-white rounded-lg shadow-lg p-2" data-testid="select-options">
              <ScrollUpButton>
                <FontAwesomeIcon icon={faChevronUp} />
              </ScrollUpButton>
              <Viewport className="max-h-64 overflow-y-scroll">
                {Object.entries( options ).map(([ itemValue, itemLabel ]) => (
                  <Item
                    key={`select-${itemValue}-${itemLabel}`}
                    value={isArray( options ) ? itemLabel : itemValue}
                    textValue={itemLabel}
                    className={classNames(
                      'py-1 px-2 rounded cursor-pointer hover:bg-gb-blue-600/25',
                      {
                        'bg-gb-blue-600/25': selected === itemValue || selected === itemLabel,
                      }
                    )}
                  >
                    <ItemText>{itemLabel}</ItemText>
                  </Item>
                ))}
              </Viewport>
              <ScrollDownButton>
                <FontAwesomeIcon icon={faChevronDown} />
              </ScrollDownButton>
            </div>
          </Content>
        </div>
      </ReduxSelect>
    </div>
  )
}

export default Dropdown
