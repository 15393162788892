import { FC } from 'react'
import { Outlet } from 'react-router-dom'

const FullViewportLayout: FC<{}> = () => (
  <main className="w-[100vw] h-[100vh]">
    <Outlet />
  </main>
)

export default FullViewportLayout
