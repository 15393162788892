import { FC, FormEventHandler } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'

import Button from 'src/components/01-atoms/Button'
import TextInput from 'src/components/01-atoms/TextInput'

interface ILoginFormProps {
  /**
   * The method called when the form is submitted.
   */
  handleSubmit: FormEventHandler<HTMLFormElement>

  /**
   * Props for the email input element, likely from `react-hook-form` register method, but can be an object of string key => value pairs.
   */
  emailProps:
    | UseFormRegisterReturn
    | {
        [key: string]: string
      }

  /**
   * Props for the password input element, likely from `react-hook-form` register method, but can be an object of string key => value pairs.
   */
  passwordProps:
    | UseFormRegisterReturn
    | {
        [key: string]: string
      }
}

const LoginForm: FC<ILoginFormProps> = ({ handleSubmit, emailProps, passwordProps }) => (
  <form onSubmit={handleSubmit}>
    <TextInput
      {...emailProps}
      type="email"
      placeholder="E-Mail"
      className="bg-gb-gray-100 mb-4 text-gb-gray-900  placeholder:text-gb-gray-900"
      required
    />
    <TextInput
      {...passwordProps}
      type="password"
      placeholder="Password"
      className="bg-gb-gray-100 mb-4 text-gb-gray-900  placeholder:text-gb-gray-900"
      required
    />
    <Button type="submit">Sign In</Button>
  </form>
)

export default LoginForm
