import { FC, useState } from 'react'
import classNames from 'classnames'
import { isEmpty } from 'lodash'
import HideWhenNotPrintable from 'src/components/01-atoms/HideWhenNotPrintable'
import PackingSlipPage, { ISlipNLabelThermal4x6PackingSlipPageProps } from './PackingSlipPage'
import ShippingLabelPage, { ISlipNLabelThermal4x6ShippingLabelProps } from './ShippingLabelPage'
import DryIcePage, { ISlipNLabelThermal4x6DryIceProps } from './DryIcePage'

export interface ISlipNLabelThermal4x6Props
  extends Omit<ISlipNLabelThermal4x6PackingSlipPageProps, 'showBarcode'>,
    ISlipNLabelThermal4x6ShippingLabelProps {
  showPackageBarcode?: boolean
  dryIceWeightInKg?: ISlipNLabelThermal4x6DryIceProps['weightInKg']
  showDryIceSticker?: boolean
  showDryIcePlaceholder?: boolean
}

const Thermal4x6: FC<ISlipNLabelThermal4x6Props> = ({
  className,
  labelImgPath,
  dryIceWeightInKg = 0,
  showDryIceSticker = false,
  showDryIcePlaceholder = false,
  handleLabelImageLoad = () => {},
  handleLabelImageError = () => {},
  handleSetPrintable = () => {},
  showPackageBarcode = false,
  ...packingSlipProps
}) => {
  const [ isPrintable, setIsPrintable ] = useState( !isEmpty( labelImgPath ))

  return (
    <HideWhenNotPrintable isPrintable={isPrintable}>
      <div data-testid="printable-thermal-4x6">
        <PackingSlipPage
          {...packingSlipProps}
          showBarcode={showPackageBarcode}
          className={classNames( className, 'screen:border-b-0' )}
        />
        {dryIceWeightInKg > 0 && showDryIceSticker && packingSlipProps.version === 2 && (
          <DryIcePage
            packageId={packingSlipProps.packageId}
            weightInKg={dryIceWeightInKg}
            usePlaceholder={showDryIcePlaceholder}
          />
        )}
        <ShippingLabelPage
          labelImgPath={labelImgPath}
          handleLabelImageLoad={( e ) => handleLabelImageLoad( e )}
          handleLabelImageError={( e ) => {
            setIsPrintable( false )
            if ( !handleLabelImageError ) return
            handleLabelImageError( e )
          }}
          handleSetPrintable={handleSetPrintable}
          className="screen:border-t-0"
        />
      </div>
    </HideWhenNotPrintable>
  )
}

export default Thermal4x6
