import { FC, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/pro-duotone-svg-icons'

import Modal, { IModalProps } from 'src/components/02-molecules/Modal'
import AlertList from 'src/components/02-molecules/AlertList'
import Button from 'src/components/01-atoms/Button'
import { plural } from 'pluralize'
import InlineLink from 'src/components/01-atoms/InlineLink'
import { faCaretDown, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'

interface IPrintConfirmModalProps extends IModalProps {
  entity?: {
    fullName?: string
    shortName?: string
  }
  errors?: string[]
  disableButtons?: boolean
  backButtonCallback?: CallableFunction
  continueButtonCallback?: CallableFunction
}

const PrintConfirmModal: FC<IPrintConfirmModalProps> = ({
  entity,
  errors,
  disableButtons,
  backButtonCallback,
  continueButtonCallback,
  ...modalProps
}) => {
  const [ isOptionsOpen, setIsOptionsOpen ] = useState( false )
  const entityName = entity && entity.shortName && plural( entity.shortName )

  return (
    <Modal
      {...modalProps}
      contentClassName="center w-10/12 sm:max-w-125 md:max-w-125 lg:max-w-125 pt-12 pb-9 px-8 sm:px-14"
    >
      <div data-testid="print-modal" className="flex flex-col center gap-y-6 text-sm lg:w-full">
        <div className="bg-teal-200 text-teal-800 aspect-square w-16 h-16 rounded-full flex center">
          <FontAwesomeIcon icon={faPrint} size="2x" />
        </div>
        <div className="text-center">
          <p className="font-semibold text-lg">
            Did all {entity && entity.fullName && plural( entity.fullName )} print correctly?
          </p>
          {errors && errors.length > 0 && (
            <AlertList type="error" className="mt-4 mx-auto self-center" alerts={errors} />
          )}
        </div>
        <div className="flex flex-col center gap-6 w-full md:w-10/12">
          <Button
            className="center py-3 w-full"
            disabled={disableButtons}
            onClick={() => {
              if ( !continueButtonCallback ) return
              continueButtonCallback()
            }}
          >
            Yes, mark {`${entityName}`} as printed
          </Button>

          <InlineLink
            className="flex items-center gap-2"
            onClick={() => setIsOptionsOpen(( x ) => !x )}
          >
            No. What are my options?
            <FontAwesomeIcon
              icon={faCaretDown}
              className={classNames(
                isOptionsOpen && 'rotate-180 transition-transform duration-300'
              )}
            />
          </InlineLink>
          <AnimatePresence>
            {isOptionsOpen && (
              <motion.div
                className="grid grid-cols-1 gap-4 border-t pt-6"
                initial={{ opacity: 0, maxHeight: 0 }}
                animate={{ opacity: 1, maxHeight: '320px' }}
                exit={{ opacity: 0, maxHeight: 0 }}
              >
                <div>
                  <InlineLink
                    className="text-center"
                    disabled={disableButtons}
                    onClick={() => {
                      if ( !backButtonCallback ) return
                      backButtonCallback()
                      setIsOptionsOpen( false )
                    }}
                  >
                    Let me reprint these {`${entityName}`}
                  </InlineLink>
                  <div className="text-left bg-orange-100 p-2 rounded font-normal text-gb-gray-900 flex items-center gap-2 mt-2">
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      className="text-gb-yellow-500 text-2xl px-2"
                    />
                    <div className="text-red-500">
                      Note: this will temporarily mark the {`${entityName}`} as <i>NOT</i> printed
                      until they are reprinted!
                    </div>
                  </div>
                </div>
                <div>
                  <InlineLink
                    className="text-center"
                    disabled={disableButtons}
                    onClick={() => {
                      if ( !continueButtonCallback ) return
                      continueButtonCallback()
                    }}
                  >
                    Nevermind, I&apos;ll continue
                  </InlineLink>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </Modal>
  )
}

export default PrintConfirmModal
