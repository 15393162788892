import { range } from 'lodash'

interface IStepperInput {
  entityCount: number
  entityPerStep?: number
  maxStepToDisplay?: number
  currentStep?: number
  qualifier?: string
}

// This function selects maxStepToDisplay elements, adjusted by
// lower-bound-upper-bound limits
const buildRange = ( preStepIndex: number, postStepIndex: number, stepCount: number ) => {
  // shifts selection to the right when lower-bound drops below zero
  // Consider: [0 1 2 3 4 5 6 7]
  // At step 0 with max 5 steps to display: [-2 1 0 1 2]
  // We shift right by two: [0 1 2 3 4]
  if ( preStepIndex < 0 ) {
    const diff = 0 - preStepIndex
    return [ preStepIndex + diff, Math.min( stepCount, postStepIndex + diff ) ]
  }

  // shifts selection to the left when upper-bound crosses the limit
  // Consider: [0 1 2 3 4 5 6 7]
  // At step 7 with max steps to display: [5 6 7 8 9]
  // We shift left by two: [3 4 5 6 7]
  if ( postStepIndex > stepCount ) {
    const diff = postStepIndex - stepCount
    return [ Math.max( 0, preStepIndex - diff ), postStepIndex - diff ]
  }

  return [ preStepIndex, postStepIndex ]
}

// This function returns a lower-bound-upper-bound tuple in the form
// of [a, b). The generator should create b - a - 1 amount of array elements
export const build = ({
  entityCount,
  entityPerStep = 100,
  maxStepToDisplay,
  currentStep = 0,
}: IStepperInput ) => {
  const stepCount = Math.ceil( entityCount / entityPerStep )

  // if we don't care about limiting steps to display
  // or we can fit all the steps within maxStepToDisplayParameter
  if ( !maxStepToDisplay || maxStepToDisplay >= stepCount ) return [ 0, stepCount ]

  const halfLength = Math.floor( maxStepToDisplay / 2 )
  const preStepIndex = currentStep - halfLength
  const postStepIndex = currentStep + halfLength + ( maxStepToDisplay % 2 )

  return buildRange( preStepIndex, postStepIndex, stepCount )
}

export const generate = ({
  entityCount,
  entityPerStep = 100,
  maxStepToDisplay,
  currentStep = 0,
  qualifier = 'Batch',
}: IStepperInput ) => {
  const stepCount = Math.ceil( entityCount / entityPerStep )
  const bRange = build({ entityCount, entityPerStep, maxStepToDisplay, currentStep })
  const steps = range( bRange[0], bRange[1]).map(( x ) => {
    const stepNumber = x + 1
    const lb = x * entityPerStep + 1
    const ub = Math.min( stepNumber * entityPerStep, entityCount )

    return {
      stepNumber,
      text: [ `${qualifier}`, `${lb} - ${ub}` ].join( ' ' ),
    }
  })

  return {
    steps,
    hasPreviousPage: bRange[0] > 0,
    hasNextPage: bRange[1] < stepCount,
    currentStep: currentStep - bRange[0],
  }
}
