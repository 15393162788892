import { FC } from 'node_modules/@types/react'

import Address from 'src/components/01-atoms/Address'

import { IAddress } from 'src/utils/types/IAddress'

interface IRestrictedAddressProps {
  address: IAddress
  showFullAddress: boolean
}

const RestrictedAddress: FC<IRestrictedAddressProps> = ({ address, showFullAddress }) =>
  showFullAddress ? (
    <div data-testid="full-address">
      <Address address={address} />
    </div>
  ) : (
    <div data-testid="partial-address">
      <Address
        address={{
          city: address.city,
          state: address.state,
          postalCode: address.postalCode,
          countryCode: address.countryCode,
        }}
      />
    </div>
  )

export default RestrictedAddress
