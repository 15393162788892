import { FC } from 'react'
import Logo, { LogoType } from 'src/components/01-atoms/Logo'
import PrintablePage, { PageSize } from 'src/components/01-atoms/PrintablePage'

import PrintableChecklists from 'src/components/02-molecules/PrintableChecklists'

import { IGetSubProductSummaryQuery } from 'src/graphql/queries/getSubProductSummary.types'
import { shortDate, shortTimeWithMeridiem } from 'src/utils/helpers/date'

interface IProps {
  displaySku?: boolean
  toShipOn: Date
  printedAt: Date
  products: IGetSubProductSummaryQuery['subproductSummary']['products']
  subproducts: IGetSubProductSummaryQuery['subproductSummary']['subproducts']
  totalPackages?: IGetSubProductSummaryQuery['subproductSummary']['totalPackages']
  selectedPackages?: IGetSubProductSummaryQuery['subproductSummary']['selectedPackages']

  /**
   * Classes to pass down to the containing element.
   */
  className?: string
}

const USLetter: FC<IProps> = ({
  displaySku,
  printedAt,
  products,
  toShipOn,
  subproducts,
  totalPackages = 0,
  selectedPackages = 0,
  className = '',
}) => {
  const productsAsEntities = products.map(({ subproducts, id, quantity, name }) => ({
    subentities: subproducts.map(({ name, quantity }) => `${quantity} ${name}` ),
    id,
    quantity,
    name,
  }))

  return (
    <PrintablePage className={className} size={PageSize.US_LETTER_LOOSE}>
      {productsAsEntities && (
        <>
          <div className="flex">
            <div className="pr-6 w-3/4">
              <div className="flex">
                <Logo type={LogoType.STACKED} className="w-auto h-[0.675in] pr-6" />
              </div>
            </div>
            <div className="w-1/4 text-right" data-testid="pick-list-details">
              <h1 className="text-2xl font-semibold">Pick List</h1>
              <div>
                Printed: {shortDate( printedAt )} {shortTimeWithMeridiem( printedAt )}
              </div>
              <div>Ship Date: {shortDate( toShipOn )}</div>
              <div>
                <span className="font-bold">{selectedPackages}</span>
                {` of `}
                <span className="font-bold">{totalPackages}</span>
                {` Total Orders`}
              </div>
            </div>
          </div>
          <div>
            {subproducts.length > 0 && (
              <div className="pb-6" data-testid="checklist-subproducts">
                <PrintableChecklists
                  entities={subproducts}
                  displayCheckbox
                  displaySku={displaySku}
                  pluralizeTitle
                  title="Subproduct"
                />
              </div>
            )}
            <div data-testid="checklist-products">
              <PrintableChecklists
                subEntityTitle="Subproducts"
                entities={productsAsEntities}
                displayCheckbox
                pluralizeTitle
                title="Product"
              />
            </div>
          </div>
        </>
      )}
    </PrintablePage>
  )
}

export default USLetter
