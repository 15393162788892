import { useCallback, useEffect, useState } from 'react'
import { IShoppingCartProps, IProductOptionForUpdate } from '../types'

export const useDirtyTracking = ({
  cartItems,
  onCartItemAddition,
  onCartItemDeletion,
  onProductChange,
  onProductQuantityChange,
  onProductOptionChange,
  onSpecialInstructionsChange,
}: Omit<IShoppingCartProps, 'availableProducts'> ) => {
  const [ latchedCartItems, setLatchedCartItems ] = useState( cartItems )
  const [ isRecalculatingCartItemId, setIsRecalculatingCartItemId ] = useState<string | null>( null )

  const handleCartItemAddition = useCallback(
    ({ productId }: { productId: string }) => {
      if ( onCartItemAddition ) onCartItemAddition({ productId })
      setLatchedCartItems(( prevState ) =>
        prevState.concat({
          product: { id: productId },
          quantity: 1,
          weight: null,
          unitPriceInCents: 0,
          subtotalInCents: 0,
          selectedProductOptions: [],
          id: String( new Date().getTime()), // needs to wait BE confirmation
          isDirty: true, // so temporarily assign random ID
        })
      )
    },
    [ onCartItemAddition, setLatchedCartItems ]
  )

  const handleCartItemDeletion = useCallback(
    ({ cartItemId }: { cartItemId: string }) => {
      if ( onCartItemDeletion ) onCartItemDeletion({ cartItemId })
      setLatchedCartItems(( prevState ) => prevState.filter(( x ) => x.id !== cartItemId ))
    },
    [ onCartItemDeletion, setLatchedCartItems ]
  )

  const handleProductChange = useCallback(
    ({ cartItemId, productId }: { cartItemId: string; productId: string }) =>
      setLatchedCartItems(( prevState ) => {
        const affectedCartItem = prevState.find(( x ) => x.id === cartItemId )

        if ( !affectedCartItem ) return prevState

        if ( onProductChange ) {
          onProductChange({ cartItemId, productId })
          setIsRecalculatingCartItemId( cartItemId )
        }

        return prevState
          .filter(( x ) => x.id !== cartItemId )
          .concat({
            id: cartItemId,
            quantity: 1,
            weight: null,
            product: { id: productId },
            unitPriceInCents: 0,
            subtotalInCents: 0,
            selectedProductOptions: [],
          })
      }),
    [ onProductChange, setIsRecalculatingCartItemId, setLatchedCartItems ]
  )

  const handleProductQuantityChange = useCallback(
    ({ cartItemId, quantity }: { cartItemId: string; quantity: number }) =>
      setLatchedCartItems(( prevState ) => {
        const affectedCartItem = prevState.find(( x ) => x.id === cartItemId )

        if ( !affectedCartItem ) return prevState

        if ( onProductQuantityChange ) onProductQuantityChange({ cartItemId, value: quantity })

        return prevState
          .filter(( x ) => x.id !== cartItemId )
          .concat({ ...affectedCartItem, quantity })
      }),
    [ onProductQuantityChange, setLatchedCartItems ]
  )

  const handleProductOptionChange = useCallback(
    ({ cartItemId, varianceId, optionId }: IProductOptionForUpdate ) =>
      setLatchedCartItems(( prevState ) => {
        const affectedCartItem = prevState.find(( x ) => x.id === cartItemId )

        if ( !affectedCartItem ) return prevState

        if ( onProductOptionChange ) {
          const cartItemOptions = affectedCartItem.selectedProductOptions
          const allOptionsWithUpdates = cartItemOptions.map(( o ) => ({
            cartItemId,
            optionId: o.productOptionId,
            varianceId: o.varianceId,
          })) as IProductOptionForUpdate[]
          const affectedOptIndex = allOptionsWithUpdates.findIndex(
            ( o ) => o.varianceId === varianceId
          )
          if ( affectedOptIndex >= 0 ) {
            allOptionsWithUpdates[affectedOptIndex] = { cartItemId, optionId, varianceId }
          } else {
            allOptionsWithUpdates.push({ cartItemId, optionId, varianceId })
          }
          onProductOptionChange( allOptionsWithUpdates )
          setIsRecalculatingCartItemId( cartItemId )
        }

        return prevState
          .filter(( x ) => x.id !== cartItemId )
          .concat({
            ...affectedCartItem,
            unitPriceInCents: 0,
            subtotalInCents: 0,
          })
      }),
    [ onProductOptionChange, setLatchedCartItems, setIsRecalculatingCartItemId ]
  )

  const handleSpecialInstructionsChange = useCallback(
    ({ cartItemId, specialInstructions }: { cartItemId: string; specialInstructions: string }) =>
      setLatchedCartItems( prevState => {
        const affectedCartItem = prevState.find(( x ) => x.id === cartItemId )

        if ( !affectedCartItem ) return prevState

        if ( onSpecialInstructionsChange ) onSpecialInstructionsChange({ cartItemId, specialInstructions })

        return prevState
          .filter(( x ) => x.id !== cartItemId )
          .concat({ ...affectedCartItem, specialInstructions })
      }),
    [ onSpecialInstructionsChange, setLatchedCartItems ]
  );

  useEffect(() => {
    setLatchedCartItems( cartItems )
    setIsRecalculatingCartItemId( null )
  }, [ cartItems, setLatchedCartItems, setIsRecalculatingCartItemId ])

  return {
    latchedCartItems,
    handleCartItemAddition,
    handleCartItemDeletion,
    handleProductChange,
    handleProductQuantityChange,
    handleProductOptionChange,
    handleSpecialInstructionsChange,
    isRecalculatingCartItemId,
  }
}
