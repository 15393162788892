import { IUpdateShippingInfoMutationVariables } from 'src/graphql/mutations/updateShippingInfo.types'

export type ShippingInfoOnly = Omit<IUpdateShippingInfoMutationVariables, 'orderId'>

export const isShippingAddressIncomplete = ({
  deliveryFirstName,
  deliveryLastName,
  deliveryStreet1,
  deliveryCity,
  deliveryState,
  deliveryPostalCode,
}: ShippingInfoOnly ) =>
  !(
    deliveryFirstName &&
    deliveryLastName &&
    deliveryStreet1 &&
    deliveryCity &&
    deliveryState &&
    deliveryPostalCode
  )

export const addressOnly = [
  'deliveryStreet1',
  'deliveryStreet2',
  'deliveryCity',
  'deliveryState',
  'deliveryPostalCode',
]

export const requiredShippingFieldNames = [
  'deliveryFirstName',
  'deliveryLastName',
  'deliveryStreet1',
  'deliveryCity',
  'deliveryState',
  'deliveryPostalCode',
]

export const allShippingFieldNames = [
  'deliveryCompany',
  'deliveryStreet2',
  'recipientEmail',
  'deliveryPhone',
  ...requiredShippingFieldNames,
]
