import { loader } from 'graphql.macro'
import {
  IGetPackagesWithLabelIssueQuery,
  IGetPackagesWithLabelIssueQueryVariables,
} from 'src/graphql/queries/getPackagesWithLabelIssue.types'
import { useQuery, UseQueryResponse } from 'urql'
import { IUseGetManifestVariables } from '../types/GetManifestHookQueryTypes'
import useAppParams from './useAppParams'

const getPackagesWithLabelIssue = loader( 'src/graphql/queries/getPackagesWithLabelIssue.graphql' )

const useGetPackagesWithLabelIssue = ({
  variables,
}: IUseGetManifestVariables<
  IGetPackagesWithLabelIssueQuery,
  IGetPackagesWithLabelIssueQueryVariables
> ): UseQueryResponse<IGetPackagesWithLabelIssueQuery, IGetPackagesWithLabelIssueQueryVariables> => {
  const { toShipOnAsString: toShipOn } = useAppParams()
  const [ response, refetch ] = useQuery<
    IGetPackagesWithLabelIssueQuery,
    IGetPackagesWithLabelIssueQueryVariables
  >({
    query: getPackagesWithLabelIssue,
    variables: {
      toShipOn,
      ...variables,
    },
    requestPolicy: 'cache-and-network',
  })

  return [ response, refetch ]
}

export default useGetPackagesWithLabelIssue
