import { decimalToPercent } from 'src/utils/helpers/math'

/**
 * @param {number} val The original value.
 * @returns {number} The value formatted for percentage
 */
export const getIssuePercentageValue = ( val: number | null | undefined ): String => {
  const percentDisplay = val ? decimalToPercent( val ) : 0
  return percentDisplay <= 0.1 && percentDisplay > 0 ? '<0.1%' : `${percentDisplay}%`
}
