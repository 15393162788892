import { FieldValues, FormState } from 'react-hook-form'

const getFieldError = <T extends FieldValues>(
  formState: FormState<T>,
  fieldName: string
): string | undefined => formState.errors[fieldName]?.message?.toString() || undefined

const getFieldErrors = <T extends FieldValues>(
  formState: FormState<T>,
  fieldNames: string[]
): Record<string, string> => {
  const errors: Record<string, string> = {}
  fieldNames.forEach(( fieldName ) => {
    const errorMsg = getFieldError( formState, fieldName )
    if ( !errorMsg ) return
    errors[fieldName] = errorMsg
  })
  return errors
}

export { getFieldError, getFieldErrors }
