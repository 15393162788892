import { faArrowUpRightFromSquare } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { IGetMdashAccountUserAdoptionsQuery } from 'src/graphql/queries/getMdashAccountUserAdoptions.types'

interface IProps {
  adoptionData?: IGetMdashAccountUserAdoptionsQuery
}

const Header: FC<IProps> = ({ adoptionData }) => (
  <div>
    {adoptionData?.mdashAccount && (
      <div className="border-b border-b-gb-gray-500 pb-2 mb-2">
        <Link
          className="text-xl bold"
          to={`/${adoptionData.mdashAccount.id}/manifest`}
          target="_manifest"
        >
          {adoptionData.mdashAccount.name}
          <FontAwesomeIcon className="underline pl-2" icon={faArrowUpRightFromSquare} />
        </Link>
      </div>
    )}

    {adoptionData?.mdashAccount?.merchantUserAccessLogs.length === 0 && (
      <div>No Access Logs found for this Account</div>
    )}
  </div>
)

export default Header
