import { FC } from 'react'
import Button from 'src/components/01-atoms/Button'

import Table from 'src/components/02-molecules/Table'
import { IGetShipperInvoicesQuery } from 'src/graphql/queries/getShipperInvoices.types'

import './styles.css'

interface IStatementsTableProps {
  lineItems: IGetShipperInvoicesQuery['shipperInvoices']
}

const StatementsTable: FC<IStatementsTableProps> = ({ lineItems }) => {
  const tableHeaders = [
    { name: 'ID' },
    { name: 'Period' },
    { name: 'Invoice Total' },
    { name: 'Details' },
  ]
  const columnWidths = [{}, {}, {}, { className: 'lg:w-32' }]

  const tableCellClasses = 'flex gap-x-4 first-of-type:pt-4 pb-4 pl-4 pr-4 lg:p-4 lg:table-cell '

  return (
    <Table
      tableClasses="statements"
      headers={tableHeaders}
      borderRule="headers-only"
      columnWidths={columnWidths}
    >
      {lineItems &&
        lineItems.map(({ id, invoiceableTotal, statementStart, statementEnd }, index ) => (
          <tr
            key={`statement-${id}`}
            className="flex flex-col even:bg-gb-gray-50 odd:bg-white lg:table-row"
            data-testid={`statement-row-${index}`}
          >
            <td className={tableCellClasses}>
              <div className="font-bold w-1/3 lg:hidden">{tableHeaders[0].name}</div>
              {id}
            </td>
            <td className={tableCellClasses}>
              <div className="font-bold w-1/3 lg:hidden">{tableHeaders[1].name}</div>
              {statementStart} - {statementEnd}
            </td>
            <td className={tableCellClasses}>
              <div className="font-bold w-1/3 lg:hidden">{tableHeaders[2].name}</div>
              {invoiceableTotal}
            </td>
            <td className={tableCellClasses}>
              <div className="font-bold w-1/3 lg:hidden">{tableHeaders[3].name}</div>
              <Button className="justify-center" outline to={`${id}`}>
                View
              </Button>
            </td>
          </tr>
        ))}
    </Table>
  )
}

export default StatementsTable
