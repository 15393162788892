import { FC, useMemo, useState } from 'react'
import MDashHead from 'src/pages/elements/MDashHead'
import Container from 'src/components/01-atoms/Container'
import SectionCard from 'src/components/01-atoms/SectionCard'
import IssuesTable, { IIssuesTableProps } from 'src/components/02-molecules/IssuesTable'
import { Outlet } from 'react-router-dom'
import IssuesContext from 'src/contexts/IssuesContext'
import DatePicker from 'src/components/01-atoms/DatePicker'
import { dateForManifest, getPrevWeek, getToday } from 'src/utils/helpers/date'
import { RequestPolicy, useQuery } from 'urql'
import {
  IGetMissedShipmentIssuesQuery,
  IGetMissedShipmentIssuesQueryVariables,
} from 'src/graphql/queries/getMissedShipmentIssues.types'
import { loader } from 'graphql.macro'
import useAppParams from 'src/utils/hooks/useAppParams'
import Alert from 'src/components/01-atoms/Alert'
import Skeleton from 'react-loading-skeleton'
import {
  IGetReshipmentIssuesQuery,
  IGetReshipmentIssuesQueryVariables,
} from 'src/graphql/queries/getReshipmentIssues.types'
import {
  IGetRefundIssuesQuery,
  IGetRefundIssuesQueryVariables,
} from 'src/graphql/queries/getRefundIssues.types'

import IssuesTabs from './elements/IssuesTabs'

const getMissedShipmentIssues = loader( 'src/graphql/queries/getMissedShipmentIssues.graphql' )
const getReshipmentIssues = loader( 'src/graphql/queries/getReshipmentIssues.graphql' )
const getRefundIssues = loader( 'src/graphql/queries/getRefundIssues.graphql' )

const Issues: FC = () => {
  const { mdashAccountId } = useAppParams()
  const [ startDate, setStartDate ] = useState<Date | null>( getPrevWeek( getPrevWeek( getToday())))
  const [ endDate, setEndDate ] = useState<Date | null>( getToday())

  const onStartDateChanged = ( date: Date | null ) => {
    if ( !date ) return
    setStartDate( date )
    if ( endDate && date > endDate ) {
      setEndDate( null )
    }
  }

  const onEndDateChanged = ( date: Date | null ) => {
    if ( !date ) return
    setEndDate( date )
  }

  const queryConfig = {
    variables: {
      mdashAccountId: mdashAccountId ?? '',
      startDate: startDate ? dateForManifest( startDate ) : null,
      endDate: endDate ? dateForManifest( endDate ) : null,
    },
    requestPolicy: 'cache-and-network' as RequestPolicy,
    pause: !startDate || !endDate,
  }

  const [
    { fetching: fetchingMissedShipments, error: errorMissedShipments, data: missedShipments },
  ] = useQuery<IGetMissedShipmentIssuesQuery, IGetMissedShipmentIssuesQueryVariables>({
    query: getMissedShipmentIssues,
    ...queryConfig,
  })

  const [{ fetching: fetchingReshipments, error: errorReshipments, data: reshipments }] = useQuery<
    IGetReshipmentIssuesQuery,
    IGetReshipmentIssuesQueryVariables
  >({
    query: getReshipmentIssues,
    ...queryConfig,
  })

  const [{ fetching: fetchingRefunds, error: errorRefunds, data: refunds }] = useQuery<
    IGetRefundIssuesQuery,
    IGetRefundIssuesQueryVariables
  >({
    query: getRefundIssues,
    ...queryConfig,
  })

  const issuesContextValue = useMemo(
    () => ({
      missedShipmentIssues: missedShipments?.missedShipmentIssues,
      reshipmentIssues: reshipments?.reshipmentIssues,
      refundIssues: refunds?.refundIssues,
    }),
    [ missedShipments, reshipments, refunds ]
  )

  const issues: IIssuesTableProps['issueItems'] = [
    {
      type: 'Missed Shipments',
      ...missedShipments?.missedShipmentIssues,
    },
    {
      type: 'Reshipments',
      ...reshipments?.reshipmentIssues,
    },
    {
      type: 'Refunds',
      ...refunds?.refundIssues,
    },
  ]

  const fetching = fetchingMissedShipments || fetchingReshipments || fetchingRefunds
  return (
    <IssuesContext.Provider value={issuesContextValue}>
      <div className="flex grow flex-col">
        <MDashHead pageTitle="Package Issues" />
        <div className="grow-0 py-2 lg:py-6 border-b border-b-gb-gray-400">
          <Container>
            <h1 className="text-2xl font-semibold mb-2">Package Issues</h1>
            <div className="md:w-72 grid grid-cols-2 gap-4" data-test-id="datepicker">
              <div>
                <div className="mb-1 text-sm pl-1">Start date</div>
                <DatePicker
                  selected={startDate}
                  startDate={startDate}
                  onChange={( date ) => onStartDateChanged( date )}
                />
              </div>
              <div>
                <div className="mb-1 text-sm pl-1">End date*</div>
                <DatePicker
                  selected={endDate}
                  startDate={endDate}
                  excludeDateIntervals={[{ start: new Date( 0 ), end: startDate ?? new Date() }]}
                  onChange={( date ) => onEndDateChanged( date )}
                />
              </div>
            </div>
            <p className="text-xs text-gb-gray-800 py-2">
              *Packages with issues more recent than your latest statement period are subject to
              change since they still need to be reviewed by our Finance Team.
            </p>
          </Container>
        </div>
        <div className="bg-white flex flex-col grow">
          <Container className="flex flex-col grow pt-6 pb-9">
            {fetching && <Skeleton className="mt-4.5" height="80vh" />}
            {!fetching && ( !missedShipments || errorMissedShipments ) && (
              <Alert type="error">{errorMissedShipments?.message}</Alert>
            )}
            {!fetching && ( !refunds || errorRefunds ) && (
              <Alert type="error">{errorRefunds?.message}</Alert>
            )}
            {!fetching && ( !reshipments || errorReshipments ) && (
              <Alert type="error">{errorReshipments?.message}</Alert>
            )}

            {issues && (
              <SectionCard className="col-span-12 md:w-2/3">
                <div data-testid="issues-table">
                  <IssuesTable issueItems={issues} />
                </div>
              </SectionCard>
            )}
            <IssuesTabs />
            <Outlet />
          </Container>
        </div>
      </div>
    </IssuesContext.Provider>
  )
}

export default Issues
