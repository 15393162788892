import { FC, useState } from 'react'
import { OperationResult, useMutation, useQuery } from 'urql'
import { loader } from 'graphql.macro'

import { IDeliveryDate } from 'src/components/02-molecules/DeliveryDatePicker'
import SelectDeliveryDateModal, {
  ISelectDeliveryDateModal,
} from 'src/components/03-organisms/SelectDeliveryDateModal'

import useAppParams from 'src/utils/hooks/useAppParams'
import { dateForManifest } from 'src/utils/helpers/date'

import {
  IGetPackageDeliveryOptionsQuery,
  IGetPackageDeliveryOptionsQueryVariables,
} from 'src/graphql/queries/getPackageDeliveryOptions.types'
import {
  IEditDeliveryDateOnCompletedSuborderMutation,
  IEditDeliveryDateOnCompletedSuborderMutationVariables,
} from 'src/graphql/mutations/editDeliveryDateOnCompletedSuborder.types'

interface IEditDeliveryDateModal extends Omit<ISelectDeliveryDateModal, 'handleChange'> {
  selected: Date
  packageId: string
}

const getPackageDeliveryOptions = loader( 'src/graphql/queries/getPackageDeliveryOptions.graphql' )
const editDeliveryDateOnCompletedSuborder = loader(
  'src/graphql/mutations/editDeliveryDateOnCompletedSuborder.graphql'
)

const EditDeliveryDateModal: FC<IEditDeliveryDateModal> = ({
  selected,
  packageId,
  ...modalProps
}) => {
  const { mdashAccountId } = useAppParams()
  const [ savedLastResponse, setSavedLastResponse ] =
    useState<OperationResult<IEditDeliveryDateOnCompletedSuborderMutation> | null>( null )
  const [ saving, setSaving ] = useState( false )

  const [{ fetching, data, error }] = useQuery<
    IGetPackageDeliveryOptionsQuery,
    IGetPackageDeliveryOptionsQueryVariables
  >({
    query: getPackageDeliveryOptions,
    variables: { mdashAccountId, packageId },
    pause: !packageId || !modalProps.open,
  })

  const [ , editDeliveryDate ] = useMutation<
    IEditDeliveryDateOnCompletedSuborderMutation,
    IEditDeliveryDateOnCompletedSuborderMutationVariables
  >( editDeliveryDateOnCompletedSuborder )

  const shippingCostOffsetInCents = data?.package?.shippingChargedToCustomerInCents ?? 0
  const isUpchargeable = data?.package?.isUpchargeable ?? false

  const saveDeliveryDate = ( newDate: Date ) => {
    setSaving( true )
    editDeliveryDate({
      mdashAccountId,
      packageId,
      deliveryOn: dateForManifest( newDate ),
    }).then(( response ) => {
      setSavedLastResponse( response )
      setSaving( false )
    })
  }

  const errors =
    error?.message ||
    savedLastResponse?.error?.message ||
    savedLastResponse?.data?.editDeliveryDate?.errors.join( ' ' )

  const hasShippingCalendar =
    data?.package?.shippingCalendarData && data?.package?.shippingCalendarData?.length > 0
  const shippingCalendar =
    hasShippingCalendar &&
    ( !isUpchargeable
      ? data?.package?.shippingCalendarData?.filter(
          ( d ) => d.rateInCents <= shippingCostOffsetInCents
        )
      : data?.package?.shippingCalendarData )

  return (
    <SelectDeliveryDateModal
      {...modalProps}
      handleClose={() => {
        modalProps.handleClose?.()
        setSavedLastResponse( null )
      }}
      shippingCalendar={( shippingCalendar as IDeliveryDate[]) ?? []}
      selected={selected}
      handleSave={( date: Date ) => saveDeliveryDate( date )}
      loading={fetching}
      saving={saving}
      showShippingCost={isUpchargeable}
      error={errors}
      success={errors?.length === 0}
      shippingChargedToCustomerInCents={shippingCostOffsetInCents}
    />
  )
}

export default EditDeliveryDateModal
