import { format, isEqual } from 'date-fns'
import { loader } from 'graphql.macro'
import { useIntervalWhen } from 'rooks'
import {
  IGetManifestCalendarQuery,
  IGetManifestCalendarQueryVariables,
} from 'src/graphql/queries/getManifestCalendar.types'
import {
  IGetManifestIssuesCalendarQuery,
  IGetManifestIssuesCalendarQueryVariables,
} from 'src/graphql/queries/getManifestIssuesCalendar.types'
import { IManifestCalendarInterval } from 'src/graphql/types'
import useAppParams from 'src/utils/hooks/useAppParams'
import { useQuery } from 'urql'

const getManifestCalendar = loader( 'src/graphql/queries/getManifestCalendar.graphql' )
const getManifestIssuesCalendar = loader( 'src/graphql/queries/getManifestIssuesCalendar.graphql' )

const useShippingIssueCalendarPair = ({ toShipOn }: { toShipOn: Date }) => {
  const { toShipOn: currentToShipOn } = useAppParams()

  const [ shippingResponse, refetchShipData ] = useQuery<
    IGetManifestCalendarQuery,
    IGetManifestCalendarQueryVariables
  >({
    query: getManifestCalendar,
    variables: {
      toShipOn: format( toShipOn, 'yyyy-MM-dd' ),
      interval: IManifestCalendarInterval.DAILY,
    },
    requestPolicy: 'cache-and-network',
  })

  const [ issueResponse, refetchIssueData ] = useQuery<
    IGetManifestIssuesCalendarQuery,
    IGetManifestIssuesCalendarQueryVariables
  >({
    query: getManifestIssuesCalendar,
    variables: {
      toShipOn: format( toShipOn, 'yyyy-MM-dd' ),
      interval: IManifestCalendarInterval.DAILY,
    },
    requestPolicy: 'cache-and-network',
  })

  useIntervalWhen(
    () => {
      refetchShipData()
      refetchIssueData()
    },
    30 * 1000, // refresh once every 30 seconds
    isEqual( currentToShipOn, toShipOn )
  )

  return {
    toShipOn,
    isShippingDataPending: shippingResponse.fetching,
    isIssueDataPending: issueResponse.fetching,
    packagesToShipCount: shippingResponse.data?.manifestCalendar[0]?.toShipCount ?? 0,
    packagesWithLabelIssueCount:
      issueResponse.data?.manifestIssuesCalendar[0]?.labelIssuesCount ?? 0,
  }
}

export default useShippingIssueCalendarPair
