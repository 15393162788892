import { FC, useState } from 'react'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { Popover, PopoverTrigger } from '@radix-ui/react-popover'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'

import NavDropdownContent, {
  INavDropdownContent,
} from 'src/components/02-molecules/NavDropdownContent'

interface IMainNavDropdown extends INavDropdownContent {
  /**
   * The title of the dropdown.
   */
  title?: string

  /**
   * Classes to pass down to the containing element.
   */
  className?: string
}

const MainNavDropdown: FC<IMainNavDropdown> = ({ className, title, links }) => {
  const [ isOpen, setIsOpen ] = useState( false )
  const location = useLocation()
  const isActive: boolean =
    links.filter(({ to }) => location.pathname.includes( to.toString())).length > 0
  const titleClasses = classNames(
    'text-sm px-3 py-2 relative font-semibold hover:no-underline flex items-center',
    'after:content-[""] after:block after:w-full after:h-1 after:rounded-xl after:absolute after:left-0 after:-bottom-[2px]',
    {
      'text-gb-gray-800 hidden md:flex': !isActive,
      'text-gb-gray-900 md:after:bg-gb-blue-600': isActive,
    }
  )
  return (
    <div className={className}>
      <Popover defaultOpen={false} open={isOpen}>
        <PopoverTrigger className="hidden md:flex" onClick={() => setIsOpen( !isOpen )}>
          <div className={titleClasses}>
            {title} <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
          </div>
        </PopoverTrigger>
        <div className={classNames( titleClasses, 'md:hidden' )}>{title}</div>
        <NavDropdownContent
          links={links}
          className="w-72 z-100"
          onInteractOutside={() => setIsOpen( false )}
          handleLinkClick={() => setIsOpen( false )}
          useArrow
        />
      </Popover>
    </div>
  )
}

export default MainNavDropdown
