import { faStore } from '@fortawesome/pro-duotone-svg-icons'
import { FC, useState } from 'react'

import Button from 'src/components/01-atoms/Button'
import ChoiceInput from 'src/components/01-atoms/ChoiceInput'
import CircleIcon from 'src/components/01-atoms/CircleIcon'
import AlertList from 'src/components/02-molecules/AlertList'
import FinishButton from 'src/components/02-molecules/FinishButton'
import Modal, { IModalProps } from 'src/components/02-molecules/Modal'

import { Facility } from 'src/utils/types/Facility'

export interface IAssignFacilityModalProps extends IModalProps {
  /**
   * The number of packages being assigned.
   */
  numPackages: number

  /**
   * The available facilities for which packages can be assigned.
   */
  facilities: Facility[]

  /**
   * The value to select by default.
   */
  defaultValue?: Facility

  /**
   * The method to call when the form is submitted.
   */
  handleSubmit?: ( selected: Facility ) => void

  /**
   * The method to call to close the modal.
   */
  handleClose?: () => void

  /**
   * The method to call when the user clicks the `Done` button.
   */
  handleDone?: () => void

  /**
   * A list of errors to display when something goes wrong.
   */
  errors?: string[]

  /**
   * When the assignment is in progress.
   */
  fetching?: boolean

  /**
   * Whether or not the assignment was completed successfully.
   */
  success?: boolean
}

const AssignFacilityModal: FC<IAssignFacilityModalProps> = ({
  numPackages,
  facilities,
  defaultValue,
  handleSubmit = () => {},
  handleClose = () => {},
  handleDone = () => {},
  errors = [],
  fetching = false,
  success = false,
  ...modalProps
}) => {
  const [ selected, setSelected ] = useState<Facility | undefined>( defaultValue )

  return (
    <Modal
      {...modalProps}
      showCloseButton
      closeOnBlur
      takeoverOnMobile
      handleClose={success ? handleDone : handleClose}
      contentClassName="md:max-w-175 lg:max-w-175"
    >
      <div data-testid="facility-assignment-modal">
        <div className="relative flex flex-col gap-y-4 mb-6 mt-5 md:mt-3 md:w-2/3 md:mx-auto">
          <div className="flex flex-col gap-2 items-center text-center">
            <CircleIcon size="large" color="indigo" icons={[ faStore ]} />
            <div className="text-gb-gray-900 text-lg font-semibold mx-4 leading-tight">
              Assign <span className="font-bold">{numPackages} Packages</span> to a Location
            </div>
            <div className="mx-4">
              Select which location you would like to assign these packages to:
            </div>
          </div>
          {errors.length > 0 && <AlertList alerts={errors} type="error" />}
          {facilities.map(( f ) => (
            <ChoiceInput
              key={`assign-facility-${f.id}-${f.type}-${f.name}`}
              type="radio"
              variant="large"
              labelText={f.name}
              value={f.id}
              checked={selected?.id === f.id}
              disabled={fetching || success}
              name="assign-to-facility"
              onChange={( e ) =>
                setSelected( facilities.filter(( f ) => f.id === e.currentTarget.value )[0])
              }
            />
          ))}
        </div>
        <div className="flex gap-3 justify-center">
          <Button
            onClick={handleClose}
            className="flex-1 center md:flex-initial"
            disabled={fetching || success}
            outline
          >
            No, Cancel
          </Button>
          {success ? (
            <FinishButton className="flex-1 center md:flex-initial" onClick={handleDone}>
              Done
            </FinishButton>
          ) : (
            <Button
              onClick={() => {
                if ( !selected || Object.keys( selected ).length === 0 ) return
                handleSubmit( selected )
              }}
              className="flex-1 center md:flex-initial"
              disabled={!selected || fetching}
            >
              Yes, Assign
            </Button>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default AssignFacilityModal
