import { FC, InputHTMLAttributes, forwardRef, Ref } from 'react'
import classNames from 'classnames'

interface ITextAreaProps extends Omit<InputHTMLAttributes<HTMLTextAreaElement>, 'type'> {
  labelText?: string
  ref?: Ref<HTMLTextAreaElement>
  outline?: boolean
  showLabel?: boolean
  wrapperClassName?: string
}

const TextArea: FC<ITextAreaProps> = forwardRef<HTMLTextAreaElement, ITextAreaProps>(
  ({ labelText, showLabel, className, outline = false, wrapperClassName, ...inputProps }, ref ) => (
    <label htmlFor={inputProps.id} className={wrapperClassName}>
      <span
        className={classNames(
          {
            'sr-only': !showLabel,
          },
          'mb-1.5 inline-block text-gb-gray-900 font-weight-normal font-medium text-sm'
        )}
      >
        {labelText ?? inputProps.placeholder}
      </span>
      <textarea
        ref={ref}
        className={classNames(
          'w-full p-2 rounded text-sm focus:outline-gb-blue-600 bg-white placeholder:text-gb-gray-800 disabled:placeholder-shown:opacity-50 disabled:bg-gb-gray-100 invalid:border-red-600',
          {
            'border-2 border-gb-gray-400': outline,
          },
          className
        )}
        {...inputProps}
      />
    </label>
  )
)

export default TextArea
