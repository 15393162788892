import { FC, PropsWithChildren, useState } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faXmark } from '@fortawesome/pro-solid-svg-icons'
import { Link } from 'react-router-dom'

import Button from 'src/components/01-atoms/Button'
import { IMainNavProps } from 'src/components/03-organisms/MainNav/interface'

import useAppParams from 'src/utils/hooks/useAppParams'
import ProtectedByPermission from 'src/pages/elements/ProtectedByPermission'

import Modal from '../Modal'

const HamburgerNav: FC<PropsWithChildren<IMainNavProps>> = ({
  linkPrefix,
  productsLinks,
  performanceLinks,
  purchasersLinks,
  showStatements = false,
}) => {
  const [ isOpen, setIsOpen ] = useState( false )
  const { isAdmin, mdashAccountId, makeLinkUrls } = useAppParams()
  const btnClass =
    'hover:bg-gb-blue-600/25 block rounded w-full py-1 px-3 text-left transition-colors'

  return (
    <div className="md:hidden">
      <button
        type="button"
        onClick={() => setIsOpen( !isOpen )}
        data-testid="mobile-menu-trigger"
        className="text-gb-gray-800 cursor-pointer transition-opacity hover:opacity-80"
      >
        <span className="sr-only">Open Mobile Menu</span>
        <FontAwesomeIcon className="mr-1 mt-1 text-2xl" icon={faBars} />
      </button>
      <Modal contentClassName="sm:min-w-[500px]" takeoverOnMobile open={isOpen}>
        <div className="text-3xl text-gb-gray-800 -mt-4">
          <FontAwesomeIcon
            className="mr-1 p-1 transition-opacity hover:opacity-80 cursor-pointer"
            icon={faXmark}
            onClick={() => setIsOpen( false )}
          />
        </div>
        <nav
          className="w-full text-gb-gray-900"
          data-testid="mobile-nav"
          aria-label="Mobile Navigation"
        >
          {mdashAccountId ? (
            <div className="gap-2 flex flex-col items-center">
              <Link
                to={`/${linkPrefix}/manifest`}
                className={btnClass}
                onClick={() => setIsOpen( !isOpen )}
              >
                Orders
              </Link>
              {productsLinks && (
                <>
                  <div className="w-full px-3">Products</div>
                  <div className="w-full pl-6">
                    {productsLinks.map(( link ) => (
                      <Link
                        key={link.text}
                        to={link.to}
                        className={btnClass}
                        onClick={() => setIsOpen( !isOpen )}
                      >
                        {link.text}
                      </Link>
                    ))}
                  </div>
                </>
              )}

              {performanceLinks && (
                <>
                  <div className="w-full px-3">Performance</div>
                  <div className="w-full pl-6">
                    {performanceLinks.map(( link ) => (
                      <Link
                        key={link.text}
                        to={link.to}
                        className={btnClass}
                        onClick={() => setIsOpen( !isOpen )}
                      >
                        {link.text}
                      </Link>
                    ))}
                  </div>
                </>
              )}

              {purchasersLinks && purchasersLinks.length && (
                <Link className={btnClass} to={purchasersLinks[0].to}>
                  {purchasersLinks[0].text}
                </Link>
              )}

              {showStatements && (
                <Link
                  to={`/${linkPrefix}/statements`}
                  className={btnClass}
                  onClick={() => setIsOpen( !isOpen )}
                >
                  Statements
                </Link>
              )}
              {isAdmin && (
                <>
                  <div className="w-full px-3">Admin Tools</div>
                  <div className="w-full pl-6">
                    <Link
                      to={`/${linkPrefix}/auto_assignments`}
                      className={btnClass}
                      onClick={() => setIsOpen( !isOpen )}
                    >
                      Facility AutoAssignment
                    </Link>
                    <Link
                      to={`/${linkPrefix}/product_tags`}
                      className={btnClass}
                      onClick={() => setIsOpen( !isOpen )}
                    >
                      Product Tags
                    </Link>
                  </div>
                </>
              )}

              <ProtectedByPermission userPermissions={[ 'canUseGuac' ]}>
                <Button
                  to={makeLinkUrls().checkout( 'new' )}
                  className={classNames( btnClass, 'rounded-md' )}
                  onClick={() => setIsOpen( !isOpen )}
                >
                  Place an Order
                </Button>
              </ProtectedByPermission>
            </div>
          ) : (
            <div className="gap-2 flex flex-col items-center">
              <Link to="/admin/issues" className={btnClass}>
                Label Issues
              </Link>
              <Link to="/admin/printmap" className={btnClass}>
                Print Map
              </Link>
              <Link to="/admin/adoption" className={btnClass}>
                Adoption
              </Link>
              <Link to="/admin/sigma-example" className={btnClass}>
                Sigma Example
              </Link>
            </div>
          )}
        </nav>
      </Modal>
    </div>
  )
}

export default HamburgerNav
