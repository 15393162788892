import { FC } from 'react'

import CardAccordion from 'src/components/02-molecules/CardAccordion'
import CardAccordionContent from 'src/components/02-molecules/CardAccordion/content'
import CardAccordionHeader from 'src/components/02-molecules/CardAccordion/header'

import { centsToUSD } from 'src/utils/helpers/currencies'
import { longFormatDayAndDate } from 'src/utils/helpers/date'
import PackageTag, { PackageType } from 'src/components/01-atoms/PackageTag'
import OrderDetailBody, { IOrderDetailBodyProps } from './OrderDetailBody'

export interface IOrderPreviewProps {
  /**
   * The order ID.
   */
  orderId: string

  /**
   * The date the order was placed.
   */
  placedOn: Date

  /**
   * Whether or not the order is a gift.
   */
  isGift?: boolean

  /**
   * The total amount of the order in cents.
   */
  totalAmountInCents: number

  /**
   * The action to take when the accordion is opened for an order.
   * @param orderId
   * @returns void
   */
  handleOpen?: ( orderId: string ) => void
}
export interface IOrderSummaryAccordionProps extends IOrderPreviewProps, IOrderDetailBodyProps {}

const OrderSummaryAccordion: FC<IOrderSummaryAccordionProps> = ({
  orderId,
  placedOn,
  isGift = false,
  totalAmountInCents,
  handleOpen = () => {},
  ...orderSummaryProps
}) => (
  <CardAccordion
    className="px-6 py-6 shadow-none"
    onValueChange={() => {
      handleOpen( orderId )
    }}
  >
    <CardAccordionHeader className="flex flex-row gap-6 items-center">
      <div className="flex flex-col md:flex-row md:gap-6 flex-grow text-left">
        <div className="font-bold flex-grow flex items-center gap-2">
          <span>Order {orderId}</span>
          {isGift && <PackageTag packageType={PackageType.GIFT} />}
        </div>
        <div className="text-xs text-gb-gray-800 md:center">
          Placed on {longFormatDayAndDate( placedOn )}
        </div>
      </div>
      <div className="font-bold text-xs">{centsToUSD( totalAmountInCents )}</div>
    </CardAccordionHeader>
    <CardAccordionContent className="pt-6 text-sm">
      <div className="border-t border-gb-gray-400 md:pt-4">
        <OrderDetailBody {...orderSummaryProps} />
      </div>
    </CardAccordionContent>
  </CardAccordion>
)

export default OrderSummaryAccordion
