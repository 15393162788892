import { FC } from 'react'

import Grid from 'src/components/01-atoms/Grid'
import TextInput from 'src/components/01-atoms/TextInput'
import { FormTextInputProps } from 'src/utils/types/forms'

interface IRecipientInfoForm {
  /**
   * Props for the email input element, likely from `react-hook-form` register method, but can be an object of string key => value pairs.
   */
  fieldProps?: {
    firstName: FormTextInputProps
    lastName: FormTextInputProps
    company: FormTextInputProps
    email: FormTextInputProps
    phone: FormTextInputProps
  }
}

const RecipientInfoForm: FC<IRecipientInfoForm> = ({ fieldProps }) => (
  <Grid className="gap-y-0">
    <TextInput
      labelText="First Name *"
      wrapperClassName="col-span-12 md:col-span-4"
      showLabel
      outline
      reduceLayoutShift
      {...fieldProps?.firstName}
    />
    <TextInput
      labelText="Last Name *"
      wrapperClassName="col-span-12 md:col-span-4"
      showLabel
      outline
      reduceLayoutShift
      {...fieldProps?.lastName}
    />
    <TextInput
      labelText="Company"
      wrapperClassName="col-span-12 md:col-span-4"
      showLabel
      outline
      reduceLayoutShift
      {...fieldProps?.company}
    />
    <TextInput
      labelText="Recipient Email"
      type="text"
      helperText="Sends recipient order information when the package ships."
      wrapperClassName="col-span-12 md:col-span-6"
      showLabel
      outline
      reduceLayoutShift
      {...fieldProps?.email}
    />
    <TextInput
      labelText="Phone Number"
      type="tel"
      wrapperClassName="col-span-12 md:col-span-6"
      showLabel
      outline
      reduceLayoutShift
      {...fieldProps?.phone}
    />
  </Grid>
)

export default RecipientInfoForm
