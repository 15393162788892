import { useMutation } from 'urql'
import { loader } from 'graphql.macro'

import {
  ILogUserInMutation,
  ILogUserInMutationVariables,
} from 'src/graphql/mutations/logUserIn.types'
import {
  ILogUserOutMutation,
  ILogUserOutMutationVariables,
} from 'src/graphql/mutations/logUserOut.types'

const LoginMutation = loader( 'src/graphql/mutations/logUserIn.graphql' )
const LogoutMutation = loader( 'src/graphql/mutations/logUserOut.graphql' )

const useManageUserSession = () => {
  const [ loginResponse, logUserIn ] = useMutation<ILogUserInMutation, ILogUserInMutationVariables>(
    LoginMutation
  )
  const [ logoutResponse, logUserOut ] = useMutation<
    ILogUserOutMutation,
    ILogUserOutMutationVariables
  >( LogoutMutation )

  return { logUserIn, loginResponse, logUserOut, logoutResponse }
}

export default useManageUserSession
