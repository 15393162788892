import { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarLines } from '@fortawesome/pro-regular-svg-icons'

import SectionCard from 'src/components/01-atoms/SectionCard'
import DetailsList from 'src/components/02-molecules/DetailsList'

import { longFormatDayAndDate } from 'src/utils/helpers/date'
import { valueOrSkeleton } from 'src/utils/helpers/loadingState'

export interface ICustomerHistoryBlockProps {
  /**
   * The date the customer account was created.
   */
  createdOn: Date

  /**
   * The date of the first order placed by the customer.
   */
  firstPurchaseOn?: Date | string

  /**
   * The date of the most recent order placed by the customer.
   */
  mostRecentPurchaseOn?: Date | string
}

const CustomerHistoryBlock: FC<ICustomerHistoryBlockProps> = ({
  createdOn,
  firstPurchaseOn,
  mostRecentPurchaseOn,
}) => (
  <SectionCard
    size="medium"
    data-testid="customer-history-block"
    title={
      <>
        <FontAwesomeIcon icon={faCalendarLines} className="mr-2" />
        History
      </>
    }
  >
    <DetailsList
      variant="bordered"
      testIdPrefix="customer-history-block"
      list={[
        {
          label: 'Account Created',
          value: valueOrSkeleton( createdOn && longFormatDayAndDate( createdOn )),
        },
        {
          label: 'First Purchase',
          value: valueOrSkeleton(
            firstPurchaseOn instanceof Date
              ? longFormatDayAndDate( firstPurchaseOn )
              : firstPurchaseOn
          ),
        },
        {
          label: 'Last Purchase',
          value: valueOrSkeleton(
            mostRecentPurchaseOn instanceof Date
              ? longFormatDayAndDate( mostRecentPurchaseOn )
              : mostRecentPurchaseOn
          ),
        },
      ]}
    />
  </SectionCard>
)

export default CustomerHistoryBlock
