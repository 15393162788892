import classNames from 'classnames'
import { FC, useState } from 'react'
import Container from 'src/components/01-atoms/Container'
import AdminHead from 'src/pages/elements/AdminHead'
import LabelPrintAggregate from './LabelPrintAggregate'
import SystemAdoption from './SystemAdoption'
import UserAdoption from './UserAdoption'

type TAdoption = 'system' | 'user' | 'print'

const Adoption: FC = () => {
  const [ adoptionType, setAdoptionType ] = useState<TAdoption>( 'system' )

  return (
    <>
      <AdminHead pageTitle="Adoption" />
      <Container className="mt-6">
        <div className="flex justify-center text-gb-gray-700 h-10">
          <button
            type="button"
            className={classNames( 'transition-all duration-500', {
              'text-2xl bold text-gb-gray-900 cursor-default': adoptionType === 'system',
              'hover:underline': adoptionType !== 'system',
            })}
            data-testid="admin-adoption"
            onClick={() => adoptionType !== 'system' && setAdoptionType( 'system' )}
          >
            System Adoption
          </button>
          <button
            type="button"
            className={classNames( 'transition-all duration-500 pl-4', {
              'text-2xl bold text-gb-gray-900 cursor-default': adoptionType === 'user',
              'hover:underline': adoptionType !== 'user',
            })}
            onClick={() => adoptionType !== 'user' && setAdoptionType( 'user' )}
          >
            User Adoption
          </button>
          <button
            type="button"
            className={classNames( 'transition-all duration-500 pl-4', {
              'text-2xl bold text-gb-gray-900 cursor-default': adoptionType === 'print',
              'hover:underline': adoptionType !== 'print',
            })}
            onClick={() => adoptionType !== 'print' && setAdoptionType( 'print' )}
          >
            Label Print
          </button>
        </div>
        <div className="flex justify-center">
          <div className="w-175 flex overflow-x-hidden">
            <div
              className={classNames(
                'transition-all duration-500',
                adoptionType === 'system' ? 'ml-0' : '-ml-175'
              )}
            >
              <SystemAdoption />
            </div>
            <div
              className={classNames(
                'transition-all duration-500',
                adoptionType === 'user' && 'ml-0',
                adoptionType === 'system' && 'ml-175',
                adoptionType === 'print' && '-ml-175'
              )}
            >
              <UserAdoption />
            </div>
            <div
              className={classNames(
                'transition-all duration-500',
                adoptionType === 'print' ? 'ml-0' : 'ml-175'
              )}
            >
              <LabelPrintAggregate />
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Adoption
