import { FC, forwardRef, useMemo, useRef, useState } from 'react'
import { useOutsideClick } from 'rooks'
import ScaleLoader from 'react-spinners/ScaleLoader'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFrown } from '@fortawesome/pro-duotone-svg-icons'

import SimpleSearch, { ISimpleSearchProps } from 'src/components/01-atoms/SimpleSearch'
import TypeaheadSearchResult, {
  ITypeaheadSearchResult,
} from 'src/components/01-atoms/TypeaheadSearchResult'

export interface ITypeaheadSearchProps extends ISimpleSearchProps {
  /**
   * Structured search results that match the search result component.
   */
  searchResults?: ITypeaheadSearchResult[]

  /**
   * Title for the highlighted search results section.
   */
  highlightedSearchResultsTitle?: string

  /**
   * Structured search results for the highlighted search results section.
   */
  highlightedSearchResults?: ITypeaheadSearchResult[]

  /**
   * Whether or not search results are still being fetched.
   */
  isSearching?: boolean

  /**
   * Action to take when the user clicks on a search result.
   * @param x The value of the search result.
   */
  handleSelect?: ( x: string ) => void

  /**
   * Classes to pass down to the typeahead search wrapper element.
   */
  wrapperClassName?: string

  /**
   * Whether or not the search box should be focused by default. (This also opens the results by default.)
   */
  defaultFocused?: boolean

  /**
   * How to sort the search results.
   */
  sortResults?: 'alpha' | 'none'
}

const TypeaheadSearch: FC<ITypeaheadSearchProps> = forwardRef<
  HTMLInputElement,
  ITypeaheadSearchProps
>(
  (
    {
      searchResults,
      highlightedSearchResultsTitle,
      highlightedSearchResults = [],
      isSearching,
      handleSelect = () => {},
      wrapperClassName,
      defaultFocused = false,
      sortResults = 'none',
      ...simpleSearchProps
    },
    ref
  ) => {
    const containerRef = useRef( null )
    const [ isCleared, setIsCleared ] = useState( false )
    const [ isFocused, setIsFocused ] = useState( defaultFocused )
    const isSearchResultsExpanded = ( isSearching || searchResults ) && !isCleared && isFocused

    const sortedResults = useMemo(
      () =>
        sortResults === 'alpha'
          ? searchResults?.sort(( a, b ) => a.label.localeCompare( b.label ))
          : searchResults,
      [ searchResults, sortResults ]
    )

    const handleChange = ( x: string ) => {
      setIsCleared( false )
      if ( simpleSearchProps?.handleChange ) simpleSearchProps?.handleChange( x )
    }

    useOutsideClick( containerRef, () => setTimeout(() => setIsFocused( false ), 200 ))

    return (
      <div
        className={classNames( wrapperClassName, 'relative w-full pointer-events-auto' )}
        ref={containerRef}
        data-testid="typeahead-search-container"
      >
        <div className="flex items-center ml-0 w-full pr-0">
          <SimpleSearch
            showSpyGlass={false}
            showSearchButton={false}
            handleChange={( x: string ) => handleChange( x )}
            handleClear={() => setIsCleared( true )}
            handleFocus={() => setIsFocused( true )}
            handleDropdownClick={() => setIsFocused(( x ) => !x )}
            testId="typeahead-search-form"
            className="w-full"
            reduceLayoutShift={false}
            ref={ref}
            {...simpleSearchProps}
          />
        </div>
        {!simpleSearchProps?.disabled && (
          <div className="absolute w-full h-0 z-30 md:mt-2 mt-4">
            {highlightedSearchResults.length > 0 && (
              <div
                data-testid="typeahead-highlighted-search-result"
                className={classNames(
                  'bg-white md:rounded-md md:shadow-md w-full text-sm transition-all duration-200',
                  isSearchResultsExpanded
                    ? 'max-h-32 overflow-y-auto mb-2'
                    : 'max-h-0 overflow-y-hidden'
                )}
              >
                {isSearchResultsExpanded && highlightedSearchResultsTitle && (
                  <div className="bg-gb-gray-50 text-gb-gray-800 font-semibold border-b px-4 py-2 sticky top-0">
                    {highlightedSearchResultsTitle}
                  </div>
                )}
                {highlightedSearchResults?.map(( result, i ) => (
                  <TypeaheadSearchResult
                    onClick={() => {
                      if ( result.disabled ) return
                      handleSelect( result.value )
                      setIsFocused( false )
                    }}
                    className={classNames({ 'border-t': i > 0 })}
                    {...result}
                  />
                ))}
              </div>
            )}
            <div
              className={classNames(
                'bg-white md:rounded-md md:shadow-md w-full text-sm transition-all duration-200 mb-4 motion-reduce:transition-none',
                isSearchResultsExpanded
                  ? 'md:max-h-80 overflow-y-auto max-h-[calc(100vh-72px)]'
                  : 'max-h-0 overflow-y-hidden'
              )}
            >
              {isSearching && (
                <div className="flex content-center justify-center items-center flex-col flex-wrap h-32">
                  <div className="w-80 center pb-2">
                    <ScaleLoader color="hsla(0, 0%, 67%, 1)" height={24} />
                  </div>
                  <div className="text-gb-gray-700 text-sm">Searching...</div>
                </div>
              )}
              {!isSearching && sortedResults && sortedResults.length === 0 && (
                <div
                  data-testid="typeahead-no-results"
                  className="flex content-center flex-col justify-center flex-wrap h-32"
                >
                  <FontAwesomeIcon
                    icon={faFrown}
                    className="md:w-80 text-gb-gray-700 text-2xl pb-2"
                  />
                  <div className="w-80 text-gb-gray-700 text-sm text-center">No Results</div>
                </div>
              )}
              {!isSearching && sortedResults && sortedResults.length > 0 && (
                <div className="text-gb-gray-800" data-testid="typeahead-search-result">
                  {sortedResults.map(( result, i ) => (
                    <TypeaheadSearchResult
                      key={`result-${simpleSearchProps.name}-${result.value}`}
                      onClick={() => {
                        if ( result.disabled ) return
                        handleSelect( result.value )
                        setIsFocused( false )
                      }}
                      className={classNames({ 'border-t': i > 0 })}
                      {...result}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
)

export default TypeaheadSearch
