import { FC, useEffect } from 'react'
import { Outlet } from 'react-router-dom'

interface IRunOnLoadWrapperProps {
  fn: () => void
}

const RunOnLoadWrapper: FC<IRunOnLoadWrapperProps> = ({ fn }) => {
  useEffect( fn, [])

  return <Outlet />
}

export default RunOnLoadWrapper
