import React, { FC } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export interface IIconLinkProps extends React.HTMLProps<HTMLAnchorElement> {
  icon: IconProp
  variant?: 'light' | 'dark' | 'darker'
}

const IconLink: FC<IIconLinkProps> = ({
  children,
  className,
  icon,
  variant = 'dark',
  ...props
}) => (
  <a
    {...props}
    className={classNames(
      className,
      {
        'text-white/85 hover:text-white': variant === 'light',
        'text-gb-gray-800/85 hover:text-gb-gray-900/85': variant === 'dark',
        'text-gb-gray-900/85 hover:text-gb-gray-900/85': variant === 'darker',
      },
      'font-semibold underline text-sm'
    )}
  >
    <FontAwesomeIcon icon={icon} size="lg" className="lg:mr-2" />
    <span className="sr-only lg:not-sr-only">{children}</span>
  </a>
)

IconLink.displayName = 'IconLink'

export default IconLink
