import { FC, useMemo } from 'react'
import { useQuery } from 'urql'
import { loader } from 'graphql.macro'
import Skeleton from 'react-loading-skeleton'
import { useParams } from 'react-router'

import Alert from 'src/components/01-atoms/Alert'
import Container from 'src/components/01-atoms/Container'
import Table from 'src/components/02-molecules/Table'
import StatementPageHeader from 'src/components/02-molecules/StatementPageHeader'
import SectionCard from 'src/components/01-atoms/SectionCard'
import USDCellValue from 'src/components/01-atoms/USDCellValue'

import MDashHead from 'src/pages/elements/MDashHead'
import useAppParams from 'src/utils/hooks/useAppParams'
import { gbmcOrderLink } from 'src/utils/gbmcLink'
import useMakeCSVDownloadButtonProps from 'src/utils/hooks/useMakeCSVDownloadButtonProps'

import {
  IGetInvoiceReshipmentsReportQuery,
  IGetInvoiceReshipmentsReportQueryVariables,
} from 'src/graphql/queries/getInvoiceReshipmentsReport.types'

// Ugh, we need to clean this up.
import '../../components/02-molecules/StatementsTable/styles.css'

const getInvoiceReshipmentsReport = loader(
  'src/graphql/queries/getInvoiceReshipmentsReport.graphql'
)

const Reshipments: FC = () => {
  const { isAdmin, mdashAccountId } = useAppParams()
  const { statementId } = useParams()

  const tableCellClasses = 'flex gap-x-4 first-of-type:pt-4 pb-4 pl-4 pr-4 lg:p-4 lg:table-cell '
  const [{ fetching, error, data }] = useQuery<
    IGetInvoiceReshipmentsReportQuery,
    IGetInvoiceReshipmentsReportQueryVariables
  >({
    query: getInvoiceReshipmentsReport,
    variables: {
      mdashAccountId: mdashAccountId ?? '',
      invoiceId: statementId ?? '',
    },
    requestPolicy: 'cache-and-network',
  })

  const reshipments = data?.invoiceReshipmentsReport

  const csvData = useMemo(
    () =>
      reshipments?.map(( reshipment ) => {
        const { lineItems, ...rest } = reshipment
        const products = lineItems?.map(( lineItem ) => lineItem.name ).join( '\n' )
        return { products, ...rest }
      }),
    [ reshipments ]
  )
  const columnHeaders = [
    { key: 'packageId', displayLabel: 'Package ID' },
    { key: 'orderId', displayLabel: 'Customer Order' },
    { key: 'originalShipOn', displayLabel: 'Original Ship Date' },
    { key: 'newShipOn', displayLabel: 'New Ship Date' },
    { key: 'reason', displayLabel: 'Culprit' },
    { key: 'comment', displayLabel: 'Comment' },
    { key: 'products', displayLabel: 'Products' },
    { key: 'productAdjustment', displayLabel: 'Product Adjustment' },
    { key: 'shippingAdjustment', displayLabel: 'Shipping Adjustment' },
    { key: 'totalAdjustment', displayLabel: 'Total Adjustment' },
  ]
  const downloadButtonProps = useMakeCSVDownloadButtonProps({
    filename: `statement-${statementId}-reshipments`,
    columnHeaders,
    csvData,
    fetching,
  })

  const tableHeaders = columnHeaders.map(( header ) => ({ name: header.displayLabel }))

  return (
    <div className="flex grow flex-col">
      <MDashHead pageTitle="Statements: Reshipments" />

      <Container className="pt-6">
        <StatementPageHeader
          title="Reshipments"
          mdashAccountId={mdashAccountId}
          total={reshipments?.length}
          statementId={statementId}
          downloadButtonProps={downloadButtonProps}
        />

        {fetching && <Skeleton className="mt-4.5" height="80vh" />}
        {!fetching && ( !data || error ) && <Alert type="error">{error?.message}</Alert>}
        {reshipments && (
          <SectionCard className="col-span-12 mb-8">
            <Table tableClasses="statements" headers={tableHeaders} borderRule="headers-only">
              {reshipments.map(
                (
                  {
                    comment,
                    id,
                    originalShipOn,
                    newShipOn,
                    packageId,
                    orderId,
                    productAdjustment,
                    shippingAdjustment,
                    reason,
                    totalAdjustment,
                    lineItems,
                  },
                  index
                ) => (
                  <tr
                    key={`missed-shipment-${id}`}
                    className="flex flex-col even:bg-gb-gray-50 odd:bg-white lg:table-row"
                  >
                    <td className={tableCellClasses}>
                      <div className="font-bold w-1/3 lg:hidden">{tableHeaders[0].name}</div>
                      {packageId}
                    </td>
                    <td className={tableCellClasses}>
                      <div className="font-bold w-1/3 lg:hidden">{tableHeaders[1].name}</div>
                      {orderId}
                      {isAdmin && (
                        <a
                          className="block text-2xs text-gb-blue-600 hover:text-gb-blue-700 transition-colors"
                          href={gbmcOrderLink( orderId ?? '' )}
                          target="_blank"
                          rel="noreferrer"
                        >
                          VIEW IN GBMC
                        </a>
                      )}
                    </td>
                    <td className={tableCellClasses}>
                      <div className="font-bold w-1/3 lg:hidden">{tableHeaders[2].name}</div>
                      {originalShipOn}
                    </td>
                    <td className={tableCellClasses}>
                      <div className="font-bold w-1/3 lg:hidden">{tableHeaders[3].name}</div>
                      {newShipOn}
                    </td>
                    <td className={tableCellClasses}>
                      <div className="font-bold w-1/3 lg:hidden">{tableHeaders[4].name}</div>
                      {reason}
                    </td>
                    <td className={tableCellClasses}>
                      <div className="font-bold w-1/3 lg:hidden">{tableHeaders[5].name}</div>
                      {comment}
                    </td>
                    <td className={tableCellClasses}>
                      <div className="font-bold w-1/3 lg:hidden">{tableHeaders[6].name}</div>
                      <ul className="text-xs list-disc flex flex-col gap-2">
                        {lineItems &&
                          lineItems.map(( item ) => <li key={`lineitem-${item}`}>{item.name}</li> )}
                      </ul>
                    </td>
                    <td className={tableCellClasses}>
                      <div className="font-bold w-1/3 lg:hidden">{tableHeaders[7].name}</div>
                      <USDCellValue amount={productAdjustment} />
                    </td>
                    <td className={tableCellClasses}>
                      <div className="font-bold w-1/3 lg:hidden">{tableHeaders[8].name}</div>
                      <USDCellValue amount={shippingAdjustment} />
                    </td>
                    <td className={tableCellClasses} data-testid={`total-adjustment-${index}`}>
                      <div className="font-bold w-1/3 lg:hidden">{tableHeaders[9].name}</div>
                      <USDCellValue amount={totalAdjustment} />
                    </td>
                  </tr>
                )
              )}
            </Table>
          </SectionCard>
        )}
      </Container>
    </div>
  )
}
export default Reshipments
