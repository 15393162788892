import { FC } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import useAppParams from 'src/utils/hooks/useAppParams'

const AdminOnly: FC = () => {
  const { isAdmin, mdashAccountId, makeLinkUrls } = useAppParams()

  return mdashAccountId && !isAdmin ? <Navigate to={makeLinkUrls().manifest} replace /> : <Outlet />
}

export default AdminOnly
