import { FC } from 'react'
import classNames from 'classnames'
import { isBefore, isValid } from 'date-fns'

import DatePicker, { IDateRangePickerProps } from 'src/components/01-atoms/DatePicker'
import { dateForManifest, stringAsDate } from 'src/utils/helpers/date'
import { centsToUSD } from 'src/utils/helpers/currencies'

export interface IDeliveryDate {
  requestedDeliveryOn: string
  isSoldOut?: boolean
  rateInCents?: number
}

interface IDeliveryDatePickerProps extends IDateRangePickerProps {
  shippingCalendar?: IDeliveryDate[]
  selected?: Date
  handleChange?: ( date: Date ) => void
  showShippingCost?: boolean
  shippingChargedToCustomerInCents?: number
}

const DeliveryDatePicker: FC<IDeliveryDatePickerProps> = ({
  shippingCalendar = [],
  selected,
  showShippingCost = true,
  shippingChargedToCustomerInCents = 0,
  ...datePickerProps
}) => {
  const formattedShippingAdd = ( addlCost: number | undefined ) => {
    if ( !addlCost || !showShippingCost ) return ''
    return addlCost > 0 ? `+${centsToUSD( addlCost )}` : 'FREE'
  }

  const getDeliveryInfoFromDate = ( date: Date, shippingCalendar: IDeliveryDate[]) =>
    shippingCalendar.find(( d ) => d.requestedDeliveryOn === dateForManifest( date ))

  const shippingDates = shippingCalendar.map(( d ) => stringAsDate( d.requestedDeliveryOn ))
  const selectedIsOld =
    selected &&
    !!shippingCalendar[0]?.requestedDeliveryOn &&
    isBefore( selected, stringAsDate( shippingCalendar[0].requestedDeliveryOn ))

  const error = (() => {
    if ( selected && !isValid( selected )) return 'Please select a valid delivery date.'
    if ( selectedIsOld ) return 'Date is before the first available delivery date.'
    if (( !shippingCalendar || shippingCalendar.length === 0 ) && !selected )
      return 'No delivery dates are available for the selected products.'
    return null
  })()

  return (
    <div data-testid="delivery-date-picker">
      <DatePicker
        popperClassName="z-30"
        disabled={!shippingCalendar || shippingCalendar.length === 0}
        autoFocus={false}
        {...datePickerProps}
        placeholderText="Date"
        minDate={shippingDates[0]}
        className={classNames({ 'border-red-600': selectedIsOld })}
        selected={selected}
        includeDates={shippingDates}
        renderDayContents={( day: number, date: Date ) => {
          const calDateDeliveryInfo = getDeliveryInfoFromDate( date, shippingCalendar )
          if ( !calDateDeliveryInfo ) return day
          return (
            <div className="flex flex-col">
              <span className="text-sm">{day}</span>
              <span className="text-2xs font-semibold center">
                {formattedShippingAdd(
                  Math.max(
                    ( calDateDeliveryInfo?.rateInCents ?? 0 ) - shippingChargedToCustomerInCents,
                    0
                  )
                )}
              </span>
            </div>
          )
        }}
      />
      {error && <p className="text-red-600 text-xs mt-1">{error}</p>}
    </div>
  )
}

export default DeliveryDatePicker
