import { addDays, eachDayOfInterval, format, startOfWeek } from 'date-fns'
import { AnimatePresence, motion, useAnimation, useReducedMotion } from 'framer-motion'
import { groupBy, keyBy } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { GridLoader } from 'react-spinners'
import SlideToggle from 'src/components/01-atoms/SlideToggle'
import PantryItemLimitCell, {
  IPantryItemLimitUpdatePayload,
} from 'src/components/02-molecules/PantryItemLimitCell'
import Table from 'src/components/02-molecules/Table'

export type TPantryItemCell = {
  pantryItemId: string
  toShipOn: string
}
interface IPantryItemLimit {
  pantryItemId: string
  pantryItemName: string
  toShipOn: Date
  currentQuantity: number
  isShippable: boolean
  limit?: number | null
}

interface IPantryItemLimitEditor {
  anchorDate: Date
  pantryItemLimits: IPantryItemLimit[]
  forceDisableAnimation?: boolean
  affectedCell: TPantryItemCell | null
  error?: string
  isFetching: boolean
  isUpdating?: boolean
  isUpdateSuccessful?: boolean
  onChange?: ( payload: IPantryItemLimitUpdatePayload ) => void
}

const PantryItemLimitEditor: FC<IPantryItemLimitEditor> = ({
  anchorDate,
  pantryItemLimits,
  forceDisableAnimation = false,
  affectedCell,
  error,
  isFetching,
  isUpdating,
  isUpdateSuccessful,
  onChange,
}) => {
  const controls = useAnimation()
  const shouldReduceMotion = useReducedMotion()
  const disableAnimation = forceDisableAnimation || shouldReduceMotion
  const timeoutRef = useRef( setTimeout(() => null ))
  const { register, watch } = useForm()
  const [ latchedDate, setLatchedDate ] = useState( anchorDate )
  const [ animateForward, setAnimateForward ] = useState( true )

  const handleUpdate = useCallback(( payload: IPantryItemLimitUpdatePayload ) => {
    if ( onChange ) {
      onChange( payload )
    }
  }, [])

  // TODO: extract these two to utils/date.ts
  const startOfInterval = useMemo(() => startOfWeek( anchorDate, { weekStartsOn: 1 }), [ anchorDate ])
  const dateColumns = useMemo(
    () => eachDayOfInterval({ start: startOfInterval, end: addDays( startOfInterval, 5 ) }),
    [ startOfInterval ]
  )

  const pantryItemKeys = keyBy( pantryItemLimits, ( x ) => x.pantryItemName )
  const pantryItemRows = Object.values( pantryItemKeys )
    .map(( x ) => ({ id: x.pantryItemId, name: x.pantryItemName }))
    .sort(( a, b ) => a.name.localeCompare( b.name ))
  const hasNonShippingPantryItems = useMemo(() => {
    const pantryItemGroups = groupBy( pantryItemLimits, ( x ) => x.pantryItemId )

    return (
      Object.values( pantryItemGroups ).filter(( weeklyShipments ) =>
        weeklyShipments.every(( x ) => !x.isShippable && x.currentQuantity === 0 )
      ).length > 0
    )
  }, [ pantryItemLimits ])

  const hasAnyShipmentThisWeek = useCallback(
    ( pantryItemId: string ) =>
      pantryItemLimits.filter(( limits ) =>
        watch( 'showAllSubproducts', false )
          ? true
          : limits.pantryItemId === pantryItemId &&
            ( limits.isShippable || limits.currentQuantity > 0 )
      ).length > 0,
    [ pantryItemLimits ]
  )

  useEffect(() => {
    if ( pantryItemRows.length > 0 ) {
      clearTimeout( timeoutRef.current )

      timeoutRef.current = setTimeout(() => {
        controls.start( 'show' )
      }, 200 )
    }
  }, [ startOfInterval.toISOString(), controls, pantryItemRows ])

  useEffect(() => {
    setLatchedDate( anchorDate )
    setAnimateForward( anchorDate >= latchedDate )
  }, [ anchorDate ])

  return (
    <>
      {hasNonShippingPantryItems && (
        <SlideToggle
          variant="large"
          labelText="Show All Subproducts"
          className="pb-4"
          data-testid="show-all-subproducts-toggle"
          {...register( 'showAllSubproducts' )}
        />
      )}
      <Table
        stickyHeader
        headers={[
          { name: '' },
          ...dateColumns.map(( x ) => ({
            name: format( x, 'EEE M/d' ),
          })),
        ]}
      >
        <AnimatePresence>
          {isFetching && (
            <motion.tr
              className="sticky top-16"
              initial={{ opacity: 1 }}
              exit={{ opacity: 0, height: 0 }}
            >
              <td colSpan={6}>
                <div className="absolute w-full h-0">
                  <div className="flex items-center justify-center w-full h-64">
                    <div className="border rounded-lg px-24 py-12 backdrop-blur-sm drop-shadow-xl">
                      <GridLoader />
                      <div>Loading...</div>
                    </div>
                  </div>
                </div>
              </td>
            </motion.tr>
          )}
        </AnimatePresence>
        {pantryItemRows
          .filter(( x ) => hasAnyShipmentThisWeek( x.id ))
          .map(( pantryItem, rowIndex ) => (
            <tr
              key={pantryItem.id}
              className="hover:bg-gb-gray-200 transition-colors duration-300"
              data-testid={`subproducts-${pantryItem.id}`}
            >
              <td className="font-bold pl-2 w-[14%] border-b-2 border-gb-gray-400">
                {pantryItem.name}
              </td>
              {dateColumns.map(( date, colIndex ) => {
                const dateAsString = format( date, 'M/d' )
                const fullDateString = format( date, 'yyyy-MM-dd' )
                const pantryItemLimit = pantryItemLimits.find(
                  ( x ) =>
                    x.pantryItemId === pantryItem.id && format( x.toShipOn, 'M/d' ) === dateAsString
                )
                const isCellAffected =
                  affectedCell &&
                  affectedCell.pantryItemId === pantryItem.id &&
                  affectedCell.toShipOn === fullDateString

                return (
                  <td
                    key={dateAsString}
                    className="border-l-2 border-b-2 border-gb-gray-400 py-2 w-[14%]"
                  >
                    <motion.div
                      className="flex justify-center"
                      initial="normal"
                      animate={
                        disableAnimation
                          ? { transition: { duration: 0, delay: 0 }, opacity: 1, scaleY: 1 }
                          : controls
                      }
                      variants={{
                        normal: {
                          scaleY: 0,
                          transition: {
                            duration: 0,
                          },
                        },
                        show: {
                          scaleY: 1,
                          transition: {
                            delay: Math.min(
                              0.5,
                              0.025 * rowIndex + 0.05 * ( animateForward ? colIndex : 4 - colIndex )
                            ),
                            duration: 0.25,
                            type: 'spring',
                          },
                        },
                      }}
                    >
                      <PantryItemLimitCell
                        pantryItem={pantryItem}
                        toShipOn={fullDateString}
                        currentQuantity={pantryItemLimit?.currentQuantity || 0}
                        limit={pantryItemLimit?.limit}
                        isShippable={pantryItemLimit?.isShippable || false}
                        error={isCellAffected ? error : undefined}
                        isUpdating={isCellAffected ? isUpdating : undefined}
                        isUpdateSuccessful={isCellAffected ? isUpdateSuccessful : undefined}
                        onChange={handleUpdate}
                      />
                    </motion.div>
                  </td>
                )
              })}
            </tr>
          ))}
      </Table>
    </>
  )
}

export default PantryItemLimitEditor
