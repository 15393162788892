import { FC, ReactEventHandler } from 'react'
import PrintablePage, { PageSize } from 'src/components/01-atoms/PrintablePage'
import ShippingLabel from 'src/components/01-atoms/ShippingLabel'

export interface ISlipNLabelThermal4x6ShippingLabelProps {
  /**
   * The URL to the shipping label image.
   */
  labelImgPath?: string | null

  /**
   * Method to run after the image loads.
   */
  handleLabelImageLoad?: ReactEventHandler<HTMLImageElement>

  /**
   * Method to run if the image returns an error.
   */
  handleLabelImageError?: ReactEventHandler<HTMLImageElement>

  /**
   * Method to run to let the page know if the label loaded and is printable.
   */
  handleSetPrintable?: ( isPrintable: boolean ) => void

  /**
   * Classes to add to the containing element.
   */
  className?: string
}

const ShippingLabelPage: FC<ISlipNLabelThermal4x6ShippingLabelProps> = ({
  labelImgPath,
  handleLabelImageLoad = () => {},
  handleLabelImageError = () => {},
  handleSetPrintable = () => {},
  className,
}) => (
  <PrintablePage size={PageSize.THERMAL_4x6} className={className}>
    <div className="screen:center w-[4in] h-[6in]">
      {labelImgPath && (
        <ShippingLabel
          forceOrientation="portrait"
          prescribedWidthInInches={4}
          prescribedHeightInInches={6}
          imgPath={labelImgPath}
          handleLoad={( e ) => {
            handleLabelImageLoad( e )
            handleSetPrintable( true )
          }}
          handleError={( e ) => {
            handleSetPrintable( false )
            if ( !handleLabelImageError ) return
            handleLabelImageError( e )
          }}
        />
      )}
    </div>
  </PrintablePage>
)

export default ShippingLabelPage
