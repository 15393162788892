import { FC, PropsWithChildren } from 'react'
import { To } from 'react-router-dom'
import classNames from 'classnames'
import { isToday } from 'date-fns'

import Button from 'src/components/01-atoms/Button'
import Card from 'src/components/01-atoms/Card'

import {
  RelativeDay,
  dayOfTheWeek,
  shortMonthAndDate,
  todayTomorrowOrElse,
} from 'src/utils/helpers/date'

import './styles.css'

interface IActionsPendingCard {
  /**
   * The number of orders shipping.
   */
  toShipCount: number

  /**
   * The date displayed on the card.
   */
  toShipOn: Date

  /**
   * The link URL to the product summary.
   */
  productSummaryLink?: To

  /**
   * Override whether or not the given ship date is today.
   */
  relativeShippingDay?: RelativeDay

  /**
   * Whether or not the data is fetching.
   */
  fetching?: boolean
}

const ActionsPendingCard: FC<PropsWithChildren<IActionsPendingCard>> = ({
  toShipCount,
  toShipOn,
  productSummaryLink,
  relativeShippingDay,
  fetching = false,
  children,
}) => {
  const dayLabel = relativeShippingDay || todayTomorrowOrElse( toShipOn, dayOfTheWeek )
  const bgColor: 'green' | 'blue' =
    isToday( toShipOn ) || relativeShippingDay === RelativeDay.TODAY ? 'green' : 'blue'

  return (
    <Card className="w-full" color={bgColor}>
      <div
        data-testid="actions-pending-card"
        className="2xl:flex 2xl:min-h-48 2xl:justify-items-stretch"
      >
        <div className="flex justify-between 2xl:flex-col 2xl:justify-start 2xl:min-w-48 2xl:border-r-2 2xl:border-r-gb-gray-400 2xl:my-2 2xl:mr-4 2xl:ml-2">
          <div>
            {toShipOn && (
              <div data-testid="actions-pending-date" className="text-xs">
                {shortMonthAndDate( toShipOn )}
              </div>
            )}
            <h3 data-testid="actions-pending-day-label" className="text-2xl 2xl:text-3xl">
              {dayLabel}
            </h3>
          </div>
          <div className="2xl:mt-2">
            <div className={classNames( fetching && 'blur-sm', 'flex items-center 2xl:mt-8' )}>
              <div
                data-testid="actions-pending-total-orders"
                className="text-4xl 2xl:text-5xl font-semibold"
              >
                {toShipCount}
              </div>
              <div className="leading-4 ml-2">
                Total <span className="block 2xl:mt-1">Orders</span>
              </div>
            </div>
            {productSummaryLink && (
              <div className="flex">
                <Button
                  className="mt-3"
                  outline
                  size="small"
                  data-testid="actions-pending-product-summary-link"
                  to={productSummaryLink}
                >
                  View Summary
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="mt-3 2xl:mt-7 2xl:flex 2xl:flex-col 2xl:pb-2 2xl:grow">
          <div className="text-sm font-semibold 2xl:text-base">Actions Pending</div>
          <div className={classNames( fetching && 'blur-sm saturate-0' )}>
            <div className="flex flex-col gap-2 mt-2 empty:hidden actions-children">{children}</div>
            <div className="flex items-center justify-center flex-col h-32 text-xs show-when-no-actions">
              <div>No pending actions at this time.</div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default ActionsPendingCard
