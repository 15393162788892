import { FC } from 'react'
import { useQuery } from 'urql'
import { loader } from 'graphql.macro'
import Skeleton from 'react-loading-skeleton'
import { useParams } from 'react-router'

import Alert from 'src/components/01-atoms/Alert'
import Container from 'src/components/01-atoms/Container'
import Table from 'src/components/02-molecules/Table'
import StatementPageHeader from 'src/components/02-molecules/StatementPageHeader'
import SectionCard from 'src/components/01-atoms/SectionCard'
import USDCellValue from 'src/components/01-atoms/USDCellValue'

import MDashHead from 'src/pages/elements/MDashHead'
import useAppParams from 'src/utils/hooks/useAppParams'
import { gbmcOrderLink } from 'src/utils/gbmcLink'
import useMakeCSVDownloadButtonProps, {
  CSVData,
} from 'src/utils/hooks/useMakeCSVDownloadButtonProps'

import {
  IGetInvoiceMissedShipmentsReportQuery,
  IGetInvoiceMissedShipmentsReportQueryVariables,
} from 'src/graphql/queries/getInvoiceMissedShipmentsReport.types'

import '../../components/02-molecules/StatementsTable/styles.css'

const getInvoiceMissedShipmentsReport = loader(
  'src/graphql/queries/getInvoiceMissedShipmentsReport.graphql'
)

const MissedShipments: FC = () => {
  const { isAdmin, mdashAccountId } = useAppParams()
  const { statementId } = useParams()

  const columnWidths = [
    {},
    {},
    { className: 'min-w-32' },
    {},
    { className: 'min-w-32' },
    {},
    { className: 'lg:w-32' },
    {},
  ]

  const tableCellClasses = 'flex gap-x-4 first-of-type:pt-4 pb-4 pl-4 pr-4 lg:p-4 lg:table-cell '
  const [{ fetching, error, data }] = useQuery<
    IGetInvoiceMissedShipmentsReportQuery,
    IGetInvoiceMissedShipmentsReportQueryVariables
  >({
    query: getInvoiceMissedShipmentsReport,
    variables: {
      mdashAccountId: mdashAccountId ?? '',
      invoiceId: statementId ?? '',
    },
    requestPolicy: 'cache-and-network',
  })

  const missedShipments = data?.invoiceMissedShipmentsReport
  const columnHeaders = [
    { key: 'packageId', displayLabel: 'Package ID' },
    { key: 'orderId', displayLabel: 'Customer Order' },
    { key: 'missedShipOn', displayLabel: 'Missed Ship Date' },
    { key: 'originalShipSpeed', displayLabel: 'Missed Carrier Speed' },
    { key: 'newShipOn', displayLabel: 'New Ship Date' },
    { key: 'newShipSpeed', displayLabel: 'New Carrier Speed' },
    { key: 'comment', displayLabel: 'Comment' },
    { key: 'totalAdjustment', displayLabel: 'Total Adjustment' },
  ]
  const downloadButtonProps = useMakeCSVDownloadButtonProps({
    filename: `statement-${statementId}-missed-shipments`,
    columnHeaders,
    csvData: missedShipments as CSVData,
    fetching,
  })

  const tableHeaders = columnHeaders.map(( header ) => ({ name: header.displayLabel }))

  return (
    <div className="flex grow flex-col">
      <MDashHead pageTitle="Statements: Missed Shipments" />

      <Container className="pt-6">
        <StatementPageHeader
          title="Missed Shipments"
          mdashAccountId={mdashAccountId}
          total={missedShipments?.length}
          statementId={statementId}
          downloadButtonProps={downloadButtonProps}
        />

        {fetching && <Skeleton className="mt-4.5" height="80vh" />}
        {!fetching && ( !data || error ) && <Alert type="error">{error?.message}</Alert>}
        {missedShipments && (
          <SectionCard className="col-span-12 mb-8">
            <Table
              tableClasses="statements"
              headers={tableHeaders}
              borderRule="headers-only"
              columnWidths={columnWidths}
            >
              {missedShipments.map(
                (
                  {
                    comment,
                    id,
                    missedShipOn,
                    newShipOn,
                    packageId,
                    newShipSpeed,
                    orderId,
                    originalShipSpeed,
                    totalAdjustment,
                  },
                  index
                ) => (
                  <tr
                    key={`missed-shipment-${id}`}
                    className="flex flex-col even:bg-gb-gray-50 odd:bg-white lg:table-row"
                  >
                    <td className={tableCellClasses}>
                      <div className="font-bold w-1/3 lg:hidden">{tableHeaders[0].name}</div>
                      {packageId}
                    </td>
                    <td className={tableCellClasses}>
                      <div className="font-bold w-1/3 lg:hidden">{tableHeaders[1].name}</div>
                      {orderId}
                      {isAdmin && (
                        <a
                          className="block text-2xs text-gb-blue-600 hover:text-gb-blue-700 transition-colors"
                          href={gbmcOrderLink( orderId ?? '' )}
                          target="_blank"
                          rel="noreferrer"
                        >
                          VIEW IN GBMC
                        </a>
                      )}
                    </td>
                    <td className={tableCellClasses}>
                      <div className="font-bold w-1/3 lg:hidden">{tableHeaders[2].name}</div>
                      {missedShipOn}
                    </td>
                    <td className={tableCellClasses}>
                      <div className="font-bold w-1/3 lg:hidden">{tableHeaders[3].name}</div>
                      {originalShipSpeed}
                    </td>
                    <td className={tableCellClasses}>
                      <div className="font-bold w-1/3 lg:hidden">{tableHeaders[4].name}</div>
                      {newShipOn}
                    </td>
                    <td className={tableCellClasses}>
                      <div className="font-bold w-1/3 lg:hidden">{tableHeaders[5].name}</div>
                      {newShipSpeed}
                    </td>
                    <td className={tableCellClasses}>
                      <div className="font-bold w-1/3 lg:hidden">{tableHeaders[6].name}</div>
                      {comment}
                    </td>
                    <td className={tableCellClasses} data-testid={`total-adjustment-${index}`}>
                      <div className="font-bold w-1/3 lg:hidden">{tableHeaders[7].name}</div>
                      <USDCellValue amount={totalAdjustment} />
                    </td>
                  </tr>
                )
              )}
            </Table>
          </SectionCard>
        )}
      </Container>
    </div>
  )
}
export default MissedShipments
