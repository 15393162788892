import { FC, PropsWithChildren, ReactElement } from 'react'

import Grid from 'src/components/01-atoms/Grid'

interface IAsymmetricalWithSidebarProps {
  /**
   * The title of the page.
   */
  pageTitle?: string

  /**
   * The elements that should be displayed in the sidebar.
   */
  sidebar: ReactElement
}

/**
 * Keeping this simple for now. We can make it more complex in the future if we need to make
 * different versions of this layout. For example, providing a prop for which side the sidebar
 * should show on on desktop and mobile.
 */
const AsymmetricalWithSidebar: FC<PropsWithChildren<IAsymmetricalWithSidebarProps>> = ({
  pageTitle,
  sidebar,
  children,
}) => (
  <>
    {pageTitle && <h1 className="text-2xl font-semibold mb-5">{pageTitle}</h1>}
    <Grid className="gap-y-9">
      <section className="col-span-12 lg:col-span-9 flex flex-col gap-y-9">{children}</section>
      <aside className="col-span-12 lg:col-span-3">{sidebar}</aside>
    </Grid>
  </>
)

export default AsymmetricalWithSidebar
