import { FC } from 'react'
import Skeleton from 'react-loading-skeleton'
import StatusIconWrapper from 'src/components/01-atoms/StatusIconWrapper'
import { IShippingLabelStatus } from 'src/graphql/types'
import { actionableCategories } from 'src/utils/helpers/actionButtons'
import useAppParams from 'src/utils/hooks/useAppParams'
import { useGetManifestLabelAggregate } from 'src/utils/hooks/useGetManifestLabelAggregate'
import { Facility } from 'src/utils/types/Facility'

const MultiFacilityActionAlert: FC<Partial<Facility>> = ({ id, type, name }) => {
  const { mdashAccountId, toShipOnAsString: toShipOn } = useAppParams()
  const { error, data, fetching } = useGetManifestLabelAggregate({
    variables: {
      mdashAccountId,
      toShipOn,
      facilityId: id,
      facilityType: type,
    },
  })

  if ( !id || !name || !type || data?.manifestPackages.metadata?.aggregates[0].value === 0 )
    return null

  const numActions = (
    data?.manifestPackages.metadata?.aggregates.filter(
      ( a ) => actionableCategories.includes( a.query as IShippingLabelStatus ) && a.value > 0
    ) || []
  ).length

  if ( fetching && !data && !error ) return <Skeleton />

  if ( numActions === 0 ) return null

  return (
    <StatusIconWrapper level="info">
      There are 1 or more actions pending on the {name} facility.
    </StatusIconWrapper>
  )
}

export default MultiFacilityActionAlert
