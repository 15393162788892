import { FC, HTMLAttributes } from 'react'

import Grid from 'src/components/01-atoms/Grid'
import TextArea from 'src/components/01-atoms/TextArea'
import TextInput from 'src/components/01-atoms/TextInput'

interface IGiftMessageForm {
  /**
   * Props for the email input element, likely from `react-hook-form` register method, but can be an object of string key => value pairs.
   */
  fieldProps?: {
    giftMessage: HTMLAttributes<HTMLTextAreaElement>
    giftMessageFrom: HTMLAttributes<HTMLInputElement>
  }
}

const GiftMessageForm: FC<IGiftMessageForm> = ({ fieldProps }) => (
  <Grid>
    <TextArea
      {...fieldProps?.giftMessage}
      labelText="Gift Message has a 280 character maximum"
      wrapperClassName="col-span-12"
      maxLength={280}
      showLabel
      outline
    />
    <TextInput
      {...fieldProps?.giftMessageFrom}
      labelText="From"
      wrapperClassName="col-span-12"
      maxLength={80}
      showLabel
      outline
    />
  </Grid>
)

export default GiftMessageForm
