import { FC, PropsWithChildren } from 'react'
import classNames from 'classnames'

interface IGridProps {
  className?: string
}

const Grid: FC<PropsWithChildren<IGridProps>> = ({ className, children }) => (
  <div className={classNames( className, 'grid grid-cols-12 auto-rows-auto gap-4' )}>{children}</div>
)

export default Grid
