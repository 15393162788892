import classNames from 'classnames'
import { kebabCase } from 'lodash'
import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { IChildLink } from 'src/utils/types/IChildLink'
import PopoverContent, { IPopoverContentProps } from '../PopoverContent'

export interface INavDropdownContent extends IPopoverContentProps {
  /**
   * An array of links to show in the dropdown.
   */
  links: IChildLink[]

  /**
   * Action to take when a link is clicked.
   */
  handleLinkClick?: CallableFunction
}

const NavDropdownContent: FC<INavDropdownContent> = ({
  links,
  handleLinkClick,
  ...popoverProps
}) => {
  const elementClasses = 'block py-1 px-3 w-full text-gb-gray-900 text-left'

  return (
    <PopoverContent align="center" {...popoverProps}>
      <nav>
        {links.map(( link ) =>
          link.disabled ? (
            <div
              key={`navlink-${kebabCase( link.text )}`}
              className={classNames( elementClasses, 'opacity-50 cursor-not-allowed' )}
            >
              {link.text}
            </div>
          ) : (
            <NavLink
              onClick={() => {
                if ( !handleLinkClick ) return
                handleLinkClick()
              }}
              key={`navlink-${kebabCase( link.text )}`}
              className={() => classNames( elementClasses, 'hover:bg-gb-blue-600/25 rounded' )}
              {...link}
            >
              {link.text}
            </NavLink>
          )
        )}
      </nav>
    </PopoverContent>
  )
}

export default NavDropdownContent
