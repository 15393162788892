import { FC, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import useAppParams from 'src/utils/hooks/useAppParams'

const StatementsLanding: FC = () => {
  const { mdashAccountId, showStatements } = useAppParams()
  const navigate = useNavigate()

  useEffect(() => {
    if ( typeof showStatements !== 'undefined' && !showStatements ) {
      navigate( `/${mdashAccountId}/manifest` )
    }
  }, [ showStatements, mdashAccountId ])

  return <Outlet />
}

export default StatementsLanding
