import { FC } from 'react'
import { useQuery } from 'urql'
import { loader } from 'graphql.macro'
import Skeleton from 'react-loading-skeleton'
import MDashHead from 'src/pages/elements/MDashHead'
import Alert from 'src/components/01-atoms/Alert'
import {
  IGetShipperInvoicesQuery,
  IGetShipperInvoicesQueryVariables,
} from 'src/graphql/queries/getShipperInvoices.types'
import Container from 'src/components/01-atoms/Container'
import useAppParams from 'src/utils/hooks/useAppParams'
import SectionCard from 'src/components/01-atoms/SectionCard'
import StatementsTable from 'src/components/02-molecules/StatementsTable'

const getShipperInvoices = loader( 'src/graphql/queries/getShipperInvoices.graphql' )

const Statements: FC = () => {
  const { mdashAccountId } = useAppParams()

  const [{ fetching, error, data }] = useQuery<
    IGetShipperInvoicesQuery,
    IGetShipperInvoicesQueryVariables
  >({
    query: getShipperInvoices,
    variables: {
      mdashAccountId: mdashAccountId ?? '',
    },
    requestPolicy: 'cache-and-network',
    pause: !mdashAccountId,
  })

  const statements = data?.shipperInvoices

  return (
    <>
      <MDashHead pageTitle="Statements" />
      <Container className="pt-6 pb-9">
        {!fetching && ( !data || error ) && <Alert type="error">{error?.message}</Alert>}
        <>
          <div className="mb-6 flex justify-between">
            <h1 className="text-2xl font-semibold">Statements</h1>
          </div>

          {statements && (
            <SectionCard className="col-span-12">
              <div data-testid="section-statements">
                <StatementsTable lineItems={statements} />
              </div>
            </SectionCard>
          )}
          {fetching && <Skeleton height="50vh" />}
        </>
      </Container>
    </>
  )
}

export default Statements
