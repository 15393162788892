// import { faHeadset } from "@fortawesome/pro-solid-svg-icons"
import { faUserHeadset } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC, HTMLAttributes, useState } from 'react'

interface IZendeskBadgeProps extends HTMLAttributes<HTMLElement> {
  /**
   * Pass through classes.
   */
  className?: string
}

const ZendeskBadge: FC<IZendeskBadgeProps> = ({ className }) => {
  const [ messageCount, setMessageCount ] = useState<number>( 0 )

  const w = window as any

  // for storybook support, in case zendesk is not in use
  if ( w.zE && typeof w.zE === 'function' ) {
    try {
      // unread Message on listener
      w.zE( 'messenger:on', 'unreadMessages', ( count: number ) => {
        if ( count !== messageCount ) {
          setMessageCount( count )
        }
      })
    } catch ( e ) {
      // doNothing()
    }
  }

  const openMessenger = () => {
    if ( typeof w.zE === 'function' ) {
      // open widget
      w.zE( 'messenger', 'open' )
    }
    // reset unread indicator
    setMessageCount( 0 )
  }

  return (
    <div className={className}>
      <button
        type="button"
        className="bg-gb-blue-600 px-4 md:px-6 py-3 rounded-full font-medium hover:text-gb-gray-900/90 transition-opacity drop-shadow-xl"
        onClick={() => openMessenger()}
      >
        <FontAwesomeIcon icon={faUserHeadset} className="md:mr-2" />
        <div className="hidden md:inline-block">
          Need help? {messageCount > 0 && <span>({messageCount.toString()})</span>}
        </div>
      </button>
    </div>
  )
}

export default ZendeskBadge
