import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { FC } from 'react'
import twoLetters from 'src/utils/twoLetters'

type TIcon = {
  icon: IconDefinition
  className?: string
}

interface IShapedIconProps {
  baseIcon: TIcon
  stackedIcons?: TIcon[]
  className?: string
  textLayer?: {
    text: string
    className?: string
  }
}

const ShapedIcon: FC<IShapedIconProps> = ({ baseIcon, stackedIcons, className, textLayer }) => (
  <span className={classNames( 'fa-layers fa-fw', className )}>
    <FontAwesomeIcon icon={baseIcon.icon} className={baseIcon.className} />
    {textLayer && (
      <span className={classNames( 'fa-layers-text', textLayer.className )}>
        {twoLetters({ text: textLayer.text })}
      </span>
    )}
    {stackedIcons?.map(( icon ) => (
      <FontAwesomeIcon key={String( icon )} icon={icon.icon} className={icon.className} />
    ))}
  </span>
)

export default ShapedIcon
