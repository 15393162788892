import { FC, HTMLAttributes } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkLarge } from '@fortawesome/pro-solid-svg-icons'

interface IProductSelectorTagProps extends HTMLAttributes<HTMLButtonElement> {
  /**
   * The text for the selected product tag or reset button.
   */
  text: string

  /**
   * Values associated to this tag
   */
  value?: number

  /**
   * Whether or not the product is selected.
   */
  selected?: boolean

  /**
   * Whether or not to show the icon. (Defaults to use the value from `selected`.)
   */
  showIcon?: boolean

  /**
   * Pass through classes.
   */
  className?: string
}

const ProductSelectorTag: FC<IProductSelectorTagProps> = ({
  text,
  value,
  selected = false,
  showIcon = selected,
  className,
  ...buttonProps
}) => (
  <button
    type="button"
    {...buttonProps}
    className={classNames(
      className,
      'center group gap-x-1 py-0.5 px-2 mb-2 mr-1 block text-2xs border rounded-xl transition-colors',
      {
        'bg-gb-blue-600/10 text-gb-blue-700 hover:bg-gb-blue-600/25 border-gb-blue-600': selected,
        'hover:bg-gb-gray-200 border-gb-gray-900 text-gb-gray-900': !selected,
      }
    )}
  >
    <div className="flex items-center">
      {text}
      {value && (
        <div
          className={classNames( 'px-1 ml-1 text-gb-gray-50 rounded-xl font-semibold', {
            'bg-gb-blue-700 group-hover:bg-gb-blue-800': selected,
            'bg-gb-gray-800': !selected,
          })}
        >
          {value}
        </div>
      )}
      {showIcon && (
        <FontAwesomeIcon
          icon={faXmarkLarge}
          className={classNames( 'icon pl-1', {
            'text-gb-blue-600': selected,
            'text-gb-gray-900': !selected,
          })}
        />
      )}
    </div>
  </button>
)

export default ProductSelectorTag
