import classNames from 'classnames'
import { getYear } from 'date-fns'
import { FC } from 'react'
import Dropdown from 'src/components/01-atoms/Dropdown'

import {
  fullMonthName,
  getFirstWeekdayInMonth,
  getMonthList,
  getYearList,
} from 'src/utils/helpers/date'

interface IMonthYearSelectorProps {
  /**
   * The date selected.
   */
  selectedDate?: Date

  /**
   * Method to call when the month and year changes.
   */
  handleChange?: CallableFunction

  /**
   * Class names to pass to the containing element.
   */
  className?: string
}

const MonthYearSelector: FC<IMonthYearSelectorProps> = ({
  selectedDate = new Date(),
  handleChange = () => {},
  className,
}) => {
  const selectedMonth = `${fullMonthName( selectedDate )}`
  const selectedYear = `${getYear( selectedDate )}`

  return (
    <div className={classNames( className, 'flex flex-row relative z-20' )}>
      <Dropdown
        size="small"
        className="w-3/5"
        showDropdownArrow
        triggerButtonClasses="rounded-r-none"
        selected={selectedMonth}
        handleChange={( month: string ) => {
          handleChange( getFirstWeekdayInMonth( new Date( `${month} 1, ${selectedYear}` )))
        }}
        options={getMonthList()}
      />
      <Dropdown
        size="small"
        className="w-2/5"
        showDropdownArrow
        triggerButtonClasses="border-l-0 rounded-l-none"
        selected={selectedYear}
        handleChange={( year: string ) => {
          handleChange( getFirstWeekdayInMonth( new Date( `${selectedMonth} 1, ${year}` )))
        }}
        options={getYearList( new Date().getFullYear() + 1, 2011 ).map(( v ) => `${v}` )}
      />
    </div>
  )
}

export default MonthYearSelector
