import { FC } from 'react'
import { IGetMdashAccountUserAdoptionsQuery } from 'src/graphql/queries/getMdashAccountUserAdoptions.types'

interface IProps {
  adoptionData?: IGetMdashAccountUserAdoptionsQuery
}

const Legend: FC<IProps> = ({ adoptionData }) => {
  if ( !adoptionData || Number( adoptionData?.mdashAccount?.merchantUserAccessLogs?.length ) === 0 )
    return null

  return (
    <div className="flex justify-center mb-6">
      <div className="flex">
        <div className="mt-1 w-4 h-4" style={{ backgroundColor: '#FC8D62' }} />
        <span className="pl-2 pr-4">MD2</span>
      </div>
      <div className="flex">
        <div className="mt-1 w-4 h-4" style={{ backgroundColor: '#a6d854' }} />
        <span className="pl-2 pr-4">MDX</span>
      </div>
      <div className="flex">
        <div className="mt-1 w-4 h-4" style={{ backgroundColor: '#8da0cb' }} />
        <span className="pl-2">Both</span>
      </div>
    </div>
  )
}

export default Legend
