import { useEffect, useState } from 'react'
import { UseQueryArgs, UseQueryResponse, UseQueryState, useMutation, useQuery } from 'urql'

import { FieldValues } from 'react-hook-form'
import { IOrder } from 'src/graphql/types'
import useAppParams from './useAppParams'
import useCheckoutParams from './useCheckoutParams'

interface IDefaultQueryVariables {
  orderId: string
  mashAccountId?: string
}

export interface IDefaultOrderPartialResponse {
  errors: string[]
  order: Partial<IOrder> | {}
}

interface IDefaultOrderPartialMutationResponse {
  [key: string]: IDefaultOrderPartialResponse | any
}

const useManageOrderPartial = <
  IQuery extends Omit<IDefaultOrderPartialResponse, 'errors'>,
  IMutation extends IDefaultOrderPartialMutationResponse,
  IMutationVariables extends FieldValues
>(
  query: UseQueryArgs['query'],
  mutation: UseQueryArgs['query'],
  pause: boolean = false
) => {
  const { mdashAccountId } = useAppParams()
  const { orderId, validOrderId } = useCheckoutParams()
  const [ errors, setErrors ] = useState<string[]>([])

  const [ updateResponse, update ] = useMutation<IMutation, IMutationVariables>( mutation )

  const [ queryState, refetchPartial ]: UseQueryResponse<IQuery, IDefaultQueryVariables> = useQuery<
    IQuery,
    IDefaultQueryVariables
  >({
    query,
    variables: {
      orderId,
      mdashAccountId,
    } as IDefaultQueryVariables,
    pause: pause ?? !validOrderId,
    requestPolicy: 'cache-and-network',
  })

  const {
    data,
    fetching: fetchingPartial,
    error,
  } = queryState as UseQueryState<IQuery, IDefaultQueryVariables>

  const updateOrderPartial = ( args: IMutationVariables ) => update({ orderId, ...args })

  const fetching = fetchingPartial || updateResponse.fetching

  useEffect(() => {
    if ( !error ) {
      setErrors([])
      return
    }

    if ( fetching || !data ) return

    const errors = []
    if ( error && error.message ) {
      errors.push( error.message )
    }
    if ( updateResponse.error?.message ) {
      errors.push( updateResponse.error?.message )
    }
    if ( errors.length > 0 ) {
      setErrors( errors )
    }
  }, [ error, data, updateResponse ])

  return {
    data,
    fetching,
    errors,
    refetch: refetchPartial,
    update: updateOrderPartial,
    updateResponse,
  }
}

export default useManageOrderPartial
