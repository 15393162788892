import classNames from 'classnames'
import { FC, FieldsetHTMLAttributes, PropsWithChildren } from 'react'

export interface IFieldsetProps extends FieldsetHTMLAttributes<HTMLFieldSetElement> {}

/**
 * A basic fieldset, extensible to any form that we need to use but making the disabled state more universal.
 */
const Fieldset: FC<PropsWithChildren<IFieldsetProps>> = ({ className, children, ...props }) => (
  <fieldset
    className={classNames( className, 'with-disabled-state invalid:border-red-600' )}
    {...props}
  >
    {children}
  </fieldset>
)

export default Fieldset
