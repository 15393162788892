import { useState } from 'react'
import { useDebounceFn } from 'rooks'
import { join, compact, isEmpty } from 'lodash'
import {
  core as SmartySDK,
  usAutocompletePro as autocomplete,
  usStreet,
} from 'smartystreets-javascript-sdk'
import { isAmbiguous, isInvalid, isMissingSecondary } from 'smartystreets-javascript-sdk-utils'

export interface IDeliveryAddress {
  street1: string
  street2?: string
  city: string
  state: string
  zipCode: string
}

interface IValidatedAddress {
  address?: IDeliveryAddress
  error?: string | null
  note?: string | null
  valid: boolean
  showOverrideModal?: boolean
}

const useSmartyStreets = () => {
  const htmlAuthKey = process.env.REACT_APP_SMARTY_STREETS_HTML_KEY ?? ''
  const client = new SmartySDK.ClientBuilder( new SmartySDK.SharedCredentials( htmlAuthKey ))
  const [ addressSearchResults, setAddressSearchResults ] = useState<IDeliveryAddress[]>()

  const fetchAutocompleteSuggestions = async ( query: string ) => {
    if ( isEmpty( query )) return false
    const autoCompleteClient = client.buildUsAutocompleteProClient()
    const lookup = new autocomplete.Lookup( query )
    return autoCompleteClient.send( lookup ).then(( response ) => {
      if ( !response.result ) return
      const suggestions: IDeliveryAddress[] = response.result.map(
        ({ streetLine, secondary, city, state, zipcode }) => ({
          street1: streetLine,
          street2: secondary,
          city,
          state,
          zipCode: zipcode,
        })
      )
      setAddressSearchResults( suggestions )
    })
  }

  const [ debounceAutocompleteSearch ] = useDebounceFn(
    ( query ) => fetchAutocompleteSuggestions( query as string ),
    500
  )

  const composeLookup = ( address: IDeliveryAddress ): usStreet.Lookup => {
    const { street1, street2, city, state, zipCode } = address
    return new usStreet.Lookup( street1, street2, undefined, city, state, zipCode )
  }

  const parseLookup = ( lookup: usStreet.Lookup ): IValidatedAddress => {
    if ( isAmbiguous( lookup )) return { valid: false, error: 'The address is ambiguous.' }
    if ( !lookup.result || isInvalid( lookup ))
      return {
        valid: false,
        error: 'The provided address appears to be invalid. Are you sure you want to use it?',
        showOverrideModal: true,
      }

    const candidate = lookup.result[0]
    const address = {
      street1: candidate.deliveryLine1,
      street2: candidate.deliveryLine2 || '',
      city: candidate.components.cityName,
      state: candidate.components.state,
      zipCode: join( compact([ candidate.components.zipCode, candidate.components.plus4Code ]), '-' ),
    }

    if ( candidate.metadata.recordType === 'P' ) {
      return { address, error: 'Sorry, but we cannot ship to PO Boxes.', valid: false }
    }

    if ( isMissingSecondary( lookup )) {
      return {
        address,
        error: 'Do you need to enter your apartment or suite number?',
        valid: false,
        showOverrideModal: true,
      }
    }

    return {
      address,
      valid: true,
    }
  }

  const validateAddress = async ( address: IDeliveryAddress ): Promise<IValidatedAddress> => {
    if ( !address.street1 ) {
      return { valid: false, error: 'A street address is required.' }
    }

    const lookupRequest = composeLookup( address )
    const usStreetClient = client.buildUsStreetApiClient()
    const batch = await usStreetClient.send( lookupRequest )

    const lookupResponse = batch.lookups[0]
    return parseLookup( lookupResponse )
  }

  const makePrettyAddressString = ({
    street1,
    street2,
    city,
    state,
    zipCode,
  }: IDeliveryAddress ) => {
    const streets = `${street1} ${street2}`.trim()
    return `${streets} ${city}, ${state} ${zipCode}`
  }

  return {
    client,
    fetchAutocompleteSuggestions,
    debounceAutocompleteSearch,
    composeLookup,
    validateAddress,
    addressSearchResults,
    makePrettyAddressString,
    errors: [],
  }
}

export default useSmartyStreets
