import classNames from 'classnames'
import { FC, ImgHTMLAttributes } from 'react'

interface IMotionSafeImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  /**
   * The URL for the moving image to display.
   */
  movingImageUrl: string

  /**
   * The URL for the still image to display.
   */
  stillImageUrl: string
}

const MotionSafeImage: FC<IMotionSafeImageProps> = ({
  movingImageUrl,
  stillImageUrl,
  alt,
  className,
  ...imgProps
}) => (
  <>
    <img
      src={movingImageUrl}
      alt={alt}
      className={classNames( className, 'motion-reduce:hidden' )}
      {...imgProps}
    />
    <img
      src={stillImageUrl}
      alt={alt}
      className={classNames( className, 'motion-safe:hidden' )}
      {...imgProps}
    />
  </>
)

export default MotionSafeImage
