import { FC } from 'react'
import classNames from 'classnames'

interface IBarcodeProps {
  /**
   * The value of the barcode. Will be surrounded by asterisks (*) by default.
   */
  value: string | number

  /**
   * Additional classnames for the barcode element.
   */
  className?: string
}

const Barcode: FC<IBarcodeProps> = ({ value, className }) => (
  <span
    data-testid="barcode"
    className={classNames( 'font-barcode font-normal text-4xl h-6', className )}
  >
    *{value}*
  </span>
)

export default Barcode
