/**
 * We are currently only supporting USD, but we should make sure we can support more in the future.
 */
export const usdFormatter = new Intl.NumberFormat( 'en-US', {
  style: 'currency',
  currency: 'USD',
})
export const signedUsdFormatter = new Intl.NumberFormat( 'en-US', {
  style: 'currency',
  currency: 'USD',
  signDisplay: 'exceptZero',
})

export const centsToUSD = ( amount: number ) => usdFormatter.format( amount / 100 )
export const signedCentsToUSD = ( amount: number ) => signedUsdFormatter.format( amount / 100 )
