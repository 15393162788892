import classNames from 'classnames'
import { FC, PropsWithChildren } from 'react'
import StatusIcon, { Level } from 'src/components/01-atoms/StatusIcon'

export interface IStatusIconWrapperProps {
  /**
   * The level of the alert.
   */
  level: Level

  /**
   * The style being used. ('primary' uses a duotone icon, 'secondary' uses an outline icon.)
   */
  variant?: 'primary' | 'secondary'

  /**
   * The size of the alert.
   */
  size?: 'small' | 'default'

  /**
   * Classes to pass down to the containing element.
   */
  className?: string
}

const StatusIconWrapper: FC<PropsWithChildren<IStatusIconWrapperProps>> = ({
  level,
  variant = 'primary',
  size = 'default',
  className,
  children,
}) => (
  <div
    className={classNames(
      size === 'small' ? 'text-xs' : 'text-sm',
      'flex gap-2 items-center',
      className
    )}
  >
    <StatusIcon
      className={size === 'small' ? 'text-2xl' : 'text-3xl'}
      level={level}
      outline={variant === 'secondary'}
    />
    <div>{children}</div>
  </div>
)

export default StatusIconWrapper
