import { FC } from 'react'
import { useQuery } from 'urql'
import { loader } from 'graphql.macro'
import Skeleton from 'react-loading-skeleton'
import MDashHead from 'src/pages/elements/MDashHead'
import Alert from 'src/components/01-atoms/Alert'

import {
  IGetSubProductSummaryQuery,
  IGetSubProductSummaryQueryVariables,
} from 'src/graphql/queries/getSubProductSummary.types'
import Container from 'src/components/01-atoms/Container'
import useAppParams from 'src/utils/hooks/useAppParams'
import Grid from 'src/components/01-atoms/Grid'
import SectionCard from 'src/components/01-atoms/SectionCard'
import Button from 'src/components/01-atoms/Button'
import ProductTable from 'src/components/02-molecules/ProductTable'

import { longFormatDayAndDate } from 'src/utils/helpers/date'
import { isNil, omitBy } from 'lodash'

const getSubProductSummary = loader( 'src/graphql/queries/getSubProductSummary.graphql' )

const ProductSummary: FC = () => {
  const {
    mdashAccountId,
    toShipOn,
    toShipOnAsString,
    displaySku,
    facilityId,
    facilityType,
    withFacilityParams,
  } = useAppParams()

  const [{ fetching, error, data }] = useQuery<
    IGetSubProductSummaryQuery,
    IGetSubProductSummaryQueryVariables
  >({
    query: getSubProductSummary,
    variables: {
      mdashAccountId,
      toShipOn: toShipOnAsString,
      facilityId,
      facilityType,
    },
    requestPolicy: 'cache-and-network',
  })

  const summary = data?.subproductSummary
  const products =
    summary?.products.map(({ id, name, quantity, subproducts }) => ({
      id,
      name,
      quantity,
      subproducts: subproducts.length
        ? subproducts.map(({ quantity, name }) => `${quantity} ${name}` )
        : [ '' ],
    })) ?? null
  const subproducts =
    summary?.subproducts.map(({ id, name, sku, quantity }) => ({
      id,
      name,
      sku: displaySku ? sku || undefined : undefined,
      quantity,
    })) ?? null

  return (
    <>
      <MDashHead pageTitle="Product and Subproducts Summary" />
      <Container className="pt-6 pb-9">
        {fetching && <Skeleton height="50vh" />}
        {!fetching && ( !data || error ) && <Alert type="error">{error?.message}</Alert>}

        {summary && (
          <>
            <div className="mb-6 flex justify-between">
              <div>
                <h1 className="text-2xl">
                  <span className="font-semibold">Product and Subproducts Summary</span> for{' '}
                  {longFormatDayAndDate( toShipOn )}
                </h1>
                <h2 className="text-lg">Total Packages: {summary.totalPackages}</h2>
              </div>
              <div
                className="flex flex-col gap-2 items-end md:flex-row md:items-start"
                data-testid="print-pick-list"
              >
                <Button
                  to={omitBy(
                    {
                      pathname: `/${mdashAccountId}/pick_list/${toShipOnAsString}`,
                      search: withFacilityParams(),
                    },
                    isNil
                  )}
                >
                  Print Pick List
                </Button>
              </div>
            </div>
            <Grid>
              {products && (
                <SectionCard
                  title={`Total Products: ${products
                    .map(( x ) => x.quantity )
                    .reduce(( a, b ) => a + b, 0 )}`}
                  className="col-span-12 md:col-span-6 lg:col-span-8 md:row-span-1"
                >
                  <div data-testid="section-products">
                    <ProductTable lineItems={products} />
                  </div>
                </SectionCard>
              )}
              {subproducts && (
                <SectionCard
                  title={`Total Subproducts: ${subproducts
                    .map(( x ) => x.quantity )
                    .reduce(( a, b ) => a + b, 0 )}`}
                  className="col-span-12 md:col-span-6 lg:col-span-4"
                >
                  <div data-testid="section-subproducts">
                    <ProductTable productColumnHeader="Subproduct" lineItems={subproducts} />
                  </div>
                </SectionCard>
              )}
            </Grid>
          </>
        )}
      </Container>
    </>
  )
}

export default ProductSummary
