import { uniq } from 'lodash'
import { useCallback, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { IManifestAggregate, IManifestFilterCategory } from 'src/graphql/types'

interface IProps {
  aggregates: IManifestAggregate[]
}
export const useTaxonomy = ({ aggregates }: IProps ) => {
  const { watch, setValue } = useFormContext()
  const kw = watch()
  const [ selectedTaxonomyIds, setSelectedTaxonomyIds ] = useState<string[]>([])
  const resetFields = useCallback(() => setSelectedTaxonomyIds([]), [ setSelectedTaxonomyIds ])
  const taxonomyProps = aggregates
    .filter(( f ) => f.category === IManifestFilterCategory.TAXONOMY_IDS )
    .map(( x ) => ({
      label: String( x.label ),
      value: x.value,
      key: String( x.query ),
      taxonomyProducts: x.taxonomyProducts,
    }))

  const onTaxonomyChange = useCallback(
    ({ taxonomyId }: { taxonomyId: string }) => {
      const taxonomy = taxonomyProps.find(( x ) => x.key === taxonomyId )

      if ( !taxonomy ) return

      const currentlyActive = selectedTaxonomyIds.indexOf( taxonomyId ) !== -1
      const productIdsViaControl = kw.productIds || []
      const productIdsViaTaxonomy = taxonomy.taxonomyProducts?.map(( x ) => x.id ) || []

      if ( currentlyActive ) {
        setValue(
          'productIds',
          productIdsViaControl.filter(( x: string ) => productIdsViaTaxonomy.indexOf( x ) === -1 )
        )
        setSelectedTaxonomyIds(( prev ) => prev.filter(( x ) => x !== taxonomyId ))
      } else {
        setValue( 'productIds', uniq( productIdsViaControl.concat( productIdsViaTaxonomy )))

        setSelectedTaxonomyIds(( prev ) => prev.concat( taxonomyId ))
      }
    },
    [ kw.productIds, selectedTaxonomyIds, setValue, taxonomyProps ]
  )

  return {
    selectedTaxonomyIds,
    taxonomyProps,
    onTaxonomyChange,
    resetFields,
  }
}
