import { FC, PropsWithChildren } from 'react'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { kebabCase } from 'lodash'

interface IPackingSlipSectionProps extends PropsWithChildren<{}> {
  /**
   * The icon to display in the section.
   */
  icon?: IconDefinition | string

  /**
   * The alignment of the icon.
   */
  iconAlignment?: 'center' | 'top'

  /**
   * The variant of the section.
   */
  variant?: 'default' | 'topBorderOnly' | 'topAndBottomBorder' | 'noBorder'

  /**
   * Text to show at the beginning of the section.
   */
  prefix?: string

  /**
   * Text to show at the end of the section.
   */
  suffix?: string

  /**
   * The URL to the image to display in the section. If provided, the image will be displayed on the right side of the section.
   */
  imageUrl?: string | null

  /**
   * Extra classes to add to this element's container.
   */
  className?: string

  /**
   * The name of the section for testing purposes.
   */
  nameForTests?: string
}

/**
 * Detect if the icon passed in is a FontAwesome IconDefinition.
 * @param { IconDefinition | ReactSVGElement } icon The icon to display in the section.
 * @returns boolean True if the icon is a FontAwesome IconDefinition, false otherwise.
 */
const iconIsFontAwesome = ( icon: IconDefinition | string ): icon is IconDefinition =>
  Object.keys( icon ).includes( 'iconName' )

const PackingSlipSection: FC<IPackingSlipSectionProps> = ({
  icon,
  iconAlignment = 'top',
  variant = 'default',
  prefix,
  suffix,
  imageUrl,
  children,
  className = '',
  nameForTests,
}) => (
  <div
    className={classNames(
      'flex gap-x-4 items-center text-black text-xs',
      variant === 'default' && 'border-2 border-gb-gray-300 rounded-md p-3',
      className
    )}
    data-testid={`packing-slip-section${nameForTests ? `-${kebabCase( nameForTests )}` : ''}`}
  >
    <div
      className={classNames(
        variant === 'topBorderOnly' && 'border-t border-black pt-2',
        variant === 'topAndBottomBorder' && 'border-t border-b border-black py-2',
        'flex gap-x-2 flex-grow h-full'
      )}
    >
      {icon && (
        <div
          className={classNames(
            {
              'flex flex-col items-center justify-center': iconAlignment === 'center',
              'items-center justify-center h-4': iconAlignment === 'top',
            },
            'flex-shrink-0 flex w-4'
          )}
        >
          {iconIsFontAwesome( icon ) ? (
            <FontAwesomeIcon
              size="4x"
              icon={icon}
              className="w-full text-black"
              data-testid="fontawesome-icon"
            />
          ) : (
            <img src={icon} alt="" className="w-full" data-testid="custom-icon" />
          )}
        </div>
      )}
      <div className="flex gap-y-1 flex-col justify-center flex-grow">
        {prefix && (
          <div className="font-bold" data-testid="section-prefix">
            {prefix}
          </div>
        )}
        <div>{children}</div>
        {suffix && <div data-testid="section-suffix">{suffix}</div>}
      </div>
    </div>
    {imageUrl && (
      <img
        src={imageUrl}
        alt=""
        className="max-w-16 max-h-16 snap-end"
        data-testid="section-image"
      />
    )}
  </div>
)

export default PackingSlipSection
