import classNames from 'classnames'
import { kebabCase } from 'lodash'
import { FC, IframeHTMLAttributes } from 'react'

interface IContentEmbedProps extends IframeHTMLAttributes<HTMLIFrameElement> {
  /**
   * The title of the content being embedded.
   */
  title: string

  /**
   * Page to be embedded in an iframe.
   */
  src: string

  /**
   * Classes to pass down to the iframe.
   */
  className?: string
}

const ContentEmbed: FC<IContentEmbedProps> = ({ title, className, ...iframeProps }) => (
  <iframe
    {...iframeProps}
    title={title}
    id={`embed-${kebabCase( title )}`}
    className={classNames( className, 'w-full border-0 overflow-hidden' )}
  />
)

export default ContentEmbed
