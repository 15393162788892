import { FC } from 'react'
import Skeleton from 'react-loading-skeleton'
import { isNil, kebabCase, omitBy } from 'lodash'
import pluralize from 'pluralize'

import Card from 'src/components/01-atoms/Card'
import ActionButton from 'src/components/01-atoms/ActionButton'
import StatusIconWrapper from 'src/components/01-atoms/StatusIconWrapper'
import ActionsPendingCard from 'src/components/02-molecules/ActionsPendingCard'

import useAppParams from 'src/utils/hooks/useAppParams'

import {
  IFacilityType,
  IManifestAggregate,
  IManifestFilterCategory,
  IShippingLabelStatus,
} from 'src/graphql/types'
import {
  getActionsFromAggregates,
  getAggregateValue,
  IAction,
} from 'src/utils/helpers/actionButtons'

import useMultiFacilityParams from 'src/utils/hooks/useMultiFacilityParams'
import MultiFacilityActionAlert from './MultiFacilityActionAlert'

interface IActionsPending {
  facilityAggregates: IManifestAggregate[]
  labelAggregates: IManifestAggregate[]
  fetchingAggregates?: boolean
}

const ActionsPending: FC<IActionsPending> = ({
  fetchingAggregates,
  labelAggregates,
  facilityAggregates,
}) => {
  const {
    mdashAccountId,
    toShipOn,
    toShipOnAsString,
    makeLinkUrls,
    withFacilityParams,
    canUseMultiFacility,
  } = useAppParams()
  const search = withFacilityParams()
  const actions: IAction[] = getActionsFromAggregates(
    labelAggregates,
    mdashAccountId!,
    toShipOnAsString,
    search
  )
  const { isPhysicallyActionable, facilitiesWithOrders } = useMultiFacilityParams({
    facilityAggregates,
  })
  const toShipCount = labelAggregates.filter(( v ) => v.query === IShippingLabelStatus.TO_SHIP )[0]
    ?.value

  const numUnassignedShippingFacility = canUseMultiFacility
    ? getAggregateValue( facilityAggregates, '0', IManifestFilterCategory.SHIPPING_FACILITY )
    : 0

  const numUnassignedPickupLocation = canUseMultiFacility
    ? getAggregateValue( facilityAggregates, '0', IManifestFilterCategory.PICK_UP_LOCATION )
    : 0

  const numUnassigned = numUnassignedPickupLocation + numUnassignedShippingFacility

  const packagePluralized = pluralize( 'package', numUnassigned )

  return (
    <div className="col-span-12 lg:col-span-6 flex">
      {toShipOn ? (
        <ActionsPendingCard
          toShipOn={toShipOn}
          productSummaryLink={omitBy(
            { pathname: makeLinkUrls( toShipOnAsString ).productSummary, search },
            isNil
          )}
          toShipCount={toShipCount || 0}
          fetching={fetchingAggregates}
        >
          {isPhysicallyActionable ? (
            actions.length >= 1 &&
            actions &&
            actions.map(({ buttonText, count, buttonTextPlural, ...action }) => (
              <ActionButton key={`action-${kebabCase( buttonText )}`} {...action}>
                {count} {Number( count ) > 1 ? buttonTextPlural : buttonText}
              </ActionButton>
            ))
          ) : (
            <>
              {numUnassigned > 0 && (
                <StatusIconWrapper level="error">
                  {numUnassigned} {packagePluralized} {numUnassigned === 1 ? 'needs' : 'need'} to be
                  assigned to a facility.
                </StatusIconWrapper>
              )}
              {facilitiesWithOrders.length > 0 &&
                facilitiesWithOrders.map(( f ) => (
                  <MultiFacilityActionAlert
                    key={`tangible-facility-alert-${f.query}-${kebabCase( f.label ?? '' )}`}
                    id={f.query!}
                    name={f.label!}
                    type={f.category! as unknown as IFacilityType}
                  />
                ))}
            </>
          )}
        </ActionsPendingCard>
      ) : (
        <Card className="p-0 w-full">
          <Skeleton className="items-center" height="100%" width="100%" />
        </Card>
      )}
    </div>
  )
}

export default ActionsPending
