import { loadStripe } from '@stripe/stripe-js/pure'
import { StripeElementsOptions } from '@stripe/stripe-js'

loadStripe.setLoadParameters({ advancedFraudSignals: false })
export const stripePromise = process.env.REACT_APP_STRIPE_PUBLIC_KEY
  ? loadStripe( process.env.REACT_APP_STRIPE_PUBLIC_KEY )
  : null

export const stripeOptions: StripeElementsOptions = {
  fonts: [
    {
      family: 'Poppins',
      cssSrc: 'https://fonts.googleapis.com/css2?family=Poppins&display=swap',
    },
  ],
  appearance: {
    variables: {
      fontFamily: 'Poppins',
    },
  },
}
