import classNames from 'classnames'
import { FC } from 'react'

interface IUSDCellValue {
  /**
   * amount in USD
   */
  amount: string | null | undefined

  /**
   * Extra classes to add to this element's container, which also contains the border and padding.
   */
  className?: string
}

const USDCellValue: FC<IUSDCellValue> = ({ amount, className }) => {
  const isNegative = amount && amount.indexOf( '-' ) === 0
  return <span className={classNames( className, { 'text-red-700': isNegative })}>{amount}</span>
}

export default USDCellValue
