import { useParams } from 'react-router-dom'

const useCheckoutParams = () => {
  const params = useParams()
  const { orderId } = params
  const validOrderId = !!orderId && Number( orderId ?? 0 ) > 0

  return {
    orderId: orderId ?? '',
    validOrderId,
  }
}

export default useCheckoutParams
