/**
 * PPI = Pixels Per Inch, on web, to translate to/from inches for print
 */
export const PPI = 96

/**
 * Get the inch value from a pixel value.
 * @param {number} pixels - The size in pixels to be converted to inches.
 * @returns {number} The size in inches.
 */
export const getInValue = ( pixels: number ): number => pixels / PPI

/**
 * Get the pixel value from an inch value.
 * @param {number} inches - The size in inches to be converted to pixels.
 * @returns {number} The size in pixels.
 */
export const getPxValue = ( inches: number ): number => inches * PPI
