import { FC, useState } from 'react'
import classNames from 'classnames'

import Button, { ButtonProps } from 'src/components/01-atoms/Button'

interface IToggleGroupProps {
  /**
   * The value of the selected item.
   */
  selected?: string

  /**
   * An array of buttons and their respective props to be passed down to the Button component.
   */
  buttons: {
    value: string
    label: string
    props?: ButtonProps
  }[]

  /**
   * Function called when the value changes.
   */
  handleChange?: CallableFunction

  /**
   * Class names to pass down to the containing element.
   */
  className?: string
}

const ToggleGroup: FC<IToggleGroupProps> = ({
  selected,
  buttons,
  handleChange = () => {},
  className,
}) => {
  const [ value, setValue ] = useState( selected )

  return (
    <div className={classNames( className, 'flex flex-row' )}>
      {buttons.map(({ value: buttonValue, label, ...props }, i ) => (
        <Button
          size="small"
          outline
          selected={value === buttonValue}
          key={`toggle-button-${buttonValue}-${label}`}
          onClick={() => {
            setValue( buttonValue )
            handleChange( buttonValue )
          }}
          className={classNames({
            'rounded-r-none': i < buttons.length - 1,
            'rounded-l-none': i > 0 && i < buttons.length,
            'border-l-0': i > 0,
          })}
          {...props}
        >
          {label}
        </Button>
      ))}
    </div>
  )
}

export default ToggleGroup
