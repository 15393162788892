import { FC } from 'react'
import { isNil, kebabCase, omitBy } from 'lodash'
import classNames from 'classnames'
import Skeleton from 'react-loading-skeleton'

import TabsNav from 'src/components/02-molecules/TabsNav'
import { IManifestAggregate, IManifestFilterCategory, IShippingFacility } from 'src/graphql/types'

import useAppParams from 'src/utils/hooks/useAppParams'

interface IMultiFacilityTabsProps {
  activeFacilities: IShippingFacility[]
  facilities: IManifestAggregate[]
  fetching?: boolean
}

const MultiFacilityTabs: FC<IMultiFacilityTabsProps> = ({
  activeFacilities,
  facilities: facilityAggregates,
  fetching = false,
}) => {
  const {
    isMultiShippingFacility,
    isMultiPickUpLocation,
    makeLinkUrls,
    withFacilityParams,
    facilityId,
  } = useAppParams()
  const shippingFacilities =
    ( isMultiShippingFacility &&
      facilityAggregates.filter(( v ) => v.category === IManifestFilterCategory.SHIPPING_FACILITY )) ||
    []
  const pickupFacilities =
    ( isMultiPickUpLocation &&
      facilityAggregates.filter(( v ) => v.category === IManifestFilterCategory.PICK_UP_LOCATION )) ||
    []

  const unsortedFacilities = [ ...shippingFacilities, ...pickupFacilities ]
  const facilities = unsortedFacilities
    .sort(( a, b ) => ( Number( a.query ) < Number( b.query ) ? -1 : 1 ))
    .filter(( f ) => !!f?.label )

  // We should display these facilities:
  // - active (NOT soft-deleted facilities)
  // - intangible facilities ('All', 'Unassigned')
  // - soft-deleted facilities that has orders routed through them
  //   - if they are soft-deleted BEFORE current ship date, mark as problematic
  //       because it means somehow orders still route to soft-deleted facilities
  //   - otherwise, business as usual. meaning orders were routed there BEFORE they got soft-deleted

  const selectedFacility = facilities.filter(( f ) => f?.query === facilityId )[0]
  const trulyActiveFacilities = facilities.filter(
    ( x ) =>
      Number( x.query || 0 ) === 0 ||
      x.category === IManifestFilterCategory.PICK_UP_LOCATION ||
      x.value > 0 ||
      activeFacilities.find(( y ) => y.id === x.query )
  )
  const tabs = trulyActiveFacilities.map(( f ) => ({
    text: `${f.label} (${f.value})`,
    key: `facility-tab-${kebabCase( f.label || '' )}`,
    'data-testid': `facility-tab-${f.query}`,
    isActive: f?.query === facilityId,
    isProblematic:
      Number( f.query || 0 ) > 0 &&
      f.category === IManifestFilterCategory.SHIPPING_FACILITY &&
      !activeFacilities.find(( x ) => x.id === f.query ) &&
      f.value > 0,
    to: omitBy(
      {
        pathname: makeLinkUrls().manifest,
        search:
          f?.query &&
          withFacilityParams({
            facilityId: f.query,
            facilityType: f.category,
          }),
      },
      isNil
    ),
  }))

  if ( trulyActiveFacilities.filter(( x ) => Number( x.query || 0 ) > 0 ).length < 2 ) return null

  return (
    <>
      {fetching && facilities.length === 0 && <Skeleton className="h-8 mt-6" />}
      <div
        className={classNames({ 'opacity-50': fetching }, 'relative' )}
        data-testid="multifacility-tab-navigation"
      >
        {fetching && <div className="absolute inset-0 z-100" />}
        {facilities.length > 0 && (
          <TabsNav
            mobileTriggerTitle={
              selectedFacility
                ? `${selectedFacility?.label} (${selectedFacility?.value})`
                : 'Select Facility'
            }
            tabs={tabs}
          />
        )}
      </div>
    </>
  )
}

export default MultiFacilityTabs
