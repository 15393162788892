export const colorize = ({ id, fetching }: { id: string; fetching?: boolean }) => {
  if ( fetching ) return 'gray'

  switch ( id ) {
    case 'not-migrated-not-printed':
    case 'migrated-not-printed':
      return '#b3b3b3'

    case 'not-migrated':
    case 'not-migrated-printed':
      return '#ee9369'

    case 'migrated':
    case 'migrated-printed-mdx':
      return '#b0d566'

    case 'migrated-printed-md2':
      return '#90a0c8'

    default:
      return '#fada53'
  }
}
