import classNames from 'classnames'
import { isEqual, isString } from 'lodash'
import { FC, MouseEventHandler } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import Tab, { ITabProps } from 'src/components/01-atoms/Tab'

import { IChildLink } from 'src/utils/types/IChildLink'

export interface ITabNavLink
  extends ITabProps,
    Partial<Pick<IChildLink, 'text' | 'disabled' | 'to'>> {
  /**
   * Action to take when a link is clicked.
   */
  handleLinkClick?: MouseEventHandler<HTMLAnchorElement>
}

const TabNavLink: FC<ITabNavLink> = ({
  text,
  disabled = false,
  to = '#',
  isActive = false,
  handleLinkClick = () => {},
  ...props
}) => {
  const location = useLocation()
  const toProp = disabled ? '#' : to
  const toAsString = isString( to ) ? to : to.pathname ?? ''
  const onExactPage = location.pathname.includes( toAsString ) && isEqual( to.search, location.search )
  const tabIsActive: boolean = isActive || onExactPage

  return (
    <Tab isActive={tabIsActive} disabled={disabled} {...props}>
      <NavLink
        onClick={handleLinkClick}
        className={() => classNames({ 'cursor-not-allowed opacity-50': disabled }, 'w-full h-full' )}
        to={toProp}
        end
      >
        {text}
      </NavLink>
    </Tab>
  )
}

export default TabNavLink
