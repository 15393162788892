import { useQuery } from 'urql'
import { loader } from 'graphql.macro'

import {
  IGetDeliveryStatesQuery,
  IGetDeliveryStatesQueryVariables,
} from 'src/graphql/queries/getDeliveryStates.types'

const getDeliveryStatesQuery = loader( 'src/graphql/queries/getDeliveryStates.graphql' )

const useGetDeliveryStates = () => {
  const [{ error, data, fetching }] = useQuery<
    IGetDeliveryStatesQuery,
    IGetDeliveryStatesQueryVariables
  >({
    query: getDeliveryStatesQuery,
  })

  return { fetching, error, data }
}

export default useGetDeliveryStates
