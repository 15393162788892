import { createContext } from 'react'

import { PackageListPackage } from 'src/utils/types/PackageListPackage'

export interface PackagesContextProps {
  packages: PackageListPackage[]
}

const PackagesContext = createContext<PackagesContextProps>({
  packages: [],
})

export default PackagesContext
