import { useWindowSize } from 'rooks'
import { faHourglassHalf, faPlus } from '@fortawesome/pro-duotone-svg-icons'
import { faTrashXmark } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC, useCallback, useMemo } from 'react'
import Button from 'src/components/01-atoms/Button'
import TextInput from 'src/components/01-atoms/TextInput'
import TextArea from 'src/components/01-atoms/TextArea'
import * as Accordion from '@radix-ui/react-accordion'
import LatchedTypeaheadSearch from 'src/components/02-molecules/LatchedTypeaheadSearch'
import Table from 'src/components/02-molecules/Table'
import { centsToUSD, signedCentsToUSD } from 'src/utils/helpers/currencies'
import PackageTag, { PackageType } from 'src/components/01-atoms/PackageTag'
import { useDirtyTracking } from './hooks/useDirtyTracking'
import { IShoppingCartProps } from './types'

import './styles.css'

const ShoppingCart: FC<IShoppingCartProps> = ({
  availableProducts,
  cartItems,
  onCartItemAddition,
  onCartItemDeletion,
  onProductChange,
  onProductQuantityChange,
  onProductOptionChange,
  onSpecialInstructionsChange,
}) => {
  const { innerWidth } = useWindowSize()
  const dirtyTrack = useDirtyTracking({
    cartItems,
    onCartItemAddition,
    onCartItemDeletion,
    onProductChange,
    onProductQuantityChange,
    onProductOptionChange,
    onSpecialInstructionsChange,
  })
  const availableProductsToChooseFrom = useMemo(
    () =>
      availableProducts.map(
        ({ id, name, isSoldOut = false, isAvailable = true, isWhitelabel = false, sku }) => ({
          value: id,
          label: `${name}${isSoldOut ? ' ***SOLD OUT***' : ''}`,
          children: (
            <>
              <div className="grow">
                {name}
                {sku && <div className="text-2xs text-gb-gray-700 font-normal">(SKU #{sku})</div>}
              </div>
              {( isWhitelabel || !isAvailable || isSoldOut ) && (
                <div className="mt-1 flex flex-row gap-1">
                  {isWhitelabel && <PackageTag packageType={PackageType.WHITE_LABEL} />}
                  {!isAvailable ? (
                    <PackageTag
                      packageType={PackageType.GENERIC_WHITE_ON_BLACK}
                      label="Unavailable"
                    />
                  ) : (
                    isSoldOut && (
                      <PackageTag packageType={PackageType.GENERIC_LIGHT_GRAY} label="Sold Out" />
                    )
                  )}
                </div>
              )}
            </>
          ),
        })
      ),
    availableProducts
  )
  const availableOptionsForProductVariance = useCallback(
    ({ productId, varianceId }: { productId: string; varianceId: string }) =>
      availableProducts
        .find(( x ) => x.id === productId )
        ?.variances.find(( x ) => x.id === varianceId )
        ?.options.map(( x ) => ({
          value: x.id,
          label: [
            x.name || x.id,
            x.priceAdjInCents > 0 && `(${signedCentsToUSD( x.priceAdjInCents )})`,
          ]
            .filter(( x ) => x )
            .join( ' ' ),
        })),
    availableProducts
  )

  return (
    <Table
      headers={[
        { name: 'Product' },
        { name: 'Qty', className: 'w-1/12' },
        { name: 'Item Cost', className: 'w-1/12' },
        { name: 'Subtotal', className: 'w-1/12' },
        { name: '', className: 'w-4' },
      ]}
      tableClasses="shopping-cart-table text-sm"
    >
      {dirtyTrack.latchedCartItems
        .sort(( a, b ) => Number( a.id ) - Number( b.id ))
        .map(( cartItem ) => {
          const productInCart = availableProducts.find(( x ) => x.id === cartItem.product.id )
          const variances = productInCart?.variances

          return (
            <tr key={`cart-item-${cartItem.id}`} data-testid={`cart-item-${cartItem.id}`}>
              <td className="p-2 align-top block pt-6 md:pt-2 md:table-cell md:border-b-2">
                <LatchedTypeaheadSearch
                  availableOptions={availableProductsToChooseFrom}
                  placeholder="Select a Product"
                  value={productInCart?.id}
                  handleChange={( x ) =>
                    dirtyTrack.handleProductChange({ cartItemId: cartItem.id, productId: x })
                  }
                />
                <div className="flex justify-end text-right">
                  <table className="w-[calc(100%-32px)]">
                    <tbody>
                      {variances?.map(( variance ) => {
                        const varianceOptionPairInCartItem = cartItem.selectedProductOptions.find(
                          ( x ) => x.varianceId === variance.id
                        )

                        const availableOptions = availableOptionsForProductVariance({
                          productId: cartItem.product.id,
                          varianceId: variance.id,
                        })

                        return availableOptions ? (
                          <tr
                            key={`variance-${variance.id}-options`}
                            data-testid={`variance-${variance.id}-options`}
                          >
                            <td className="pr-2 font-bold">
                              {variance.name && `${variance.name}`}
                            </td>
                            <td className="px-0 pt-0.5 pb-0 w-3/4">
                              <LatchedTypeaheadSearch
                                availableOptions={availableOptions}
                                value={varianceOptionPairInCartItem?.productOptionId}
                                placeholder={
                                  variance.name
                                    ? `Select an option for ${variance.name}`
                                    : 'Select an option'
                                }
                                handleChange={( x ) =>
                                  dirtyTrack.handleProductOptionChange({
                                    cartItemId: cartItem.id,
                                    varianceId: variance.id,
                                    optionId: x,
                                  })
                                }
                              />
                            </td>
                          </tr>
                        ) : null
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="pt-2">
                  <Accordion.Root className="w-full" type="single" collapsible>
                    <Accordion.Item value="item-1">
                      <Accordion.Trigger className="flex">
                        <FontAwesomeIcon icon={faPlus} className="text-sm p-2 pt-0.5" />
                        <span className="text-gb-blue-700">Add Special Instructions</span>
                      </Accordion.Trigger>
                      <Accordion.Content className="py-2">
                        <TextArea
                          outline
                          defaultValue={cartItem.specialInstructions || ''}
                          onBlur={( x ) =>
                            dirtyTrack.handleSpecialInstructionsChange({
                              cartItemId: cartItem.id,
                              specialInstructions: x.currentTarget.value,
                            })
                          }
                        />
                      </Accordion.Content>
                    </Accordion.Item>
                  </Accordion.Root>
                </div>
              </td>

              <td className="p-2 align-top flex md:table-cell md:border-b-2" data-label="Quantity">
                <TextInput
                  outline
                  labelText="Quantity"
                  value={Number( cartItem.quantity || 1 )}
                  type="number"
                  className="text-right"
                  onChange={( x ) =>
                    dirtyTrack.handleProductQuantityChange({
                      cartItemId: cartItem.id,
                      quantity: Number( x.currentTarget.value || 1 ),
                    })
                  }
                />
              </td>
              <td
                className="text-right p-2 py-3 align-top flex md:table-cell md:border-b-2"
                data-label="Item Cost"
              >
                {dirtyTrack.isRecalculatingCartItemId === cartItem.id ? (
                  <FontAwesomeIcon icon={faHourglassHalf} className="animate-spin" />
                ) : (
                  centsToUSD( cartItem.unitPriceInCents )
                )}
              </td>
              <td
                className="flex text-right p-2 py-3 align-top md:border-b-2 md:table-cell"
                data-label="Subtotal"
              >
                {dirtyTrack.isRecalculatingCartItemId === cartItem.id ? (
                  <FontAwesomeIcon icon={faHourglassHalf} className="animate-spin" />
                ) : (
                  centsToUSD( cartItem.subtotalInCents )
                )}
              </td>
              <td
                className="block text-center p-2 pt-1.5 pb-6 md:pb-2 align-top border-b-2 md:table-cell"
                data-testid="delete-action"
              >
                <Button
                  variant={innerWidth! < 768 ? 'alert' : 'clear'}
                  className="w-full center md:border-0"
                  outline
                  onClick={() => {
                    // I'm okay with using a confirm here. We can always change it later or disable the `no-alert` rule in eslint to allow this globally.
                    // eslint-disable-next-line no-restricted-globals, no-alert
                    if ( !confirm( 'Are you sure you want to remove this item from the cart?' )) return
                    dirtyTrack.handleCartItemDeletion({ cartItemId: cartItem.id })
                  }}
                >
                  <span className="md:sr-only">Remove From Cart</span>
                  <FontAwesomeIcon
                    icon={faTrashXmark}
                    className="hidden text-lg md:text-gb-gray-800 md:block"
                  />
                </Button>
              </td>
            </tr>
          )
        })}
      <tr data-testid="new-cart-item-row">
        <td className="p-1 bg-gb-gray-100" colSpan={1}>
          <div className="flex">
            <FontAwesomeIcon icon={faPlus} className="text-xl p-2 pt-2.5" />
            <LatchedTypeaheadSearch
              availableOptions={availableProductsToChooseFrom}
              placeholder="Add a Product"
              handleSelect={( x ) => dirtyTrack.handleCartItemAddition({ productId: x })}
              forceUpdateValue=""
              sortResults="alpha"
              showDropdownButton
            />
          </div>
        </td>
        <td className="hidden p-1 bg-gb-gray-100 md:table-cell" colSpan={4} />
      </tr>
    </Table>
  )
}

export default ShoppingCart
