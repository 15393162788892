import { IGetAggregateInput } from '../types/IGetAggregateInput'
import { useGetManifestAuxiliaryAggregate } from './useGetManifestAuxiliaryAggregate'
import { useGetManifestLabelAggregate } from './useGetManifestLabelAggregate'
import { useGetManifestOrderTypeAggregate } from './useGetManifestOrderTypeAggregate'
import { useGetManifestProductAggregate } from './useGetManifestProductAggregate'
import { useGetManifestFacilityAggregate } from './useGetManifestFacilityAggregate'
import { useGetManifestCorporateOrderAggregate } from './useGetManifestCorporateOrderAggregate'

export const useGetManifestCombinedAggregates = ({ variables }: IGetAggregateInput ) => {
  const {
    error: labelAggregatesError,
    data: manifestLabelAggregates,
    fetching: fetchingLabelAggregates,
    refetch: refetchLabelAggregates,
  } = useGetManifestLabelAggregate({ variables })

  const {
    error: productAggregatesError,
    data: manifestProductAggregates,
    fetching: fetchingProductAggregates,
    refetch: refetchProductAggregates,
  } = useGetManifestProductAggregate({ variables })

  const {
    error: orderTypeAggregatesError,
    data: manifestOrderTypeAggregates,
    fetching: fetchingOrderTypeAggregates,
    refetch: refetchOrderTypeAggregates,
  } = useGetManifestOrderTypeAggregate({ variables })

  const {
    error: corporateOrderAggregatesError,
    data: manifestCorporateOrderAggregates,
    fetching: fetchingCorporateOrderAggregates,
    refetch: refetchCorporateOrderAggregates,
  } = useGetManifestCorporateOrderAggregate({ variables })

  const {
    error: auxiliaryAggregatesError,
    data: manifestAuxiliaryAggregates,
    fetching: fetchingAuxiliaryAggregates,
    refetch: refetchAuxiliaryAggregates,
  } = useGetManifestAuxiliaryAggregate({ variables })

  const {
    error: facilityAggregatesError,
    data: manifestFacilityAggregates,
    fetching: fetchingFacilityAggregates,
    refetch: refetchFacilityAggregates,
  } = useGetManifestFacilityAggregate({ variables })

  const labelAggregates =
    manifestLabelAggregates?.manifestPackages.metadata &&
    manifestLabelAggregates?.manifestPackages.metadata.aggregates
  const productAggregates =
    manifestProductAggregates?.manifestPackages.metadata &&
    manifestProductAggregates.manifestPackages.metadata.aggregates
  const orderTypeAggregates =
    manifestOrderTypeAggregates?.manifestPackages.metadata &&
    manifestOrderTypeAggregates.manifestPackages.metadata.aggregates
  const corporateOrderAggregates =
    manifestCorporateOrderAggregates?.manifestPackages.metadata &&
    manifestCorporateOrderAggregates.manifestPackages.metadata.aggregates
  const auxiliaryAggregates =
    manifestAuxiliaryAggregates?.manifestPackages.metadata &&
    manifestAuxiliaryAggregates?.manifestPackages.metadata.aggregates
  const facilityAggregates =
    manifestFacilityAggregates?.manifestPackages.metadata &&
    manifestFacilityAggregates?.manifestPackages.metadata.aggregates

  const aggregates = ( labelAggregates || [])
    .concat( productAggregates || [])
    .concat( orderTypeAggregates || [])
    .concat( corporateOrderAggregates || [])
    .concat( auxiliaryAggregates || [])
    .concat( facilityAggregates || [])

  const error =
    labelAggregatesError ||
    productAggregatesError ||
    orderTypeAggregatesError ||
    corporateOrderAggregatesError ||
    auxiliaryAggregatesError ||
    facilityAggregatesError
  const errorMessage =
    error &&
    ( labelAggregatesError?.message ||
      productAggregatesError?.message ||
      orderTypeAggregatesError?.message ||
      corporateOrderAggregatesError?.message ||
      auxiliaryAggregatesError?.message ||
      facilityAggregatesError?.message )

  return {
    aggregates,
    labelAggregates,
    facilityAggregates,
    fetchingLabelAggregates,
    refetchLabelAggregates,
    fetchingProductAggregates,
    refetchProductAggregates,
    fetchingOrderTypeAggregates,
    refetchOrderTypeAggregates,
    fetchingCorporateOrderAggregates,
    refetchCorporateOrderAggregates,
    fetchingAuxiliaryAggregates,
    refetchAuxiliaryAggregates,
    fetchingFacilityAggregates,
    refetchFacilityAggregates,
    error,
    errorMessage,
  }
}
