import { Configuration, Payload } from 'rollbar'
import packageConfig from './package.config'

export default {
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: true,
  hostSafeList: [ 'goldbelly.com' ],
  payload: {
    environment: process.env.REACT_APP_ENVIRONMENT,
    client: {
      javascript: {
        code_version: packageConfig.appVersion,
        source_map_enabled: true,
      },
    },
  } as Payload,
} as Configuration
