import { FC } from 'react'
import { kebabCase } from 'lodash'

import TimelineList from 'src/components/01-atoms/TimelineList'
import Grid from 'src/components/01-atoms/Grid'
import DeliveryDatePicker, { IDeliveryDate } from 'src/components/02-molecules/DeliveryDatePicker'

import { ILineItem, IProduct } from 'src/graphql/types'
import { centsToUSD } from 'src/utils/helpers/currencies'
import { dateForManifest } from 'src/utils/helpers/date'

import './styles.css'

export type TDeliveryOptionCartItem = {
  id: ILineItem['id']
  name: ILineItem['name']
  sku?: IProduct['sku']
  selectedOptions?: { id: string; name: string }[]
}

interface IDeliveryOptionsFormProps {
  /**
   * Cart items to display in the form.
   */
  cartItems?: TDeliveryOptionCartItem[]

  /**
   * The shipping calendar that comes back from the back end for this package.
   */
  shippingCalendar?: IDeliveryDate[]

  /**
   * The date the user selected.
   */
  selected?: Date

  /**
   * A method to be called when the user changes the selected date.
   */
  handleChange?: ( date: Date ) => void
}

const DeliveryOptionsForm: FC<IDeliveryOptionsFormProps> = ({
  cartItems = [],
  shippingCalendar = [],
  selected,
  handleChange = () => {},
}) => {
  if ( !cartItems || cartItems.length === 0 ) return null

  const formattedShippingAdd = ( addlCost: number | undefined ) => {
    if ( !addlCost ) return ''
    return addlCost > 0 ? `+${centsToUSD( addlCost )}` : 'FREE'
  }

  const getDeliveryInfoFromDate = ( date: Date, shippingCalendar: IDeliveryDate[]) =>
    shippingCalendar.find(( d ) => d.requestedDeliveryOn === dateForManifest( date ))
  const deliveryInfo = selected ? getDeliveryInfoFromDate( selected, shippingCalendar ) : null

  return (
    <Grid>
      <div className="col-span-12 md:col-span-6" data-testid="suborder-product-list">
        Products in this order:
        <TimelineList className="my-2">
          {cartItems.map(( li ) => (
            <li key={`line-item-${li.id}`}>
              {li.name} {li.sku && <>(SKU# {li.sku})</>}
              {li.selectedOptions && li.selectedOptions?.length > 0 && (
                <ul className="block text-2xs list-none">
                  {li.selectedOptions?.map(({ id, name }) => (
                    <li key={`product-option-${id}-${kebabCase( name )}`}>{name}</li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </TimelineList>
      </div>
      <div className="col-span-12 md:col-span-6">
        <Grid>
          <div className="col-span-8 md:col-span-6">
            <DeliveryDatePicker
              shippingCalendar={shippingCalendar}
              selected={selected}
              onChange={handleChange}
            />
          </div>
          <div
            className="col-span-4 md:col-span-6 font-semibold self-center"
            data-testid="shipping-cost"
          >
            {shippingCalendar.length > 0 && formattedShippingAdd( deliveryInfo?.rateInCents )}
          </div>
        </Grid>
      </div>
    </Grid>
  )
}

export default DeliveryOptionsForm
