import { FC, useState } from 'react'
import { faCalendar } from '@fortawesome/pro-duotone-svg-icons'

import LoadingBar from 'src/components/01-atoms/LoadingBar'
import Alert from 'src/components/01-atoms/Alert'
import Button from 'src/components/01-atoms/Button'
import CircleIcon from 'src/components/01-atoms/CircleIcon'
import DeliveryDatePicker, { IDeliveryDate } from 'src/components/02-molecules/DeliveryDatePicker'
import Modal, { IModalProps } from 'src/components/02-molecules/Modal'
import FinishButton from 'src/components/02-molecules/FinishButton'
import ChoiceInput from 'src/components/01-atoms/ChoiceInput'

import { dateForManifest } from 'src/utils/helpers/date'
import { centsToUSD } from 'src/utils/helpers/currencies'

import styles from './styles.module.css'

export interface ISelectDeliveryDateModal extends IModalProps {
  shippingCalendar?: IDeliveryDate[]
  selected: Date
  handleSave?: ( date: Date ) => void
  error?: string
  loading?: boolean
  saving?: boolean
  showShippingCost?: boolean
  success?: boolean
  shippingChargedToCustomerInCents?: number
}

const SelectDeliveryDateModal: FC<ISelectDeliveryDateModal> = ({
  shippingCalendar = [],
  selected,
  handleSave = () => {},
  error,
  loading = false,
  saving = false,
  showShippingCost = true,
  success = false,
  shippingChargedToCustomerInCents = 0,
  ...modalProps
}) => {
  const { handleClose = () => {} } = modalProps
  const [ calendarIsOpen, setCalendarIsOpen ] = useState<boolean>( false )
  const [ deliveryDate, setDeliveryDate ] = useState<Date>( selected )
  const [ isUpchargeApproved, setIsUpchargeApproved ] = useState<boolean>( false )

  const getDeliveryInfoFromDate = ( date: Date, shippingCalendar: IDeliveryDate[]) =>
    shippingCalendar.find(( d ) => d.requestedDeliveryOn === dateForManifest( date ))
  const newShippingRateInCents =
    getDeliveryInfoFromDate( deliveryDate, shippingCalendar )?.rateInCents ?? 0
  const upchargeNeedsApproval = newShippingRateInCents > shippingChargedToCustomerInCents

  return (
    <Modal
      contentClassName="overflow-y-visible sm:max-w-125 md:max-w-125 lg:max-w-125 pb-9 pt-12 px-8 sm:px-14"
      {...modalProps}
    >
      <fieldset
        disabled={loading || saving}
        data-testid="select-delivery-date-modal"
        className="flex flex-col center gap-y-6 text-sm lg:w-full relative"
      >
        {loading && (
          <>
            <div className="absolute inset-0 w-full h-full bg-white z-60" />
            <LoadingBar
              titleText="Loading delivery dates..."
              current={99}
              total={100}
              className="absolute self-center w-2/3 z-100"
            />
          </>
        )}
        <div className="flex flex-col gap-2 items-center text-center">
          <CircleIcon size="large" color="indigo" icons={[ faCalendar ]} />
          <div className="text-gb-gray-900 text-lg font-semibold leading-tight">
            Select a New Delivery Date
          </div>
        </div>
        <DeliveryDatePicker
          shippingCalendar={( shippingCalendar as IDeliveryDate[]) ?? []}
          selected={deliveryDate}
          disabled={loading || success}
          onChange={( value: Date ) => setDeliveryDate( value )}
          popperClassName={styles.datePickerPopper}
          wrapperClassName="w-full"
          showShippingCost={showShippingCost}
          shippingChargedToCustomerInCents={shippingChargedToCustomerInCents}
          triggerWithButton
          onCalendarOpen={() => setCalendarIsOpen( true )}
          onCalendarClose={() => setCalendarIsOpen( false )}
        />

        <div className="flex flex-col center gap-y-2">
          {error && <Alert type="error">{error}</Alert>}
          {upchargeNeedsApproval && (
            <>
              <Alert type="warning" className="text-center">
                This delivery date will incur an upcharge of{' '}
                <strong>{centsToUSD( newShippingRateInCents )}</strong> to the customer. Please
                confirm with them before proceeding.
              </Alert>
              <ChoiceInput
                disabled={loading || success}
                labelText="I have confirmed with the customer."
                onChange={( e ) => setIsUpchargeApproved( e.currentTarget.checked )}
              />
            </>
          )}
        </div>
        <fieldset className="flex justify-center gap-3">
          <Button outline onClick={() => handleClose()} disabled={success}>
            Cancel
          </Button>
          {success ? (
            <FinishButton className="flex-1 center md:flex-initial" onClick={() => handleClose()}>
              Done
            </FinishButton>
          ) : (
            <Button
              disabled={calendarIsOpen || ( upchargeNeedsApproval && !isUpchargeApproved )}
              onClick={() => handleSave( deliveryDate )}
            >
              Save
            </Button>
          )}
        </fieldset>
      </fieldset>
    </Modal>
  )
}

export default SelectDeliveryDateModal
