import { FC } from 'react'
import { kebabCase } from 'lodash'
import classNames from 'classnames'
import { IVersioned } from 'src/utils/types/Versioned'

export interface IPackingSlipPackageContentsProps extends IVersioned {
  /**
   * The merchant's name.
   */
  merchantName?: string

  /**
   * The size of the package contents element.
   */
  size?: 'small' | 'default'

  /**
   * The line items in the package.
   */
  lineItems: {
    id: string
    quantity: number
    name: string
    productOptions?: string[]
    specialInstructions?: string | null
    sku?: string[] | string | null
  }[]
}

const PackingSlipPackageContents: FC<IPackingSlipPackageContentsProps> = ({
  merchantName,
  size = 'default',
  lineItems,
  version = 1,
}) => {
  const hasSKUs = lineItems.filter(( x ) => Number( x.sku?.length || 0 ) > 0 ).length

  return version === 1 ? (
    <table
      className={classNames( 'w-full text-black font-semibold', {
        'text-sm': size === 'default',
        'text-xs': size === 'small',
      })}
    >
      <thead className="font-bold uppercase" data-testid="table-header">
        <tr>
          <td className="pr-2 w-10 text-center">Qty</td>
          <td>Items</td>
          {hasSKUs ? <td>SKU</td> : null}
        </tr>
      </thead>
      <tbody>
        {lineItems.map(({ id, quantity, name, productOptions = [], specialInstructions, sku }) => (
          <tr
            key={`lineItem-${id}-${kebabCase( name )}`}
            data-testid={`line-item-${id}`}
            className="align-top"
          >
            <td className="pr-2 pt-0.5 font-bold text-center">{quantity}</td>
            <td className={classNames( sku && sku.length > 0 && 'w-2/3 pr-2' )}>
              <p>{name}</p>
              {specialInstructions && (
                <div
                  className={classNames({
                    'text-xs': size === 'default',
                    'text-2xs': size === 'small',
                  })}
                >
                  <span className="font-bold">Instructions:</span> {specialInstructions}
                </div>
              )}
              {productOptions.length > 0 && (
                <ul
                  className={classNames( 'pl-4 my-1', {
                    'text-xs': size === 'default',
                    'text-2xs': size === 'small',
                  })}
                >
                  {productOptions.map(( optionName ) => (
                    <li key={`productOption-${kebabCase( optionName )}`}>{optionName}</li>
                  ))}
                </ul>
              )}
            </td>
            {sku && sku.length > 0 && (
              <td
                className={classNames( 'pt-1', {
                  'text-xs': size === 'default',
                  'text-2xs': size === 'small',
                })}
                data-testid={`merchant-sku-${id}`}
              >
                {( Array.isArray( sku ) ? sku : [ sku ]).map(( x ) => (
                  <div>{x}</div>
                ))}
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <table className="w-full text-black text-xs">
      <thead className="sr-only" data-testid="table-header">
        <tr>
          <td>Qty</td>
          <td>Items</td>
          {hasSKUs ? <td>SKU</td> : null}
        </tr>
      </thead>
      <tbody>
        <tr className="font-bold">
          <td className="pb-1" colSpan={hasSKUs ? 2 : 3}>
            {merchantName} Order
          </td>
          {hasSKUs ? <td className="pb-1">SKU</td> : null}
        </tr>
        {lineItems.map(({ id, quantity, name, productOptions = [], specialInstructions, sku }) => (
          <tr
            key={`lineItem-${id}-${kebabCase( name )}`}
            data-testid={`line-item-${id}`}
            className="align-top"
          >
            <td className="pr-0.5 font-bold text-center">{quantity}</td>
            <td className={classNames( sku && sku.length > 0 && 'pr-2' )}>
              <p className="font-bold">{name}</p>
              {specialInstructions && (
                <div className="text-xs">
                  <span className="font-bold">Instructions:</span> {specialInstructions}
                </div>
              )}
              {productOptions.length > 0 && (
                <ul className="pl-2 my-1 text-xs">
                  {productOptions.map(( optionName ) => (
                    <li key={`productOption-${kebabCase( optionName )}`}>{optionName}</li>
                  ))}
                </ul>
              )}
            </td>
            {sku && sku.length > 0 && (
              <td className="pt-1 text-xs w-18" data-testid={`merchant-sku-${id}`}>
                {( Array.isArray( sku ) ? sku : [ sku ]).map(( x ) => (
                  <div>{x}</div>
                ))}
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default PackingSlipPackageContents
