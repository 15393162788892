import { FC, PropsWithChildren } from 'react'
import classNames from 'classnames'
import Alert from '../Alert'

interface IHideWhenNotPrintableProps {
  /**
   * Whether or not to show the page due to images not loading or something.
   */
  isPrintable?: boolean
}

const HideWhenNotPrintable: FC<PropsWithChildren<IHideWhenNotPrintableProps>> = ({
  isPrintable = false,
  children,
}) => (
  <div>
    {!isPrintable && (
      <Alert
        type="warning"
        className={classNames({ 'print:hidden': !isPrintable }, 'inline-block mb-4' )}
      >
        There was an error while loading this shipping label. The label will remain in the Not
        Printed queue so you can try again later.
      </Alert>
    )}
    <div
      className={classNames({ 'print:hidden opacity-25 flex flex-col items-center': !isPrintable })}
    >
      {children}
    </div>
  </div>
)

export default HideWhenNotPrintable
