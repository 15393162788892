import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { NavLink, Path } from 'react-router-dom'
import { isString, lowerCase } from 'lodash'
import { motion, useAnimation, useReducedMotion } from 'framer-motion'
import classNames from 'classnames'
import {
  addDays,
  endOfMonth,
  endOfWeek,
  eachDayOfInterval,
  eachWeekOfInterval,
  isSameDay,
  isSameMonth,
  isToday,
  startOfMonth,
  startOfWeek,
  getWeek,
  isEqual,
} from 'date-fns'

import {
  dateForManifest,
  dayWithNumberSuffix,
  shortDate,
  shortDayOfTheWeek,
  shortMonthAndDate,
  todayOrElse,
} from 'src/utils/helpers/date'

import { IManifestCalendarInterval } from 'src/graphql/types'
import CalendarStripIssues from 'src/components/01-atoms/CalendarStripIssues'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHourglass } from '@fortawesome/pro-solid-svg-icons'

export interface ICalendarProps {
  /**
   * The selected date.
   */
  selectedDate?: Date

  /**
   * The dates to show on the calendar. Provide 5 dates for the weekly calendar strip.
   */
  dates?: {
    toShipOn: Date
    toShipCount: number
    fetching?: boolean
  }[]

  /**
   * The prefix for all of the links to dates in this calendar.
   */
  dateLinkObject?: Partial<Path>

  /**
   * Auxiliary information that contains number of issues for each dat in the calendar strip.
   */
  issuesCalendar?: {
    toShipOn: Date
    unassignedPackagesCount: number
    labelIssuesCount: number
    fetching?: boolean
  }[]

  /**
   * Function to call when a date is clicked.
   */
  handleClickOnDate?: CallableFunction

  /**
   * Whether or not the calendar data is still loading.
   */
  loading?: boolean

  /**
   * Whether or not the issue calendar data is still loading
   */
  loadingIssuesCount?: boolean

  /**
   * Whether or not the
   */
  disabled?: boolean

  /**
   * Class names to pass down to the container.
   */
  className?: string

  /**
   * Whether to display calendar in Monthly or Weekly format
   */
  interval?: IManifestCalendarInterval

  /**
   * Whether or not the animation should be disabled.
   */
  forceDisableAnimation?: boolean

  /**
   * Whether or not to show Saturday in the calendar.
   */
  withSaturdayShipping?: boolean
}

const SELECTED_DATE = new Date() // without constant, this will render infinitely

const Calendar: FC<ICalendarProps> = ({
  selectedDate = SELECTED_DATE,
  dates = [],
  issuesCalendar = [],
  dateLinkObject = { pathname: '/' },
  handleClickOnDate = () => {},
  loading = false,
  loadingIssuesCount = false,
  disabled = false,
  interval = IManifestCalendarInterval.WEEKLY,
  forceDisableAnimation = false,
  withSaturdayShipping = false,
  className,
}) => {
  const controls = useAnimation()
  const shouldReduceMotion = useReducedMotion()
  const disableAnimation = forceDisableAnimation || shouldReduceMotion
  const timeoutRef = useRef( setTimeout(() => null ))
  const [ latchedDate, setLatchedDate ] = useState( selectedDate )
  const [ animateForward, setAnimateForward ] = useState( true )
  const [ isAnimationStarting, setIsAnimationStarting ] = useState( false )

  const startOfInterval = useMemo(
    () =>
      addDays(
        startOfWeek(
          interval === IManifestCalendarInterval.MONTHLY
            ? addDays( startOfMonth( selectedDate ), 1 )
            : selectedDate
        ),
        1
      ),
    [ interval, selectedDate ]
  )

  const endOfInterval = useMemo(
    () =>
      addDays(
        endOfWeek(
          interval === IManifestCalendarInterval.MONTHLY ? endOfMonth( selectedDate ) : selectedDate,
          { weekStartsOn: 1 }
        ),
        -1
      ),
    [ interval, selectedDate ]
  )

  const weekdays = [ 'Mon', 'Tue', 'Wed', 'Thu', 'Fri' ]
  if ( withSaturdayShipping ) {
    weekdays.push( 'Sat' )
  }

  const weeklyRows = useMemo(
    () =>
      eachWeekOfInterval({
        start: startOfInterval,
        end: endOfInterval,
      }),
    [ startOfInterval, endOfInterval ]
  )

  useEffect(() => {
    setLatchedDate( selectedDate )
    setAnimateForward( selectedDate >= latchedDate )
  }, [ selectedDate ])

  useEffect(() => {
    clearTimeout( timeoutRef.current )
    setIsAnimationStarting( true )

    controls.start( 'normal' )

    timeoutRef.current = setTimeout(() => {
      controls.start( 'show' )
      setIsAnimationStarting( false )
    }, 200 )
  }, [ startOfInterval.toISOString(), interval ])

  const dateLinkPrefix = isString( dateLinkObject ) ? dateLinkObject : dateLinkObject.pathname

  return (
    <div className={classNames( 'relative lg:shadow-md', className )} data-testid="calendar">
      {disabled && <div className="absolute inset-0 bg-white opacity-50 z-20" />}
      <div
        className={classNames(
          'text-xs grid grid-flow-row gap-0.5 font-medium md:hidden',
          withSaturdayShipping ? 'grid-cols-6' : 'grid-cols-5'
        )}
      >
        {weekdays.map(( day ) => (
          <div className="p-1 lg:p-3" key={`weekday-${lowerCase( day )}`}>
            {day}
          </div>
        ))}
      </div>
      {weeklyRows.map(( weekDayAnchor, weekIndex ) => (
        <div
          key={getWeek( weekDayAnchor, { weekStartsOn: 1 })}
          className={classNames(
            'text-xs grid grid-flow-row border-2 bg-gb-gray-400 gap-0.5',
            withSaturdayShipping ? 'grid-cols-6' : 'grid-cols-5'
          )}
        >
          {eachDayOfInterval({
            start: addDays( weekDayAnchor, 1 ),
            end: addDays( weekDayAnchor, withSaturdayShipping ? 6 : 5 ),
          }).map(( toShipOn, dayIndex ) => {
            const dateIsToday = isToday( toShipOn )
            const dateIsSelected = isSameDay( toShipOn, selectedDate )
            const toShipOnAsString = dateForManifest( toShipOn )
            const dateData = dates.find(( x ) => x.toShipOn.toISOString() === toShipOn.toISOString())
            const toShipCount = dateData?.toShipCount ?? 0
            const issueData = dates.find(( x ) => x.toShipOn.toISOString() === toShipOn.toISOString())

            return (
              <motion.div
                key={toShipOn.toISOString()}
                initial="normal"
                animate={
                  disableAnimation
                    ? { transition: { duration: 0, delay: 0 }, opacity: 1, scaleY: 1 }
                    : controls
                }
                variants={{
                  normal: {
                    scaleY: 0,
                    transition: {
                      duration: 0,
                    },
                  },
                  show: {
                    scaleY: 1,
                    transition: {
                      delay: 0.025 * weekIndex + 0.05 * ( animateForward ? dayIndex : 4 - dayIndex ),
                      duration: dayIndex === ( animateForward ? 0 : 4 ) ? 0.35 : 0.25,
                      type: 'spring',
                    },
                  },
                }}
                className={classNames( 'text-left outline-offset-0 bg-white', {
                  'bg-gb-gray-100': !isSameMonth( selectedDate, toShipOn ),
                  'bg-gb-gray-600 hover:bg-gb-gray-400 text-gb-gray-900':
                    !dateIsSelected && dateIsToday,
                  'bg-gb-blue-100 outline-gb-blue-600': dateIsSelected && !dateIsToday,
                  'bg-green-200 outline-green-500': dateIsToday && dateIsSelected,
                  'text-gb-gray-800 hover:bg-gb-blue-100': !( dateIsSelected || dateIsToday ),
                  'outline outline-2 text-gb-gray-900': dateIsSelected,
                  'opacity-0': isAnimationStarting,
                })}
                data-testid="calendar-link"
              >
                <NavLink
                  to={{
                    ...dateLinkObject,
                    pathname: `${dateLinkPrefix}/${toShipOnAsString}`,
                  }}
                  key={`date-${toShipOnAsString}`}
                  onClick={( e ) => handleClickOnDate( e )}
                >
                  <div className="p-1 lg:p-3" data-testid={`calendar-day-${shortDate( toShipOn )}`}>
                    <span className="lg:hidden">{dayWithNumberSuffix( toShipOn )}</span>
                    <span className="hidden lg:block">
                      {todayOrElse( toShipOn, shortDayOfTheWeek )}, {shortMonthAndDate( toShipOn )}
                    </span>
                    <div className="block md:flex justify-between items-center">
                      <div
                        className={classNames( 'font-semibold lg:text-sm', {
                          'blur-sm': dateData?.fetching || ( loading && dateData === undefined ),
                        })}
                        data-testid="packages-count"
                      >
                        {toShipCount}
                        <span className="sr-only lg:not-sr-only"> orders</span>
                      </div>
                      <div className="flex gap-1 items-center">
                        {( loadingIssuesCount || issueData?.fetching ) && (
                          <FontAwesomeIcon icon={faHourglass} className="animate-pulse" />
                        )}
                        <CalendarStripIssues
                          {...issuesCalendar.find(( x ) => isEqual( x.toShipOn, toShipOn ))}
                        />
                      </div>
                    </div>
                  </div>
                </NavLink>
              </motion.div>
            )
          })}
        </div>
      ))}
    </div>
  )
}

export default Calendar
