import { FC } from 'react'
import classNames from 'classnames'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isArray, isString } from 'lodash'

type Color = 'indigo' | 'orange' | 'red' | 'teal' | 'gray'

interface ICircleIconProps {
  /**
   * The icon being used.
   */
  icons?: IconProp[]

  /**
   * The primary color to use for the icon and background.
   */
  color?: Color

  /**
   * The size of the icon and circle.
   */
  size?: 'large' | 'default'

  /**
   * Classes to pass to the containing element.
   */
  className?: string

  /**
   * Classes to pass to the icon element.
   */
  iconClassName?: string
}

const sizeMap = {
  large: 'h-16 w-16 text-3xl',
  default: 'h-12 w-12 text-2xl',
}

const colorMap: Record<Color, string> = {
  red: 'bg-red-100 text-red-600',
  orange: 'bg-orange-100 text-orange-600',
  teal: 'bg-teal-100 text-teal-600',
  indigo: 'bg-indigo-100 text-indigo-600',
  gray: 'bg-gb-gray-500',
}

const CircleIcon: FC<ICircleIconProps> = ({
  icons = [ '' ],
  color = 'gray',
  size = 'default',
  className,
  iconClassName,
}) => (
  <div className="flex">
    {icons.map(( i, idx ) => (
      <div
        className={classNames(
          sizeMap[size],
          colorMap[color],
          'center rounded-full',
          { '-ml-6': idx > 0 },
          className
        )}
        key={`icon-${isString( i ) ? i : isArray( i ) ? i[1] : ( !!i?.iconName && i?.iconName! ) || idx}`}
      >
        <FontAwesomeIcon
          className={classNames( 'relative z-10', iconClassName )}
          icon={i as IconProp}
        />
      </div>
    ))}
  </div>
)

export default CircleIcon
