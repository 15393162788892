import { FC } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import Alert from 'src/components/01-atoms/Alert'
import PackageListTable from 'src/components/03-organisms/PackageListTable'
import PackagesContext from 'src/contexts/PackagesContext'
import { IShippingLabelStatus } from 'src/graphql/types'

import useAppParams from 'src/utils/hooks/useAppParams'
import DiscardLabelsWrapper from './elements/DiscardLabelsWrapper'

const ConfirmReprint: FC = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const { makeLinkUrls, packageSort } = useAppParams()

  return (
    <DiscardLabelsWrapper
      pageTitle="Print"
      labelPrintStatus={IShippingLabelStatus.PRINTED}
      stepperSteps={[{ text: 'Review' }, { text: 'Print' }]}
      continueButton={{
        label: 'Continue To Print',
        action: () => {
          navigate(
            `${makeLinkUrls().printLabels}${
              search ? `${search}&` : '?'
            }valid_reprint=true&status=printed`
          )
        },
      }}
    >
      <PackagesContext.Consumer>
        {({ packages }) => (
          <>
            <Alert
              titleText="Print these labels again? The labels listed have already been printed."
              size="xl"
              type="warning"
              className="mb-6"
            >
              If not, please go back and change your selection. Be careful as printintg a label
              twice may lead to shipping the order twice.
            </Alert>
            <PackageListTable
              packages={packages!}
              showPackageActionLinks={false}
              sortValue={packageSort}
            />
          </>
        )}
      </PackagesContext.Consumer>
    </DiscardLabelsWrapper>
  )
}

export default ConfirmReprint
