import { FC } from 'react'
import classNames from 'classnames'

import Card from 'src/components/01-atoms/Card'
import Container from 'src/components/01-atoms/Container'

import { ReactComponent as ActionsIcon } from 'src/assets/icons/whats-new-actions.svg'
import { ReactComponent as PrintingIcon } from 'src/assets/icons/whats-new-printing.svg'
import { ReactComponent as MobileFriendlyIcon } from 'src/assets/icons/whats-new-mobile-friendly.svg'

interface IWhatsNewProps {
  forceStillHeroImage?: boolean
}

const WhatsNew: FC<IWhatsNewProps> = ({ forceStillHeroImage = false }) => (
  <>
    <Container className="pt-6">
      <h1 className="text-2xl font-semibold mb-6">What&apos;s New?</h1>
    </Container>
    <div
      className={classNames(
        forceStillHeroImage
          ? 'bg-[url(../assets/whats-new-hero.png)]'
          : 'bg-[url(../assets/whats-new-hero.gif)] motion-reduce:bg-[url(../assets/whats-new-hero.png)]',
        'bg-cover bg-center w-full pb-[70%] md:pb-[33%]'
      )}
    />
    <Container className="md:px-12 pt-9 pb-9">
      <h2 className="text-lg md:text-2xl font-bold leading-6">
        Introducing a new dashboard to support our partnership at scale!
      </h2>
      <p className="text-sm md:text-lg mt-4 mb-9 leading-6">
        We made performance and usability improvements across the board so you can accomplish day to
        day tasks, from shipping nationally to getting financial insights, with ease.
      </p>
      <h3 className="text-lg md:text-2xl font-semibold">Features Overview:</h3>
      <div className="grid gap-6 grid-flow-row lg:grid-flow-row lg:grid-cols-3 mt-6">
        <Card className="p-6">
          <ActionsIcon />
          <h4 className="font-semibold mt-6 mb-2">Action Pending Reminders</h4>
          <p className="text-gb-gray-800 text-sm leading-6">
            Our new action buttons give you clear messages indicating what&apos;s pending for
            fulfilling your orders and launch workflows to help you take care of it.{' '}
            <a
              className="font-semibold underline"
              href="https://www.goldbelly.com/merchant_support/sso?origin=https://merchantsupport.goldbelly.com/hc/en-us/articles/7426290282651"
              target="_blank"
              rel="noreferrer"
            >
              Learn more.
            </a>
          </p>
        </Card>
        <Card className="p-6">
          <PrintingIcon />
          <h4 className="font-semibold mt-6 mb-2">Robust Printing Experience</h4>
          <p className="text-gb-gray-800 text-sm leading-6 md:mb-3">
            Whether you&apos;re printing large batches or just a few, our new printing experience
            guides you through the process, provides detailed status info, and makes it clear what
            actions are pending.{' '}
            <a
              className="font-semibold underline"
              href="https://www.goldbelly.com/merchant_support/sso?origin=https://goldbelly-merchant.zendesk.com/hc/en-us/articles/7428968717723"
              target="_blank"
              rel="noreferrer"
            >
              Learn more.
            </a>
          </p>
        </Card>
        <Card className="p-6">
          <MobileFriendlyIcon />
          <h4 className="font-semibold mt-6 mb-2">Mobile Friendly</h4>
          <p className="text-gb-gray-800 text-sm leading-6">
            Access the improved dashboard on the go! Checkout anything from daily orders to looking
            up order details or checkout reports all from your mobile device.
          </p>
        </Card>
      </div>
      <p className="mt-9 md:text-lg text-sm leading-6">
        More to come, because we built the new dashboard in a way to support innovation that meets
        your needs better and faster.
      </p>
    </Container>
  </>
)
export default WhatsNew
