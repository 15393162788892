import { FC } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileArrowDown } from '@fortawesome/pro-duotone-svg-icons'
import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons'

import Button, { ButtonProps } from 'src/components/01-atoms/Button'
import classNames from 'classnames'

interface IStatementPageHeaderProps {
  /*
    Statement ID
  */
  statementId?: string

  /*
    MDash Account ID
  */
  mdashAccountId?: string

  /*
    title of page
  */
  title?: string

  /*
    total of line items on page
  */
  total?: number

  /**
   * Props for the CSV download button
   */
  downloadButtonProps?: ButtonProps
}

const StatementPageHeader: FC<IStatementPageHeaderProps> = ({
  statementId,
  mdashAccountId,
  title,
  total,
  downloadButtonProps,
}) => (
  <>
    <Link
      className="text-gb-gray-800 font-semibold text-xs"
      to={`/${mdashAccountId}/statements/${statementId}`}
    >
      <FontAwesomeIcon icon={faAngleLeft} /> Back to Statement #{statementId}
    </Link>
    <div className="flex flex-col md:justify-between md:items-start md:flex-row">
      <div>
        <h1 className="text-2xl font-semibold">{title}</h1>
        <div className="text-lg mb-6">Total: {total}</div>
      </div>
      {downloadButtonProps && (
        <Button
          {...downloadButtonProps}
          className={classNames( downloadButtonProps.className, 'justify-center' )}
          outline
        >
          <FontAwesomeIcon icon={faFileArrowDown} className="mr-2 text-lg" />
          Download CSV
        </Button>
      )}
    </div>
  </>
)

export default StatementPageHeader
