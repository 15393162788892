import { ChangeEvent, PointerEvent, useCallback, useState } from 'react'

export const useShiftSelect = <T>(
  items: Array<T>,
  add: ( items: Array<T> ) => void,
  remove: ( items: Array<T> ) => void
) => {
  const [ currentItem, setCurrentItem ] = useState<T | null>( null )
  const [ previousItem, setPreviousItem ] = useState<T | null>( null )

  const [ isChecked, setIsChecked ] = useState<boolean>( false )

  const change = useCallback(
    ( isAdd: boolean, items: Array<T> ) => {
      if ( isAdd ) {
        add( items )
        return
      }
      remove( items )
    },
    [ add, remove ]
  )

  const onChange = useCallback(
    ( event: ChangeEvent<HTMLInputElement>, item: T ) => {
      const { nativeEvent } = event

      if ( !( nativeEvent as unknown as PointerEvent ).shiftKey ) {
        setPreviousItem( item )
        setCurrentItem( null )
        setIsChecked( event.target.checked )

        change( event.target.checked, [ item ])
        return
      }

      const next = items.indexOf( item )
      const previous = previousItem ? items.indexOf( previousItem ) : -1
      const current = currentItem ? items.indexOf( currentItem ) : -1

      const first = Math.min( next, previous )
      const last = Math.max( next, previous )

      // check if we have found a valid range of selected items, -1 means we have not found items
      if ( first > -1 && last > -1 ) {
        // add or remove all items in the range from the selection
        change( isChecked, items.slice( first, last + 1 ))

        // check if the previously selected item is outside of the new range
        if ( current > last ) {
          change( !isChecked, items.slice( last + 1, current + 1 ))
        }

        // check if the previously selected item is outside of the new range
        if ( current < first ) {
          change( !isChecked, items.slice( current, first ))
        }

        // set the current item to the newly clicked item
        setCurrentItem( item )
        return
      }

      change( event.target.checked, [ item ])
    },
    [
      change,
      items,
      currentItem,
      setCurrentItem,
      previousItem,
      setPreviousItem,
      isChecked,
      setIsChecked,
    ]
  )

  return onChange
}
