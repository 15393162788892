import { FC, PropsWithChildren } from 'react'
import classNames from 'classnames'
import {
  faAlarmExclamation,
  faBell,
  faMegaphone,
  faMessageLines,
  faUserBountyHunter,
  faUserChef,
  faUserCowboy,
  faUserHairLong,
} from '@fortawesome/pro-duotone-svg-icons'

import Card from 'src/components/01-atoms/Card'
import CircleIcon from 'src/components/01-atoms/CircleIcon'

interface IMOTDCard {
  /**
   * Headline for the card
   */
  headline?: string

  /**
   * Extra classes to add to this element's container, which also contains the border and padding.
   */
  className?: string

  /**
   * Type of card - for colors and icons
   */
  type?: 'beta' | 'notice' | 'deadline' | 'announcement' | 'feedback' | 'default'
}

const MOTDCard: FC<PropsWithChildren<IMOTDCard>> = ({
  children,
  className,
  headline,
  type = 'beta',
}) => (
  <Card className={`${className} p-0`}>
    <header className="pt-2 pl-4 relative">
      <div
        className={classNames(
          'absolute -left-0.5 -top-0.5 w-[calc(100%+4px)] h-6 block rounded-t',
          { 'bg-indigo-400': type === 'feedback' || type === 'beta' },
          { 'bg-orange-400': type === 'notice' },
          { 'bg-teal-400': type === 'announcement' },
          { 'bg-red-500': type === 'deadline' },
          { 'bg-gb-gray-800': type === 'default' }
        )}
      />
      <div className="relative">
        {type === 'beta' ? (
          <CircleIcon
            icons={[ faUserHairLong, faUserChef, faUserCowboy, faUserBountyHunter ]}
            color="indigo"
          />
        ) : (
          <div>
            {type === 'default' && <CircleIcon color="gray" />}
            {type === 'feedback' && <CircleIcon color="indigo" icons={[ faMessageLines ]} />}
            {type === 'notice' && <CircleIcon color="orange" icons={[ faBell ]} />}
            {type === 'deadline' && <CircleIcon color="red" icons={[ faAlarmExclamation ]} />}
            {type === 'announcement' && <CircleIcon color="teal" icons={[ faMegaphone ]} />}
          </div>
        )}
      </div>
    </header>
    <div className="p-4 pt-3">
      <h3 className="text-lg font-bold mb-1">{headline}</h3>
      <div className="text-xs leading-relaxed flex flex-col gap-y-2">{children}</div>
    </div>
  </Card>
)

export default MOTDCard
