import { FC } from 'react'

export interface IPackageRecipientProps {
  name: string
  city: string
  carrier: string
  service: string
}
const PackageRecipient: FC<IPackageRecipientProps> = ({ name, city, carrier, service }) => (
  <div className="uppercase text-xs">
    <div>
      <span className="font-bold pr-2">Recipient</span>
      <span>{name}</span>
    </div>
    <div>
      <span className="font-bold pr-2">Ship To</span>
      <span>{city}</span>
    </div>
    <div>
      <span className="font-bold pr-2">Carrier</span>
      <span>{carrier}</span>
    </div>
    <div>
      <span className="font-bold pr-2">Speed</span>
      <span>{service}</span>
    </div>
  </div>
)

export default PackageRecipient
