import { FC } from 'react'
import classNames from 'classnames'
import { kebabCase } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faCircleX } from '@fortawesome/pro-solid-svg-icons'

export type Step = {
  text: string
  stepNumber?: number
  isCompleted?: boolean
}

export interface IStepperProps {
  steps: Step[]
  size?: 'small' | 'large'
  currentStep?: number
  hasPreviousPage?: boolean
  hasNextPage?: boolean
  className?: string
}

const Stepper: FC<IStepperProps> = ({
  steps,
  size = 'small',
  currentStep = 0,
  hasPreviousPage = false,
  hasNextPage = false,
  className,
}) => {
  const isLarge = size === 'large'
  const isSmall = size === 'small'

  const afterTriangleClasses = classNames(
    'hidden xl:block absolute inset-y-0 right-0 clip-triangle aspect-square border border-solid border-gb-gray-500 origin-top-right -rotate-45 z-10',
    {
      'h-7 w-7': isSmall,
      'h-8 w-8': isLarge,
    }
  )
  const nextPrevSharedClasses = 'px-4 bg-white center'

  return (
    <div
      className={classNames(
        'border border-solid border-gb-gray-500 rounded flex flex-col xl:flex-row xl:flex-auto overflow-hidden',
        {
          'xl:inline-flex': isSmall,
          'text-lg place-content-evenly': isLarge,
        },
        className
      )}
    >
      {hasPreviousPage && (
        <div className={classNames( nextPrevSharedClasses, 'relative' )}>
          &hellip;
          <div className={classNames( afterTriangleClasses, 'bg-white border-gb-gray-900' )} />
        </div>
      )}
      {steps.map(({ text, stepNumber, isCompleted = true }, i ) => {
        const isCurrent = currentStep === i
        const isError = !isCompleted

        return (
          <div
            key={`step-${kebabCase( text )}`}
            className={classNames(
              'border-t border-inherit first-of-type:border-t-0 flex items-center py-2 px-4 gap-x-2 relative',
              'xl:center xl:border-t-0 xl:pr-8',
              {
                'xl:pl-6': i === 0 && !hasPreviousPage,
                'xl:pl-10': i > 0 || hasPreviousPage,
                'bg-white text-gb-gray-800': i < currentStep,
                'bg-gb-gray-400': i > currentStep,
                'bg-gb-blue-100 text-gb-blue-700': isCurrent,
                'text-lg flex-grow': isLarge,
                'text-base': isSmall,
              }
            )}
          >
            <div
              className={classNames({
                'w-5 h-5': isSmall,
                'w-6 h-6': isLarge,
              })}
            >
              {i >= currentStep ? (
                <div
                  className={classNames(
                    'flex center text-xs aspect-square border-solid border rounded-full',
                    {
                      'text-sm': isLarge,
                      'border-2 border-gb-blue-700 font-semibold': isCurrent,
                      'border border-gb-gray-900 font-normal': !isCurrent,
                    }
                  )}
                >
                  {stepNumber}
                </div>
              ) : (
                <FontAwesomeIcon
                  className={classNames( 'h-full w-full', {
                    'text-green-600': isCompleted,
                    'text-red-600': isError,
                  })}
                  icon={isError ? faCircleX : faCheckCircle}
                />
              )}
            </div>
            <div
              className={classNames({
                'font-medium': isCurrent,
                'font-normal': !isCurrent,
              })}
            >
              {text}
            </div>
            {( i + 1 < steps.length || hasNextPage ) && (
              <div
                className={classNames( afterTriangleClasses, {
                  'bg-white': i < currentStep,
                  'bg-gb-blue-100': isCurrent,
                  'bg-gb-gray-400': i > currentStep,
                })}
              />
            )}
          </div>
        )
      })}
      {hasNextPage && (
        <div
          className={classNames(
            nextPrevSharedClasses,
            'border-t border-inherit pl-8 xl:border-t-0'
          )}
        >
          &hellip;
        </div>
      )}
    </div>
  )
}

export default Stepper
