import { useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

import { IShippingLabelStatus } from 'src/graphql/types'

const labelPrintStatuses = {
  to_ship: IShippingLabelStatus.TO_SHIP,
  not_printed: IShippingLabelStatus.NOT_PRINTED,
  need_reprint: IShippingLabelStatus.NEED_REPRINT,
  need_discard: IShippingLabelStatus.NEED_DISCARD,
  printed: IShippingLabelStatus.PRINTED,
}

type TLabelPrintStatusKey = keyof typeof labelPrintStatuses

interface IProps {
  defaultLabelPrintStatusFilter?: TLabelPrintStatusKey
}

const usePrintingParams = ({ defaultLabelPrintStatusFilter = 'not_printed' }: IProps ) => {
  const [ search ] = useSearchParams()
  const location = useLocation()
  const packageIds = Array.from( new Set( search.getAll( 'id' )))

  const isValidReprint = search.get( 'valid_reprint' ) === 'true'
  const labelPrintStatusFromUrl = ( search.get( 'status' ) ||
    defaultLabelPrintStatusFilter ) as TLabelPrintStatusKey
  const labelPrintStatus =
    packageIds.length > 0 ? null : labelPrintStatuses[labelPrintStatusFromUrl]
  const showBigStepper = labelPrintStatusFromUrl !== 'not_printed'

  const [ showPrintConfirmModal, setShowPrintConfirmModal ] = useState( false )
  const [ printingIsComplete, setPrintingIsComplete ] = useState( false )
  const [ showPostPrintModal, setShowPostPrintModal ] = useState( false )
  const [ batchPrintActionId, setBatchPrintActionId ] = useState<string | undefined>( undefined )

  useEffect(() => {
    window.scrollTo( 0, 0 )
  }, [ location ])

  return {
    packageIds,
    labelPrintStatus,
    showBigStepper,
    isValidReprint,
    batchPrintActionId,
    setBatchPrintActionId,
    showPrintConfirmModal,
    setShowPrintConfirmModal,
    printingIsComplete,
    setPrintingIsComplete,
    showPostPrintModal,
    setShowPostPrintModal,
  }
}

export default usePrintingParams
