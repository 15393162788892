import { loader } from 'graphql.macro'
import { FC, useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import Button from 'src/components/01-atoms/Button'
import TextInput from 'src/components/01-atoms/TextInput'
import {
  ICreateTaxonomyMutation,
  ICreateTaxonomyMutationVariables,
} from 'src/graphql/mutations/createTaxonomy.types'
import useAppParams from 'src/utils/hooks/useAppParams'
import { useMutation } from 'urql'

const createTaxonomy = loader( 'src/graphql/mutations/createTaxonomy.graphql' )

const NewTaxonomy: FC = () => {
  const { mdashAccountId } = useAppParams()
  const [ successMessage, setSuccessMessage ] = useState<string | null>( null )
  const [ errorMessage, setErrorMessage ] = useState<string | null>( null )
  const {
    register,
    setFocus,
    setValue,
    watch,
    formState: { isValid },
  } = useForm({ defaultValues: { name: '' }})
  const { name } = watch()

  const [ createResult, create ] = useMutation<
    ICreateTaxonomyMutation,
    ICreateTaxonomyMutationVariables
  >( createTaxonomy )

  const handleCreate = useCallback(() => {
    setSuccessMessage( null )
    setErrorMessage( null )

    create({ mdashAccountId: String( mdashAccountId ), name }).then(( res ) => {
      if ( res.data?.createTaxonomy?.errors.length === 0 ) {
        setValue( 'name', '', { shouldDirty: true, shouldValidate: true })
        setSuccessMessage( `Successfully created taxonomy ${name}` )
      } else {
        setErrorMessage(
          `Failed to create taxonomy ${name}: ${res.data?.createTaxonomy?.errors.join( ', ' )}`
        )
      }

      setFocus( 'name' )
    })
  }, [ name ])

  return (
    <div>
      <div className="flex justify-center py-4 gap-4">
        <TextInput placeholder="Create New Tag..." {...register( 'name', { required: true })} />
        <Button disabled={!isValid || createResult.fetching} onClick={handleCreate}>
          {createResult.fetching ? 'Adding...' : 'Add Tag'}
        </Button>
      </div>
      {successMessage && <div className="text-center text-gb-blue-600">{successMessage}</div>}
      {errorMessage && <div className="text-center text-red-600">{errorMessage}</div>}
    </div>
  )
}

export default NewTaxonomy
