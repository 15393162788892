import { Reducer, useCallback, useReducer } from 'react'

const useSet = <T>( initialState: Set<T> = new Set()) => {
  type Action = { type: 'none' } | { type: 'add' | 'remove'; id: T }

  const [ set, dispatch ] = useReducer<Reducer<Set<T>, Action>>(
    ( state: Set<T>, action: Action ): Set<T> => {
      const mutableState: Set<T> = new Set( state )
      switch ( action.type ) {
        case 'none':
          return new Set()
        case 'remove':
          mutableState.delete( action.id )
          break
        case 'add':
          mutableState.add( action.id )
          break
        default:
          return state
      }
      return mutableState
    },
    initialState
  )

  return {
    set,
    remove: useCallback(( id: T ) => dispatch({ type: 'remove', id }), []),
    removeMultiple: useCallback(
      ( ids: T[]) => ids.forEach(( id ) => dispatch({ type: 'remove', id })),
      []
    ),
    add: useCallback(( id: T ) => dispatch({ type: 'add', id }), []),
    addMultiple: useCallback(( ids: T[]) => ids.forEach(( id ) => dispatch({ type: 'add', id })), []),
    reset: useCallback(() => dispatch({ type: 'none' }), []),
  }
}

export default useSet
