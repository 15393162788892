import { FC } from 'react'

import Grid from 'src/components/01-atoms/Grid'
import TextInput from 'src/components/01-atoms/TextInput'
import TypeaheadSearch from 'src/components/02-molecules/TypeaheadSearch'

import {
  FormSelectProps,
  FormTextInputProps,
  FormTypeaheadSearchProps,
} from 'src/utils/types/forms'
import Label from 'src/components/01-atoms/Label'
import Select from 'src/components/01-atoms/Select'

interface IShippingAddressForm {
  /**
   * Props for the email input element, likely from `react-hook-form` register method, but can be an object of string key => value pairs.
   */
  fieldProps?: {
    addressLine1: FormTypeaheadSearchProps
    addressLine2: FormTextInputProps
    city: FormTextInputProps
    state: FormSelectProps
    zip: FormTextInputProps
  }
}

const ShippingAddressForm: FC<IShippingAddressForm> = ({ fieldProps }) => (
  <Grid className="gap-y-0">
    <TypeaheadSearch
      wrapperClassName="col-span-12 md:col-span-8 mb-4"
      useClearButton={false}
      labelText="Address Line 1 *"
      className="w-full"
      showLabel
      outline
      placeholder=""
      {...fieldProps?.addressLine1}
    />
    <TextInput
      labelText="Address Line 2"
      wrapperClassName="col-span-12 md:col-span-4"
      showLabel
      outline
      reduceLayoutShift
      {...fieldProps?.addressLine2}
    />
    <TextInput
      labelText="City *"
      wrapperClassName="col-span-12 md:col-span-4"
      showLabel
      outline
      reduceLayoutShift
      {...fieldProps?.city}
    />
    <Label
      text="State *"
      htmlFor={fieldProps?.state.name}
      showLabel
      className="col-span-12 md:col-span-4 mb-4"
    >
      <Select id={fieldProps?.state.name} {...fieldProps?.state} />
    </Label>
    <TextInput
      labelText="Zip Code *"
      wrapperClassName="col-span-12 md:col-span-4"
      showLabel
      outline
      reduceLayoutShift
      {...fieldProps?.zip}
    />
  </Grid>
)

export default ShippingAddressForm
