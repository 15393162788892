import { FC, PropsWithChildren, useState } from 'react'
import classNames from 'classnames'
import Button from '../Button'

interface IHideableProps {
  /**
   * Whether or not to show the element as "open".
   */
  defaultOpen?: boolean

  /**
   * Text to show for the button toggle.
   */
  toggleText: string

  /**
   * Classes to pass down to the containing element.
   */
  className?: string
}

const Hideable: FC<PropsWithChildren<IHideableProps>> = ({
  defaultOpen = false,
  toggleText,
  className,
  children,
}) => {
  const [ isOpen, setIsOpen ] = useState( defaultOpen )

  return (
    <div className={classNames( 'relative', className )}>
      <div className={classNames({ 'invisible pointer-events-none': !isOpen })}>{children}</div>
      {!isOpen && (
        <div className="absolute inset-0 center z-100">
          <Button variant="clear" onClick={() => setIsOpen( true )}>
            {toggleText}
          </Button>
        </div>
      )}
    </div>
  )
}

export default Hideable
