import { FC, Fragment, ReactNode } from 'react'
import { kebabCase } from 'lodash'
import classNames from 'classnames'

import styles from './index.module.css'

interface IDetailsListProps {
  /**
   * A list of key-value pairs to display.
   */
  list: ({
    label: string
    value: string | ReactNode
  } | null )[]

  className?: string

  /**
   * The class to apply to the label elements.
   */
  labelClass?: string

  /**
   * The class to apply to the value elements.
   */
  valueClass?: string

  /**
   * The variant of the list to display.
   */
  variant?: 'bordered' | 'borderless' | 'vertical'

  /**
   * A prefix to apply to all test IDs.
   */
  testIdPrefix?: string
}

const DetailsList: FC<IDetailsListProps> = ({
  list,
  className,
  labelClass,
  valueClass,
  variant = 'borderless',
  testIdPrefix,
}) => {
  const prefix = testIdPrefix ? `${testIdPrefix}-` : ''
  return (
    <dl
      data-testid={`${prefix}details-list`}
      className={classNames( className, styles.detailsList, {
        [styles.borderless]: variant === 'borderless',
        [styles.bordered]: variant === 'bordered',
        [styles.vertical]: variant === 'vertical',
      })}
    >
      {list.map(
        ( li ) =>
          li && (
            <Fragment key={`detail-${kebabCase( li.label )}`}>
              <dt
                data-testid={`${prefix}details-list-${kebabCase( li.label )}-label`}
                className={classNames( labelClass )}
              >
                {li.label}
              </dt>
              <dd
                data-testid={`${prefix}details-list-${kebabCase( li.label )}-value`}
                className={classNames( valueClass )}
              >
                {li.value}
              </dd>
            </Fragment>
          )
      )}
    </dl>
  )
}

export default DetailsList
