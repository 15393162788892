/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, MouseEvent as RME, useCallback, useState } from 'react'
import { fromPairs, omit } from 'lodash'
import classNames from 'classnames'
import useGetDeliveryStates from 'src/utils/hooks/useGetDeliveryStates'
import { IGetFacilityAutoAssignmentQuery } from 'src/graphql/queries/getFacilityAutoAssignment.types'
import Table from 'src/components/02-molecules/Table'
import AutoAssignmentCell from './AutoAssignments/AutoAssignmentCell'
import PopUp, { TPopUp } from './PopUp'
import AutoAssignmentFlag from './AutoAssignmentFlag'

const colors = [
  'bg-red-500',
  'bg-lime-500',
  'bg-fuchsia-500',
  'bg-amber-500',
  'bg-cyan-500',
  'bg-indigo-500',
  'bg-rose-500',
]

interface IProps {
  shipper: IGetFacilityAutoAssignmentQuery['shippers'][0]
}

const Shipper: FC<IProps> = ({ shipper }) => {
  const { facilityAutoAssignmentRules, shipperFacilities, products, name: shipperName } = shipper

  const { data: deliveryStates } = useGetDeliveryStates()
  const shipperFacilitiesDictionary = fromPairs(
    shipperFacilities.map(( x, i ) => [ x.id, { id: x.id, name: x.name, index: i }])
  )
  const usStates = ( deliveryStates?.states || []).map(( x ) => x.abbreviation )
  const [ popUp, setPopUp ] = useState<TPopUp>({})
  const createPopUp = useCallback(
    ({
      evt,
      ruleId,
      productId,
      productName,
      deliveryState,
      selectedShipperFacilityId,
      isBlocklist,
    }: {
      evt: RME<HTMLElement>
      ruleId?: string
      productId?: string | null
      productName?: string
      deliveryState?: string | null
      selectedShipperFacilityId?: string | null
      isBlocklist?: boolean
    }) => {
      if ( shipperFacilities ) {
        setPopUp(( x ) => ({
          ...x,
          dimension: {
            x: evt.pageX,
            y: evt.pageY,
          },
          ruleId,
          productId,
          productName,
          deliveryState,
          selectedShipperFacilityId: selectedShipperFacilityId || shipperFacilities[0].id,
          isBlocklist,
        }))
      }
    },
    [ shipperFacilities ]
  )

  return (
    <div className="my-8">
      <div className="my-4 flex items-center">
        <div className="font-bold text-xl">{shipperName}</div>
        <AutoAssignmentFlag isEnabled={shipper.usesAutoAssignmentV2} shipperId={shipper.id} />
      </div>
      <div className="flex mb-4">
        {Object.values( shipperFacilitiesDictionary ).map(( x ) => (
          <div
            key={x.id}
            className={classNames( 'px-2 py-1 mr-2 border rounded text-white', colors[x.index])}
          >
            <span className="font-semibold">{x.name}</span>
            <span className="text-gb-gray-300">{` #${x.id}`}</span>
          </div>
        ))}
      </div>

      <div className="max-w-[calc(100vw-64px)] max-h-[calc(100vh-256px)] overflow-auto">
        <Table
          stickyHeader
          tableClasses="border-collapse"
          headers={[
            {
              name: 'Products',
              className: 'cursor-pointer',
              onClick: ( evt: RME<HTMLTableCellElement> ) => createPopUp({ evt }),
            },
          ].concat(
            usStates.map(( x ) => ({
              name: x,
              className: 'cursor-pointer',
              onClick: ( evt: RME<HTMLTableCellElement> ) =>
                createPopUp({
                  evt,
                  deliveryState: x,
                }),
            }))
          )}
        >
          {products
            .sort(( a, b ) => ( a.name || '' ).localeCompare( b.name || '' ))
            .map(( product ) => (
              <tr key={product.id}>
                <td
                  className="sticky left-0 bg-gb-gray-300 z-20 border border-b-gb-gray-50 min-w-[192px] p-2 cursor-pointer"
                  onClick={( evt ) =>
                    createPopUp({ evt, productId: product.id, productName: product.name })
                  }
                >
                  <span className="font-semibold">{product.name}</span>
                  <span className="text-gb-gray-700">{` #${product.id}`}</span>
                </td>
                {usStates.map(( state ) => (
                  <td
                    key={state}
                    className="border cursor-pointer"
                    onClick={( evt ) =>
                      createPopUp({
                        evt,
                        productId: product.id,
                        productName: product.name,
                        deliveryState: state,
                      })
                    }
                  >
                    <AutoAssignmentCell
                      key={`${product.id}-${state}`}
                      rules={facilityAutoAssignmentRules.filter(
                        ( x ) =>
                          ( !x.deliveryState && !x.productId ) ||
                          ( !x.deliveryState && x.productId === product.id ) ||
                          ( x.deliveryState === state && !x.productId ) ||
                          ( x.deliveryState === state && x.productId === product.id )
                      )}
                      shipperFacilitiesDictionary={shipperFacilitiesDictionary}
                      onClick={( evt, ruleId ) => {
                        evt.stopPropagation()
                        const rule = facilityAutoAssignmentRules.find(( x ) => x.id === ruleId )
                        createPopUp({
                          evt,
                          ruleId,
                          productId: rule?.productId,
                          productName: products.find(( x ) => x.id === rule?.productId )?.name,
                          deliveryState: rule?.deliveryState,
                          selectedShipperFacilityId: rule?.shipperFacilityId,
                          isBlocklist: rule?.mode === 'blocklist',
                        })
                      }}
                    />
                  </td>
                ))}
              </tr>
            ))}
        </Table>
      </div>

      <PopUp
        dimension={popUp.dimension}
        shipperId={shipper.id}
        rule={omit( popUp, 'dimension' )}
        shipperFacilitiesDictionary={shipperFacilitiesDictionary}
        facilityOptions={Object.fromEntries( shipperFacilities.map(( x ) => [ x.id, String( x.name ) ]))}
        onClose={() => setPopUp(( x ) => ({ ...x, dimension: undefined }))}
        onChange={( key, value ) => setPopUp(( x ) => ({ ...x, [key]: value }))}
      />
    </div>
  )
}

export default Shipper
