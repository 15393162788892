import { FC } from 'react'
import useFacilityAutoAssignment from 'src/utils/hooks/useFacilityAutoAssignment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircle,
  faHexagon,
  faLightbulb,
  faRhombus,
  faSquare,
} from '@fortawesome/pro-solid-svg-icons'
import { ScaleLoader } from 'react-spinners'
import ShapedIcon from 'src/components/01-atoms/ShapedIcon'
import { faBan } from '@fortawesome/pro-regular-svg-icons'
import useAppParams from 'src/utils/hooks/useAppParams'
import Shipper from './Shipper'

const AutoAssignment: FC = () => {
  const { isAdmin } = useAppParams()
  const { data, fetching } = useFacilityAutoAssignment()

  if ( !isAdmin ) return null

  return (
    <div className="flex justify-center" data-testid="autoassignment-container">
      <div className="max-w-[calc(100vw-64px)]">
        <div className="rounded-lg p-4 mt-4 bg-gb-blue-50 border border-gb-electric-blue-600">
          <div className="flex">
            <FontAwesomeIcon
              icon={faLightbulb}
              className="text-yellow-400 text-3xl pr-4 pl-2 pt-2"
            />
            <div>
              Multiple Facility Assignments in a single cell are ordered by priority, highest first.
              <div className="flex items-center mt-2">
                <ShapedIcon
                  className="fa-2x"
                  baseIcon={{ icon: faCircle, className: 'text-lime-500' }}
                  textLayer={{ text: 'Product and Delivery', className: 'text-xs text-white' }}
                />
                Product and Delivery Rule
              </div>
              <div className="flex items-center mt-2">
                <ShapedIcon
                  className="fa-2x"
                  baseIcon={{ icon: faHexagon, className: 'text-lime-500' }}
                  textLayer={{ text: 'Delivery', className: 'text-xs text-white' }}
                />
                Delivery Rule
              </div>
              <div className="flex items-center mt-2">
                <ShapedIcon
                  className="fa-2x"
                  baseIcon={{ icon: faRhombus, className: 'text-lime-500' }}
                  textLayer={{ text: 'P', className: 'text-xs text-white' }}
                />
                Product Rule
              </div>
              <div className="flex items-center mt-2">
                <ShapedIcon
                  className="fa-2x"
                  baseIcon={{ icon: faSquare, className: 'text-lime-500' }}
                  textLayer={{ text: 'Df', className: 'text-xs text-white' }}
                />
                Default Rule
              </div>
              <div className="flex items-center mt-2">
                <ShapedIcon
                  className="fa-2x"
                  baseIcon={{ icon: faCircle, className: 'text-red-500 saturate-50' }}
                  textLayer={{ text: 'Product and Delivery', className: 'text-xs text-white' }}
                  stackedIcons={[
                    { icon: faBan, className: 'text-gray-500 rotate-90 scale-125 opacity-50' },
                  ]}
                />
                <ShapedIcon
                  className="fa-2x"
                  baseIcon={{ icon: faHexagon, className: 'text-amber-500 saturate-50' }}
                  textLayer={{ text: 'Delivery', className: 'text-xs text-white' }}
                  stackedIcons={[
                    { icon: faBan, className: 'text-gray-500 rotate-90 scale-125 opacity-50' },
                  ]}
                />
                <ShapedIcon
                  className="fa-2x"
                  baseIcon={{ icon: faRhombus, className: 'text-cyan-500 saturate-50' }}
                  textLayer={{ text: 'Product', className: 'text-xs text-white' }}
                  stackedIcons={[
                    { icon: faBan, className: 'text-gray-500 rotate-90 scale-125 opacity-50' },
                  ]}
                />
                <ShapedIcon
                  className="fa-2x"
                  baseIcon={{ icon: faSquare, className: 'text-indigo-500 saturate-50' }}
                  textLayer={{ text: 'Df', className: 'text-xs text-white' }}
                  stackedIcons={[
                    { icon: faBan, className: 'text-gray-500 rotate-90 scale-125 opacity-50' },
                  ]}
                />
                <div className="pl-2">Forbidden Facility Assignment</div>
              </div>
            </div>
          </div>
        </div>
        {fetching ? (
          <ScaleLoader color="#233D90" />
        ) : (
          <div>
            {data?.shippers.map(( shipper ) => <Shipper key={shipper.id} shipper={shipper} /> )}
          </div>
        )}
      </div>
    </div>
  )
}

export default AutoAssignment
