import { FC } from 'react'
import { useWindowSize } from 'rooks'
import ReactPaginate, { ReactPaginateProps } from 'react-paginate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons'

import './styles.css'

const Pager: FC<ReactPaginateProps> = ( props ) => {
  const { innerWidth } = useWindowSize()

  return (
    <ReactPaginate
      {...props}
      className="pager"
      breakLabel="..."
      nextLabel={<FontAwesomeIcon icon={faChevronRight} size="sm" />}
      previousLabel={<FontAwesomeIcon icon={faChevronLeft} size="sm" />}
      pageRangeDisplayed={1}
      marginPagesDisplayed={innerWidth! < 1024 ? 1 : 3}
    />
  )
}

export default Pager
