import classNames from 'classnames'
import { FC } from 'react'
import { IVersioned } from 'src/utils/types/Versioned'

interface IPackageNoticeProps extends IVersioned {
  /**
   * The order number that the package belongs to.
   */
  orderId: string

  /**
   * The size of the package notice element.
   */
  size?: 'small' | 'default'

  /**
   * Extra classes to add to this element's container, which also contains the border and padding.
   */
  className?: string
}

const PackageNotice: FC<IPackageNoticeProps> = ({
  orderId,
  size = 'default',
  version = 1,
  className,
}) =>
  version === 1 ? (
    <div
      className={classNames(
        className,
        'bg-white border-dashed border-black text-black font-bold uppercase -rotate-6 flex center text-center mx-auto',
        {
          'text-sm border-4 py-2': size === 'default',
          'text-2xs border-2 py-1': size === 'small',
        }
      )}
    >
      There are more items in this order.
      <br />
      View order #{orderId} for all items.
    </div>
  ) : (
    <div
      className={classNames(
        className,
        'bg-white border-solid border-black border rounded text-black inline-flex center text-center mx-auto p-2',
        {
          'text-2xs': size === 'default',
          'text-3xs': size === 'small',
        }
      )}
    >
      There are more items in this order. View order #{orderId} for all items.
    </div>
  )

export default PackageNotice
