import { FC } from 'react'
import Skeleton from 'react-loading-skeleton'
import { kebabCase } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse } from '@fortawesome/pro-regular-svg-icons'
import { Maybe } from 'src/graphql/types'

import Address from 'src/components/01-atoms/Address'
import SectionCard from 'src/components/01-atoms/SectionCard'
import Tag, { Color } from 'src/components/01-atoms/Tag'

import { IAddress } from 'src/utils/types/IAddress'

import styles from './index.module.css'

export interface ICustomerAddressesBlockProps {
  /**
   * The customer's addresses.
   */
  addresses: Maybe<IAddress>[]
}

const CustomerAddressesBlock: FC<ICustomerAddressesBlockProps> = ({ addresses }) => (
  <SectionCard
    size="medium"
    data-testid="customer-addresses-block"
    title={
      <>
        <FontAwesomeIcon icon={faHouse} className="mr-2" />
        Addresses
      </>
    }
  >
    {addresses.length === 0 ? (
      <Skeleton className="w-full h-[80px]" />
    ) : (
      addresses?.map(( address ) => {
        const key = `address-${kebabCase( address?.street1 )}-${kebabCase( address?.street2 )}`
        return (
          <div className={styles.address} data-testid={key} key={key}>
            <div className="relative">
              <Address address={address as IAddress} />
              {address?.isDefault && (
                <Tag color={Color.VIOLET} className="absolute top-0 right-0">
                  Default
                </Tag>
              )}
            </div>
          </div>
        )
      })
    )}
  </SectionCard>
)

export default CustomerAddressesBlock
