import classNames from 'classnames'
import { FC } from 'react'

import rocket from './rocket.svg'

interface ISuccessBlockProps {
  /**
   * Classes to add to the containing element.
   */
  className?: string
}

const SuccessBlock: FC<ISuccessBlockProps> = ({ className }) => (
  <div className={classNames( className, 'bg-white center pt-18 pb-21 px-8 rounded md:pb-40' )}>
    <div className="text-2xl text-center flex flex-col gap-16 md:gap-24">
      Done!
      <img src={rocket} alt="All done!" className="max-w-full w-40" />
    </div>
  </div>
)

export default SuccessBlock
