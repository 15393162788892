import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { loader } from 'graphql.macro'
import { useQuery } from 'urql'

import useAppParams from 'src/utils/hooks/useAppParams'

import {
  IGetOrderInfoQuery,
  IGetOrderInfoQueryVariables,
} from 'src/graphql/queries/getOrderInfo.types'
import OrderDetail from 'src/components/05-pages/OrderDetail'
import Container from 'src/components/01-atoms/Container'
import { stringAsDate } from 'src/utils/helpers/date'
import Skeleton from 'react-loading-skeleton'
import Alert from 'src/components/01-atoms/Alert'
import MDashHead from './elements/MDashHead'

const getOrderInfo = loader( 'src/graphql/queries/getOrderInfo.graphql' )

const OrderDetailPage: FC = () => {
  const { orderId } = useParams()
  const { makeLinkUrls, mdashAccountId, isAdmin, mdashAccountPermissions } = useAppParams()
  const { canQueryPurchaserInfo } = mdashAccountPermissions || {}

  const [{ fetching, error, data }] = useQuery<IGetOrderInfoQuery, IGetOrderInfoQueryVariables>({
    query: getOrderInfo,
    variables: {
      id: orderId!,
      mdashAccountId,
    },
    pause: Number( mdashAccountId || 0 ) < 1 || !orderId,
  })

  const { order } = data || {}
  const packages =
    order?.packages?.map(({ gbmcState, toShipOn, requestedDeliveryOn, cartItems, ...pkg }) => ({
      ...pkg,
      toShipOn: stringAsDate( toShipOn ),
      deliveryDate: stringAsDate( requestedDeliveryOn ),
      lineItems: cartItems?.map(({ product, quantity, weight, selectedProductOptions, ...li }) => ({
        ...li,
        name: product?.name,
        productOptions: selectedProductOptions?.map(({ productOption, variance }) =>
          [ variance?.name, productOption?.name ].filter( Boolean ).join( ': ' )
        ),
        quantity: ( quantity && quantity > 0 ? quantity : weight ) ?? 0,
      })),
      isCanceled: gbmcState === 'canceled',
      isWhitelabel: pkg.isWhitelabel,
    })) || []

  return (
    <>
      <MDashHead pageTitle={`Order #${orderId}`} />
      <Container className="pt-6 pb-9">
        {fetching && <Skeleton height="50vh" />}
        {!fetching && ( !data || error ) && <Alert type="error">{error?.message}</Alert>}

        {order && (
          <OrderDetail
            id={orderId!}
            corporateOrderCompanyName={order?.deliveryCompany}
            type={{
              isCorporateOrder: order?.isCorporateOrder,
              isInStoreOrder: order?.isInStoreOrder,
              isWhitelabel: order?.isWhitelabel,
              isSubscription: order?.isSubscription,
              isGift: !!order?.giftMessage && !!order?.giftMessageFrom,
            }}
            backLink={makeLinkUrls().manifest}
            deliveryCompany={order?.deliveryCompany || ''}
            deliveryStreet1={order?.deliveryStreet1!}
            deliveryStreet2={order?.deliveryStreet2 || ''}
            deliveryCity={order?.deliveryCity!}
            deliveryState={order?.deliveryState!}
            deliveryPostalCode={order?.deliveryPostalCode!}
            deliveryCountry={order?.deliveryCountry!}
            giftMessage={
              order?.giftMessage
                ? {
                    body: order?.giftMessage || '',
                    from: order?.giftMessageFrom || '',
                  }
                : undefined
            }
            processedAt={new Date( order?.processedAt )}
            purchaser={{
              name: `${order?.purchaserFirstName} ${order?.purchaserLastName}`,
            }}
            recipient={`${order?.deliveryFirstName} ${order?.deliveryLastName}`}
            packages={packages}
            subtotalInCents={order?.productsSubtotalInCents}
            shippingSubtotalInCents={order?.shippingSubtotalInCents}
            taxInCents={order?.taxInCents}
            discountInCents={order?.discountInCents}
            totalInCents={order?.totalInCents}
            showLinkToGbmc={isAdmin}
            purchaserLinkUrl={
              order.purchaserAccountId && order.isWhitelabel && canQueryPurchaserInfo
                ? makeLinkUrls().purchaserProfile( order.purchaserAccountId )
                : undefined
            }
          />
        )}
      </Container>
    </>
  )
}

export default OrderDetailPage
