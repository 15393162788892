import { FC, PropsWithChildren } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons'

import * as TooltipPrimitive from '@radix-ui/react-tooltip'

export const { Provider, Root, Trigger, Arrow, Content } = TooltipPrimitive

interface ITooltipProps {
  /**
   * The Font Awesome icon that the trigger button should show.
   */
  buttonIcon?: FontAwesomeIconProps['icon']
  /**
   * Screenreader text for the tooltip button.
   */
  buttonText?: string
  /**
   * Whether or not the button text should be available only to screen readers.
   */
  hideButtonText?: boolean
  /**
   * Classes to add to the trigger.
   */
  triggerClassName?: string
  /**
   * Classes to add to the button inside of the trigger.
   */
  buttonClassName?: string
  /**
   * Classes to add to the tooltip content container.
   */
  contentClassName?: string
}

const Tooltip: FC<PropsWithChildren<ITooltipProps>> = ({
  buttonIcon = faCircleInfo,
  buttonText = '',
  hideButtonText = true,
  triggerClassName = '',
  buttonClassName = '',
  contentClassName = '',
  children,
}) => (
  <Provider>
    <Root>
      <Trigger className={triggerClassName} asChild>
        <button type="button" className={buttonClassName}>
          <FontAwesomeIcon icon={buttonIcon} />{' '}
          <span
            className={classNames({
              'sr-only': hideButtonText,
            })}
          >
            {buttonText ?? 'Open tooltip'}
          </span>
        </button>
      </Trigger>
      <Content
        className={classNames( 'py-2 px-4 rounded shadow-default bg-white', contentClassName )}
      >
        {children}
        <Arrow className="fill-white" />
      </Content>
    </Root>
  </Provider>
)

export default Tooltip
