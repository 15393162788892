import classNames from 'classnames'
import { FC } from 'react'
import DryIceSticker from 'src/components/01-atoms/DryIceSticker'
import DryIceStickerPlaceholder from 'src/components/01-atoms/DryIceStickerPlaceholder'
import PrintablePage, { PageSize } from 'src/components/01-atoms/PrintablePage'

export interface ISlipNLabelThermal4x6DryIceProps {
  /**
   * The weight of the dry ice in kilograms.
   */
  weightInKg: number

  /**
   * The package ID.
   */
  packageId: string

  /**
   * Whether or not to use the placeholder instead of the sticker.
   */
  usePlaceholder?: boolean
}

const DryIcePage: FC<ISlipNLabelThermal4x6DryIceProps> = ({
  weightInKg,
  packageId,
  usePlaceholder,
}) => (
  <PrintablePage size={PageSize.THERMAL_4x6}>
    <div className={classNames({ 'p-2': usePlaceholder }, 'center w-[4in] h-[6in]' )}>
      {usePlaceholder ? (
        <DryIceStickerPlaceholder weightInKg={weightInKg} packageId={packageId} variant="black" />
      ) : (
        <DryIceSticker weightInKg={weightInKg} />
      )}
    </div>
  </PrintablePage>
)

export default DryIcePage
