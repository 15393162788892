import { FC, PropsWithChildren } from 'react'
import {
  PopoverArrow,
  PopoverContent as RadixPopoverContent,
  PopoverContentProps,
} from '@radix-ui/react-popover'
import classNames from 'classnames'

export interface IPopoverContentProps extends PopoverContentProps {
  className?: string
  innerClassName?: string
  useArrow?: boolean
  arrowOffset?: number
  arrowClassName?: string
}

const PopoverContent: FC<PopoverContentProps & PropsWithChildren<IPopoverContentProps>> = ({
  children,
  className,
  innerClassName,
  arrowOffset = 24,
  useArrow = false,
  arrowClassName,
  ...props
}) => (
  <RadixPopoverContent className={classNames( className )} {...props}>
    <div
      className={classNames( 'rounded-[6px] shadow-lg px-2 py-2 text-sm bg-white', innerClassName )}
    >
      {useArrow && (
        <PopoverArrow
          offset={arrowOffset}
          className={classNames( 'mb-0 w-5 h-2 text-white fill-white', arrowClassName )}
        />
      )}
      {children}
    </div>
  </RadixPopoverContent>
)

export default PopoverContent
