import { loader } from 'graphql.macro'
import {
  IGetPantryItemLimitsQuery,
  IGetPantryItemLimitsQueryVariables,
} from 'src/graphql/queries/getPantryItemLimits.types'
import { useQuery } from 'urql'
import useAppParams from './useAppParams'

interface IProps {
  toShipOn: string
  pantryItemIds?: string[]
}

const getPantryItemLimits = loader( 'src/graphql/queries/getPantryItemLimits.graphql' )

export const useGetPantryItemLimits = ({ toShipOn, pantryItemIds }: IProps ) => {
  const { mdashAccountId } = useAppParams()

  const [{ data, error, fetching }] = useQuery<
    IGetPantryItemLimitsQuery,
    IGetPantryItemLimitsQueryVariables
  >({
    query: getPantryItemLimits,
    variables: {
      mdashAccountId: String( mdashAccountId ),
      toShipOn,
      pantryItemIds,
    },
    requestPolicy: 'cache-and-network',
    pause: Number( mdashAccountId || 0 ) < 1,
  })

  const pantryItemLimits = data?.pantryItemLimits || []

  return { pantryItemLimits, error, fetching }
}
