import { faCheck, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { loader } from 'graphql.macro'
import { FC } from 'react'
import {
  IUpdateShipperMutation,
  IUpdateShipperMutationVariables,
} from 'src/graphql/mutations/updateShipper.types'
import { useMutation } from 'urql'

interface IProps {
  isEnabled?: boolean
  shipperId: string
}

const updateShipperMutation = loader( 'src/graphql/mutations/updateShipper.graphql' )

const AutoAssignmentFlag: FC<IProps> = ({ isEnabled, shipperId }) => {
  const [ updateShipperResponse, updateShipper ] = useMutation<
    IUpdateShipperMutation,
    IUpdateShipperMutationVariables
  >( updateShipperMutation )

  return (
    <button
      type="button"
      className={classNames( 'flex items-center ml-4 pl-4 px-2 pr-4 py-1 rounded text-white', {
        'bg-gb-electric-blue-600': isEnabled && !updateShipperResponse.fetching,
        'bg-rose-600': !isEnabled && !updateShipperResponse.fetching,
        'bg-gb-gray-800': updateShipperResponse.fetching,
      })}
      onClick={() =>
        updateShipper({
          shipperId,
          usesAutoAssignmentV2: !isEnabled,
        })
      }
    >
      {!updateShipperResponse.fetching && (
        <FontAwesomeIcon icon={isEnabled ? faCheck : faExclamationTriangle} className="pr-2" />
      )}
      {updateShipperResponse.fetching
        ? 'Updating...'
        : `AutoAssignment V2 is ${isEnabled ? 'enabled' : 'disabled'}`}
    </button>
  )
}

export default AutoAssignmentFlag
