import { FC, PropsWithChildren } from 'react'
import classNames from 'classnames'
import Container from 'src/components/01-atoms/Container'
import { ReactComponent as Mouth } from 'src/assets/icons/mouth.svg'

interface IGlobalFooter {
  isAdmin?: boolean
}

const GlobalFooter: FC<PropsWithChildren<IGlobalFooter>> = ({ children, isAdmin = false }) => (
  <footer
    className={classNames(
      {
        'bg-gb-electric-blue-600 text-white/85': !isAdmin,
        'bg-gb-yellow-500 text-gb-gray-900/85': isAdmin,
      },
      'py-4'
    )}
  >
    <Container>
      <div className="spread-h">
        <div className="center text-xs md:text-sm font-medium">
          <Mouth className="w-8 mr-2" />
          &copy; {new Date().getFullYear()}
        </div>
        <nav className="spread-h gap-3 lg:gap-7">{children}</nav>
      </div>
    </Container>
  </footer>
)

export default GlobalFooter
