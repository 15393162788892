import { FC } from 'react'
import Skeleton from 'react-loading-skeleton'

import Address from 'src/components/01-atoms/Address'
import Button from 'src/components/01-atoms/Button'
import DetailsList from 'src/components/02-molecules/DetailsList'
import ManifestPackageContents from 'src/components/02-molecules/ManifestPackageContents'

import { longFormatDayAndDate } from 'src/utils/helpers/date'
import { IAddress } from 'src/utils/types/IAddress'

export interface IOrderDetailBodyProps {
  /**
   * The URL to the order details page.
   */
  orderUrl?: string

  /**
   * The products in the order.
   */
  products?: {
    id: string
    quantity: number
    name: string
    productOptions?: string[]
    specialInstructions?: string | null
    sku?: string[] | string | null
  }[]

  /**
   * The address the order is shipping to.
   */
  shipTo?: IAddress

  /**
   * The date the order is scheduled to ship on.
   */
  toShipOn?: Date

  /**
   * The date the order is scheduled to be delivered on.
   */
  requestedDeliveryOn?: Date
}

const OrderDetailBody: FC<IOrderDetailBodyProps> = ({
  orderUrl,
  products,
  shipTo,
  toShipOn,
  requestedDeliveryOn,
}) => {
  if ( !orderUrl || !products || !shipTo || !toShipOn || !requestedDeliveryOn ) {
    return <Skeleton className="w-full h-[200px]" />
  }

  return (
    <>
      <div className="md:grid md:grid-cols-3 gap-y-6 gap-x-4">
        <DetailsList
          testIdPrefix="order-summary-products"
          variant="vertical"
          className="mt-4 md:mt-0"
          list={[
            {
              label: 'Products',
              value: (
                <ManifestPackageContents
                  lineItems={products}
                  lineItemsClasses="text-sm"
                  productOptionsClasses="text-xs"
                />
              ),
            },
          ]}
        />
        <DetailsList
          testIdPrefix="order-summary-ship-to"
          variant="vertical"
          className="mt-4 md:mt-0"
          list={[
            {
              label: 'Ship To',
              value: <Address address={shipTo} />,
            },
          ]}
        />
        <DetailsList
          testIdPrefix="order-summary-dates"
          variant="vertical"
          className="mt-4 md:mt-0"
          list={[
            {
              label: 'Ship Date',
              value: longFormatDayAndDate( toShipOn ),
            },
            {
              label: 'Delivery Date',
              value: longFormatDayAndDate( requestedDeliveryOn ),
            },
          ]}
        />
      </div>
      <div className="mt-4 flex flex-col sm:center sm:flex-row md:justify-end">
        <Button
          data-testid="view-more-button"
          variant="primary"
          outline
          className="center"
          to={orderUrl}
        >
          View More Details
        </Button>
      </div>
    </>
  )
}

export default OrderDetailBody
