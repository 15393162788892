import { FC, PropsWithChildren } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckDouble } from '@fortawesome/pro-duotone-svg-icons'
import Button from 'src/components/01-atoms/Button'

interface IPostPrintCard {
  isCompleted: boolean
  icon?: string
  title?: string
  onClick: () => void
  className?: string
  testName?: string
}

const PostPrintCard: FC<PropsWithChildren<IPostPrintCard>> = ({
  isCompleted,
  icon,
  title,
  onClick,
  className,
  children,
  testName,
}) => (
  <div
    className={classNames(
      'border rounded-md shadow-lg p-6',
      isCompleted ? 'border-gb-gray-200 shadow-gray-100' : 'border-gb-gray-400 shadow-gray-200',
      className
    )}
  >
    <div className="flex flex-col h-full">
      <div className="flex-grow">
        <div className="flex justify-between w-full mb-4">
          {icon && (
            <img src={icon} alt="" className={classNames({ 'opacity-50': isCompleted }, 'h-18' )} />
          )}
          {isCompleted && (
            <FontAwesomeIcon icon={faCheckDouble} className="text-2xl text-green-500" />
          )}
        </div>
        <div className={classNames( isCompleted && 'opacity-50' )}>
          {title && <div className="font-semibold text-base mb-2">{title}</div>}
          <div className="text-sm">{children}</div>
        </div>
      </div>
      <Button
        data-testid={`post-print-modal-${testName}`}
        className="border-gb-blue-600 rounded w-full text-sm font-semibold mt-6 center"
        onClick={() => !isCompleted && onClick()}
        disabled={isCompleted}
      >
        Print
      </Button>
    </div>
  </div>
)

export default PostPrintCard
