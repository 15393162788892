import { kebabCase } from 'lodash'
import { FC } from 'react'
import pluralize from 'pluralize'
import ChoiceInput from 'src/components/01-atoms/ChoiceInput'
import classNames from 'classnames'

export interface IPrintableChecklist {
  entities: {
    id: string
    quantity: number
    name: string
    subentities?: string[]
    sku?: string[] | string | null
    specialInstructions?: string | null
  }[]
  displayCheckbox?: boolean
  displaySku?: boolean
  displayQtyLast?: boolean
  title: string
  mainColumnTitle?: string
  subEntityTitle?: string
  border?: boolean
  pluralizeTitle?: boolean
}

const PrintableChecklists: FC<IPrintableChecklist> = ({
  entities,
  displayCheckbox,
  displaySku,
  title,
  border,
  displayQtyLast,
  subEntityTitle,
  mainColumnTitle = title,
  pluralizeTitle,
}) => (
  <div>
    <div className="uppercase font-medium text-lg pl-1">
      {pluralizeTitle ? <span>{`${pluralize( title )}`}</span> : <span>{title}</span>}
    </div>
    <table
      className={classNames(
        { 'outline-gb-gray-700 outline outline-2': border },
        'table-fixed w-full my-3'
      )}
    >
      <thead className="text-base">
        <tr
          className={classNames(
            { 'border-gb-gray-700 border-bottom-2': border },
            'text-left bg-gb-gray-300'
          )}
        >
          <th className="w-12"> </th>
          {!displayQtyLast && <th className="w-16 py-2 font-medium">Qty</th>}
          <th className="font-medium">{mainColumnTitle}</th>
          {displayQtyLast && <th className="w-16 py-2 font-medium">Qty</th>}
          {subEntityTitle && <th className="font-medium">{subEntityTitle}</th>}
          {displaySku && <th className="w-32 font-medium">SKU</th>}
        </tr>
      </thead>
      <tbody className="text-sm">
        {entities.map(( entity, index ) => (
          <tr
            key={`entity-${entity.id}-${kebabCase( entity.name )}`}
            className={classNames({
              'border-b-2 border-gray-200': !border ? true : index < entities.length - 1,
            })}
          >
            <td className="py-2 pl-3">{displayCheckbox && <ChoiceInput labelText="" />}</td>
            {!displayQtyLast && <td className="py-3">{entity.quantity}</td>}
            <td className="py-3">
              {entity.name}
              {entity.specialInstructions && (
                <div className="text-xs">
                  <span className="font-bold">Instructions:&nbsp;</span>
                  {entity.specialInstructions}
                </div>
              )}
            </td>
            {displayQtyLast && <td className="py-3">{entity.quantity}</td>}
            {subEntityTitle && (
              <td className="py-3">
                {entity.subentities?.map(( x ) => (
                  <div key={x} className="list-item ml-6">
                    {x}
                  </div>
                ))}
              </td>
            )}
            {displaySku && <td className="py-3">{entity.sku}</td>}
          </tr>
        ))}
      </tbody>
    </table>
    <div className="text-xs font-bold text-right w-full">
      TOTAL:
      {` ${entities.map(( x ) => x.quantity ).reduce(( a, b ) => a + b, 0 )}`}
    </div>
  </div>
)

export default PrintableChecklists
