import { FC, LabelHTMLAttributes, PropsWithChildren } from 'react'
import classNames from 'classnames'

export interface ILabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  text?: string
  showLabel?: boolean

  /**
   * Make htmlFor required, for accessibility.
   */
  htmlFor: LabelHTMLAttributes<HTMLLabelElement>['htmlFor']
}

const Label: FC<PropsWithChildren<ILabelProps>> = ({
  text,
  showLabel,
  htmlFor,
  children,
  ...labelProps
}) => (
  <label htmlFor={htmlFor} {...labelProps}>
    <span
      className={classNames(
        {
          'sr-only': !showLabel,
        },
        'block mb-1.5 text-gb-gray-900 font-weight-normal font-medium text-sm'
      )}
    >
      {text}
    </span>
    {children}
  </label>
)

export default Label
