import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'

import Alert from 'src/components/01-atoms/Alert'
import PaymentForm from 'src/components/02-molecules/PaymentForm'

import useAppParams from 'src/utils/hooks/useAppParams'
import SectionCard from 'src/components/01-atoms/SectionCard'
import { getFieldErrors } from 'src/utils/helpers/forms'

import { PurchaserInfoOnly } from '../PurchaserInfo'

interface IStripePaymentProps {
  error?: string

  required?: boolean
}

const StripePayment: FC<IStripePaymentProps> = ({ error, required }) => {
  const stripe = useStripe()
  const elements = useElements()
  const { mdashAccountPermissions } = useAppParams()
  const { register, getValues, setValue, formState, reset } = useFormContext()
  const fieldNames = [ 'nameOnCard', 'paymentPhone' ]

  const handleCopyFromPurchaser = () => {
    const { purchaserFirstName, purchaserLastName, purchaserPhone } =
      getValues() as Partial<PurchaserInfoOnly>
    setValue( 'paymentPhone', purchaserPhone ?? '' )
    setValue( 'nameOnCard', `${purchaserFirstName ?? ''} ${purchaserLastName ?? ''}`.trim())
    reset()
  }

  const validationErrors = getFieldErrors( formState, fieldNames )
  const hasValidationErrors = Object.values( validationErrors ).length > 0

  return (
    <SectionCard
      size="medium"
      title="Payment"
      className="relative"
      error={!!error || hasValidationErrors}
    >
      {!!error && (
        <Alert type="error" className="mb-4">
          {error}
        </Alert>
      )}

      <PaymentForm
        fieldProps={{
          nameOnCard: {
            required,
            error: validationErrors.nameOnCard,
            ...register( 'nameOnCard', { required }),
          },
          phone: {
            required,
            error: validationErrors.paymentPhone,
            ...register( 'paymentPhone', { required }),
          },
          ...( mdashAccountPermissions?.canPayInStore && {
            inStorePayment: register( 'inStorePayment' ),
          }),
        }}
        handleCopyFromPurchaser={handleCopyFromPurchaser}
      >
        {!( stripe && elements ) ? (
          <Alert type="error">
            The Stripe service is currently unavailable. You can still create orders and fill in the
            details, but you will be unable to process non-in-store payments. Please check with
            Partner Support for more details.
          </Alert>
        ) : (
          <CardElement />
        )}
      </PaymentForm>
    </SectionCard>
  )
}

export default StripePayment
