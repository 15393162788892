import { FC, PropsWithChildren } from 'react'
import { Link, To } from 'react-router-dom'
import classNames from 'classnames'
import { isToday } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons'

import Container from 'src/components/01-atoms/Container'
import Button from 'src/components/01-atoms/Button'
import AlertList, { IAlertListProps } from 'src/components/02-molecules/AlertList'
import FinishButton from 'src/components/02-molecules/FinishButton'

import { shortDayOfTheWeek, shortMonthAndDate, todayOrElse } from 'src/utils/helpers/date'

export interface IPrintHeader {
  /**
   * Link for going back to the orders page.
   */
  backLink: To

  /**
   * The button label.
   */
  alerts?: IAlertListProps['alerts']

  /**
   * Title for page.
   */
  pageTitle: string

  /**
   * Ship date.
   */
  shipDate: Date

  /**
   * Total orders for the day.
   */
  totalOrders?: number

  /**
   * Method to call when the Optional Printing Actions button is clicked.
   */
  handleOptionalPrintingActions?: CallableFunction

  /**
   * Whether or not new data is being fetched.
   */
  fetching?: boolean

  /**
   * Method to call after printing.
   */
  postPrintAction?: () => void

  /**
   * Whether or not printing is complete.
   */
  printingIsComplete?: boolean
}

const PrintHeader: FC<PropsWithChildren<IPrintHeader>> = ({
  backLink,
  pageTitle,
  alerts,
  shipDate,
  totalOrders,
  handleOptionalPrintingActions,
  fetching,
  postPrintAction = () => {},
  printingIsComplete,
  children,
}) => {
  const shippingToday = isToday( shipDate )

  return (
    <div
      className="print-header print:hidden sticky top-0 z-20 border-b-2 border-gb-gray-400 bg-white py-4"
      data-testid="print-header"
    >
      <Container>
        <div className="grid grid-rows-1 gap-4 items-center lg:flex">
          <div className="flex flex-1 justify-center">
            <div className="flex mr-auto flex-col">
              <Link
                className="text-gb-gray-800 font-semibold text-xs mb-1"
                to={backLink}
                data-testid="back-link"
              >
                <FontAwesomeIcon icon={faAngleLeft} /> Back to Orders
              </Link>
              <h1 className="text-4xl">{pageTitle}</h1>
            </div>
          </div>
          <div className="flex flex-1 justify-center">
            <div
              className={classNames(
                {
                  'bg-gb-gray-200': !shippingToday,
                  'bg-green-200': shippingToday,
                },
                'flex rounded py-2 px-4 w-full lg:w-auto justify-center'
              )}
            >
              {typeof totalOrders !== 'undefined' && (
                <>
                  <span className="font-semibold">Total:&nbsp;</span>
                  {totalOrders}
                  <span className="px-3">|</span>
                </>
              )}
              <span className="font-semibold">Ship Date:&nbsp;</span>
              {todayOrElse( shipDate, shortDayOfTheWeek )}
              {', '}
              {shortMonthAndDate( shipDate )}
            </div>
          </div>
          <div className="print-action-buttons flex flex-1 flex-col gap-2 md:flex-row lg:flex-col xl:flex-row lg:justify-end">
            {children}
            {!children && (
              <>
                {handleOptionalPrintingActions && (
                  <Button
                    onClick={() => handleOptionalPrintingActions()}
                    className="flex-1 center lg:flex-initial"
                    data-testid="optional-print-action-button"
                    outline
                  >
                    Optional Printing Actions
                  </Button>
                )}
                {printingIsComplete ? (
                  <FinishButton className="flex-1 center lg:flex-initial" to={backLink} />
                ) : (
                  <Button
                    variant="primary"
                    onClick={() => {
                      window.print()
                      postPrintAction()
                    }}
                    className="flex-1 center lg:flex-initial"
                    disabled={fetching}
                    data-testid="print-button"
                  >
                    Print
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
        {alerts && alerts.length > 0 && <AlertList type="error" alerts={alerts} />}
      </Container>
    </div>
  )
}

export default PrintHeader
