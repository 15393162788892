import { FC } from 'react'
import WhatsNew from 'src/components/05-pages/WhatsNew'

import MDashHead from './elements/MDashHead'

const WhatsNewPage: FC = () => (
  <>
    <MDashHead pageTitle="What's New" />
    <WhatsNew />
  </>
)
export default WhatsNewPage
