import { ElementType, FC, useMemo } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import classNames from 'classnames'

export type IInlineLinkProps = Omit<LinkProps, 'to'> & {
  to?: LinkProps['to']
  href?: HTMLLinkElement['href']
  disabled?: boolean
  className?: string
  'data-testid'?: string
}

const InlineLink: FC<IInlineLinkProps> = ({
  children,
  className,
  disabled,
  'data-testid': dataTestId,
  ...props
}) => {
  const Element = useMemo(() => ( 'to' in props ? Link : 'a' ), [ props ]) as ElementType

  return (
    <Element
      {...props}
      data-testid={dataTestId}
      className={classNames(
        className,
        {
          'opacity-50 pointer-events-none': disabled,
          'hover:text-gb-blue-800 cursor-pointer': !disabled,
        },
        'font-semibold text-gb-blue-600'
      )}
    >
      {children}
    </Element>
  )
}

export default InlineLink
