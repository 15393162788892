import { FC } from 'react'
import { To } from 'react-router-dom'

import InlineLink from 'src/components/01-atoms/InlineLink'
import StatusIconWrapper, {
  IStatusIconWrapperProps,
} from 'src/components/01-atoms/StatusIconWrapper'

import { shortDate, shortTimeWithMeridiem } from 'src/utils/helpers/date'

interface IPrintableStatusProps {
  /**
   * The name of the entity being printed.
   */
  entity?: string
  /**
   * When the entity was printed. If not present, it assumes it has not been printed and advises the merchant to do so.
   */
  printedAt?: Date
  /**
   * A link to print the entity.
   */
  printLink?: To
  /**
   * A link to reprint the entity. If present, it assumes it needs to be reprinted and advises the merchant to do so.
   */
  reprintLink?: To
  /**
   * Whether or not to show the action links.
   */
  showActionLink?: boolean

  /**
   * The status icon variant to use.
   */
  statusIconVariant?: IStatusIconWrapperProps['variant']
}

const PrintableStatus: FC<IPrintableStatusProps> = ({
  entity,
  printedAt,
  printLink,
  reprintLink,
  showActionLink = true,
  statusIconVariant = 'primary',
}) => {
  const entityLabelElem = entity && (
    <span className="text-2xs font-semibold text-gb-gray-800">{entity}: </span>
  )

  if ( !printedAt && !reprintLink && !!printLink ) {
    return (
      <InlineLink to={printLink} className="text-xs">
        {entityLabelElem}Not Printed
      </InlineLink>
    )
  }

  return (
    <StatusIconWrapper
      level={reprintLink ? 'error' : 'success'}
      size="small"
      variant={statusIconVariant}
      className="text-2xs"
    >
      <div className="font-semibold">
        {entityLabelElem}
        {reprintLink ? 'Needs Re-print' : 'Printed'}
      </div>
      {reprintLink
        ? showActionLink && <InlineLink to={reprintLink}>Print &amp; Replace</InlineLink>
        : printedAt && `${shortDate( printedAt )} at ${shortTimeWithMeridiem( printedAt )}`}
    </StatusIconWrapper>
  )
}

export default PrintableStatus
