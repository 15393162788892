import { isNil, omitBy } from 'lodash'
import { faArrowsRotate, faEnvelope, faPrint, faTrashCan } from '@fortawesome/pro-solid-svg-icons'

import { stringAsDate } from 'src/utils/helpers/date'
import { IActionButtonProps } from 'src/components/01-atoms/ActionButton'

import {
  IManifestAggregate,
  IManifestFilterCategory,
  IShippingLabelStatus,
} from 'src/graphql/types'
import { addDays, isBefore } from 'date-fns'

export interface IAction extends IActionButtonProps {
  buttonText?: string | undefined
  buttonTextPlural?: string | undefined
  count?: number
}

export const actionableCategories = [
  IShippingLabelStatus.NOT_PRINTED,
  IShippingLabelStatus.NEED_REPRINT,
  IShippingLabelStatus.NEED_DISCARD,
  IShippingLabelStatus.LABEL_PENDING,
  IShippingLabelStatus.LABEL_ERROR,
]

export const getAggregateValue = (
  aggregates: IManifestAggregate[],
  query: string | null,
  category: IManifestFilterCategory
) =>
  aggregates.find(( aggregate ) => aggregate.query === query && aggregate.category === category )
    ?.value ?? 0

export const getActionsFromAggregates = (
  aggregates: IManifestAggregate[],
  mdashAccountId: string,
  toShipOnAsString: string,
  search?: string
): IAction[] => {
  const actionConfigs: { [k in IShippingLabelStatus]?: IAction } = {
    [IShippingLabelStatus.NOT_PRINTED]: {
      actionText: 'Click to Print',
      icon: faPrint,
      buttonText: 'Label not printed',
      buttonTextPlural: 'Labels not printed',
      to: omitBy(
        {
          pathname: `/${mdashAccountId}/labels/${toShipOnAsString}/print`,
          search,
        },
        isNil
      ),
    },

    [IShippingLabelStatus.NEED_REPRINT]: {
      actionText: 'Click to Re-Print',
      icon: faArrowsRotate,
      buttonText: 'Label needs to be re-printed',
      buttonTextPlural: 'Labels need to be re-printed',
      to: omitBy(
        {
          pathname: `/${mdashAccountId}/labels/${toShipOnAsString}/reprint`,
          search,
        },
        isNil
      ),
    },

    [IShippingLabelStatus.NEED_DISCARD]: {
      actionText: 'Discard',
      icon: faTrashCan,
      buttonText: 'Cancelled package with printed label',
      buttonTextPlural: 'Cancelled packages with printed labels',
      to: omitBy(
        {
          pathname: `/${mdashAccountId}/labels/${toShipOnAsString}/discard`,
          search,
        },
        isNil
      ),
    },
  }

  const packagesMissingLabels: IShippingLabelStatus[] = [
    IShippingLabelStatus.LABEL_PENDING,
    IShippingLabelStatus.LABEL_ERROR,
  ]
  const packagesMissingLabelsAction: IAction = {
    actionText: 'Email partners@goldbelly.com',
    icon: faEnvelope,
    buttonText: 'Package missing a label',
    buttonTextPlural: 'Packages missing labels',
    to: 'mailto:partners@goldbelly.com',
  }
  const packagesMissingLabelsCount = packagesMissingLabels.reduce(
    ( acc, status ) =>
      acc + getAggregateValue( aggregates, status, IManifestFilterCategory.LABEL_PRINT_STATUS ),
    0
  )
  const toShipOn = stringAsDate( toShipOnAsString )
  const shipsWithinTheWeek = isBefore( toShipOn, addDays( new Date(), 7 ))

  const actions = Object.keys( actionConfigs )
    .filter(( k ) => getAggregateValue( aggregates, k, IManifestFilterCategory.LABEL_PRINT_STATUS ) > 0 )
    .map(( key ) => ({
      ...actionConfigs[key as IShippingLabelStatus],
      count: getAggregateValue( aggregates, key, IManifestFilterCategory.LABEL_PRINT_STATUS ),
    }))

  if ( packagesMissingLabelsCount > 0 && shipsWithinTheWeek ) {
    actions.push({
      ...packagesMissingLabelsAction,
      count: packagesMissingLabelsCount,
    })
  }

  return actions
}
