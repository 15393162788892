import classNames from 'classnames'
import { useRef, RefObject, FC, PropsWithChildren } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useWindowSize } from 'rooks'
import { PageSize } from 'src/components/01-atoms/PrintablePage'

interface IPrintPreviewWrapperProps {
  /**
   * Whether or not to show the preview loading.
   */
  loading?: boolean

  /**
   * The size of the page being printed.
   */
  pageSize?: PageSize

  /**
   * Classes to pass down to the containing element.
   */
  className?: string
}

const PrintPreviewWrapper: FC<PropsWithChildren<IPrintPreviewWrapperProps>> = ({
  loading = false,
  pageSize = PageSize.US_LETTER_STRICT,
  className,
  children,
}) => {
  const content = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>
  const { innerWidth } = useWindowSize()

  const calculateHeight = () => {
    if ( content.current ) {
      if ( innerWidth! < 768 ) return `${content.current.clientHeight * 0.4}px`
      if ( innerWidth! >= 768 && innerWidth! < 1024 )
        return `${content.current.clientHeight * 0.75}px`
    }
    return 'auto'
  }

  return (
    <div style={{ height: calculateHeight() }}>
      <div
        ref={content}
        className={classNames(
          'screen:w-full screen:flex screen:flex-col screen:gap-y-8 screen:center',
          'screen:flex-grow-0 screen:mx-auto',
          {
            'screen:scale-40 screen:-translate-y-[30%] screen:md:scale-75 screen:md:-translate-y-[12.5%] print:scale-100 screen:lg:scale-100 screen:lg:translate-x-0 screen:lg:translate-y-0':
              pageSize === PageSize.US_LETTER_STRICT,
          },
          className
        )}
      >
        {loading && <Skeleton height="11in" width="8.5in" />}
        <div
          className={classNames(
            { hidden: loading },
            'screen:flex screen:flex-col screen:gap-y-8 items-center'
          )}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default PrintPreviewWrapper
