import {
  faCheck,
  faHourglassHalf,
  faLightbulb,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import TextInput from 'src/components/01-atoms/TextInput'
import { format } from 'src/utils/helpers/numbers'

interface IPantryItem {
  id: string
  name: string
}

export interface IPantryItemLimitUpdatePayload {
  pantryItemId: string
  toShipOn: string
  limit: number
}

export interface IPantryItemLimitCell {
  pantryItem: IPantryItem
  toShipOn: string
  currentQuantity: number
  limit?: number | null
  isShippable: boolean
  error?: string
  isUpdating?: boolean
  isUpdateSuccessful?: boolean
  onChange?: ({ limit, pantryItemId, toShipOn }: IPantryItemLimitUpdatePayload ) => void
}

const PantryItemLimitCell: FC<IPantryItemLimitCell> = ({
  pantryItem,
  toShipOn,
  currentQuantity,
  limit,
  isShippable,
  error,
  isUpdating,
  isUpdateSuccessful,
  onChange,
}) => {
  const inputRef = useRef<HTMLInputElement>( null )
  const retryRef = useRef<HTMLDivElement>( null )
  const retryButtonRef = useRef<HTMLButtonElement>( null )
  const [ isEditMode, setIsEditMode ] = useState( false )
  const [ lastKnownValue, setLastKnownValue ] = useState<number | null | undefined>( limit )

  const isUnlimited = lastKnownValue === null || lastKnownValue === undefined
  const quantityAvailable = Number( lastKnownValue ) - currentQuantity
  const isAlmostOutOfStock = !isUnlimited && quantityAvailable <= 5
  const isOutOfStock = !isUnlimited && quantityAvailable === 0
  const isOverLimit = !isUnlimited && quantityAvailable < 0
  const noSales = currentQuantity === 0

  const [ bgColor, badgeColor ] = useMemo(() => {
    const getBgColor = () => {
      if ( isEditMode ) return 'bg-gb-gray-50'
      if ( isUnlimited || noSales ) return 'bg-gb-gray-50'
      if ( !isUnlimited && ( isOutOfStock || isOverLimit )) return 'bg-red-200'
      if ( !isUnlimited && isAlmostOutOfStock ) return 'bg-orange-200'

      return 'bg-gb-gray-400'
    }

    const getBadgeColor = () => {
      if ( !isUnlimited && noSales ) return 'bg-stone-600'
      if ( !isUnlimited && ( isOutOfStock || isOverLimit )) return 'bg-rose-500'
      if ( !isUnlimited && isAlmostOutOfStock ) return 'bg-orange-400'
      if ( !isUnlimited ) return 'bg-stone-600'
      if ( isUnlimited && currentQuantity > 0 ) return 'bg-stone-600'
      if ( !isUnlimited || noSales ) return 'bg-gb-gray-400'

      return 'bg-stone-600'
    }

    return [ getBgColor(), getBadgeColor() ]
  }, [
    currentQuantity,
    isEditMode,
    isAlmostOutOfStock,
    isOutOfStock,
    isOverLimit,
    isUnlimited,
    noSales,
  ])

  const soldValue = useMemo(() => {
    if ( isOutOfStock ) return 'Out of Stock'

    return `${format( currentQuantity )} Sold`
  }, [ currentQuantity, limit ])

  const handleBlur = useCallback(
    ( value: number | string ) => {
      setIsEditMode( false )
      setLastKnownValue( Number( value ))

      if ( value === '' ) {
        setLastKnownValue( null )
      } else if ( onChange && Number( value ) !== Number( limit || 0 )) {
        onChange({ limit: Number( value ), toShipOn, pantryItemId: pantryItem.id })
      }
    },
    [ limit, setIsEditMode, setLastKnownValue, onChange ]
  )

  const handleAbort = useCallback(() => {
    if ( inputRef.current ) {
      if ( !isUnlimited ) {
        inputRef.current.value = String( limit )
      } else {
        inputRef.current.value = ''
      }
      inputRef.current.blur()
    }
  }, [ inputRef, limit ])

  useEffect(() => {
    if ( inputRef.current ) {
      inputRef.current.value = String( limit )
    }

    if ( isEditMode && inputRef.current ) {
      inputRef.current.focus()
      inputRef.current.select()
    }
  }, [ isEditMode, limit ])

  useEffect(() => {
    setLastKnownValue( limit )
  }, [ limit, setLastKnownValue ])

  if ( !isShippable && noSales ) {
    return <div className="h-[90px]" />
  }

  return (
    <div
      className={classNames(
        'rounded-lg max-w-[160px] w-full cursor-pointer border',
        bgColor,
        isEditMode ? 'border-dashed border-gb-gray-900' : 'border-solid border-transparent'
      )}
      role="button"
      tabIndex={0}
      onClick={( x ) => {
        if ( x.target !== retryRef.current && x.target !== retryButtonRef.current ) {
          setIsEditMode( true )
        }
      }}
      onKeyDown={( x ) => {
        if ( x.key === 'Enter' && !isEditMode && x.target !== retryButtonRef.current ) {
          setIsEditMode( true )
        }
      }}
    >
      <div className="relative h-0">
        <div className="absolute right-2 top-2">
          {isUpdating && <FontAwesomeIcon icon={faHourglassHalf} className="animate-spin" />}
          {isUpdateSuccessful && <FontAwesomeIcon icon={faCheck} />}
          {isOverLimit && (
            <FontAwesomeIcon icon={faTriangleExclamation} className="text-red-500 text-2xl" />
          )}
        </div>
      </div>
      <div
        className={classNames(
          'flex items-end justify-center pt-3 text-center',
          ( lastKnownValue === undefined || lastKnownValue === null ) && 'text-gb-gray-600'
        )}
      >
        {isEditMode ? (
          <>
            <div className="relative h-0">
              <div className="absolute bg-lime-200 w-52 -left-[200px] -top-10 border rounded-md border-lime-400 p-2 text-sm text-gb-gray-900 text-left">
                <table>
                  <thead>
                    <tr>
                      <th colSpan={2}>
                        <div className="text-center">
                          <FontAwesomeIcon icon={faLightbulb} />
                          <span className="bold px-2">Keyboard Shortcut</span>
                          <FontAwesomeIcon icon={faLightbulb} />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="font-bold pr-2">Enter</td>
                      <td>Save change</td>
                    </tr>
                    <tr>
                      <td className="font-bold pr-2">Tab</td>
                      <td>
                        Save <i>and</i> advance
                      </td>
                    </tr>
                    <tr>
                      <td className="font-bold pr-2">Esc</td>
                      <td>Abort</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <TextInput
              ref={inputRef}
              type="number"
              className="text-4xl text-gb-gray-900 text-center font-semibold w-[calc(100%-36px)] bg-transparent p-0 pl-3"
              min={0}
              onBlur={( x ) => {
                handleBlur( x.target.value )
              }}
              onKeyDown={( x ) => {
                if ( x.key === 'Enter' && inputRef.current ) {
                  inputRef.current.blur()
                } else if ( x.key === 'Escape' ) {
                  handleAbort()
                }
              }}
            />
          </>
        ) : (
          <div className="text-4xl font-semibold">
            {lastKnownValue !== null && lastKnownValue !== undefined
              ? format( Number( lastKnownValue ))
              : '∞'}
          </div>
        )}
      </div>
      <div className="pb-2 px-2">
        <div
          className={classNames(
            'rounded-2xl border border-transparent text-center text-gb-gray-50 font-semibold p-0.5',
            badgeColor
          )}
        >
          {soldValue}
        </div>
        {error && (
          <div className="relative h-0">
            <div className="absolute right-0">
              <div className="w-64 p-2 text-right border bg-red-100 border-red-500 text-red-500 text-sm rounded-lg">
                {error}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default PantryItemLimitCell
