import { useMemo } from 'react'
import { mkConfig, generateCsv, download, ConfigOptions } from 'export-to-csv'
import { Maybe } from 'graphql/jsutils/Maybe'

export type CSVData = Record<string | number, Maybe<string>>[]

interface IUseMakeCSVDownloadButtonProps extends ConfigOptions {
  /**
   * The data to be used for the CSV.
   */
  csvData?: CSVData

  /**
   * Whether the data is currently being fetched.
   */
  fetching?: boolean
}

const useMakeCSVDownloadButtonProps = ({
  csvData,
  fetching = false,
  ...config
}: IUseMakeCSVDownloadButtonProps ) => {
  const hasDownloadableData = ( csvData?.length ?? 0 ) > 0
  const csvConfig = mkConfig({ filename: 'download', ...config })
  const csv = useMemo(() => csvData && generateCsv( csvConfig )( csvData ), [ csvData ])

  return {
    disabled: !hasDownloadableData || fetching,
    title: hasDownloadableData
      ? 'Download CSV version of this data'
      : 'No data available for download',
    onClick: csv ? () => download( csvConfig )( csv ) : () => {},
  }
}

export default useMakeCSVDownloadButtonProps
