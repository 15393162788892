import { kebabCase } from 'lodash'
import { FC } from 'react'

import Table from 'src/components/02-molecules/Table'

import './styles.css'

interface IProductTableProps {
  productColumnHeader?: 'Product' | 'Subproduct'
  lineItems: {
    id: string
    quantity: number
    name: string
    subproducts?: string[]
    sku?: string[] | string
    productOptions?: string[]
    specialInstructions?: string | null
  }[]
}

const ProductTable: FC<IProductTableProps> = ({ lineItems, productColumnHeader = 'Product' }) => {
  const hasSkus = lineItems.filter(( item ) => item.sku ).length > 0
  const hasSubProducts = lineItems.filter(( item ) => item.subproducts ).length > 0
  const tableHeaders = [{ name: 'QTY' }, { name: productColumnHeader }]
  const columnWidths = [{ className: 'lg:w-20' }, {}]
  if ( hasSkus ) {
    tableHeaders.push({ name: 'SKU' })
    columnWidths.push({ className: 'lg:w-32' })
  }

  if ( hasSubProducts ) {
    tableHeaders.push({ name: 'Subproducts' })
  }

  const tableCellClasses =
    'flex gap-x-4 first-of-type:pt-4 pb-4 pl-4 pr-4 lg:p-4 lg:table-cell align-top'

  return (
    <Table
      tableClasses="order-details text-sm"
      headers={tableHeaders}
      borderRule="headers-only"
      columnWidths={columnWidths}
    >
      {lineItems.map(
        ({ id, quantity, name, sku, subproducts, productOptions = [], specialInstructions }) => (
          <tr
            key={`product-${id}`}
            className="flex flex-col even:bg-gb-gray-50 odd:bg-white lg:table-row"
          >
            <td className={tableCellClasses}>
              <div className="font-bold w-1/3 lg:hidden">{tableHeaders[0].name}</div>
              <span className="text-gb-gray-800">{quantity}</span>
            </td>
            <td className={tableCellClasses}>
              <div className="font-bold w-1/3 lg:hidden">{tableHeaders[1].name}</div>
              <div>
                {name}
                {specialInstructions && (
                  <div className="text-xs">
                    <span className="font-bold">Instructions:&nbsp;</span>
                    {specialInstructions}
                  </div>
                )}
                {productOptions.length > 0 && (
                  <ul className="text-xs pl-4 my-1">
                    {productOptions.map(( optionName ) => (
                      <li key={`productOption-${kebabCase( optionName )}`}>{optionName}</li>
                    ))}
                  </ul>
                )}
              </div>
            </td>
            {subproducts && subproducts.length > 0 && (
              <td className={tableCellClasses}>
                <div className="font-bold w-1/3 lg:hidden">{tableHeaders[2].name}</div>
                <ul className="text-gb-gray-800 text-xs leading-7">
                  {subproducts.map(( subproduct ) => (
                    <li key={`subproduct-${subproduct}`}>{subproduct}</li>
                  ))}
                </ul>
              </td>
            )}
            {hasSkus && (
              <td className={tableCellClasses}>
                <div className="font-bold w-1/3 lg:hidden">{tableHeaders[2].name}</div>
                {( Array.isArray( sku ) ? sku : [ sku ]).map(( x ) => (
                  <div>{x}</div>
                ))}
              </td>
            )}
          </tr>
        )
      )}
    </Table>
  )
}

export default ProductTable
