import classNames from 'classnames'
import { FC, HTMLAttributes, PropsWithChildren } from 'react'
import StatusIcon from '../StatusIcon'

export interface ITabProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * Whether or not the tab is active.
   */
  isActive?: boolean

  /**
   * Highlight error
   */
  isProblematic?: boolean

  /**
   * Whether or not the tab is disabled.
   */
  disabled?: boolean
}

const Tab: FC<PropsWithChildren<ITabProps>> = ({
  isActive,
  isProblematic,
  disabled,
  className,
  children,
  ...props
}) => (
  <div
    {...props}
    className={classNames(
      {
        'active bg-gb-blue-300 text-gb-gray-900 disabled:hover:bg-white after:content-[""] after:block after:w-full after:h-1 after:absolute after:left-0 after:-bottom-[2px] after:bg-gb-blue-600':
          isActive && !disabled,
        'bg-white text-gb-gray-800 hover:bg-gb-blue-100 hover:text-gb-gray-900':
          !isActive && !disabled,
        'bg-white opacity-50 cursor-not-allowed': disabled,
      },

      'relative flex items-center justify-center rounded-sm font-semibold text-sm disabled:cursor-not-allowed transition-colors lg:min-w-36 px-2 py-1.5 rounded-t rounded-b-none text-center',
      className
    )}
  >
    {children}
    {isProblematic && <StatusIcon data-testid="error" level="error" className="pl-2" />}
  </div>
)

export default Tab
