import { FC, OptionHTMLAttributes, SelectHTMLAttributes, forwardRef } from 'react'
import classNames from 'classnames'

export interface IOption extends OptionHTMLAttributes<HTMLOptionElement> {
  /**
   * The name of the option.
   */
  label: string
}

export interface ISelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  /**
   * Options to display for the select field.
   */
  options?: IOption[]

  /**
   * Error message to show underneath the select box.
   */
  error?: string

  /**
   * Whether or not space under the input box should be preserved to prevent too much layout shift.
   */
  reduceLayoutShift?: boolean

  /**
   * Helper text to display underneat the select box.
   */
  helperText?: string
}

/**
 * A form-compatible select box.
 */
const Select: FC<ISelectProps> = forwardRef<HTMLSelectElement, ISelectProps>(
  ({ options, className, error, helperText, reduceLayoutShift = false, ...selectProps }, ref ) => (
    <>
      <select
        className={classNames(
          'border-gb-gray-400 border-2 rounded w-full p-2 text-sm h-10 focus:outline-gb-blue-600 bg-white placeholder:text-gb-gray-800 disabled:placeholder-shown:opacity-50 invalid:border-red-600',
          { 'border-red-600': error },
          className
        )}
        ref={ref}
        {...selectProps}
      >
        {options?.map(({ value, label }) => (
          <option key={`select-${selectProps.name}-${value}`} value={value}>
            {label}
          </option>
        ))}
      </select>
      {( reduceLayoutShift || helperText || error ) && (
        <div className="mt-1 text-xs">
          {helperText && <span className="block text-gb-gray-800">{helperText}</span>}
          {error && <span className="text-red-600 error-message">{error}</span>}
          {reduceLayoutShift && <>&nbsp;</>}
        </div>
      )}
    </>
  )
)

export default Select
