import { FC, MouseEventHandler, PropsWithChildren, useState } from 'react'

import Button from 'src/components/01-atoms/Button'
import ChoiceInput from 'src/components/01-atoms/ChoiceInput'
import Fieldset from 'src/components/01-atoms/Fieldset'
import Grid from 'src/components/01-atoms/Grid'
import TextInput from 'src/components/01-atoms/TextInput'

import { FormChoiceInputProps, FormTextInputProps } from 'src/utils/types/forms'

interface IPaymentFormProps {
  /**
   * Props for the Name on Card, Phone Number and In-Store Payment fields.
   */
  fieldProps?: {
    nameOnCard: FormTextInputProps
    phone: FormTextInputProps
    inStorePayment?: FormChoiceInputProps
  }

  /**
   * Method to call when the user clicks on the 'Copy from Purchaser' button in the title of the payments section.
   */
  handleCopyFromPurchaser?: MouseEventHandler<HTMLButtonElement>
}

const PaymentForm: FC<PropsWithChildren<IPaymentFormProps>> = ({
  fieldProps,
  handleCopyFromPurchaser,
  children,
}) => {
  const [ isInStorePayment, setIsInStorePayment ] = useState<boolean>(
    !!fieldProps?.inStorePayment?.defaultChecked
  )

  return (
    <>
      <div className="flex flex-col gap-y-2 mb-4 md:absolute md:flex-row md:gap-x-4 md:top-4 md:right-4 md:mb-0">
        {fieldProps?.inStorePayment && (
          <ChoiceInput
            defaultChecked={isInStorePayment}
            {...fieldProps?.inStorePayment}
            onChange={( e ) => {
              setIsInStorePayment( e.currentTarget.checked )
              if ( !fieldProps?.inStorePayment?.onChange ) return
              fieldProps?.inStorePayment?.onChange( e )
            }}
            type="checkbox"
            labelText="In-store payment"
            className="text-base font-normal"
          />
        )}
        <Button
          onClick={handleCopyFromPurchaser}
          disabled={isInStorePayment}
          className="center"
          size="small"
          outline
        >
          Copy from Purchaser
        </Button>
      </div>
      <Fieldset disabled={isInStorePayment}>
        <Grid>
          <div className="bg-white border-2 border-gb-gray-400 rounded p-2 col-span-12">
            {children}
          </div>
          <TextInput
            {...fieldProps?.nameOnCard}
            required={false}
            labelText={`Name on Card ${fieldProps?.nameOnCard.required ? '*' : ''}`}
            wrapperClassName="col-span-12 md:col-span-6"
            showLabel
            outline
          />
          <TextInput
            {...fieldProps?.phone}
            required={false}
            type="tel"
            labelText={`Phone ${fieldProps?.phone.required ? '*' : ''}`}
            wrapperClassName="col-span-12 md:col-span-6"
            maxLength={10}
            showLabel
            outline
          />
        </Grid>
      </Fieldset>
    </>
  )
}

export default PaymentForm
