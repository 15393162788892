import { FC } from 'react'
import Modal, { IModalProps } from 'src/components/02-molecules/Modal'
// import SlipNLabelSvg from 'src/assets/icons/slip-n-label.svg'
import FulfillmentSheetSvg from 'src/assets/icons/fulfillment-sheet.svg'
import PickListSvg from 'src/assets/icons/pick-list.svg'

import Button from 'src/components/01-atoms/Button'
import PostPrintCard from 'src/components/02-molecules/PostPrintCard'

interface IPostPrintModalElement {
  isCompleted: boolean
  onClick: () => void
}

export interface IPostPrintModal extends IModalProps {
  slipNLabels: IPostPrintModalElement
  fulfillmentSheets: IPostPrintModalElement
  pickLists: IPostPrintModalElement
}

const PostPrintModal: FC<IPostPrintModal> = ({
  // slipNLabels,
  fulfillmentSheets,
  pickLists,
  ...modalProps
}) => (
  <Modal
    {...modalProps}
    showCloseButton
    closeOnBlur
    takeoverOnMobile
    contentClassName="w-full md:max-w-175 lg:max-w-175 px-8 pt-12 pb-6 lg:mt-16"
  >
    <div data-testid="post-print-modal">
      <div className="text-center">
        <div className="font-semibold text-lg">Optional Printing Actions</div>
        <div className="pt-2 text-gb-gray-800">
          Here are some additional resources you can print for these orders:
        </div>
      </div>
      <div className="flex flex-col md:flex-row md:items-stretch center gap-4 text-sm pt-6">
        {/* <PostPrintCard
          {...slipNLabels}
          title="Packing Slips & Shipping Labels"
          testName="slip-n-label"
          className="w-full md:w-1/3 shrink"
          icon={SlipNLabelSvg}
        >
          <ul className="list-disc ml-6 text-gb-gray-800">
            <li className="pb-2">Prints one sheet per box</li>
            <li>
              Each sheet includes the Packing Slip that is placed inside the box and a Shipping
              Label
            </li>
          </ul>
        </PostPrintCard> */}
        <PostPrintCard
          {...fulfillmentSheets}
          title="Fulfillment Sheets"
          testName="fulfillment-sheet"
          className="w-full md:w-1/2 shrink"
          icon={FulfillmentSheetSvg}
        >
          <ul className="list-disc ml-6 text-gb-gray-800">
            <li className="pb-2">Prints one sheet per box</li>
            <li>Each sheet lists the Subproducts that go into each package</li>
          </ul>
        </PostPrintCard>
        <PostPrintCard
          {...pickLists}
          title="Pick List"
          testName="pick-list"
          className="w-full md:w-1/2 shrink"
          icon={PickListSvg}
        >
          <ul className="list-disc ml-6 text-gb-gray-800">
            <li className="pb-2">Prints one sheet for ALL orders</li>
            <li>One list with all Subproducts for the orders selected</li>
          </ul>
        </PostPrintCard>
      </div>
      <Button
        outline
        className="border-gb-blue-600 rounded w-full mt-6 center"
        onClick={() => modalProps.handleClose && modalProps.handleClose()}
      >
        No Thanks
      </Button>
    </div>
  </Modal>
)

export default PostPrintModal
