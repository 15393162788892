import { FC } from 'react'
import { To } from 'react-router-dom'

import InlineLink from 'src/components/01-atoms/InlineLink'
import StatusIcon from 'src/components/01-atoms/StatusIcon'
import StatusIconWrapper from 'src/components/01-atoms/StatusIconWrapper'
import PrintableStatus from 'src/components/02-molecules/PrintableStatus'

interface IPackageStatusProps {
  /**
   * Carrier response error
   */
  carrierResponse?: string
  /**
   * Whether or not the order has been canceled.
   */
  isCanceled: boolean
  /**
   * Whether or not the label has been generated.
   */
  labelHasBeenGenerated?: boolean
  /**
   * Whether or not the label is pending generation.
   */
  labelIsPending?: boolean
  /**
   * When the label was printed. If not present, it assumes the label has not been printed and advises the merchant to do so.
   */
  labelPrintedAt?: Date
  /**
   * The date at which the label was discarded.
   */
  labelDiscardedAt?: Date
  /**
   * When the fulfillmentSheet was printed. If not present, the fulfillment sheet printing status won't display.
   */
  fulfillmentSheetPrintedAt?: Date
  /**
   * A link to print the label.
   */
  printLink?: To
  /**
   * A link to reprint the label. If present, it assumes the label needs to be reprinted and advises the merchant to do so.
   */
  reprintLink?: To
  /**
   * A link to discard the label. If present, it assumes the label needs to be discarded and advises the merchant to do so.
   */
  discardLink?: To
  /**
   * Whether or not to show the action links.
   */
  showActionLink?: boolean
}

const PackageStatus: FC<IPackageStatusProps> = ({
  carrierResponse,
  isCanceled,
  labelIsPending,
  labelPrintedAt,
  labelDiscardedAt,
  fulfillmentSheetPrintedAt,
  labelHasBeenGenerated = !!labelPrintedAt,
  printLink,
  reprintLink,
  discardLink,
  showActionLink = true,
}) => {
  if ( isCanceled ) {
    return discardLink ? (
      <StatusIconWrapper level="error" size="small">
        <div className="font-semibold">Canceled</div>
        <div>Printed</div>
        {showActionLink && <InlineLink to={discardLink}>Discard</InlineLink>}
      </StatusIconWrapper>
    ) : (
      <div className="text-xs">
        <div className="font-semibold">Canceled</div>
        {labelDiscardedAt ? 'Discarded' : 'Not Printed'}
      </div>
    )
  }

  if ( labelIsPending ) {
    return <div className="font-semibold text-xs">Shipping Label Pending</div>
  }

  if ( labelHasBeenGenerated ) {
    return (
      <>
        <div data-testid="shipping-label-print-status">
          <PrintableStatus
            entity="Shipping Label"
            printedAt={labelPrintedAt}
            printLink={printLink}
            reprintLink={reprintLink}
            showActionLink={showActionLink}
          />
        </div>
        {!!fulfillmentSheetPrintedAt && (
          <div data-testid="fulfillment-sheet-print-status">
            <PrintableStatus
              entity="Fulfillment Sheet"
              printedAt={fulfillmentSheetPrintedAt}
              statusIconVariant="secondary"
            />
          </div>
        )}
      </>
    )
  }

  return (
    <div className="text-xs">
      <div className="text-red-600 font-semibold flex gap-1 items-center">
        <StatusIcon className="text-sm" level="error" outline />
        {carrierResponse && carrierResponse.length > 0 ? (
          <span>{carrierResponse}</span>
        ) : (
          <span>Label Error</span>
        )}
      </div>
      Please contact GB Partner Support.
    </div>
  )
}

export default PackageStatus
