import { FC, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ITypeaheadSearchResult } from 'src/components/01-atoms/TypeaheadSearchResult'
import TypeaheadSearch, { ITypeaheadSearchProps } from '../TypeaheadSearch'

interface IProps extends Omit<ITypeaheadSearchProps, 'value'> {
  /**
   * Available options that users' input can match against
   */
  availableOptions: ITypeaheadSearchResult[]

  /**
   * Just a classic placeholder
   */
  placeholder?: string

  /**
   * Optional. Currently persisted value.
   */
  value?: string | null

  /**
   * Action to take when the user clicks on a search result
   * @param x the value of the search result
   */
  handleChange?: ( x: string ) => void
}

const LatchedTypeaheadSearch: FC<IProps> = forwardRef<HTMLInputElement, IProps>(
  ({ availableOptions, placeholder, value, handleChange = () => {}, ...typeaheadProps }, ref ) => {
    // in case user focuses away before setting a valid value, replace the text label with
    // previously-stored label
    const referentialLabel = useRef<string>( '' )
    const [ formalValue, setFormalValue ] = useState<string | null>( null )
    const [ draftLabel, setDraftLabel ] = useState<string>( '' )

    const formalLabelOf = useCallback(
      ( formalValue: string | null ) =>
        availableOptions.find(( x ) => x.value === formalValue )?.label || '',
      [ availableOptions ]
    )
    const visibleOptions = useMemo(
      () =>
        availableOptions
          .filter(( x ) => {
            if ( draftLabel.trim().length === 0 || formalLabelOf( formalValue ) === draftLabel.trim())
              return x

            return (
              x.value === formalValue || x.label.toLowerCase().includes( draftLabel.toLowerCase())
            )
          })
          .sort(( a, b ) => {
            if ( a.value === formalValue ) return -1
            if ( b.value === formalValue ) return 1

            return a.label.localeCompare( b.label )
          })
          .map(( x ) => ( x.value === formalValue ? { ...x, label: `Selected: ${x.label}` } : x )),
      [ availableOptions, draftLabel ]
    )

    const latchFormalValueToDraftLabel = useCallback(
      ( formalValue: string | null ) => {
        setDraftLabel( formalLabelOf( formalValue ))
        referentialLabel.current = formalLabelOf( formalValue )
      },
      [ availableOptions, formalLabelOf, setDraftLabel ]
    )

    useEffect(() => {
      latchFormalValueToDraftLabel( formalValue )
    }, [ formalValue ])

    useEffect(() => {
      if ( value && availableOptions.find(( x ) => x.value === value )) {
        setFormalValue( value )
      }
    }, [ setFormalValue, value ])

    return (
      <TypeaheadSearch
        showDropdownButton
        ref={ref}
        useClearButton={false}
        searchResults={visibleOptions}
        placeholder={placeholder}
        handleChange={( x: string ) => setDraftLabel( x )}
        handleClear={() => setFormalValue( null )}
        handleBlur={() => {
          setTimeout(() => {
            setDraftLabel( referentialLabel.current )
          }, 200 )
        }}
        handleSelect={( x ) => {
          setFormalValue( x )
          handleChange( x )
        }}
        forceUpdateValue={draftLabel}
        {...typeaheadProps}
      />
    )
  }
)

export default LatchedTypeaheadSearch
