import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useFormContext, useWatch } from 'react-hook-form'
import { camelCase, snakeCase } from 'lodash'

interface IProps {
  loading: boolean
}

const useAggregateSearchParams = ({ loading }: IProps ) => {
  const watch = useWatch()
  const [ searchParams, setSearchParams ] = useSearchParams()
  const { getValues, setValue } = useFormContext()
  const [ urlParamsLoaded, setUrlParamsLoaded ] = useState( false )

  const clearAllSearchParams = () => setSearchParams( undefined )

  useEffect(() => {
    if ( urlParamsLoaded ) {
      Object.entries( watch ).forEach(([ key, value ]) => {
        const keyForUrl = snakeCase( key )

        if ( keyForUrl === 'label_print_status' && value === 'TO_SHIP' ) {
          searchParams.delete( keyForUrl )
          return
        }

        if ( !value ) {
          searchParams.delete( keyForUrl )
          return
        }

        if ( Array.isArray( value )) {
          searchParams.delete( keyForUrl )
          value.forEach(( v ) => searchParams.append( keyForUrl, v ))
          return
        }

        searchParams.set( keyForUrl, value )
      })

      setSearchParams( searchParams )
    }
  }, [ urlParamsLoaded, watch ])

  useEffect(() => {
    if ( !loading && !urlParamsLoaded ) {
      searchParams.forEach(( value, key ) => {
        const keyFromUrl = camelCase( key )

        switch ( keyFromUrl ) {
          case 'labelPrintStatus':
            setValue( keyFromUrl, value, { shouldDirty: true })
            break
          case 'productExclusion':
            setValue( keyFromUrl, value === 'true', { shouldDirty: true })
            break
          case 'facilityId':
          case 'facilityType':
            // multifacility filters do not use react-hook-form
            // so don't attempt to do `setValue`
            break
          default:
            setValue( keyFromUrl, ( getValues( keyFromUrl ) || []).concat( value ), { shouldDirty: true })
        }
      })

      setUrlParamsLoaded( true )
    }
  }, [ loading, urlParamsLoaded ])

  return { clearAllSearchParams }
}

export default useAggregateSearchParams
