import { FC, PropsWithChildren } from 'react'
import classNames from 'classnames'
import { kebabCase } from 'lodash'
import { IconDefinition } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from 'src/components/01-atoms/Button'

export interface ITypeaheadSearchResult extends PropsWithChildren {
  /**
   * The value of the item being selected.
   */
  value: string

  /**
   * The label to display for the result.
   */
  label: string

  /**
   * Whether or not this result is disabled.
   */
  disabled?: boolean

  /**
   * The icon to use for the result.
   */
  icon?: IconDefinition

  /**
   * Classes to pass down to the wrapping button element.
   */
  className?: string

  /**
   * Method to run when the search result is clicked.
   * @param x The value for the search result.
   */
  onClick?: ( x: string ) => void
}

const TypeaheadSearchResult: FC<ITypeaheadSearchResult> = ({
  value,
  label,
  disabled,
  icon,
  className,
  onClick = () => {},
  children,
}) => (
  <Button
    variant="clear"
    key={`result-${kebabCase( label )}`}
    onClick={() => onClick( value )}
    data-testid="search-result-button"
    disabled={disabled}
    className={classNames(
      'leading-6 py-0 px-4 hover:bg-gb-gray-200 w-full hover:no-underline',
      className,
      {
        'pointer-events-none': disabled,
      }
    )}
  >
    <div
      className={classNames(
        'flex flex-col md:flex-row gap-x-2 md:items-center w-full text-left py-2',
        {
          'text-gb-gray-500': disabled,
        }
      )}
      data-testid="search-result-display"
    >
      {icon && <FontAwesomeIcon icon={icon} />}
      {children || label}
    </div>
  </Button>
)

export default TypeaheadSearchResult
