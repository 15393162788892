import {
  ColHTMLAttributes,
  FC,
  DetailedHTMLProps,
  PropsWithChildren,
  ReactNode,
  MouseEvent as RME,
} from 'react'
import classNames from 'classnames'
import { kebabCase, snakeCase } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort as duotoneSort } from '@fortawesome/pro-duotone-svg-icons'
import { faSort as solidSort } from '@fortawesome/pro-solid-svg-icons'

import './styles.css'

export type TableHeader = {
  /**
   * The text heading of the column.
   */
  name: string

  /**
   * If this needs to be more advanced than just text, you can use an element.
   */
  element?: ReactNode

  /**
   * The ID of the column being sorted.
   */
  sortId?: string

  /**
   * Custom classname for header
   */
  className?: string

  /**
   * Header click event handler
   */
  onClick?: ( evt: RME<HTMLTableCellElement> ) => void
}

export interface ITableProps {
  /**
   * A list of headers available for the table.
   */
  headers: TableHeader[]
  /**
   * Method to call when the sort button is clicked.
   */
  sortAction?: ( field: string, ascending: boolean ) => void

  /**
   * The column being sorted and the direction it's going in.
   */
  sortValue?: {
    sortField: TableHeader['sortId']
    sortAscending: boolean
  }
  /**
   * Whether the border should be around the whole table or just the headers.
   */
  borderRule?: 'surround' | 'headers-only'
  /**
   * Widths for each column, respectively.
   */
  columnWidths?: DetailedHTMLProps<ColHTMLAttributes<HTMLTableColElement>, HTMLTableColElement>[]
  /**
   * Classes to pass down to the table.
   */
  tableClasses?: string
  /**
   * Elements to show in the table footer.
   */
  tableFooterChildren?: ReactNode
  /**
   * Enable sticky header
   */
  stickyHeader?: boolean
}

const Table: FC<PropsWithChildren<ITableProps>> = ({
  tableClasses = '',
  headers,
  sortValue,
  sortAction,
  borderRule = 'surround',
  stickyHeader = false,
  columnWidths = [],
  tableFooterChildren = null,
  children,
}) => (
  <table
    className={classNames(
      {
        'border-2 rounded': borderRule === 'surround',
      },
      tableClasses,
      'w-full border-separate border-spacing-0 border-solid border-gb-gray-400'
    )}
  >
    {columnWidths && (
      <colgroup>
        {columnWidths.map(( attrs, i ) => (
          // We don't really have any other way to provide a key for this particular element.
          // eslint-disable-next-line react/no-array-index-key
          <col key={`col-${i}`} {...attrs} />
        ))}
      </colgroup>
    )}
    <thead>
      <tr className={classNames( 'bg-gb-gray-100 text-sm', stickyHeader && 'sticky top-0 z-50' )}>
        {headers.map(( h ) => (
          <th
            key={`table-heading-${kebabCase( h.name )}`}
            aria-sort={
              sortValue?.sortField === h.sortId
                ? sortValue?.sortAscending
                  ? 'ascending'
                  : 'descending'
                : 'none'
            }
            className={classNames(
              {
                'border-l-2 first-of-type:border-l-0 border-b-2': borderRule === 'surround',
                'border-t-2 border-b-2 border-r-2 first-of-type:border-l-2 first-of-type:rounded-tl last-of-type:rounded-tr':
                  borderRule === 'headers-only',
              },
              'text-left p-0 font-bold border-solid border-gb-gray-400',
              h.className
            )}
            onClick={h.onClick}
          >
            {h.sortId && sortAction ? (
              <button
                type="button"
                className="w-full flex justify-between items-center py-3 px-4 hover:bg-gb-gray-300"
                data-testid={`sort-${snakeCase( h.name )}`}
                onClick={() => {
                  if ( !h.sortId ) return
                  sortAction(
                    h.sortId,
                    h.sortId === sortValue?.sortField ? !sortValue?.sortAscending : true
                  )
                }}
              >
                <span className="sr-only">Click to sort by</span>
                {h.name}
                {sortValue?.sortField === h.sortId ? (
                  <span>
                    {sortValue.sortAscending ? (
                      <FontAwesomeIcon icon={duotoneSort} className="text-gb-gray-800 rotate-180" />
                    ) : (
                      <FontAwesomeIcon icon={duotoneSort} className="text-gb-gray-800" />
                    )}
                  </span>
                ) : (
                  <FontAwesomeIcon icon={solidSort} className="text-gb-gray-500" />
                )}
              </button>
            ) : (
              <div className="flex justify-between items-center py-3 px-4">
                {h.element ? h.element : h.name}
              </div>
            )}
          </th>
        ))}
      </tr>
    </thead>
    <tbody className="bg-white">{children}</tbody>
    {tableFooterChildren && (
      <tfoot>
        <tr>
          <td
            colSpan={headers.length}
            className="bg-gb-gray-100 border-solid border-t-2 border-gb-gray-400 text-xs p-2 md:p-4"
          >
            {tableFooterChildren}
          </td>
        </tr>
      </tfoot>
    )}
  </table>
)

export default Table
