import { faMessageHeart } from '@fortawesome/pro-duotone-svg-icons'
import { FC, FormEvent, useState } from 'react'

import Alert from 'src/components/01-atoms/Alert'
import Button from 'src/components/01-atoms/Button'
import CircleIcon from 'src/components/01-atoms/CircleIcon'
import Modal, { IModalProps } from 'src/components/02-molecules/Modal'

import FinishButton from 'src/components/02-molecules/FinishButton'
import GiftMessageForm from 'src/components/02-molecules/GiftMessageForm'

export interface IEditGiftMessageModalProps extends IModalProps {
  savedMessageBody?: string
  savedMessageFrom?: string
  handleSave?: ({ body, from }: { body: string; from: string }) => void
  error?: string
  success?: boolean
  saving?: boolean
}

const EditGiftMessageModal: FC<IEditGiftMessageModalProps> = ({
  savedMessageBody = '',
  savedMessageFrom = '',
  handleSave = () => {},
  error,
  success = false,
  saving = false,
  ...modalProps
}) => {
  const { handleClose = () => {} } = modalProps
  const [ giftMessageBody, setGiftMessageBody ] = useState( savedMessageBody )
  const [ giftMessageFrom, setGiftMessageFrom ] = useState( savedMessageFrom )
  const hasGiftMessage = !!savedMessageBody || !!savedMessageFrom

  return (
    <Modal
      contentClassName="overflow-y-visible sm:max-w-125 md:max-w-125 lg:max-w-125 pt-12 pb-9 px-8 sm:px-14"
      {...modalProps}
    >
      <div
        data-testid="edit-gift-message-modal"
        className="flex flex-col center gap-y-6 text-sm lg:w-full relative"
      >
        <div className="flex flex-col gap-2 items-center text-center">
          <CircleIcon size="large" color="indigo" icons={[ faMessageHeart ]} />
          <div className="text-gb-gray-900 text-lg font-semibold leading-tight">
            {hasGiftMessage ? 'Edit' : 'Add'} Gift Message
          </div>
        </div>
        {error && <Alert type="error">{error}</Alert>}
        <fieldset disabled={success || saving}>
          <GiftMessageForm
            fieldProps={{
              giftMessage: {
                defaultValue: giftMessageBody,
                onChange: ( e: FormEvent<HTMLTextAreaElement> ) =>
                  setGiftMessageBody( e.currentTarget.value ),
              },
              giftMessageFrom: {
                defaultValue: giftMessageFrom,
                onChange: ( e: FormEvent<HTMLInputElement> ) =>
                  setGiftMessageFrom( e.currentTarget.value ),
              },
            }}
          />
        </fieldset>
        <div className="flex justify-center gap-3">
          <Button outline onClick={() => handleClose()} disabled={success || saving}>
            Cancel
          </Button>
          {success ? (
            <FinishButton className="flex-1 center md:flex-initial" onClick={() => handleClose()}>
              Done
            </FinishButton>
          ) : (
            <Button
              type="submit"
              disabled={saving}
              onClick={() => handleSave({ body: giftMessageBody, from: giftMessageFrom })}
            >
              Save
            </Button>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default EditGiftMessageModal
