import { FC, PropsWithChildren, ReactElement, useMemo, useState } from 'react'
import Skeleton from 'react-loading-skeleton'

import Alert from 'src/components/01-atoms/Alert'
import Button from 'src/components/01-atoms/Button'
import Container from 'src/components/01-atoms/Container'
import Stepper, { IStepperProps } from 'src/components/01-atoms/Stepper'
import PrintHeader from 'src/components/03-organisms/PrintHeader'

import useAppParams from 'src/utils/hooks/useAppParams'
import useGetManifestPackages from 'src/utils/hooks/useGetManifestPackages'
import useMarkLabelsDiscarded from 'src/utils/hooks/useMarkLabelsDiscarded'
import usePrintingParams from 'src/utils/hooks/usePrintingParams'
import MDashHead from 'src/pages/elements/MDashHead'

import { IShippingLabelStatus } from 'src/graphql/types'
import PackagesContext from 'src/contexts/PackagesContext'
import { IAlertListProps } from 'src/components/02-molecules/AlertList'

interface IDiscardLabelsWrapperProps {
  /**
   * The title to show for the page.
   */
  pageTitle: string

  /**
   * The label print status to use to pull the labels.
   */
  labelPrintStatus?: IShippingLabelStatus

  /**
   * The steps to show on the stepper component.
   */
  stepperSteps?: IStepperProps['steps']

  /**
   * The button text and action to take after discarding labels.
   */
  continueButton?: {
    label: string
    action: CallableFunction
  }

  /**
   * Additional buttons to add to the DOM in addition to or in place of the continue button.
   */
  additionalButtons?: ReactElement
}

const DiscardLabelsWrapper: FC<PropsWithChildren<IDiscardLabelsWrapperProps>> = ({
  pageTitle,
  labelPrintStatus,
  stepperSteps,
  continueButton,
  additionalButtons,
  children,
}) => {
  const { toShipOn, facilityId, facilityType, makeLinkUrls, packageSort, withFacilityParams } =
    useAppParams()
  const { packageIds, labelPrintStatus: labelPrintStatusFromUrl } = usePrintingParams({
    defaultLabelPrintStatusFilter: 'printed',
  })
  const [ markDiscarded ] = useMarkLabelsDiscarded()
  const [ alerts, setAlerts ] = useState<IAlertListProps['alerts']>()
  const [{ error, data, fetching }] = useGetManifestPackages({
    variables: {
      labelPrintStatus: labelPrintStatus ?? labelPrintStatusFromUrl,
      packageIds,
      facilityId,
      facilityType,
      limit: packageIds.length || 1000,
      ...packageSort,
    },
  })

  const contextValue = useMemo(
    () => ({ packages: data?.manifestPackages.packages?.collection! }),
    [ data ]
  )

  return (
    <PackagesContext.Provider value={contextValue}>
      <MDashHead pageTitle={pageTitle} />
      <PrintHeader
        backLink={{
          pathname: makeLinkUrls().manifest,
          search: withFacilityParams(),
        }}
        pageTitle={pageTitle}
        shipDate={toShipOn}
        totalOrders={data?.manifestPackages.packages?.metadata.totalCount ?? packageIds.length}
        alerts={alerts}
      >
        {continueButton && (
          <Button
            onClick={() => {
              setAlerts( undefined )
              markDiscarded({
                packageIds: data?.manifestPackages.packages?.collection.map(( x ) => x.id ) ?? [],
              }).then(( result ) => {
                const commError = result.error?.message
                if ( commError ) {
                  setAlerts([ 'Something went wrong while trying to discard labels.' ])
                  return
                }

                const errors = result.data?.discardPackagesShippingLabels?.errors
                if ( errors && errors.length > 0 ) {
                  setAlerts( errors )
                  return
                }
                const packageIdsToReprint =
                  result.data?.discardPackagesShippingLabels?.packages?.map(( x ) => x.id )
                continueButton.action( packageIdsToReprint?.map(( x ) => `id=${x}` ).join( '&' ))
              })
            }}
            disabled={fetching}
            className="center"
          >
            {continueButton.label}
          </Button>
        )}
        {additionalButtons}
      </PrintHeader>
      <Container className="p-8">
        {error && <Alert type="error">{error.message}</Alert>}
        {stepperSteps && <Stepper size="large" className="mb-4" steps={stepperSteps} />}
        {data?.manifestPackages.packages?.metadata.totalCount === 0 && !fetching && (
          <Alert type="info">No discardable shipping labels available for given package IDs.</Alert>
        )}
        {fetching && !data ? <Skeleton width="100%" height="50vh" /> : children}
      </Container>
    </PackagesContext.Provider>
  )
}

export default DiscardLabelsWrapper
