import { FC, PropsWithChildren } from 'react'
import { AccordionMultipleProps, Root as Accordion } from '@radix-ui/react-accordion'
import classNames from 'classnames'
import ProductSelectorTag from 'src/components/01-atoms/ProductSelectorTag'

interface IFilterAccordionProps extends Omit<AccordionMultipleProps, 'type'> {
  /**
   * The title of the accordion and appears next to the toggle.
   */
  title: string

  /**
   * Function to call when resetting the filter selections.
   */
  handleReset?: CallableFunction | false

  /**
   * Whether or not the reset button should show on the accordion.
   */
  showResetButton?: boolean

  /**
   * A list of which filters should appear open.
   */
  openFilters?: string[]
}

const FilterAccordion: FC<PropsWithChildren<IFilterAccordionProps>> = ({
  title,
  handleReset = () => {},
  showResetButton = false,
  openFilters = [],
  children,
  ...accordionProps
}) => (
  <Accordion
    {...accordionProps}
    type="multiple"
    defaultValue={accordionProps.disabled ? [] : openFilters}
    className={classNames(
      { 'opacity-50': accordionProps.disabled },
      'mb-3 rounded border-2 border-solid border-gb-gray-400'
    )}
  >
    <div className="flex justify-between p-4 pb-2 bg-gb-gray-100">
      <h3 className="font-bold text-gb-gray-900 mb-0">{title}</h3>
      {showResetButton && handleReset && (
        <div className="-mb-2 -mt-1">
          <ProductSelectorTag
            text="Clear"
            showIcon
            onClick={() => handleReset()}
            data-testid="reset-all"
          />
        </div>
      )}
    </div>
    <div>{children}</div>
  </Accordion>
)

export default FilterAccordion
