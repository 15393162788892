import {
  FC,
  PropsWithChildren,
  ButtonHTMLAttributes,
  useMemo,
  ElementType,
  forwardRef,
} from 'react'
import classNames from 'classnames'
import { Link, LinkProps } from 'react-router-dom'

type ButtonVariant = 'primary' | 'secondary' | 'success' | 'alert' | 'clear'
type ButtonSize = 'small' | 'default'

export interface IProps {
  outline?: boolean
  variant?: ButtonVariant
  size?: ButtonSize
  selected?: boolean
}

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, IProps {}
interface ILinkProps extends LinkProps, IProps {
  disabled?: boolean
}

export type ButtonProps = IButtonProps | ILinkProps

const Button: FC<PropsWithChildren<ButtonProps>> = forwardRef<HTMLElement, ButtonProps>(
  (
    {
      outline = false,
      variant = 'primary',
      size = 'default',
      selected = false,
      className,
      children,
      ...props
    },
    ref
  ) => {
    const Element = useMemo(
      () => ( 'to' in props && !props.disabled ? Link : 'button' ),
      [ props ]
    ) as ElementType

    return (
      <Element
        {...props}
        ref={ref}
        className={classNames(
          {
            'bg-white border border-solid disabled:hover:bg-white hover:text-white': outline,
            'text-white': !outline && variant !== 'clear',
            'bg-gb-blue-600 hover:bg-gb-blue-800 disabled:bg-gb-blue-600/50':
              !outline && variant === 'primary',
            'border-gb-blue-600 text-gb-blue-600 hover:bg-gb-blue-600  disabled:border-gb-blue-600/50 disabled:text-gb-blue-600/50 ':
              outline && variant === 'primary',
            'bg-gb-gray-800 hover:bg-gb-gray-900 disabled:bg-gb-gray-800/50':
              !outline && variant === 'secondary',
            'border-gb-gray-400 text-gb-gray-900 hover:bg-gb-gray-400  disabled:border-gb-gray-400/50 disabled:text-gb-gray-900/50 hover:text-gb-gray-900':
              outline && variant === 'secondary',
            'bg-red-600 hover:bg-red-800 disabled:bg-red-600/50': !outline && variant === 'alert',
            'border-red-600 text-red-600 hover:bg-red-600  disabled:border-red-600/50 disabled:text-red-600/50 ':
              outline && variant === 'alert',
            'text-gb-blue-600 hover:underline disabled:text-gb-blue-600/50':
              !outline && variant === 'clear',
            'bg-gb-blue-100': outline && variant === 'primary' && selected,
            'bg-gb-gray-400': outline && variant === 'secondary' && selected,
            'bg-green-600 hover:bg-green-800 disabled:bg-green-600/50':
              !outline && variant === 'success',
            'border-green-600 text-green-600 hover:bg-green-600 disabled:border-green-600/50 disabled:text-green-600/50':
              outline && variant === 'success',
            'bg-green-100': outline && variant === 'success' && selected,
            'text-sm py-2 px-4': size === 'default',
            'text-xs py-1 px-4': size === 'small',
          },

          'flex items-center rounded-sm font-medium disabled:cursor-not-allowed transition-colors',
          className
        )}
      >
        {children}
      </Element>
    )
  }
)

export default Button
