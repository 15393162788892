import { loader } from 'graphql.macro'
import {
  IGetManifestFacilityAggregatesQuery,
  IGetManifestFacilityAggregatesQueryVariables,
} from 'src/graphql/queries/getManifestFacilityAggregates.types'
import { useQuery } from 'urql'
import { IGetAggregateInput } from '../types/IGetAggregateInput'
import useAppParams from './useAppParams'

const getManifestFacilityAggregates = loader(
  'src/graphql/queries/getManifestFacilityAggregates.graphql'
)

export const useGetManifestFacilityAggregate = ({ variables }: IGetAggregateInput ) => {
  const { mdashAccountId, isMultiPickUpLocation, isMultiShippingFacility } = useAppParams()
  const [{ error, data, fetching }, refetch ] = useQuery<
    IGetManifestFacilityAggregatesQuery,
    IGetManifestFacilityAggregatesQueryVariables
  >({
    query: getManifestFacilityAggregates,
    variables,
    requestPolicy: 'cache-and-network',
    pause: Number( mdashAccountId || 0 ) < 1 || !( isMultiPickUpLocation || isMultiShippingFacility ),
  })

  return { error, data, fetching, refetch }
}
