import { FC } from 'react'
import { max } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareUser } from '@fortawesome/pro-regular-svg-icons'

import SectionCard from 'src/components/01-atoms/SectionCard'
import DetailsList from 'src/components/02-molecules/DetailsList'

import { centsToUSD } from 'src/utils/helpers/currencies'
import { valueOrSkeleton } from 'src/utils/helpers/loadingState'

export interface ICustomerSummaryBlockProps {
  /**
   * The number of total orders the customer has placed.
   */
  numTotalOrders: number

  /**
   * The number of gift orders the customer has placed.
   */
  numGiftOrders: number

  /**
   * The total amount the customer has spent in cents.
   */
  totalAmountSpentInCents: number

  /**
   * The average order value in cents.
   */
  averageOrderValueInCents: number

  /**
   * The total number of reward points the customer has.
   */
  numRewardPoints: number
}

const CustomerSummaryBlock: FC<ICustomerSummaryBlockProps> = ({
  numTotalOrders,
  numGiftOrders,
  totalAmountSpentInCents,
  averageOrderValueInCents,
  numRewardPoints,
}) => (
  <SectionCard
    size="medium"
    data-testid="customer-summary-block"
    title={
      <>
        <FontAwesomeIcon icon={faSquareUser} className="mr-2" />
        Summary
      </>
    }
  >
    <DetailsList
      variant="bordered"
      testIdPrefix="customer-summary-block"
      list={[
        {
          label: 'Total Orders',
          value: valueOrSkeleton( numTotalOrders && max([ 0, numTotalOrders ])),
        },
        { label: 'Gift Orders', value: valueOrSkeleton( numGiftOrders && max([ 0, numGiftOrders ])) },
        {
          label: 'Amount Spent',
          value: valueOrSkeleton( totalAmountSpentInCents && centsToUSD( totalAmountSpentInCents )),
        },
        {
          label: 'Avg. Order Value',
          value: valueOrSkeleton( averageOrderValueInCents && centsToUSD( averageOrderValueInCents )),
        },
        {
          label: 'Reward Points',
          value: valueOrSkeleton( numRewardPoints && max([ 0, numRewardPoints ])?.toLocaleString()),
        },
      ]}
    />
  </SectionCard>
)

export default CustomerSummaryBlock
