import { FC, RefObject, useRef, useEffect, useCallback, useState, forwardRef } from 'react'
import classNames from 'classnames'
import { isEmpty } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faCircleXmark } from '@fortawesome/pro-duotone-svg-icons'
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons'

import TextInput, { ITextInputProps } from 'src/components/01-atoms/TextInput'
import Button from 'src/components/01-atoms/Button'

export interface ISimpleSearchProps extends Omit<ITextInputProps, 'type'> {
  disabled?: boolean
  handleSubmit?: CallableFunction
  handleClear?: CallableFunction
  handleChange?: CallableFunction
  handleFocus?: CallableFunction
  handleBlur?: CallableFunction
  handleDropdownClick?: CallableFunction
  showSearchButton?: boolean
  showDropdownButton?: boolean
  showSpyGlass?: boolean
  forceUpdateValue?: string
  testId?: string
  className?: string

  /**
   * Force the clear button to show or not.
   */
  useClearButton?: boolean

  /**
   * Whether or not this simple search is its own form.
   */
  asStandaloneForm?: boolean
}

const SimpleSearch: FC<ISimpleSearchProps> = forwardRef<HTMLInputElement, ISimpleSearchProps>(
  (
    {
      disabled,
      handleSubmit = () => {},
      handleClear = () => {},
      handleChange = () => {},
      handleFocus = () => {},
      handleBlur = () => {},
      handleDropdownClick = () => {},
      defaultValue = '',
      forceUpdateValue = '',
      placeholder = 'Search orders',
      showDropdownButton = false,
      showSearchButton = true,
      showSpyGlass = false,
      testId = 'table-search-form',
      className,
      useClearButton = true,
      asStandaloneForm = false,
      ...textInputProps
    },
    ref
  ) => {
    const [ showClearButton, setShowClearButton ] = useState( !!defaultValue && useClearButton )
    const inputRef = useRef() as RefObject<HTMLInputElement>

    const clearSearch = useCallback(() => {
      if ( !inputRef.current || inputRef.current.value === defaultValue ) return
      inputRef.current.value = ''
      setShowClearButton( false )
    }, [ inputRef, defaultValue ])

    useEffect(() => {
      clearSearch()
    }, [ defaultValue, clearSearch ])

    useEffect(() => {
      if ( inputRef.current ) {
        inputRef.current.value = forceUpdateValue.trim()
      }
    }, [ forceUpdateValue ])

    const Element = asStandaloneForm ? 'form' : 'fieldset'

    return (
      <Element
        onSubmit={( e ) => {
          e.preventDefault()
          handleSubmit( inputRef?.current?.value )
        }}
        data-testid={testId}
        className={`relative text-gb-gray-900 ${className}`}
      >
        {showSpyGlass && (
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="absolute z-10 pt-2.5 pl-2.5 text-lg text-gb-gray-800"
          />
        )}
        <TextInput
          type="text"
          className={classNames(
            'relative z-0 border-2 pr-10 border-gb-gray-400 bg-white placeholder:text-gb-gray-800 disabled:placeholder-shown:opacity-50',
            { 'pl-8': showSpyGlass },
            { 'pr-20': showSearchButton }
          )}
          onChange={( e ) => {
            setShowClearButton( useClearButton && !isEmpty( e.currentTarget.value ))
            handleChange( e.currentTarget.value )
          }}
          onBlur={() => handleBlur()}
          onFocus={() => handleFocus()}
          ref={ref || inputRef}
          defaultValue={disabled ? '' : defaultValue}
          disabled={disabled}
          placeholder={placeholder}
          {...textInputProps}
        />
        <div
          className={classNames(
            'absolute z-10 inset-y-0 flex gap-x-2',
            showSearchButton ? 'right-0' : 'right-2'
          )}
        >
          {showClearButton && (
            <button
              type="button"
              name="clear-search-query"
              className="disabled:opacity-50"
              disabled={disabled}
              onClick={() => {
                clearSearch()
                handleClear()
              }}
            >
              <FontAwesomeIcon icon={faCircleXmark} />
              <div className="sr-only">Clear search text</div>
            </button>
          )}
          {showSearchButton && (
            <Button
              type={asStandaloneForm ? 'submit' : 'button'}
              data-testid="search-button"
              onClick={!asStandaloneForm ? () => handleSubmit( inputRef?.current?.value ) : undefined}
              disabled={disabled}
              className="flex center-v rounded-l-none rounded-r disabled:opacity-50"
            >
              <span className="sr-only">Search Orders</span>
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </Button>
          )}
          {showDropdownButton && (
            <Button
              data-testid="search-dropdown-button"
              className="flex -mr-5"
              variant="clear"
              onClick={() => handleDropdownClick()}
              disabled={disabled}
            >
              <span className="sr-only">Open Search Dropdown</span>
              <FontAwesomeIcon
                icon={faChevronDown}
                className="text-md px-3 py-3 text-gb-gray-800 border-l border-gb-gray-400 text-center"
              />
            </Button>
          )}
        </div>
      </Element>
    )
  }
)

export default SimpleSearch
