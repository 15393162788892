import { ChangeEvent, ChangeEventHandler, FC, ReactElement, RefObject } from 'react'

import ChoiceInput from 'src/components/01-atoms/ChoiceInput'
import Table, { ITableProps, TableHeader } from 'src/components/02-molecules/Table'
import PackageListTableRow, {
  ICorporateOrder,
  IFacility,
  IOrderType,
  IPurchaserInfo,
} from 'src/components/02-molecules/PackageListTableRow'

import { getToday } from 'src/utils/helpers/date'
import { PackageListPackage } from 'src/utils/types/PackageListPackage'
import { ILineItemCustomerChoice, IManifestPackageSort } from 'src/graphql/types'

import './styles.css'
import useAppParams from 'src/utils/hooks/useAppParams'
import { isBefore } from 'date-fns'

interface IPackageListTableProps
  extends Pick<ITableProps, 'sortValue' | 'sortAction' | 'tableFooterChildren'> {
  packages: PackageListPackage[]
  orderTypes?: ( IOrderType & { id: string })[] | null
  corporateOrders?: ( ICorporateOrder & { id: string })[] | null
  productOptions?: ILineItemCustomerChoice[]
  purchaserInfo?: IPurchaserInfo[]
  facilities?: IFacility[] | null
  fetchingOrderTypes?: boolean
  fetchingCorporateOrders?: boolean
  fetchingProductOptions?: boolean
  fetchingPurchaserInfo?: boolean
  fetchingFacilities?: boolean
  packagesAreSelectable?: boolean
  selectedPackages?: Set<string | null>
  selectAllRef?: RefObject<HTMLInputElement>
  selectAllHandleChange?: ChangeEventHandler<HTMLInputElement>
  selectPackageChange?: CallableFunction
  tableFooterChildren?: ReactElement
  showPackageActionLinks?: boolean
  isAbstractFacility?: boolean
  canAssignLocation?: boolean
}

const PackageListTable: FC<IPackageListTableProps> = ({
  packages,
  orderTypes,
  corporateOrders,
  productOptions,
  purchaserInfo,
  facilities,
  fetchingOrderTypes = false,
  fetchingCorporateOrders = false,
  fetchingProductOptions = false,
  fetchingPurchaserInfo = false,
  fetchingFacilities = false,
  packagesAreSelectable = false,
  selectedPackages = null,
  selectAllRef = null,
  selectAllHandleChange = () => {},
  selectPackageChange = () => {},
  showPackageActionLinks = true,
  isAbstractFacility = false,
  canAssignLocation = false,
  ...tableProps
}) => {
  const {
    isAdmin,
    mdashAccountPermissions,
    makeLinkUrls,
    canUseMultiFacility,
    facilityId,
    withFacilityParams,
    toShipOn,
  } = useAppParams()
  const { canQueryPurchaserInfo } = mdashAccountPermissions || {}
  const headers: TableHeader[] = [
    { name: 'ID', sortId: IManifestPackageSort.PACKAGE_ID },
    { name: 'Products', sortId: IManifestPackageSort.PRODUCT },
    { name: 'Recipient', sortId: IManifestPackageSort.RECIPIENT },
    { name: 'Carrier Speed', sortId: IManifestPackageSort.CARRIER_SPEED },
    { name: 'Status' },
  ]

  const columnWidths = [
    { className: 'md:w-1/6' },
    {},
    { className: 'md:w-1/6' },
    { className: 'md:w-1/8 lg:w-1/6' },
    { className: 'md:w-1/5' },
  ]

  if ( packagesAreSelectable ) {
    headers.unshift({
      name: 'Select All',
      element: (
        <div data-testid="action-select-all">
          <ChoiceInput
            labelText="Select All"
            ref={selectAllRef}
            onChange={selectAllHandleChange}
            hideLabel
          />
        </div>
      ),
    })
    columnWidths.unshift({ className: 'md:w-10' })
  }

  return (
    <Table
      tableClasses="package-list-table"
      data-testid="package-list-table"
      borderRule="surround"
      columnWidths={columnWidths}
      headers={headers}
      {...tableProps}
    >
      {packages &&
        packages.map(( pkg ) => {
          if ( !pkg ) return null

          const orderType = orderTypes?.find(( x ) => x.id === pkg.id ) || ({} as IOrderType )
          const corporateOrder = corporateOrders?.find(( x ) => x.id === pkg.id )
          const options = productOptions?.filter(( x ) => x.suborderId === pkg.id )
          const facility = facilities?.find(( x ) => x.id === pkg.id )
          const purchaser = canQueryPurchaserInfo
            ? purchaserInfo?.find(( x ) => x.id === pkg.id )
            : undefined
          const facilityParams = withFacilityParams()
          const search = `?id=${pkg.id}${facilityParams && `&${facilityParams}`}`

          return (
            <PackageListTableRow
              key={`package-${pkg.id}`}
              selected={selectedPackages?.has( pkg.id )}
              selectPackageChange={( e: ChangeEvent<HTMLInputElement> ) =>
                selectPackageChange( e, pkg.id )
              }
              productOptions={options}
              fetchingOrderTypes={fetchingOrderTypes}
              fetchingCorporateOrders={fetchingCorporateOrders}
              fetchingProductOptions={fetchingProductOptions}
              fetchingPurchaserInfo={fetchingPurchaserInfo}
              fetchingFacilities={fetchingFacilities}
              canAssignLocation={canAssignLocation}
              isSelectable={packagesAreSelectable}
              isAbstractFacility={isAbstractFacility}
              package={{
                ...pkg,
                isCorporateOrder: orderType.isCorporateOrder,
                isInStoreOrder: orderType.isInStoreOrder,
                isReshipment: orderType.isReshipment,
                isSubscription: orderType.isSubscription,
                isWhitelabel: orderType.isWhitelabel,
                corporateOrderCompanyName: corporateOrder?.corporateOrderCompanyName,
                purchaserName: purchaser?.purchaserName,
                purchaserEmail: purchaser?.purchaserEmail,
                purchaserPhone: purchaser?.purchaserPhone,
                shippingFacility: facility?.shippingFacility,
                pickUpLocation: facility?.pickUpLocation,
              }}
              packageLink={{
                pathname: makeLinkUrls().packageDetail( pkg.id ),
                search: facilityParams,
              }}
              printLink={{
                pathname: makeLinkUrls().printLabels,
                search,
              }}
              reprintLink={{
                pathname: makeLinkUrls().reprintLabels,
                search,
              }}
              discardLink={{
                pathname: makeLinkUrls().discardLabels,
                search,
              }}
              showActionLink={showPackageActionLinks}
              showLinkToGbmc={isAdmin}
              showPurchaserInfo={!!canQueryPurchaserInfo}
              showFacilityName={canUseMultiFacility && !facilityId}
              trackingLink={
                isBefore( toShipOn, getToday()) ? pkg?.currentShippingLabel?.trackingLink : undefined
              }
            />
          )
        })}
    </Table>
  )
}

export default PackageListTable
