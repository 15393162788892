import { FC, PropsWithChildren } from 'react'
import { Link, To } from 'react-router-dom'
import { capitalize } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons'

import PackageTag, { PackageType } from 'src/components/01-atoms/PackageTag'
import { gbmcOrderLink, gbmcSuborderLink } from 'src/utils/gbmcLink'
import { longFormatDayAndDate } from 'src/utils/helpers/date'

interface IDetailPageHeaderProps {
  /**
   * The ID of the package or order.
   */
  id: string

  /**
   * The type of detail page.
   */
  type: 'package' | 'order'

  /**
   * The link to go back to the previous page.
   */
  backLink: {
    url: To
    label?: string
  }

  /**
   * Whether the package or order is canceled.
   */
  isCanceled?: boolean

  /**
   * Whether to show a link to GBMC.
   */
  showLinkToGbmc?: boolean

  /**
   * The date the package or order was placed.
   */
  placedOn: Date
}

const DetailPageHeader: FC<PropsWithChildren<IDetailPageHeaderProps>> = ({
  id,
  type,
  backLink,
  isCanceled = false,
  showLinkToGbmc,
  placedOn,
  children,
}) => {
  const isOrder = type === 'order'

  const gbmcLink = type === 'package' ? gbmcSuborderLink( id ) : gbmcOrderLink( id )

  return (
    <>
      <Link
        className="text-gb-gray-800 font-semibold text-xs mb-1"
        to={backLink.url}
        data-testid="back-link"
      >
        <FontAwesomeIcon icon={faAngleLeft} /> {backLink.label ?? 'Back to Orders'}
      </Link>
      <div
        className="mb-6 flex flex-col gap-y-4 md:flex-row md:justify-between"
        data-testid={`${isOrder ? 'order' : 'package'}-detail-header`}
      >
        <div>
          <div className="flex flex-wrap items-center gap-x-2">
            <h1 className="text-2xl font-semibold">
              {capitalize( type )} {id}
            </h1>
            {isCanceled && (
              <PackageTag
                packageType={PackageType.CANCELED}
                label={isOrder ? 'All Packages Canceled' : undefined}
              />
            )}
            {showLinkToGbmc && (
              <a
                className="font-semibold text-sm text-gb-blue-600 hover:text-gb-blue-800"
                data-testid="gbmc-link"
                href={gbmcLink}
              >
                View in GBMC
              </a>
            )}
          </div>
          <h2 className="text-lg">Placed On: {longFormatDayAndDate( placedOn )}</h2>
        </div>
        {children && <div className="flex gap-2 md:items-start">{children}</div>}
      </div>
    </>
  )
}

export default DetailPageHeader
