import { FC, PropsWithChildren, ReactElement, useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import UserContext from 'src/contexts/UserContext'
import useAppParams from 'src/utils/hooks/useAppParams'

import { IMDashAccountPermissionSet, IMerchantUserPermissionSet } from 'src/graphql/types'

interface IProtectedProps {
  /**
   * The keys to look for in the user's permissions object.
   */
  userPermissions?: Array<keyof IMerchantUserPermissionSet>

  /**
   * The keys to look for in the account's permissions object.
   */
  accountPermissions?: Array<keyof IMDashAccountPermissionSet>
}

/**
 * Protect certain markup or a whole page by the user or MDash Account permissions.
 */
const ProtectedByPermission: FC<PropsWithChildren<IProtectedProps>> = ({
  userPermissions = [],
  accountPermissions = [],
  children,
}) => {
  const { makeLinkUrls } = useAppParams()
  const { identity } = useContext( UserContext )

  if ( !identity || !identity.userId ) return null

  /**
   * These are specifically permissions for the account, so being an admin here will not
   * automatically give permission.
   * See Gloria's explanation here (her responses to the linked comment):
   * https://github.com/Goldbely/mdashx-ui/pull/1378#discussion_r1164410964
   */
  const accountHasPermission =
    accountPermissions.length > 0 &&
    accountPermissions.some(( permission ) => identity?.mdashAccountPermissions?.[permission])

  /**
   * Admins have all user permissions.
   */
  const userHasPermission =
    identity.isAdmin ||
    ( userPermissions.length > 0 &&
      userPermissions.some(( permission ) => identity?.merchantUserPermissions?.[permission]))

  const hasPermission = userHasPermission || accountHasPermission

  if ( !children ) {
    if ( !hasPermission ) {
      return <Navigate to={makeLinkUrls().manifestBase} replace />
    }
    return <Outlet />
  }

  if ( !hasPermission ) {
    return null
  }
  return children as ReactElement
}

export default ProtectedByPermission
