import { FC, PropsWithChildren } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleCheck as duoFaCircleCheck,
  faTriangleExclamation as duoFaTriangleExclamation,
  faCircleInfo as duoFaCircleInfo,
  faHexagonExclamation,
} from '@fortawesome/pro-duotone-svg-icons'

export type AlertType = 'success' | 'info' | 'warning' | 'error' | 'alert'
export type AlertSize = 'default' | 'xl'

export interface IAlertProps {
  /**
   * The type of alert to show. This also affects border, text, and icon colors.
   */
  type?: AlertType

  /**
   * The size of alert to show. This also affects border, text, and icon colors.
   */
  size?: AlertSize

  /**
   * The title for the xl alert.
   */
  titleText?: string

  /**
   * Extra classes to apply to the alert wrapper div.
   */
  className?: string | string[]
}

const Alert: FC<PropsWithChildren<IAlertProps>> = ({
  type = 'info',
  size = 'default',
  titleText,
  className = '',
  children,
}) => {
  const iconMap = {
    success: duoFaCircleCheck,
    info: duoFaCircleInfo,
    warning: duoFaTriangleExclamation,
    error: faHexagonExclamation,
    alert: faHexagonExclamation,
  }

  return (
    <div
      className={classNames(
        'rounded px-4 flex text-sm',
        {
          'bg-green-700 text-white': type === 'success',
          'bg-blue-600 text-white': type === 'info',
          'bg-amber-400 text-gray-900': type === 'warning',
          'bg-red-600 text-white': type === 'error' || type === 'alert',
          'gap-x-2 py-2': size === 'default',
          'gap-x-4 py-5': size === 'xl',
        },
        className
      )}
    >
      <FontAwesomeIcon
        icon={iconMap[type]}
        className={classNames({
          'text-5xl': size === 'xl',
          'text-lg': size === 'default',
        })}
      />
      <div>
        {titleText && size === 'xl' && <div className="text-2xl font-semibold">{titleText}</div>}
        {children}
      </div>
    </div>
  )
}

export default Alert
