import { faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'
import { useLocalstorageState } from 'rooks'

interface IResultsPickerProps {
  disabled?: boolean
}

const ResultsPicker: FC<IResultsPickerProps> = ({ disabled }) => {
  const [ numPerPage, setNumPerPage ] = useLocalstorageState( 'mdx-manifest-num-per-page', 100 )
  const options = [ 25, 100, 500, 1000 ]

  return (
    <label htmlFor="results-picker">
      <span className="sr-only md:not-sr-only">
        <span className="inline-block text-xs mr-2">Results per page</span>
      </span>
      <div className="inline-block relative border-solid border-gb-gray-400 border-2 text-gb-gray-800 bg-white rounded-sm">
        <select
          id="results-picker"
          disabled={disabled}
          value={numPerPage}
          onChange={( e ) => setNumPerPage( Number( e.target.value ))}
          className="appearance-none bg-transparent relative z-20 text-sm h-7 pl-2 pr-5 min-w-12"
        >
          {options.map(( value ) => (
            <option key={`${value}-per-page`} value={value}>
              {value}
            </option>
          ))}
        </select>
        <div className="absolute inset-y-0 right-0 h-full w-5 center-v z-10">
          <FontAwesomeIcon icon={faCaretDown} className="text-gb-gray-700" />
        </div>
      </div>
    </label>
  )
}

export default ResultsPicker
