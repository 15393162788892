import { FC, PropsWithChildren } from 'react'
import classNames from 'classnames'

export enum Color {
  PINK = 'Pink',
  RED = 'Red',
  ORANGE = 'Orange',
  AMBER = 'Amber',
  GREEN = 'Green',
  EMERALD = 'Emerald',
  BLUE = 'Blue',
  INDIGO = 'Indigo',
  VIOLET = 'Violet',
  PURPLE = 'Purple',
  SLATE = 'Slate',
  LIGHT_GRAY = 'Light Gray',
  DARK_GRAY = 'Dark Gray',
  BLACK = 'White on Black',
}

interface ITagProps {
  /**
   * The type of package.
   */
  color: Color

  /**
   * The size of the tag.
   */
  size?: 'medium' | 'default'

  /**
   * Whether the tag should use the color as the outline and the text color with a white background.
   */
  outline?: boolean

  /**
   * Additional classes to apply to the tag.
   */
  className?: string

  /**
   * The test id for the tag.
   */
  'data-testid'?: string
}

const Tag: FC<PropsWithChildren<ITagProps>> = ({
  color,
  size = 'default',
  outline = false,
  className,
  children,
  'data-testid': testId,
}) => {
  const colorClasses = {
    [Color.PINK]: outline ? 'border-pink-600 text-pink-600 bg-white' : 'text-pink-600 bg-pink-100',
    [Color.RED]: outline ? 'border-red-600 text-red-600 bg-white' : 'text-red-600 bg-red-100',
    [Color.ORANGE]: outline
      ? 'border-orange-700 text-orange-700 bg-white'
      : 'text-orange-700 bg-orange-100',
    [Color.AMBER]: outline
      ? 'border-yellow-600 text-yellow-600 bg-white'
      : 'text-yellow-600 bg-yellow-100',
    [Color.GREEN]: outline
      ? 'border-green-600 text-green-600 bg-white'
      : 'text-green-600 bg-green-100',
    [Color.EMERALD]: outline
      ? 'border-emerald-700 text-emerald-700 bg-white'
      : 'text-emerald-700 bg-emerald-100',
    [Color.BLUE]: outline
      ? 'border-gb-blue-600 text-gb-blue-600 bg-white'
      : 'text-gb-blue-700 bg-gb-blue-100',
    [Color.INDIGO]: outline
      ? 'border-indigo-600 text-indigo-600 bg-white'
      : 'text-indigo-600 bg-indigo-100',
    [Color.VIOLET]: outline
      ? 'border-violet-600 text-violet-600 bg-white'
      : 'text-violet-600 bg-violet-100',
    [Color.PURPLE]: outline
      ? 'border-purple-600 text-purple-600 bg-white'
      : 'text-purple-600 bg-purple-100',
    [Color.SLATE]: outline
      ? 'border-slate-600 text-slate-600 bg-white'
      : 'text-slate-600 bg-slate-100',
    [Color.LIGHT_GRAY]: 'text-gb-gray-900 bg-gb-gray-100',
    [Color.DARK_GRAY]: outline
      ? 'border-gb-gray-900 text-gb-gray-900 bg-white'
      : 'text-gb-gray-50 bg-gb-gray-900',
    [Color.BLACK]: outline ? 'border-black text-black bg-white' : 'text-white bg-black',
  }

  const sizeClasses = {
    default: 'text-2xs py-0.5 px-1 rounded-sm',
    medium: 'text-xs py-1 px-2 rounded',
  }

  return (
    <div
      data-testid={`tag-${testId}`}
      className={classNames(
        'inline-flex items-center gap-1 leading-tight font-normal border-solid border',
        { 'border-transparent': !outline },
        colorClasses[color],
        sizeClasses[size],
        className
      )}
    >
      {children}
    </div>
  )
}

export default Tag
