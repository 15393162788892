import { loader } from 'graphql.macro'
import { FC, useEffect, useMemo } from 'react'
import {
  IGetBatchPrintActivityQuery,
  IGetBatchPrintActivityQueryVariables,
} from 'src/graphql/queries/getBatchPrintActivity.types'
import { useQuery } from 'urql'
import Skeleton from 'react-loading-skeleton'
import { ResponsiveLine } from '@nivo/line'
import { format, parseISO, startOfWeek } from 'date-fns'
import { addDays, addWeeks, eachWeekOfInterval } from 'date-fns/esm'
import {
  IGetManifestCalendarQuery,
  IGetManifestCalendarQueryVariables,
} from 'src/graphql/queries/getManifestCalendar.types'

const getBatchPrintActivity = loader( 'src/graphql/queries/getBatchPrintActivity.graphql' )
const getManifestCalendar = loader( 'src/graphql/queries/getManifestCalendar.graphql' )

const LabelPrintAggregate: FC = () => {
  const [{ data: labelPrintData }, fetchBatchPrintActivity ] = useQuery<
    IGetBatchPrintActivityQuery,
    IGetBatchPrintActivityQueryVariables
  >({
    query: getBatchPrintActivity,
    requestPolicy: 'cache-and-network',
  })
  const thisMonday = startOfWeek( new Date(), { weekStartsOn: 1 })

  const startOfInterval = useMemo(() => addWeeks( thisMonday, -3 ), [])

  const weekAnchors = useMemo(
    () =>
      eachWeekOfInterval({
        start: startOfInterval,
        end: thisMonday,
      }),
    [ startOfInterval, thisMonday ]
  )

  // just fire 4 requests in parallel to get 4 weeks worth of data
  // since React can't have variable number of hooks
  const [{ data: weekTmin3 }, rxTmin3 ] = useQuery<
    IGetManifestCalendarQuery,
    IGetManifestCalendarQueryVariables
  >({
    query: getManifestCalendar,
    variables: { toShipOn: addDays( weekAnchors[0], 1 ).toISOString() },
    requestPolicy: 'cache-and-network',
  })
  const [{ data: weekTmin2 }, rxTmin2 ] = useQuery<
    IGetManifestCalendarQuery,
    IGetManifestCalendarQueryVariables
  >({
    query: getManifestCalendar,
    variables: { toShipOn: addDays( weekAnchors[1], 1 ).toISOString() },
    requestPolicy: 'cache-and-network',
  })
  const [{ data: weekTmin1 }, rxTmin1 ] = useQuery<
    IGetManifestCalendarQuery,
    IGetManifestCalendarQueryVariables
  >({
    query: getManifestCalendar,
    variables: { toShipOn: addDays( weekAnchors[2], 1 ).toISOString() },
    requestPolicy: 'cache-and-network',
  })
  const [{ data: weekT }, rxT ] = useQuery<
    IGetManifestCalendarQuery,
    IGetManifestCalendarQueryVariables
  >({
    query: getManifestCalendar,
    variables: { toShipOn: addDays( weekAnchors[3], 1 ).toISOString() },
    requestPolicy: 'cache-and-network',
  })

  const weeklyManifestCalendarData = useMemo(
    () =>
      [
        weekTmin3?.manifestCalendar || [],
        weekTmin2?.manifestCalendar || [],
        weekTmin1?.manifestCalendar || [],
        weekT?.manifestCalendar || [],
      ].flat(),
    [
      weekTmin3?.manifestCalendar,
      weekTmin2?.manifestCalendar,
      weekTmin1?.manifestCalendar,
      weekT?.manifestCalendar,
    ]
  )

  useEffect(() => {
    window.addEventListener( 'focus', () => {
      fetchBatchPrintActivity()
      rxTmin3()
      rxTmin2()
      rxTmin1()
      rxT()
    })
  }, [])

  const dataSeries = useMemo(
    () => [
      {
        id: 'Printed via MDX',
        data:
          labelPrintData?.batchPrintActivity.map(( x ) => ({ x: x.toShipOn, y: x.labelsCount })) ||
          [],
      },
      {
        id: 'Non-self-shipping MDash Packages',
        data: weeklyManifestCalendarData.map(( x ) => ({ x: x.toShipOn, y: x.toShipCount })) || [],
      },
    ],
    [ labelPrintData?.batchPrintActivity, weeklyManifestCalendarData ]
  )

  return (
    <div>
      <div className="flex justify-center">
        <div className="h-125 w-175">
          {dataSeries.length > 0 ? (
            <ResponsiveLine
              data={dataSeries}
              margin={{ top: 32, right: 32, bottom: 96, left: 64 }}
              animate
              enableArea
              enablePointLabel
              axisBottom={{
                legend: 'Ship Date',
                legendPosition: 'middle',
                legendOffset: 72,
                tickRotation: -90,
                format: ( value ) => format( parseISO( value ), 'EEEEEE, M/d' ),
              }}
              axisLeft={{
                legend: 'Labels Count',
                legendPosition: 'middle',
                legendOffset: -48,
              }}
              colors={{ scheme: 'set2' }}
              legends={[
                {
                  anchor: 'top-left',
                  direction: 'column',
                  itemWidth: 32,
                  itemHeight: 24,
                  translateX: 16,
                  translateY: 8,
                  symbolShape: 'circle',
                },
              ]}
            />
          ) : (
            <Skeleton className="h-full" />
          )}
        </div>
      </div>
    </div>
  )
}

export default LabelPrintAggregate
