import { loader } from 'graphql.macro'
import {
  ISearchPackagesQuery,
  ISearchPackagesQueryVariables,
} from 'src/graphql/queries/searchPackages.types'
import { useQuery } from 'urql'
import useAppParams from './useAppParams'

interface IProps {
  query: string
  isGlobalSearch?: boolean
}

const searchPackages = loader( 'src/graphql/queries/searchPackages.graphql' )

const useSearchPackages = ({ query, isGlobalSearch }: IProps ) => {
  const { mdashAccountId, isAdmin } = useAppParams()
  const [{ data, fetching }] = useQuery<ISearchPackagesQuery, ISearchPackagesQueryVariables>({
    query: searchPackages,
    variables:
      isAdmin && isGlobalSearch
        ? { query }
        : {
            query,
            mdashAccountId,
          },
    requestPolicy: 'cache-and-network',
    pause: String( query ).length < 3,
  })

  return { data, fetching }
}

export default useSearchPackages
