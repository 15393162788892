import { loader } from 'graphql.macro'
import {
  IGetManifestProductAggregatesQuery,
  IGetManifestProductAggregatesQueryVariables,
} from 'src/graphql/queries/getManifestProductAggregates.types'
import { useQuery } from 'urql'
import { IGetAggregateInput } from '../types/IGetAggregateInput'
import useAppParams from './useAppParams'

const getManifestProductAggregates = loader(
  'src/graphql/queries/getManifestProductAggregates.graphql'
)

export const useGetManifestProductAggregate = ({ variables }: IGetAggregateInput ) => {
  const { mdashAccountId, pauseForFacility } = useAppParams()
  const [{ error, data, fetching }, refetch ] = useQuery<
    IGetManifestProductAggregatesQuery,
    IGetManifestProductAggregatesQueryVariables
  >({
    query: getManifestProductAggregates,
    variables,
    requestPolicy: 'cache-and-network',
    pause: Number( mdashAccountId || 0 ) < 1 || pauseForFacility,
  })

  return { error, data, fetching, refetch }
}
