import { FC, PropsWithChildren } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTruck } from '@fortawesome/pro-regular-svg-icons'

import Fieldset from 'src/components/01-atoms/Fieldset'
import Alert from 'src/components/01-atoms/Alert'

interface IDeliveryOptionsFormWrapperProps {
  hasShippingAddress?: boolean
}

const DeliveryOptionsFormWrapper: FC<PropsWithChildren<IDeliveryOptionsFormWrapperProps>> = ({
  hasShippingAddress = false,
  children,
}) => (
  <div className="flex flex-col gap-y-4">
    {!hasShippingAddress && children && (
      <Alert type="warning">
        Please enter and save a shipping address to view delivery date options.
      </Alert>
    )}
    <Fieldset disabled={!hasShippingAddress && !!children}>
      <div className="flex flex-col gap-y-4">
        {children || (
          <div className="flex gap-x-2 items-center text-gb-gray-800">
            <FontAwesomeIcon icon={faTruck} className="w-6 h-5" />
            <p className="text-sm">Add products to the cart to view delivery options.</p>
          </div>
        )}
      </div>
    </Fieldset>
  </div>
)

export default DeliveryOptionsFormWrapper
