import { loader } from 'graphql.macro'
import {
  IGetFacilityAutoAssignmentQuery,
  IGetFacilityAutoAssignmentQueryVariables,
} from 'src/graphql/queries/getFacilityAutoAssignment.types'
import { useQuery } from 'urql'
import useAppParams from './useAppParams'

const getFacilityAutoAssignment = loader( 'src/graphql/queries/getFacilityAutoAssignment.graphql' )

const useFacilityAutoAssignment = () => {
  const { mdashAccountId } = useAppParams()

  const [{ data, fetching }] = useQuery<
    IGetFacilityAutoAssignmentQuery,
    IGetFacilityAutoAssignmentQueryVariables
  >({
    query: getFacilityAutoAssignment,
    variables: { mdashAccountId },
    pause: Number( mdashAccountId || 0 ) < 1,
  })

  return {
    data,
    fetching,
  }
}

export default useFacilityAutoAssignment
