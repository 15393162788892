import { Dictionary, groupBy } from 'lodash'
import { FC, MouseEvent as RME } from 'react'
import { IGetFacilityAutoAssignmentQuery } from 'src/graphql/queries/getFacilityAutoAssignment.types'
import { IFacilityAutoAssignmentRuleMode } from 'src/graphql/types'
import AutoAssignmentMode from './AutoAssignmentMode'

interface IProps {
  rules: IGetFacilityAutoAssignmentQuery['shippers'][0]['facilityAutoAssignmentRules']
  shipperFacilitiesDictionary: Dictionary<{ name: string | null | undefined; index: number }>
  onClick: ( evt: RME<HTMLElement>, ruleId: string ) => void
}

const AutoAssignmentCell: FC<IProps> = ({ rules, shipperFacilitiesDictionary, onClick }) => {
  const modeGroup = groupBy( rules, ( x ) => x.mode )

  return (
    <div>
      {Object.keys( modeGroup ).map(( mode ) => (
        <AutoAssignmentMode
          key={mode}
          mode={mode as IFacilityAutoAssignmentRuleMode}
          rules={modeGroup[mode]}
          shipperFacilitiesDictionary={shipperFacilitiesDictionary}
          onClick={onClick}
        />
      ))}
    </div>
  )
}

export default AutoAssignmentCell
