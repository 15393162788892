import { Dictionary } from 'lodash'
import { FC, MouseEvent as RME } from 'react'
import { IGetFacilityAutoAssignmentQuery } from 'src/graphql/queries/getFacilityAutoAssignment.types'
import { IFacilityAutoAssignmentRuleMode } from 'src/graphql/types'
import ShapedIcon from 'src/components/01-atoms/ShapedIcon'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { faCircle, faHexagon, faRhombus, faSquare, faBan } from '@fortawesome/pro-solid-svg-icons'
import classNames from 'classnames'

type TPriority = 'productDelivery' | 'delivery' | 'product' | 'default'

const shapes: Record<TPriority, IconDefinition> = {
  productDelivery: faCircle,
  delivery: faHexagon,
  product: faRhombus,
  default: faSquare,
}

const colors = [
  'text-red-500',
  'text-lime-500',
  'text-fuchsia-500',
  'text-amber-500',
  'text-cyan-500',
  'text-indigo-500',
  'text-rose-500',
]

interface IProps {
  mode: IFacilityAutoAssignmentRuleMode
  rules: IGetFacilityAutoAssignmentQuery['shippers'][0]['facilityAutoAssignmentRules']
  shipperFacilitiesDictionary: Dictionary<{ name: string | null | undefined; index: number }>
  onClick: ( evt: RME<HTMLElement>, ruleId: string ) => void
}

const AutoAssignmentMode: FC<IProps> = ({ mode, rules, shipperFacilitiesDictionary, onClick }) => {
  const defaultRules = rules
    .filter(( x ) => !x.deliveryState && !x.productId )
    .map(( x ) => ({ ...x, priority: 'default' as TPriority }))
  const productRules = rules
    .filter(( x ) => !x.deliveryState && x.productId )
    .map(( x ) => ({ ...x, priority: 'product' as TPriority }))
  const deliveryRules = rules
    .filter(( x ) => x.deliveryState && !x.productId )
    .map(( x ) => ({ ...x, priority: 'delivery' as TPriority }))
  const productDeliveryRules = rules
    .filter(( x ) => x.deliveryState && x.productId )
    .map(( x ) => ({ ...x, priority: 'productDelivery' as TPriority }))

  const orderedRules = productDeliveryRules.concat( deliveryRules, productRules, defaultRules ).flat()

  return (
    <div className="flex">
      {orderedRules.map(( rule, i ) => (
        <button
          type="button"
          key={rule.id}
          className="-ml-5 first-of-type:-ml-0"
          style={{ zIndex: 10 - i }}
          onClick={( evt ) => onClick( evt, rule.id )}
        >
          <ShapedIcon
            className="fa-2x"
            baseIcon={{
              icon: shapes[rule.priority],
              className: classNames(
                colors[shipperFacilitiesDictionary[rule.shipperFacilityId].index],
                mode === 'blocklist' && 'saturate-50'
              ),
            }}
            textLayer={{
              text: shipperFacilitiesDictionary[rule.shipperFacilityId].name || '?',
              className: 'text-xs text-white',
            }}
            stackedIcons={
              mode === 'blocklist'
                ? [
                    {
                      icon: faBan,
                      className: 'text-gray-500 rotate-90 scale-125 opacity-50',
                    },
                  ]
                : undefined
            }
          />
        </button>
      ))}
    </div>
  )
}

export default AutoAssignmentMode
