import { FC, useState } from 'react'

import Container from 'src/components/01-atoms/Container'
import ContentEmbed from 'src/components/02-molecules/ContentEmbed'

import MerchantSearch from 'src/pages/elements/MerchantSearch'

import { sigmaUrlGenerator } from './utils'

const SigmaExample: FC<{}> = () => {
  const [ mdashAccountId, setMdashAccountId ] = useState<number>()
  const url = mdashAccountId && sigmaUrlGenerator({ mdashAccountId })

  return (
    <Container isCentered>
      <div className="my-4">
        <MerchantSearch
          setMdashAccountId={setMdashAccountId}
          className="w-[22rem] md:w-[25rem]"
          placeholder="Search for account to view in Sigma"
        />
      </div>
      {url && (
        <ContentEmbed
          src={url}
          title="Metabase Chart"
          className="h-[55rem] md:h-[calc(100vh-260px)]"
        />
      )}
    </Container>
  )
}

export default SigmaExample
