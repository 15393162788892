import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/pro-duotone-svg-icons'
import Skeleton from 'react-loading-skeleton'
import { useFormContext } from 'react-hook-form'
import { camelCase } from 'lodash'

import FilterAccordion from 'src/components/02-molecules/FilterAccordion'
import FilterGroup, { IFilterGroupProps } from 'src/components/02-molecules/FilterGroup'

import ProductSelectorTag from 'src/components/01-atoms/ProductSelectorTag'
import Modal from 'src/components/02-molecules/Modal'
import Button from 'src/components/01-atoms/Button'
import Filters from 'src/components/02-molecules/Filters'

import {
  IManifestAggregate,
  IManifestFilterCategory,
  IManifestPackageSort,
} from 'src/graphql/types'
import useAppParams from 'src/utils/hooks/useAppParams'

import SlideToggle from 'src/components/01-atoms/SlideToggle'
import { useTaxonomy } from './utils/hooks/useTaxonomy'
import useAggregateSearchParams from './utils/hooks/useAggregateSearchParams'

type TFilterGroup = Omit<IFilterGroupProps, 'title'>
interface IAggregatesProps {
  aggregates: IManifestAggregate[]
  toShipOn: Date
  loading?: boolean
  disabled?: boolean
}

const FORCED_LABEL_PRINT_STATUS_SORT_ORDER: { [key: string]: number } = {
  TO_SHIP: 100,
  PRINTED: 110,
  NOT_PRINTED: 120,
  NEED_REPRINT: 130,
  LABEL_ERROR: 140,
  LABEL_PENDING: 150,
  CANCELED: 200,
  NEED_DISCARD: 210,
}

const Aggregates: FC<IAggregatesProps> = ({
  aggregates = [],
  toShipOn,
  loading = false,
  disabled = false,
}) => {
  const { register, watch, getValues, resetField, formState } = useFormContext()
  const { packageSort, setPackageSort, facilityId } = useAppParams()
  useAggregateSearchParams({ loading })

  // disable for now
  // const { aggregatesWithReference } = useReferentialAggregates({ aggregates })
  const [ isOpen, setIsOpen ] = useState( false )
  const {
    selectedTaxonomyIds,
    taxonomyProps,
    resetFields: resetTaxonomy,
    onTaxonomyChange,
  } = useTaxonomy({
    aggregates,
  })

  const resetFields = useCallback(
    ( fieldNames: string[] = []) => {
      fieldNames.forEach(( field ) => resetField( field ))
      resetTaxonomy()
    },
    [ resetField, resetTaxonomy ]
  )

  const getPropsByCategory = ( category: string ): TFilterGroup => {
    const filteredCategories = aggregates.filter(( f ) => f.category === category )
    const formattedFilters = filteredCategories
      .sort(( a, b ) => {
        if (
          a.category === IManifestFilterCategory.LABEL_PRINT_STATUS &&
          b.category === IManifestFilterCategory.LABEL_PRINT_STATUS
        ) {
          return (
            FORCED_LABEL_PRINT_STATUS_SORT_ORDER[String( a.query )] -
            FORCED_LABEL_PRINT_STATUS_SORT_ORDER[String( b.query )]
          )
        }

        return 0
      })
      .map(( s ) => {
        const isIndeterminate = s.value === 0 && aggregates.length > 1
        const label = isIndeterminate ? String( s.label ) : `${s.label} (${s.value})`

        return {
          key: s.query || '',
          indent: s.indent || false,
          label,
          isIndeterminate,
        }
      })

    const filterProps: TFilterGroup = {
      filters: formattedFilters,
      ...register( camelCase( category )),
    }

    return filterProps
  }

  const orderStatusProps = getPropsByCategory( IManifestFilterCategory.LABEL_PRINT_STATUS )
  const orderTypesProps = getPropsByCategory( IManifestFilterCategory.ORDER_TYPES )
  const corporateTypesProps = getPropsByCategory(
    IManifestFilterCategory.CORPORATE_ORDER_COMPANY_NAMES
  )
  const shippingSpeedProps = getPropsByCategory( IManifestFilterCategory.SHIPPING_SPEEDS )
  const recipientCountryProps = getPropsByCategory( IManifestFilterCategory.RECIPIENT_COUNTRIES )
  const recipientStateProps = getPropsByCategory( IManifestFilterCategory.RECIPIENT_STATES )
  const numProductsProps = getPropsByCategory( IManifestFilterCategory.PRODUCT_COUNT_IN_PACKAGES )
  const productIdsProps = getPropsByCategory( IManifestFilterCategory.PRODUCT_IDS )

  const generalFilterFieldNames = useMemo(
    () =>
      [
        orderStatusProps.name,
        orderTypesProps.name,
        corporateTypesProps.name,
        shippingSpeedProps.name,
        recipientCountryProps.name,
        recipientStateProps.name,
      ] as string[],
    [
      orderStatusProps.name,
      orderTypesProps.name,
      corporateTypesProps.name,
      recipientCountryProps.name,
      recipientStateProps.name,
      shippingSpeedProps.name,
    ]
  )
  const productFilterFieldNames = useMemo(
    () => [ numProductsProps.name, productIdsProps.name ] as string[],
    [ numProductsProps.name, productIdsProps.name ]
  )

  const clearAll = () => {
    resetFields( generalFilterFieldNames )
    resetFields( productFilterFieldNames )
    resetField( 'productExclusion' )
  }

  useEffect(() => {
    clearAll()
  }, [ generalFilterFieldNames, productFilterFieldNames, resetFields, toShipOn, facilityId ])

  useEffect(() => {
    resetTaxonomy()
  }, [ toShipOn, resetTaxonomy ])

  watch()

  if ( loading && aggregates.length === 0 )
    return <Skeleton height="100%" className="md:min-h-[456px]" width="100%" />

  const { dirtyFields } = formState

  const hasDirtyFields = ( fieldNames: string[] = []) =>
    new Set( fieldNames.map(( field ) => !!dirtyFields[field])).has( true ) ||
    selectedTaxonomyIds.length > 0 ||
    getValues( 'productExclusion' ) === true

  const corporateOrderBillingNames = aggregates.filter(
    ( x ) => x.category === IManifestFilterCategory.CORPORATE_ORDER_COMPANY_NAMES
  )

  const markup = (
    <>
      <FilterAccordion
        title="General Filters"
        openFilters={[ 'Order Status', 'Order Type', 'Recipient Country', 'Shipping Speed' ]}
        disabled={disabled}
        showResetButton={!disabled && hasDirtyFields( generalFilterFieldNames )}
        handleReset={() => resetFields( generalFilterFieldNames )}
        data-testid="aggregates-general-filters"
      >
        <FilterGroup title="Order Status" {...orderStatusProps} />
        <FilterGroup title="Recipient Country" type="checkbox" {...recipientCountryProps} />
        <FilterGroup title="Order Type" type="checkbox" {...orderTypesProps} />
        {corporateOrderBillingNames.length > 0 && (
          <FilterGroup title="Corporate Order" type="checkbox" {...corporateTypesProps} />
        )}
        <FilterGroup title="Shipping Speed" type="checkbox" {...shippingSpeedProps} />
        <FilterGroup title="Recipient State" type="checkbox" {...recipientStateProps} />
      </FilterAccordion>

      <FilterAccordion
        title="Product Filters"
        openFilters={[ 'Products' ]}
        disabled={disabled}
        showResetButton={!disabled && hasDirtyFields( productFilterFieldNames )}
        handleReset={() => {
          if ( productFilterFieldNames.length > 0 ) resetFields( productFilterFieldNames )
          resetField( 'productExclusion' )
        }}
        data-testid="aggregates-product-filters"
      >
        <FilterGroup title="# of Unique Products" type="checkbox" {...numProductsProps} />
        <FilterGroup title="Products" type="checkbox" {...productIdsProps}>
          <div className="pb-4" data-testid="product-exclusion-filter">
            <SlideToggle labelText="Exclude Selections" {...register( 'productExclusion' )} />
          </div>
          {taxonomyProps && taxonomyProps.length > 0 && (
            <div className="flex flex-wrap mb-2" data-testid="taxonomy-aggregates">
              {taxonomyProps.map(( x ) => (
                <ProductSelectorTag
                  key={x.key}
                  text={x.label}
                  value={x.value}
                  selected={selectedTaxonomyIds.indexOf( x.key ) !== -1}
                  onClick={() => {
                    onTaxonomyChange({ taxonomyId: x.key })
                  }}
                />
              ))}
            </div>
          )}
        </FilterGroup>
      </FilterAccordion>
    </>
  )

  return (
    <div data-testid="aggregates-container">
      <div className="md:hidden">
        <Button
          outline
          onClick={() => setIsOpen( !isOpen )}
          data-testid="mobile-filters-btn"
          className="gap-x-1 px-3 h-9"
        >
          <FontAwesomeIcon
            className="transition-opacity hover:opacity-80 cursor-pointer"
            size="lg"
            icon={faFilter}
          />
          {Object.keys( dirtyFields ).length > 0 && (
            <span className="text-xs">({Object.keys( dirtyFields ).length})</span>
          )}
        </Button>
      </div>
      <Modal
        takeoverOnMobile
        open={isOpen}
        contentClassName="md:hidden"
        overlayClassname="md:hidden"
      >
        <div
          className="text-3xl text-gb-gray-800 flex justify-end items-center pb-4"
          data-testid="aggregates-modal"
        >
          <div className="mr-2 -mb-2">
            {Object.keys( dirtyFields ).length > 0 && (
              <ProductSelectorTag
                text="Clear All"
                showIcon
                onClick={() => clearAll()}
                data-testid="clear-all"
              />
            )}
          </div>
          <Button onClick={() => setIsOpen( false )}>Apply</Button>
        </div>
        <FilterAccordion
          title="Sort by"
          disabled={disabled}
          showResetButton={!disabled && hasDirtyFields( productFilterFieldNames )}
          handleReset={
            productFilterFieldNames.length > 0 && (() => resetFields( productFilterFieldNames ))
          }
          data-testid="aggregates-sort-by"
        >
          <div className="py-3 px-4 text-xs">
            <Filters
              type="radio"
              name="mobile-sort-manifest"
              onChange={( e ) => {
                const sortField = e.currentTarget.value as IManifestPackageSort
                setPackageSort({ sortField, sortAscending: true })
              }}
              defaultValue={packageSort?.sortField}
              filters={[
                { label: 'ID', key: IManifestPackageSort.PACKAGE_ID },
                { label: 'Products', key: IManifestPackageSort.PRODUCT },
                { label: 'Recipient', key: IManifestPackageSort.RECIPIENT },
                { label: 'Carrier Speed', key: IManifestPackageSort.CARRIER_SPEED },
              ]}
            />
          </div>
        </FilterAccordion>
        {markup}
      </Modal>
      <div className="hidden md:block">{markup}</div>
    </div>
  )
}

export default Aggregates
