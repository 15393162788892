import { loader } from 'graphql.macro'
import {
  IGetManifestLabelAggregatesQuery,
  IGetManifestLabelAggregatesQueryVariables,
} from 'src/graphql/queries/getManifestLabelAggregates.types'
import { useQuery } from 'urql'
import { IGetAggregateInput } from '../types/IGetAggregateInput'
import useAppParams from './useAppParams'

const getManifestLabelAggregates = loader( 'src/graphql/queries/getManifestLabelAggregates.graphql' )

export const useGetManifestLabelAggregate = ({ variables }: IGetAggregateInput ) => {
  const { mdashAccountId, pauseForFacility } = useAppParams()
  const [{ error, data, fetching }, refetch ] = useQuery<
    IGetManifestLabelAggregatesQuery,
    IGetManifestLabelAggregatesQueryVariables
  >({
    query: getManifestLabelAggregates,
    variables,
    requestPolicy: 'cache-and-network',
    pause: Number( mdashAccountId || 0 ) < 1 || pauseForFacility,
  })

  return { error, data, fetching, refetch }
}
