import { FC, PropsWithChildren } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import classNames from 'classnames'
import { To } from 'react-router-dom'
import Button from '../Button'

export interface IActionButtonProps {
  /*
   * Text beside the icon.
   */
  actionText?: string

  /*
   * Pass-through classes.
   */
  className?: string

  /*
   * The faIcon IconDefinition used.
   */
  icon?: IconProp

  /*
   * Optional link.
   */
  to?: To
}

const ActionButton: FC<PropsWithChildren<IActionButtonProps>> = ({
  actionText = '',
  icon,
  className,
  children,
  ...props
}) => (
  <Button {...props} className={classNames({ className }, 'text-left' )}>
    <span className="font-normal text-xs sm:text-sm mr-0.5">{children}</span>
    {icon && <FontAwesomeIcon icon={icon} className="text-base sm:mr-1 ml-3" />}
    <span className="hidden sm:inline-block font-bold text-xs ml-0.5 underline">{actionText}</span>
  </Button>
)

export default ActionButton
