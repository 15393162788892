import { addDays, parseISO, startOfWeek } from 'date-fns'
import { useParams } from 'react-router-dom'
import useShippingIssueCalendarPair from './useShippingIssueCalendarPair'

const useWeeklyShippingIssueCalendarPair = () => {
  const { toShipOn } = useParams()
  const currentDate = toShipOn ? parseISO( toShipOn ) : new Date()
  const monday = startOfWeek( currentDate, { weekStartsOn: 1 })

  // react doesn't allow dynamic hooks, so we have to create a hook for each day of the week
  const mondayData = useShippingIssueCalendarPair({ toShipOn: monday })
  const tuesdayData = useShippingIssueCalendarPair({ toShipOn: addDays( monday, 1 ) })
  const wednesdayData = useShippingIssueCalendarPair({ toShipOn: addDays( monday, 2 ) })
  const thursdayData = useShippingIssueCalendarPair({ toShipOn: addDays( monday, 3 ) })
  const fridayData = useShippingIssueCalendarPair({ toShipOn: addDays( monday, 4 ) })
  const saturdayData = useShippingIssueCalendarPair({ toShipOn: addDays( monday, 5 ) })

  return [ mondayData, tuesdayData, wednesdayData, thursdayData, fridayData, saturdayData ]
}

export default useWeeklyShippingIssueCalendarPair
