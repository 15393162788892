import { FC, useContext } from 'react'
import Table from 'src/components/02-molecules/Table'
import SectionCard from 'src/components/01-atoms/SectionCard'
import '../../components/02-molecules/StatementsTable/styles.css'
import IssuesContext from 'src/contexts/IssuesContext'
import Skeleton from 'react-loading-skeleton'
import InlineLink from 'src/components/01-atoms/InlineLink'
import useAppParams from 'src/utils/hooks/useAppParams'

const MissedShipmentsIssues: FC = () => {
  const tableHeaders = [
    { name: 'Package ID' },
    { name: 'Customer Order' },
    { name: 'Missed Ship Date' },
    { name: 'New Ship Date' },
    { name: 'Products' },
    { name: 'Comment' },
  ]

  const columnWidths = [
    {},
    {},
    { className: 'min-w-32' },
    { className: 'min-w-32' },
    { className: 'min-w-32' },
    { className: 'min-w-32' },
    { className: 'lg:w-32' },
  ]

  const { missedShipmentIssues } = useContext( IssuesContext )
  const missedShipments = missedShipmentIssues?.missedShipments
  const numIssues = missedShipmentIssues?.numberOfIssues ?? 0
  const { makeLinkUrls } = useAppParams()

  const tableCellClasses = 'flex gap-x-4 first-of-type:pt-4 pb-4 pl-4 pr-4 lg:p-4 lg:table-cell '
  return (
    <div className="flex grow flex-col">
      <SectionCard className="col-span-12 mb-8">
        {!missedShipments ? (
          <Skeleton className="mt-4.5" height="30vh" />
        ) : (
          <div>
            {numIssues < 1 ? (
              <p data-testid="missed-shipments-empty-state">No missed shipments for this period.</p>
            ) : (
              <Table
                tableClasses="statements"
                headers={tableHeaders}
                borderRule="headers-only"
                columnWidths={columnWidths}
              >
                {missedShipments.map(
                  (
                    { comment, id, missedShipOn, newShipOn, packageId, orderId, productNames },
                    index
                  ) => (
                    <tr
                      key={`missed-shipment-${id}`}
                      data-testid={`missed-shipment-row-${index}`}
                      className="flex flex-col even:bg-gb-gray-50 odd:bg-white lg:table-row"
                    >
                      <td className={tableCellClasses}>
                        <div className="font-bold w-1/3 lg:hidden">{tableHeaders[0].name}</div>
                        <InlineLink to={makeLinkUrls().packageDetail( packageId ?? '' )}>
                          {packageId}
                        </InlineLink>
                      </td>
                      <td className={tableCellClasses}>
                        <div className="font-bold w-1/3 lg:hidden">{tableHeaders[1].name}</div>
                        {orderId}
                      </td>
                      <td className={tableCellClasses}>
                        <div className="font-bold w-1/3 lg:hidden">{tableHeaders[2].name}</div>
                        {missedShipOn}
                      </td>
                      <td className={tableCellClasses}>
                        <div className="font-bold w-1/3 lg:hidden">{tableHeaders[3].name}</div>
                        {newShipOn}
                      </td>
                      <td className={tableCellClasses}>
                        <div className="font-bold w-1/3 lg:hidden">{tableHeaders[4].name}</div>
                        <ul className="text-xs leading-7">
                          {productNames &&
                            productNames.map(( productName ) => (
                              <li key={`productNames-${productName}`}>{productName}</li>
                            ))}
                        </ul>
                      </td>
                      <td className={tableCellClasses}>
                        <div className="font-bold w-1/3 lg:hidden">{tableHeaders[5].name}</div>
                        {comment}
                      </td>
                    </tr>
                  )
                )}
              </Table>
            )}
          </div>
        )}
      </SectionCard>
    </div>
  )
}
export default MissedShipmentsIssues
