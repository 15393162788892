import { kebabCase } from 'lodash'
import { FC } from 'react'

import { IGetCarrierInfoQuery } from 'src/graphql/queries/getCarrierInfo.types'

const CarrierInfoContent: FC<
  IGetCarrierInfoQuery['manifestCarrierInfo'] & { className?: string }
> = ({
  carrierName,
  airReferenceCode,
  groundReferenceCode,
  groundPickupHours,
  airPickupHours,
  airPhoneNumbers,
  groundPhoneNumbers,
  className,
}) => {
  const groundShippingPrefix = carrierName === 'UPS' ? '' : 'Ground:'

  return (
    <div className={className}>
      {( airReferenceCode || groundReferenceCode ) && (
        <div className="mb-4 text-sm">
          <span className="font-bold block">Reference Number</span>
          {airReferenceCode && <span>Air: {airReferenceCode}</span>}
          {airReferenceCode && groundReferenceCode && ' | '}
          {groundReferenceCode && (
            <span>
              {groundShippingPrefix} {groundReferenceCode}
            </span>
          )}
        </div>
      )}
      {( airPickupHours || groundPickupHours ) && (
        <div className="mb-4 text-sm">
          <span className="font-bold block">Pickup Time</span>
          {airPickupHours && <span>Air: {airPickupHours}</span>}
          {airPickupHours && groundPickupHours && ' | '}
          {groundPickupHours && (
            <span>
              {groundShippingPrefix} {groundPickupHours}
            </span>
          )}
        </div>
      )}
      {( !!airPhoneNumbers?.length || !!groundPhoneNumbers?.length ) && (
        <div className="text-sm">
          <span className="font-bold block">Phone Number</span>
          {airPhoneNumbers &&
            airPhoneNumbers.map(( airPhoneNumber ) => (
              <div key={`air-phone-${kebabCase( airPhoneNumber )}`}>Air: {airPhoneNumber}</div>
            ))}
          {groundPhoneNumbers &&
            groundPhoneNumbers.map(( groundPhoneNumber ) => (
              <div key={`ground-phone-${kebabCase( groundPhoneNumber )}`}>
                {groundShippingPrefix} {groundPhoneNumber}
              </div>
            ))}
        </div>
      )}
    </div>
  )
}

export default CarrierInfoContent
