import { FC, HTMLAttributes, PropsWithChildren, ReactElement } from 'react'

import SectionCard from 'src/components/01-atoms/SectionCard'
import Button from 'src/components/01-atoms/Button'
import OrderSummary, { IOrderSummaryProps } from 'src/components/02-molecules/OrderSummary'
import AsymmetricalWithSidebar from 'src/components/04-templates/AsymmetricalWithSidebar'

interface ICartProps {
  /**
   * The name of the merchant.
   */
  merchantName?: string

  /**
   * Field props for use with fields provided by the react-hook-form library.
   */
  promoCodeFieldProps?: HTMLAttributes<HTMLInputElement>

  /**
   * Given order total amounts, in cents.
   */
  orderSummaryTotals?: Omit<IOrderSummaryProps, 'className'>

  /**
   * Whether or not the order has already been completed.
   */
  isCompleted?: boolean

  /**
   * Whether or not the order was placed through Guac.
   */
  isGuacOrder?: boolean

  /**
   * Whether or not the order is currently being submitted.
   */
  isSubmitting?: boolean

  /**
   * Method to call when the "Place Order" button is pressed.
   */
  handleSubmit?: () => void

  /**
   * Whether or not the order is ready to be placed.
   */
  isReadyForCheckout?: boolean

  /**
   * Whether or not data is fetching.
   */
  promoCodeForm?: ReactElement
}

const Cart: FC<PropsWithChildren<ICartProps>> = ({
  merchantName,
  orderSummaryTotals,
  isCompleted = false,
  isGuacOrder = false,
  isSubmitting = false,
  handleSubmit = () => {},
  isReadyForCheckout = false,
  promoCodeForm,
  children,
}) => {
  const sidebar = (
    <SectionCard size="medium" title="Summary" className="lg:sticky lg:top-4">
      <div data-testid="order-summary" className="flex flex-col gap-y-4">
        {!isCompleted && isGuacOrder && promoCodeForm && promoCodeForm}
        <OrderSummary {...orderSummaryTotals} />
        {!isCompleted && isGuacOrder && (
          <Button
            type="button"
            className="center"
            onClick={handleSubmit}
            disabled={isCompleted || !isReadyForCheckout}
            title={
              !isReadyForCheckout ? 'Please make sure all required fields have been completed.' : ''
            }
          >
            Place Order
          </Button>
        )}
      </div>
    </SectionCard>
  )

  return (
    <fieldset disabled={isCompleted || isSubmitting || !isGuacOrder}>
      <AsymmetricalWithSidebar
        sidebar={sidebar}
        pageTitle={`In-Store Order${merchantName ? ` (${merchantName})` : ''}`}
      >
        {children}
      </AsymmetricalWithSidebar>
    </fieldset>
  )
}

export default Cart
