import { FC } from 'react'
import { faTriangleExclamation } from '@fortawesome/pro-duotone-svg-icons'

import Alert from 'src/components/01-atoms/Alert'
import Button from 'src/components/01-atoms/Button'
import CircleIcon from 'src/components/01-atoms/CircleIcon'
import InlineLink from 'src/components/01-atoms/InlineLink'
import Modal, { IModalProps } from 'src/components/02-molecules/Modal'
import LoadingBar from 'src/components/01-atoms/LoadingBar'

export interface ICancelPackageModalProps extends IModalProps {
  handleCancel?: () => void
  cancelInProgress?: boolean
  error?: string
}

const CancelPackageModal: FC<ICancelPackageModalProps> = ({
  handleCancel = () => {},
  cancelInProgress = false,
  error,
  ...modalProps
}) => {
  const { handleClose = () => {} } = modalProps

  return (
    <Modal
      contentClassName="overflow-y-visible sm:max-w-125 md:max-w-125 lg:max-w-125 pb-9 pt-12 px-8 sm:px-14"
      {...modalProps}
    >
      <div
        data-testid="cancel-package-modal"
        className="flex flex-col center gap-y-6 text-sm lg:w-full relative"
      >
        {cancelInProgress && (
          <>
            <div className="absolute inset-0 w-full h-full bg-white z-60" />
            <LoadingBar
              titleText="Cancelation in progress. Please don't close this window."
              current={99}
              total={100}
              className="absolute self-center w-2/3 z-100"
            />
          </>
        )}
        <div className="flex flex-col gap-2 items-center text-center">
          <CircleIcon size="large" color="red" icons={[ faTriangleExclamation ]} />
          <div className="text-gb-gray-900 text-lg font-semibold leading-tight">
            Are you sure you want to cancel this package?
          </div>
          <p>This action cannot be undone.</p>
        </div>
        {error && <Alert type="error">{error}</Alert>}
        <Button
          className="center py-3 w-full md:w-2/3"
          variant="alert"
          onClick={() => {
            handleCancel()
          }}
        >
          Yes, cancel this package
        </Button>
        <InlineLink className="text-center w-full md:w-10/12" onClick={() => handleClose()}>
          No, don&apos;t cancel
        </InlineLink>
      </div>
    </Modal>
  )
}

export default CancelPackageModal
