import { FC, ReactElement } from 'react'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popover, PopoverProps, PopoverTrigger } from '@radix-ui/react-popover'

import Button, { ButtonProps } from 'src/components/01-atoms/Button'
import NavDropdownContent, {
  INavDropdownContent,
} from 'src/components/02-molecules/NavDropdownContent'

export interface INavDropdown {
  /**
   * The text for the button that triggers the dropdown.
   */
  buttonText: string | ReactElement

  /**
   * Props to pass down to the Popover root component.
   */
  popoverProps?: PopoverProps

  /**
   * Props to pass down to the Button component.
   */
  buttonProps?: ButtonProps

  /**
   * Props to pass down to the NavDropdownContent component.
   */
  dropdownContentProps: INavDropdownContent
}

const NavDropdown: FC<INavDropdown> = ({
  buttonText,
  popoverProps = {},
  buttonProps = {},
  dropdownContentProps,
}) => (
  <Popover {...popoverProps}>
    <PopoverTrigger asChild>
      <Button {...buttonProps}>
        {buttonText} <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
      </Button>
    </PopoverTrigger>
    <NavDropdownContent {...dropdownContentProps} />
  </Popover>
)

export default NavDropdown
