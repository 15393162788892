import classNames from 'classnames'
import { throttle } from 'lodash'
import React, { FC, useMemo, useState } from 'react'
import AccountPackageSearch from 'src/components/02-molecules/AccountPackageSearch'
import useAppParams from 'src/utils/hooks/useAppParams'
import useSearchPackages from 'src/utils/hooks/useSearchPackages'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/pro-solid-svg-icons'
import { faTransporter4 } from '@fortawesome/pro-duotone-svg-icons'
import useSearchAccounts from 'src/utils/hooks/useSearchAccounts'

interface IMobileSearch {
  setMobileSearchOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const MobileSearch: FC<IMobileSearch> = ({ setMobileSearchOpen }) => {
  const { isAdmin, mdashAccountId, mdashAccountPermissions } = useAppParams()
  const { canQueryPurchaserInfo } = mdashAccountPermissions || {}
  const [ query, setQuery ] = useState( '' )
  const [ isGlobalSearch, setIsGlobalSearch ] = useState( false )
  const { data, fetching } = useSearchPackages({ query, isGlobalSearch })
  const { data: accountSearchResults } = useSearchAccounts({ query })
  const throttleQuery = useMemo(() => throttle(( v ) => setQuery( v ), 500 ), [])

  return (
    <div className="fixed top-0 left-0 h-screen w-screen bg-white z-100 pt-4">
      <div
        className={classNames(
          { 'bg-gb-electric-blue-600': !isAdmin, 'bg-gb-yellow-500': isAdmin },
          'absolute left-0 top-0 w-full h-18 '
        )}
      />
      <AccountPackageSearch
        packageSearchResults={data?.searchPackages.map(( x ) => ({
          packageId: x.id,
          orderId: x.orderId,
          purchaserName: x.purchaserName,
          recipientName: String( x.deliveryFullName ),
          companyName: x.deliveryCompany,
          shipDate: x.toShipOn,
          packageDetailLink: `${x.mdashAccountId}/package/${x.id}`,
          manifestLink: `${x.mdashAccountId}/manifest/${x.toShipOn}`,
          isOnDifferentAccount: mdashAccountId !== x.mdashAccountId,
          isCancelled: x.gbmcState === 'canceled',
        }))}
        accountSearchResults={accountSearchResults?.searchAccounts.map(( x ) => ({
          id: x.id,
          name: x.name,
        }))}
        isSearchingPackages={fetching}
        allowsGlobalSearch={isAdmin}
        canQueryPurchaserInfo={!!canQueryPurchaserInfo}
        onChange={( x ) => throttleQuery( x )}
        onScopeChange={( x ) => setIsGlobalSearch( x )}
      />
      <button
        type="button"
        className="bg-white rounded-full w-8 h-8 absolute right-4 top-5 text-gb-gray-800 hover:opacity-90"
        onClick={() => setMobileSearchOpen( false )}
      >
        <FontAwesomeIcon icon={faXmark} />
      </button>

      {!fetching && (
        <div className="text-center flex flex-col mt-10 items-center justify-center">
          <div className="w-12 h-12 text-4xl bg-purple-50 text-purple-400 rounded-full flex items-center justify-center">
            <FontAwesomeIcon icon={faTransporter4} />
          </div>
          <div className="text-gb-gray-800 text-sm mt-2">
            Get started by searching for an order.
          </div>
        </div>
      )}
    </div>
  )
}

export default MobileSearch
