import { FC } from 'react'
import { ReactComponent as ShipToCanada } from 'src/assets/icons/flags/canada.svg'
import Tag, { Color } from '../Tag'

export enum PackageType {
  SUBSCRIPTION = 'Subscription',
  CORPORATE = 'Corporate',
  IN_STORE = 'In-Store',
  WHITE_LABEL = 'White Label',
  RESHIPMENT = 'Re-Shipment',
  GIFT = 'Gift',
  CANCELED = 'Canceled',
  GENERIC_WHITE_ON_BLACK = 'Generic White on Black',
  GENERIC_LIGHT_GRAY = 'Generic Light Gray',
  SHIP_TO_CANADA = 'Canada',
}

interface IPackageTagProps {
  /**
   * The type of package.
   */
  packageType: PackageType

  /**
   * Whether the tag should be displayed in grayscale.
   */
  isGrayscale?: boolean

  /**
   * The label to display on the tag.
   */
  label?: string
}

const PackageTag: FC<IPackageTagProps> = ({ packageType, isGrayscale, label }) => {
  const packageTagMap = {
    [PackageType.SUBSCRIPTION]: { color: Color.EMERALD, outline: false, testId: 'subscription' },
    [PackageType.CORPORATE]: { color: Color.VIOLET, outline: false, testId: 'corporate' },
    [PackageType.IN_STORE]: { color: Color.SLATE, outline: false, testId: 'in-store' },
    [PackageType.WHITE_LABEL]: { color: Color.ORANGE, outline: false, testId: 'white-label' },
    [PackageType.RESHIPMENT]: { color: Color.PINK, outline: true, testId: 'reshipment' },
    [PackageType.CANCELED]: { color: Color.RED, outline: false, testId: 'canceled' },
    [PackageType.GIFT]: { color: Color.INDIGO, outline: false, testId: 'gift' },
    [PackageType.GENERIC_WHITE_ON_BLACK]: {
      color: Color.BLACK,
      outline: false,
      testId: 'generic-white-on-black',
    },
    [PackageType.GENERIC_LIGHT_GRAY]: {
      color: Color.LIGHT_GRAY,
      outline: false,
      testId: 'generic-light-gray',
    },
    [PackageType.SHIP_TO_CANADA]: {
      color: Color.DARK_GRAY,
      outline: true,
      testId: 'ship-to-canada',
    },
  }

  return (
    <Tag
      color={isGrayscale ? Color.BLACK : packageTagMap[packageType].color}
      outline={isGrayscale ? true : packageTagMap[packageType].outline}
      className="flex items-center gap-1 py-0.5 px-1 leading-tight text-2xs rounded-sm font-normal"
      data-testid={packageTagMap[packageType].testId}
    >
      {packageType === PackageType.SHIP_TO_CANADA && (
        <ShipToCanada className="w-4 h-4 -my-2 ml-0.5" />
      )}
      {label || packageType}
    </Tag>
  )
}

export default PackageTag
