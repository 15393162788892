import { PackageListPackage } from 'src/utils/types/PackageListPackage'

// In multi-facility environment, we do not generate label until the package
// is assigned to a facility. Pass `isAbstractFacility` a truthy value
// to override currentShippingLabel existance constraint
export const packageIsSelectable = (
  v: PackageListPackage,
  isAbstractFacility: boolean = false,
  canAssignLocation: boolean = false
): boolean =>
  canAssignLocation && isAbstractFacility
    ? v.gbmcState !== 'canceled'
    : !( v.gbmcState === 'canceled' || v.labelNeedsReprint ) &&
      ( canAssignLocation || !!v.currentShippingLabel )
